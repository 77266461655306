import { Button, Switch, Select, Space, InputNumber, Input } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as PriceDataAction from '../action/PriceDataAction';
import * as SettingAction from '../action/SettingAction';
import ProfitHelper from '../common/ProfitHelper';
import { SketchPicker } from 'react-color';
import _ from 'lodash';
import { themes, getCustomTheme, getOpColor } from '../common/Theme';

const { Option } = Select;

class ThemeSetting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showPrimaryPicker: false,
        };
    }

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps) { }

    colorUpdated = (color) => {
        console.log(color);
        this.props.SettingAction.setAntdColor(color.hex);
    }

    togglePrimaryPicker = () => {
        this.setState({
            showPrimaryPicker: !this.state.showPrimaryPicker,
        })
    }

    setAntdTheme = (checked) => {
        let newTheme = 0;
        if (checked) {
            newTheme = 1;
        }
        this.props.SettingAction.setAntdTheme(newTheme);
    }

    render() {
        let customTheme = getCustomTheme();
        return (
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px', marginTop: '10px' }}>
                <div>
                    <div style={{ marginTop: '15px', width: '100px' }}>
                        主题颜色
                    </div>
                    <div style={{ marginTop: '5px' }}>
                        <Button onClick={this.togglePrimaryPicker} type='primary'>
                            {this.props.SettingReducer.antdColor}
                        </Button>
                        <div style={{ position: 'absolute', display: this.state.showPrimaryPicker ? '' : 'none' }}>
                            <SketchPicker color={this.props.SettingReducer.antdColor} onChangeComplete={(color) => this.colorUpdated(color)} />
                        </div>
                    </div>
                </div>
                <div>
                    <div style={{ paddingTop: '15px', width: '100px' }}>
                        夜晚模式
                    </div>
                    <div style={{ marginTop: '5px' }}>
                        <Switch checkedChildren="夜晚" unCheckedChildren="白天" checked={this.props.SettingReducer.antdTheme === 1}
                            onChange={(checked) => this.setAntdTheme(checked)} />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        SettingReducer: state.SettingReducer,
        PriceDataReducer: state.PriceDataReducer
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        PriceDataAction: bindActionCreators(PriceDataAction, dispatch),
        SettingAction: bindActionCreators(SettingAction, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ThemeSetting);
