import * as ActionTypes from "../action/ActionTypes";
import _ from 'lodash';
import * as T3Utils from '../common/T3Utils';
import update from "immutability-helper";

export const initialState = {
    materialEffect: 10,
    marketFee: '0',
    galaxyManuCost: '0',
    resourcePriceVendor: 0,
    productPriceVendor: 0,
    resourcePriceSetting: {
        useBuyPrice: false,
    },
    productPriceSetting: {
        useBuyPrice: false,
    },
    priceGalaxy: [{
        server: 'default',
        //jita
        galaxyResource: [30000142],
        galaxyProduct: [30000142]
    }, {
        server: 'infinity',
        galaxyResource: [30001363],
        galaxyProduct: [30001363]
    }, {
        server: 'infinityPriceEUBlueprint',
        galaxyResource: [30001363],
        galaxyProduct: [30001363]
        // galaxyProduct: [30001363, 30003488]
    }],
    mineralRefinePercent: 0.906,
    equipRefinePercent: 0.55,
    inventAddition: 1,
    archType: 1,
    archTier: 1,
    archSecurity: 1,
    reactArchType: 1,
    reactArchTier: 1,
    reactArchSecurity: 1,
    manuSystemId: '',
    reactSystemId: '',
    customManuIndex: 0,
    customReactIndex: 0,
    tabInfoList: [{ key: "0", blueprintTypeId: 0 }],
    activeTabKey: "0",
    considerBlueprintCopyCost: true,
    extractMaterials: true,
    favourites: [],
    customsFee: 0,
    server: 'serenity',
    language: 'chinese',
    antdTheme: 0,
    antdColor: '1453FF',
    customPrices: [],
    useCustomPrice: false,
    resourceOff: 100,
    productOff: 100,
    alwaysBuyBids: [],
    alwaysMakeBids: [],
    extract: [],
    transportFee: 0,
    transportUnit: 0,
    transportCeil: true,
    selfProductCategories: ['locked'],
    settingName: '未命名',
    settingId: '',
    settingVersion: 'initial',
    refineTaxRate: 0,
    defaultDecoderId: 0,
    depository: [],
    selfProductThreshold: 1,
};

export default (state = initialState, action) => {
    // let settingVersion = new Date().toLocaleString().replaceAll('/', '-').replaceAll(' ', '-');
    // state = update(state, { settingVersion: { $set: settingVersion } });
    switch (action.type) {
        case ActionTypes.SET_MATERIAL_EFFECT_SUCCESS:
            return update(state, { materialEffect: { $set: action.data } });
        case ActionTypes.SET_MARKET_FEE_SUCCESS:
            return update(state, { marketFee: { $set: action.data } });
        case ActionTypes.SET_GALAXY_MANU_COST_SUCCESS:
            return update(state, { galaxyManuCost: { $set: action.data } });
        case ActionTypes.SET_RESOURCE_PRICE_SETTING_SUCCESS:
            return update(state, { resourcePriceSetting: { $set: action.data } });
        case ActionTypes.SET_PRODUCT_PRICE_SETTING_SUCCESS:
            return update(state, { productPriceSetting: { $set: action.data } });
        case ActionTypes.SET_MINERAL_REFINE_PERCENT_SUCCESS:
            return update(state, { mineralRefinePercent: { $set: action.data } });
        case ActionTypes.SET_EQUIP_REFINE_PERCENT_SUCCESS:
            return update(state, { equipRefinePercent: { $set: action.data } });
        case ActionTypes.SET_ARCH_TIER_SUCCESS:
            return update(state, { archTier: { $set: action.data } });
        case ActionTypes.SET_ARCH_SECURITY_SUCCESS:
            return update(state, { archSecurity: { $set: action.data } });
        case ActionTypes.SET_ARCH_TYPE_SUCCESS:
            return update(state, { archType: { $set: action.data } });
        case ActionTypes.SET_REACT_ARCH_TIER_SUCCESS:
            return update(state, { reactArchTier: { $set: action.data } });
        case ActionTypes.SET_REACT_ARCH_SECURITY_SUCCESS:
            return update(state, { reactArchSecurity: { $set: action.data } });
        case ActionTypes.SET_REACT_ARCH_TYPE_SUCCESS:
            return update(state, { reactArchType: { $set: action.data } });
        case ActionTypes.SET_MANU_SYSTEM_ID_SUCCESS:
            return update(state, { manuSystemId: { $set: action.data } });
        case ActionTypes.SET_REACT_SYSTEM_ID_SUCCESS:
            return update(state, { reactSystemId: { $set: action.data } });
        case ActionTypes.SET_TAB_INFO_LIST_SUCCESS:
            return update(state, { tabInfoList: { $set: action.data } });
        case ActionTypes.SET_ACTIVE_TAB_KEY_SUCCESS:
            return update(state, { activeTabKey: { $set: action.data } });
        case ActionTypes.SHOW_NEW_TYPE:
            {
                let newTabInfoList = [];
                let newActiveTabKey = state.activeTabKey;
                let blueprintTypeId = action.data.blueprintTypeId;
                if (_.isEmpty(state.tabInfoList)) {
                    newTabInfoList.push({ key: "0", blueprintTypeId: action.data });
                    newActiveTabKey = "0";
                } else {
                    newTabInfoList = state.tabInfoList.map(info => {
                        if (info.key === state.activeTabKey) {
                            let quantity = 1;
                            let materialEffect = state.materialEffect;
                            let defaultDecoderId = state.defaultDecoderId;
                            let ancientId = null;

                            if (!_.isEmpty(action.data.inventBaseBlueprint)) {
                                materialEffect = 2;
                                let product = _.find(action.data.inventBaseBlueprint.activities.invention.products, p => p.typeId === blueprintTypeId);
                                if (defaultDecoderId < 0) {
                                    //免费发明
                                    quantity = 1;
                                } else if (defaultDecoderId == 0) {
                                    quantity = product.quantity;
                                } else {
                                    let decoder = action.data.defaultDecoder;
                                    quantity = product.quantity + decoder.quantity;
                                    materialEffect += decoder.materialEffect;
                                }
                            } else if (T3Utils.isT3Blueprint(blueprintTypeId)) {
                                let t3Setting = T3Utils.generateT3QuantityAndMaterialEffect(blueprintTypeId, null, action.data.defaultDecoder);
                                quantity = t3Setting.quantity;
                                materialEffect = t3Setting.materialEffect;
                                ancientId = _.find(T3Utils.getT3List(), a => a.products.includes(blueprintTypeId)).ancientId;
                            } else {
                                if (action.data.blueprint.blueprintType !== 'NORMAL') {
                                    materialEffect = 0;
                                } else if (action.data.blueprintTypeIdSde.marketGroupId === 0) {
                                    materialEffect = 0;
                                }
                            }

                            if (info.blueprintTypeId instanceof Array) {
                                //multi blueprint view
                                if (_.find(info.blueprintTypeId, b => b.blueprintTypeId === blueprintTypeId)) {
                                    return info;
                                } else {
                                    info.blueprintTypeId = [...info.blueprintTypeId, { blueprintTypeId, blueprintCount: 1, productQuantity: quantity, decoderId: defaultDecoderId, materialEffect, ancientId }]
                                    return info;
                                }
                            } else {
                                return { key: info.key, blueprintSetting: { blueprintTypeId, blueprintCount: 1, productQuantity: quantity, decoderId: defaultDecoderId, materialEffect, ancientId } };
                            }
                        } else {
                            return info;
                        }
                    })
                }
                return update(state, { tabInfoList: { $set: newTabInfoList }, activeTabKey: { $set: newActiveTabKey } });
            }
        case ActionTypes.TOGGLE_CONSIDER_BLUEPRINT_COPY_COST:
            return update(state, { considerBlueprintCopyCost: { $set: state.considerBlueprintCopyCost == null ? false : !state.considerBlueprintCopyCost } })
        case ActionTypes.TOGGLE_EXTRACT_MATERIALS:
            return update(state, { extractMaterials: { $set: state.extractMaterials == null ? false : !state.extractMaterials } })
        case ActionTypes.TOGGLE_FAVOURITE:
            let prevFavourites = state.favourites == null ? [] : state.favourites;
            if (_.find(prevFavourites, f => f === action.data) == null) {
                return update(state, { favourites: { $set: [...prevFavourites, action.data] } });
            } else {
                return update(state, { favourites: { $set: prevFavourites.filter(f => f !== action.data) } });
            }
        case ActionTypes.IMPORT_SETTINGS:
            return action.data;
        case ActionTypes.SET_CUSTOMS_FEE:
            return update(state, { customsFee: { $set: action.data } });
        case ActionTypes.SET_CUSTOM_MANU_INDEX:
            return update(state, { customManuIndex: { $set: action.data } });
        case ActionTypes.SET_CUSTOM_REACT_INDEX:
            return update(state, { customReactIndex: { $set: action.data } });
        case ActionTypes.SET_SERVER:
            return update(state, { server: { $set: action.data } });
        case ActionTypes.SET_LANGUAGE:
            return update(state, { language: { $set: action.data } });
        case ActionTypes.SET_CUSTOM_PRICE:
            {
                let typeId = action.data.typeId;
                let price = action.data.price;
                let newPrices = _.filter(state.customPrices, f => f.typeId !== typeId);
                return update(state, { customPrices: { $set: [{ typeId, price }, ...newPrices] } });
            }
        case ActionTypes.REMOVE_CUSTOM_PRICE:
            {
                let typeId = action.data;
                let newPrices = _.filter(state.customPrices, f => f.typeId !== typeId);
                return update(state, { customPrices: { $set: newPrices } });
            }
        case ActionTypes.SET_USE_CUSTOM_PRICE:
            return update(state, { useCustomPrice: { $set: action.data } });
        case ActionTypes.SET_RESOURCE_OFF:
            return update(state, { resourceOff: { $set: action.data } });
        case ActionTypes.SET_PRODUCT_OFF:
            return update(state, { productOff: { $set: action.data } });
        case ActionTypes.SET_ALWAYS_BUY_BID:
            let setBuyBids = _.uniq([...state.alwaysBuyBids, action.data]);
            return update(state, { alwaysBuyBids: { $set: setBuyBids } });
        case ActionTypes.REMOVE_ALWAYS_BUY_BID:
            let removeBuyBids = _.uniq(_.without(state.alwaysBuyBids, action.data));
            return update(state, { alwaysBuyBids: { $set: removeBuyBids } });
        case ActionTypes.SET_ALWAYS_MAKE_BID:
            let setMakeBids = _.uniq([...state.alwaysMakeBids, action.data]);
            return update(state, { alwaysMakeBids: { $set: setMakeBids } });
        case ActionTypes.REMOVE_ALWAYS_MAKE_BID:
            let removeMakeBids = _.uniq(_.without(state.alwaysMakeBids, action.data));
            return update(state, { alwaysMakeBids: { $set: removeMakeBids } });
        case ActionTypes.SET_EXTRACT:
            {
                let typeId = action.data.typeId;
                let extract = _.uniq(action.data.extract);
                let newExtract = _.filter(state.extract, f => f.typeId !== typeId);
                return update(state, { extract: { $set: [{ typeId, extract }, ...newExtract] } });
            }
        case ActionTypes.SET_TRANSPORT_FEE:
            return update(state, { transportFee: { $set: action.data } });
        case ActionTypes.SET_TRANSPORT_UNIT:
            return update(state, { transportUnit: { $set: action.data } });
        case ActionTypes.SET_TRANSPORT_CEIL:
            return update(state, { transportCeil: { $set: action.data } });
        case ActionTypes.SET_RESOURCE_GALAXY_SUCCESS:
            {
                let newPriceGalaxy = _.cloneDeep(state.priceGalaxy);
                let serverGalaxy = _.find(newPriceGalaxy, g => g.server === state.server);
                if (serverGalaxy != null) {
                    serverGalaxy.galaxyResource = action.data;
                } else {
                    serverGalaxy = {
                        server: state.server,
                        galaxyResource: action.data,
                        galaxyProduct: [30001363, 30003488]
                    }
                    newPriceGalaxy.push(serverGalaxy);
                }
                return update(state, { priceGalaxy: { $set: newPriceGalaxy } });
            }
        case ActionTypes.SET_PRODUCT_GALAXY_SUCCESS:
            {
                let newPriceGalaxy = _.cloneDeep(state.priceGalaxy);
                let serverGalaxy = _.find(newPriceGalaxy, g => g.server === state.server);
                if (serverGalaxy != null) {
                    serverGalaxy.galaxyProduct = action.data;
                } else {
                    serverGalaxy = {
                        server: state.server,
                        galaxyResource: [30001363, 30003488],
                        galaxyProduct: action.data
                    }
                    newPriceGalaxy.push(serverGalaxy);
                }
                return update(state, { priceGalaxy: { $set: newPriceGalaxy } });
            }
        case ActionTypes.SET_ANTD_COLOR:
            return update(state, { antdColor: { $set: action.data } });
        case ActionTypes.SET_RESOURCE_PRICE_VENDOR:
            return update(state, { resourcePriceVendor: { $set: action.data } });
        case ActionTypes.SET_PRODUCT_PRICE_VENDOR:
            return update(state, { productPriceVendor: { $set: action.data } });
        case ActionTypes.SET_ANTD_THEME:
            return update(state, { antdTheme: { $set: action.data } });
        case ActionTypes.SET_SELF_PRODUCTED_CATEGORIES:
            {
                let categories = [...action.data];
                if (_.find(categories, c => c === 'locked') == null) {
                    categories = [...categories, 'locked'];
                }
                return update(state, { selfProductCategories: { $set: categories } });
            }
        case ActionTypes.SET_SETTING_NAME:
            return update(state, { settingName: { $set: action.data } });
        case ActionTypes.SET_SETTING_ID:
            return update(state, { settingId: { $set: action.data } });
        case ActionTypes.SET_BLUEPRINT_SETTING:
            {
                for (let tabInfo of state.tabInfoList) {
                    if (tabInfo.key === state.activeTabKey) {
                        if (tabInfo.blueprintTypeId instanceof Array) {
                            tabInfo.blueprintTypeId = tabInfo.blueprintTypeId.map(b => {
                                if (b.blueprintTypeId === action.data.blueprintTypeId) {
                                    return action.data;
                                } else {
                                    return b;
                                }
                            })
                        } else {
                            tabInfo.blueprintSetting = action.data;
                        }
                    }
                }
                let newTabInfoList = [...state.tabInfoList];
                return update(state, { tabInfoList: { $set: newTabInfoList } });
            }
        case ActionTypes.REMOVE_BLUEPRINT_SETTING:
            {
                for (let tabInfo of state.tabInfoList) {
                    if (tabInfo.key === state.activeTabKey) {
                        tabInfo.blueprintTypeId = tabInfo.blueprintTypeId.filter(b => b.blueprintTypeId !== action.data);
                    }
                }
                let newTabInfoList = [...state.tabInfoList];
                return update(state, { tabInfoList: { $set: newTabInfoList } });
            }
        case ActionTypes.SET_REFINE_TAX_RATE:
            return update(state, { refineTaxRate: { $set: action.data } });
        case ActionTypes.SET_DEFAULT_DECODER_ID:
            return update(state, { defaultDecoderId: { $set: action.data } });
        case ActionTypes.SET_DEPOSITORY:
            return update(state, { depository: { $set: action.data } });
        case ActionTypes.SET_SELF_PRODUCT_THRESHOLD:
            return update(state, { selfProductThreshold: { $set: action.data } });
        case ActionTypes.SET_INVENT_ADDITION:
            return update(state, { inventAddition: { $set: action.data } });
        case ActionTypes.SET_PLAN_STATUS:
            {
                for (let tabInfo of state.tabInfoList) {
                    if (tabInfo.key === state.activeTabKey) {
                        if (_.isEmpty(tabInfo.plans)) {
                            tabInfo.plans = [];
                        }
                        let foundExistingPlan = false;
                        for (let plan of tabInfo.plans) {
                            if (plan.key === action.data.key && plan.stepIndex === action.data.stepIndex) {
                                plan.status = action.data.status;
                                foundExistingPlan = true;
                                break;
                            }
                        }
                        if (!foundExistingPlan) {
                            tabInfo.plans = [action.data, ...tabInfo.plans];
                        }
                    }
                }
                let newTabInfoList = [...state.tabInfoList];
                return update(state, { tabInfoList: { $set: newTabInfoList } });
            }
        case ActionTypes.REMOVE_PLAN_STATUS:
            {
                for (let tabInfo of state.tabInfoList) {
                    if (tabInfo.key === state.activeTabKey) {
                        if (_.isEmpty(tabInfo.plans)) {
                            tabInfo.plans = [];
                        }
                        tabInfo.plans = tabInfo.plans.filter(p => !(p.key === action.data.key && p.stepIndex === action.data.stepIndex));
                    }
                }
                let newTabInfoList = [...state.tabInfoList];
                return update(state, { tabInfoList: { $set: newTabInfoList } });
            }
        case ActionTypes.RESET_PLAN_STATUS:
            {
                for (let tabInfo of state.tabInfoList) {
                    if (tabInfo.key === state.activeTabKey) {
                        tabInfo.plans = [];
                    }
                }
                let newTabInfoList = [...state.tabInfoList];
                return update(state, { tabInfoList: { $set: newTabInfoList } });
            }
        case ActionTypes.SET_VIEW_MODE:
            {
                for (let tabInfo of state.tabInfoList) {
                    if (tabInfo.key === state.activeTabKey) {
                        tabInfo.viewMode = action.data;
                    }
                }
                let newTabInfoList = [...state.tabInfoList];
                return update(state, { tabInfoList: { $set: newTabInfoList } });
            }
        case ActionTypes.SET_EXPANDED_ROWKEYS:
            {
                for (let tabInfo of state.tabInfoList) {
                    if (tabInfo.key === state.activeTabKey) {
                        tabInfo.expandedRowKeys = action.data;
                    }
                }
                let newTabInfoList = [...state.tabInfoList];
                return update(state, { tabInfoList: { $set: newTabInfoList } });
            }
        default:
            return state;
    }
};
