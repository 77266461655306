import * as PriceDataAction from '../action/PriceDataAction';
import * as ItemInfoAction from '../action/ItemInfoAction';
import { bindActionCreators } from 'redux';
import store from '../store';
import _ from 'lodash';

class Initializer {
    constructor() {

    }

    initPriceData() {
        if (_.isEmpty(store.getState().PriceDataReducer.dealPriceList)) {
            store.dispatch(PriceDataAction.getDealPriceList());
        }
        if (_.isEmpty(store.getState().ItemInfoReducer.allDecoders)) {
            store.dispatch(ItemInfoAction.getAllDecoders());
        }
        if (_.isEmpty(store.getState().PriceDataReducer.allMarketDump)) {
            store.dispatch(PriceDataAction.getAllMarketDump());
        }
        if (_.isEmpty(store.getState().PriceDataReducer.allTypeIdSde)) {
            store.dispatch(PriceDataAction.getAllTypeIdSde());
        }
        if (_.isEmpty(store.getState().PriceDataReducer.industryIndices)) {
            store.dispatch(PriceDataAction.getIndustryIndices());
        }
        if (_.isEmpty(store.getState().PriceDataReducer.allMarketHistory)) {
            store.dispatch(PriceDataAction.getAllMarketHistory());
        }
        if (_.isEmpty(store.getState().PriceDataReducer.allBlueprint)) {
            store.dispatch(PriceDataAction.getAllBlueprints());
        }
        if (_.isEmpty(store.getState().PriceDataReducer.allTypeMaterials)) {
            store.dispatch(PriceDataAction.getAllTypeMaterials());
        }
        if (_.isEmpty(store.getState().PriceDataReducer.estimatedPriceList)) {
            store.dispatch(PriceDataAction.getEstimatedPriceList());
        }
    }
}

const instance = new Initializer();

export default instance;