import React from "react";
import { Layout, Modal, Input, Divider, Button } from "antd";
import { LineChartOutlined } from "@ant-design/icons";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Spreadsheet from "x-data-spreadsheet";
import zhCN from 'x-data-spreadsheet/src/locale/zh-cn';
import * as ExcelUtil from '../common/ExcelUtil';
import _ from 'lodash';
import * as SettingAction from '../action/SettingAction';

class CustomPriceView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            spreadSheetLoaded: false
        };
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.show === false && this.props.show === true && this.state.spreadSheetLoaded === false) {
            this.showSpreadSheet();
            // this.setState({ spreadSheetLoaded: true });
        }
    }

    onOK = () => {
        this.props.onCancel();
    };

    onCancel = () => {
        this.props.onCancel();
    };

    showSpreadSheet = () => {
        const parent = document.getElementById("x-spreadsheet-customPrice");
        while (parent.firstChild) {
            parent.firstChild.remove();
        }
        const option = {
            mode: 'edit', // edit | read
            showToolbar: true, // 顶部工具栏
            showGrid: true,
            showContextmenu: true, // 切换右键菜单显示状态
            showBottomBar: true,  // 底部工具栏
            view: {
                // height: () => 570,
                height: () => document.documentElement.clientHeight * 0.9,
                width: () => document.documentElement.clientWidth * 0.9,
                // width: () => document.getElementsByClassName('spreadsheet-box')[0].clientWidth - 300,
            },
            row: {
                len: 3500,
                height: 25,
            },
            col: {
                len: 26,
                width: 100,
                indexWidth: 60,
                minWidth: 60,
            },
            style: {
                bgcolor: '#ffffff',
                align: 'left',
                valign: 'middle',
                textwrap: false,
                strike: false,
                underline: false,
                color: '#0a0a0a',
                font: {
                    name: 'Helvetica',
                    size: 10,
                    bold: false,
                    italic: false,
                },
            },
        }

        let excel = ExcelUtil.getCustomPriceExcel();
        Spreadsheet.locale('zh-cn', zhCN);
        this.spreadSheet = new Spreadsheet("#x-spreadsheet-customPrice", option)
            .loadData(ExcelUtil.stox(excel));
        this.spreadSheet.on('cell-selected', (cell, ri, ci) => {
            if (ci !== 3) {
                cell.editable = false;
            }
        });
        this.spreadSheet.on('cell-edited', (text, ri, ci) => {
            if (ci === 3) {
                let typeId = parseInt(this.spreadSheet.cell(ri, 0).text);
                if (_.isEmpty(text)) {
                    this.props.SettingAction.removeCustomPrice(typeId);
                } else if (isNaN(text)) {
                    console.log(`${text} 不是数字。`)
                } else {
                    let price = parseFloat(text);
                    this.props.SettingAction.setCustomPrice(typeId, price);
                }
            }
            console.log('cell-edited' + text);
        });
        // data validation
        this.spreadSheet.validate();
    }

    render() {
        return (
            <div>
                <Modal title="自定义价格" width={'95%'} height={'90%'} style={{ top: '2%' }} wrapClassName='custom-price-view'
                    visible={this.props.show} onOk={this.onOK} onCancel={this.onCancel} okText={'好'} cancelText={'关闭'}>
                    <div style={{ display: 'flex' }}>
                        <div id="x-spreadsheet-customPrice"></div>
                    </div>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        PriceDataReducer: state.PriceDataReducer,
        SettingReducer: state.SettingReducer,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        SettingAction: bindActionCreators(SettingAction, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomPriceView);