import React from "react";
import { Layout, Modal, Input, Divider, Button } from "antd";
import { LineChartOutlined } from "@ant-design/icons";
import tesila from "../resource/tesila.PNG";
import qqqun from "../resource/qqqun.png";
import zs from "../resource/zs.png";

export default class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() { }

  onOK = () => {
    this.props.onCancel();
  };

  onCancel = () => {
    this.props.onCancel();
  };

  render() {
    return (
      <div>
        <Modal title="关于" visible={this.props.show} onOk={this.onOK} onCancel={this.onCancel} okText={'好'} cancelText={'关闭'}>
          <div style={{ display: "flex", flexDirection: 'column' }}>
            <div style={{ display: "flex" }}>
              <div style={{ width: "80%" }}>
                <div>
                  <span
                    style={{
                      fontSize: "3em",
                    }}
                  >
                    EVE蓝图计算器
                </span>
                </div>
                <div style={{ marginRight: '20px' }}>
                  如果在使用中遇到任何问题，请在游戏中联系<span style={{ fontWeight: "bold", color: "orange" }}>特斯拉与你</span>或扫下面二维码加讨论群:439584671
                </div>
              </div>
              <div style={{ width: "20%" }}>
                <img src={tesila} style={{ width: "100%" }} />
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img src={qqqun} style={{ width: "300px", marginTop: '20px' }} />
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
