import React from "react";
import { Layout, Modal, Input, Divider, Button } from "antd";
import { LineChartOutlined } from "@ant-design/icons";
import tesila from "../resource/tesila.PNG";
import zs from "../resource/zs.png";

export default class SelectProfitCalcType extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() { }

    onOK = () => {
        this.props.onCancel();
    };

    onCancel = () => {
        this.props.onCancel();
    };

    onSingleBlueprint = () => {
        this.props.onSingleBlueprint();
    }

    onMultiBlueprint = () => {
        this.props.onMultiBlueprint();
    }

    render() {
        return (
            <div>
                <Modal title="选择蓝图计算模式" visible={this.props.show} footer={null}
                    onOk={this.onOK} onCancel={this.onCancel} okText={'好'} cancelText={'关闭'}>
                    <div style={{ display: "flex", fontSize: 'large', justifyContent: 'space-evenly', margin: '50px 0px' }}>
                        <div><Button type="primary" value="large" onClick={() => this.onSingleBlueprint()}>一种产品</Button></div>
                        <div><Button type="primary" value="large" onClick={() => this.onMultiBlueprint()}>多种产品</Button></div>
                    </div>
                </Modal>
            </div>
        );
    }
}
