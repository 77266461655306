import * as Utils from "../common/Utils";
import * as UrlHelper from '../action/UrlHelper';

const host = UrlHelper.getHost();
const baseUrl = `http://${host}:8080`;

export default class QQUserApi {
  static login(qquser) {
    return Utils.postRequest(`${baseUrl}/qquser/login`, qquser).then(resp => resp.text());
  }

  static getQQUser(openId) {
    return Utils.getRequest(`${baseUrl}/qquser/getUserByOpenId/${openId}`).then(resp => resp.json());
  }

  static isValidName(name) {
    return Utils.getRequest(`${baseUrl}/qquser/isValidName/${name}`).then(resp => resp.text());
  }

  static setUserName(openId, name) {
    return Utils.getRequest(`${baseUrl}/qquser/setUserName/${openId}/${name}`);
  }

  static saveUserSetting(userSetting) {
    return Utils.postRequest(`${baseUrl}/qquser/saveUserSetting`, userSetting);
  }

  static listUserSetting(openId) {
    return Utils.getRequest(`${baseUrl}/qquser/listUserSetting/${openId}`).then(resp => resp.json());
  }

  static getUserSetting(settingId) {
    return Utils.getRequest(`${baseUrl}/qquser/getUserSetting/${settingId}`).then(resp => resp.json());
  }

  static removeUserSetting(settingId) {
    return Utils.getRequest(`${baseUrl}/qquser/removeUserSetting/${settingId}`);
  }
}