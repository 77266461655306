export const GET_ALL_BLUEPRINTS = 'GET_ALL_BLUEPRINTS';
export const GET_ALL_BLUEPRINTS_SUCCESS = 'GET_ALL_BLUEPRINTS_SUCCESS';
export const GET_ALL_TYPEMATERIALS_SUCCESS = 'GET_ALL_TYPEMATERIALS_SUCCESS';
export const GET_ALL_MARKET_DUMP = 'GET_ALL_MARKET_DUMP';
export const GET_ALL_MARKET_DUMP_SUCCESS = 'GET_ALL_MARKET_DUMP_SUCCESS';
export const GET_DEAL_PRICE_LIST_SUCCESS = 'GET_DEAL_PRICE_LIST_SUCCESS';
export const GET_ALL_MARKET_HISTORY_SUCCESS = 'GET_ALL_MARKET_HISTORY_SUCCESS';
export const GET_INDUSTTRY_INDICES_SUCCESS = 'GET_INDUSTTRY_INDICES_SUCCESS';
export const CREATE_MESSAGE_SUCCESS = 'CREATE_MESSAGE_SUCCESS';
export const GET_MESSAGE_SUCCESS = 'GET_MESSAGE_SUCCESS';
export const GET_MESSAGE_COUNT_SUCCESS = 'GET_MESSAGE_COUNT_SUCCESS';
export const GET_IP_SUCCESS = 'GET_IP_SUCCESS';
export const REMOVE_MESSAGE_SUCCESS = 'REMOVE_MESSAGE_SUCCESS';
export const SET_MATERIAL_EFFECT_SUCCESS = 'SET_MATERIAL_EFFECT_SUCCESS';
export const SET_MARKET_FEE_SUCCESS = 'SET_MARKET_FEE_SUCCESS';
export const SET_GALAXY_MANU_COST_SUCCESS = 'SET_GALAXY_MANU_COST_SUCCESS';
export const GET_TYPEID_SDE_SUCCESS = 'GET_TYPEID_SDE_SUCCESS';
export const GET_ICON_SDE_SUCCESS = 'GET_ICON_SDE_SUCCESS';
export const GET_ALL_DECODERS_SUCCESS = 'GET_ALL_DECODERS_SUCCESS';
export const UPSERT_BLUEPRINT_CONFIG_SUCCESS = 'UPSERT_BLUEPRINT_CONFIG_SUCCESS';
export const REMOVE_BLUEPRINT_CONFIG_SUCCESS = 'REMOVE_BLUEPRINT_CONFIG_SUCCESS';
export const GET_BLUEPRINT_BUILD_SUCCESS = 'GET_BLUEPRINT_BUILD_SUCCESS';
export const UPSERT_BLUEPRINT_BUILD_SUCCESS = 'UPSERT_BLUEPRINT_BUILD_SUCCESS';
export const GET_ALL_TYPEID_SDE = 'GET_ALL_TYPEID_SDE';
export const GET_ALL_TYPEID_SDE_SUCCESS = 'GET_ALL_TYPEID_SDE_SUCCESS';
export const SET_PRE_REFINE_ITEMS_SUCCESS = 'SET_PRE_REFINE_ITEMS_SUCCESS';
export const SET_MINERAL_REFINE_PERCENT_SUCCESS = 'SET_MINERAL_REFINE_PERCENT_SUCCESS';
export const SET_EQUIP_REFINE_PERCENT_SUCCESS = 'SET_EQUIP_REFINE_PERCENT_SUCCESS';
export const SET_RESOURCE_PRICE_SETTING_SUCCESS = 'SET_RESOURCE_PRICE_SETTING_SUCCESS';
export const SET_RESOURCE_GALAXY_SUCCESS = 'SET_RESOURCE_GALAXY_SUCCESS';
export const SET_PRODUCT_GALAXY_SUCCESS = 'SET_PRODUCT_GALAXY_SUCCESS';
export const SET_PRODUCT_PRICE_SETTING_SUCCESS = 'SET_PRODUCT_PRICE_SETTING_SUCCESS';
export const SET_CURRENT_INDUSTRY_INDEX_SUCCESS = 'SET_CURRENT_INDUSTRY_INDEX_SUCCESS';
export const SET_ARCH_TIER_SUCCESS = 'SET_ARCH_TIER_SUCCESS';
export const SET_ARCH_TYPE_SUCCESS = 'SET_ARCH_TYPE_SUCCESS';
export const SET_ARCH_SECURITY_SUCCESS = 'SET_ARCH_SECURITY_SUCCESS';
export const SET_REACT_ARCH_TIER_SUCCESS = 'SET_REACT_ARCH_TIER_SUCCESS';
export const SET_REACT_ARCH_TYPE_SUCCESS = 'SET_REACT_ARCH_TYPE_SUCCESS';
export const SET_REACT_ARCH_SECURITY_SUCCESS = 'SET_REACT_ARCH_SECURITY_SUCCESS';
export const SET_MANU_SYSTEM_ID_SUCCESS = 'SET_MANU_SYSTEM_ID_SUCCESS';
export const SET_REACT_SYSTEM_ID_SUCCESS = 'SET_REACT_SYSTEM_ID_SUCCESS';
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
export const GET_PREVIOUS_MONTHS_MARKET_HISTORY_SUCCESS = 'GET_PREVIOUS_MONTHS_MARKET_HISTORY_SUCCESS';
export const SET_TAB_INFO_LIST_SUCCESS = 'SET_TAB_INFO_LIST_SUCCESS';
export const SET_ACTIVE_TAB_KEY_SUCCESS = 'SET_ACTIVE_TAB_KEY_SUCCESS';
export const SHOW_NEW_TYPE = 'SHOW_NEW_TYPE';
export const TOGGLE_CONSIDER_BLUEPRINT_COPY_COST = 'TOGGLE_CONSIDER_BLUEPRINT_COPY_COST';
export const TOGGLE_EXTRACT_MATERIALS = 'TOGGLE_EXTRACT_MATERIALS';
export const TOGGLE_FAVOURITE = 'TOGGLE_FAVOURITE';
export const IMPORT_SETTINGS = 'IMPORT_SETTINGS';
export const SET_CUSTOMS_FEE = 'SET_CUSTOMS_FEE';
export const SET_RANK_DATA = 'SET_RANK_DATA';
export const SET_RANK_CHECKED_KEYS = 'SET_RANK_CHECKED_KEYS';
export const SET_CUSTOM_MANU_INDEX = 'SET_CUSTOM_MANU_INDEX';
export const SET_CUSTOM_REACT_INDEX = 'SET_CUSTOM_REACT_INDEX';
export const SET_SERVER = 'SET_SERVER';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_SELECTED_MATERIAL_STATS = 'SET_SELECTED_MATERIAL_STATS';
export const GET_AUTH_CODE_SUCCESS = 'GET_AUTH_CODE_SUCCESS';
export const SET_EXTRACT = 'SET_EXTRACT';
export const SET_CUSTOM_PRICE = 'SET_CUSTOM_PRICE';
export const SET_ANTD_COLOR = 'SET_ANTD_COLOR';
export const REMOVE_CUSTOM_PRICE = 'REMOVE_CUSTOM_PRICE';
export const SET_USE_CUSTOM_PRICE = 'SET_USE_CUSTOM_PRICE';
export const GET_LAST_ORDER_DATE_SUCCESS = 'GET_LAST_ORDER_DATE_SUCCESS';
export const SET_RESOURCE_OFF = 'SET_RESOURCE_OFF';
export const SET_PRODUCT_OFF = 'SET_PRODUCT_OFF';
export const SET_ALWAYS_BUY_BID = 'SET_ALWAYS_BUY_BID';
export const REMOVE_ALWAYS_BUY_BID = 'REMOVE_ALWAYS_BUY_BID';
export const SET_ALWAYS_MAKE_BID = 'SET_ALWAYS_MAKE_BID';
export const REMOVE_ALWAYS_MAKE_BID = 'REMOVE_ALWAYS_MAKE_BID';
export const SET_TRANSPORT_FEE = 'SET_TRANSPORT_FEE';
export const SET_TRANSPORT_UNIT = 'SET_TRANSPORT_UNIT';
export const SET_TRANSPORT_CEIL = 'SET_TRANSPORT_CEIL';
export const SET_RESOURCE_PRICE_VENDOR = 'SET_RESOURCE_PRICE_VENDOR';
export const SET_PRODUCT_PRICE_VENDOR = 'SET_PRODUCT_PRICE_VENDOR';
export const SET_ANTD_THEME = 'SET_ANTD_THEME';
export const GET_QQUSER_BY_OPENID_SUCCESS = 'GET_QQUSER_BY_OPENID_SUCCESS';
export const SET_SELF_PRODUCTED_CATEGORIES = 'SET_SELF_PRODUCTED_CATEGORIES';
export const LIST_USER_SETTING_SUCCESS = 'LIST_USER_SETTING_SUCCESS';
export const SET_SETTING_NAME = 'SET_SETTING_NAME';
export const SET_SETTING_ID = 'SET_SETTING_ID';
export const GET_ESTIMATED_PRICE_LIST_SUCCESS = 'GET_ESTIMATED_PRICE_LIST_SUCCESS';
export const SET_BLUEPRINT_SETTING = 'SET_BLUEPRINT_SETTING';
export const SET_PLAN_STATUS = 'SET_PLAN_STATUS';
export const REMOVE_PLAN_STATUS = 'REMOVE_PLAN_STATUS';
export const RESET_PLAN_STATUS = 'RESET_PLAN_STATUS';
export const REMOVE_BLUEPRINT_SETTING = 'REMOVE_BLUEPRINT_SETTING';
export const SET_REFINE_TAX_RATE = 'SET_REFINE_TAX_RATE';
export const SET_DEFAULT_DECODER_ID = 'SET_DEFAULT_DECODER_ID';
export const SET_DEPOSITORY = 'SET_DEPOSITORY';
export const SET_SELF_PRODUCT_THRESHOLD = 'SET_SELF_PRODUCT_THRESHOLD';
export const SET_INVENT_ADDITION = 'SET_INVENT_ADDITION';
export const SET_VIEW_MODE = 'SET_VIEW_MODE';
export const SET_EXPANDED_ROWKEYS = 'SET_EXPANDED_ROWKEYS';