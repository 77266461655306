import React from "react";
import { Layout, Modal, Input, Divider, Button } from "antd";
import { LineChartOutlined } from "@ant-design/icons";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import VoteEntry from './VoteEntry';
import tesila from "../resource/tesila.PNG";



export default class TextScroller extends React.Component {
    constructor(props) {
        super();
        this.state = {
            sloganList: [
                '【军团招新】你来我们就是缘分，灼烧利刃欢迎您。     我们团在逑瑞斯，虽说是一个比较穷的地方，但是穷并快乐着。玩游戏就是图个人多，热闹，下班回家轻松愉悦的玩玩游戏。我们团有星城，各种设施齐全。买东西可快递，隔壁绝地和源泉，几乎都有你需要的东西，有跳桥并不远。我们驻地主要刷血袭者的怪，产出有28抗，大X修。都是比较好出手的东西，价格也很可观。玩累了，可以YST挂挂机，也可以在YY和大家聊聊天。我们团白天大家都上班，所以上线的人不是很多，但也是有的。晚上人还是挺多的，比较热闹。军团里的人，比较互帮互助，有需求大家都会帮忙。几个玩的时间长的大佬，很认真的讲解各种你不懂的问题，只要你问。军团福利，你可以先到QQ群或者YY，我们有专门负责讲解的人。其实加团并不复杂，主要是找到能和你玩到一起的朋友。如果你想加入我们，可以先来Q群或者YY来了解我们。我相信那么多军团，我们军团会使你，开心快乐的玩游戏，而不是说说而已。最后欢迎你的加入，有你才会有快乐。谢谢!     Q群号：465802014'
            ],
            sloganIndex: 0,
        }
        this.sign = true;//判断是否进行文字滚动动画
        this.textWidth = 0;//文字的宽度
        this.textLeft = 0;
        this.offsetStep = 1;//每次移动的步长
    }

    componentDidMount() {
        let sloganContainer = this.container.clientWidth;
        this.text.style.left = sloganContainer + "px";
        this.timer = setInterval(() => {
            if (this.sign) {
                if (this.state.sloganList && this.state.sloganList.length) {
                    this.showSlogan();
                } else {
                    clearInterval(this.timer);
                }
            } else {
                this.timer = null;
            }
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    clickAd = (e) => {
        e.preventDefault();
        window.open('https://tieba.baidu.com/p/7373810047?pn=1');
    }

    render() {
        const { sloganList, sloganIndex } = this.state;
        return (
            <div ref={(ref) => this.container = ref} className="container" style={{ flex: 'auto' }} >
                <span ref={(ref) => this.text = ref} className="slogan">
                    <a href='_blank' style={{ color: 'burlywood' }} onClick={this.clickAd}>{sloganList[sloganIndex]} </a>
                </span>
            </div>
        )
    }

    showSlogan = () => {
        if (this.text == null) {
            return;
        }
        this.textWidth = this.text.clientWidth;//文字宽度
        this.textLeft = parseInt(this.text.style.left);//相对父元素偏移距离
        if (this.textLeft >= -this.textWidth) this.sign = false;
        if (this.textLeft > -this.textWidth) {
            this.text.style.left = this.textLeft - this.offsetStep + "px";
            requestAnimationFrame(this.showSlogan)
        } else {
            let nextIndex = this.state.sloganIndex != this.state.sloganList.length - 1 ? ++this.state.sloganIndex : 0;
            this.setState({
                sloganIndex: nextIndex
            }, () => {
                this.text.style.left = this.container.clientWidth + "px";
                this.textWidth = this.text.clientWidth;
                this.sign = true;
            })
        }
    }
}
