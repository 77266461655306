import React from "react";
import { Switch, Modal, Table, Checkbox, Button } from "antd";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Utils from '../common/Utils';
import * as PriceDataAction from '../action/PriceDataAction';
import ProfitHelper from '../common/ProfitHelper';
import _ from 'lodash';

const columns = [
  {
    title: '',
    dataIndex: 'typeId',
    key: 'typeId',
    sorter: Utils.createNumericSorter('typeId'),
    render: (text, record, index) => {
      return (<div><img style={{ width: '25px' }} nsrc={`https://image.evepc.163.com/Type/${record.typeId}_64.png`} src={`https://images.evetech.net/types/${record.typeId}/icon`}></img></div>)
    },
  },
  {
    title: '名称',
    dataIndex: 'name',
    key: 'name',
    sorter: Utils.createStringSorter('name'),
  },
  {
    title: '市场价',
    dataIndex: 'marketPrice',
    key: 'marketPrice',
    sorter: Utils.createNumericSorter('marketPrice'),
    render: (text) => <span>{`${Utils.formatISK(text)} ISK`}</span>,
  },
  {
    title: '每立方市场价',
    dataIndex: 'marketPricePerVolume',
    key: 'marketPricePerVolume',
    sorter: Utils.createNumericSorter('marketPricePerVolume'),
    render: (text) => <span>{`${Utils.formatISK(text)} ISK`}</span>,
  },
  {
    title: '化矿后市场价',
    dataIndex: 'refinedPrice',
    key: 'refinedPrice',
    sorter: Utils.createNumericSorter('refinedPrice'),
    render: (text) => <span>{`${Utils.formatISK(text)} ISK`}</span>,
  },
  {
    title: '每立方化矿后市场价',
    dataIndex: 'refinedPricePerVolume',
    key: 'refinedPricePerVolume',
    sorter: Utils.createNumericSorter('refinedPricePerVolume'),
    render: (text) => <span>{`${Utils.formatISK(text)} ISK`}</span>,
  },
  {
    title: '化矿收益率',
    dataIndex: 'refineProfit',
    key: 'refineProfit',
    sorter: Utils.createNumericSorter('refineProfit'),
    render: (text) => <span>{Utils.toPercentString(text)}</span>,
  }
];

const categories = ['标准矿石', '冰矿', '卫星矿石'];

class MineralList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: ['标准矿石'],
      pressed: true
    };
  }

  componentDidMount() { }

  componentWillReceiveProps(props) {
  }

  onOK = () => {
    this.props.onOK();
  };

  onCancel = () => {
    this.props.onCancel();
  };

  getRefinedPrice = (typeId) => {
    let material = _.find(this.props.PriceDataReducer.allTypeMaterials, m => m.typeId === typeId);
    if (material == null) {
      return 0;
    }
    let preRefineTypeIdSde = _.find(this.props.PriceDataReducer.allTypeIdSde, sde => sde.typeId === typeId);
    if (preRefineTypeIdSde == null) {
      return 0;
    }
    let price = 0;
    for (let materialItem of material.items) {
      let marketDump = ProfitHelper.getMarketDumpById(materialItem.materialTypeId);
      if (marketDump == null) {
        price += 0;
      } else {
        price += materialItem.quantity * marketDump.price;
      }
    }
    price = price / preRefineTypeIdSde.portionSize * this.props.SettingReducer.mineralRefinePercent;
    return price;
  }

  getDataSource = () => {
    let typeIdSdeList = this.props.PriceDataReducer.allTypeIdSde.filter(t => !_.isEmpty(t.categories) && this.state.categories.indexOf(t.categories[3]) >= 0);
    typeIdSdeList = typeIdSdeList.filter(sde => {
      if (this.state.pressed === true) {
        return sde.name.includes('高密度');
      } else {
        return !sde.name.includes('高密度');
      }
    });

    let dateSource = [];
    for (let typeIdSde of typeIdSdeList) {
      let marketDump = ProfitHelper.getMarketDumpById(typeIdSde.typeId);
      if (marketDump == null) {
        marketDump = { price: 0 };
      }
      let refinedPrice = this.getRefinedPrice(typeIdSde.typeId);
      dateSource.push({
        typeId: typeIdSde.typeId,
        name: typeIdSde.name,
        marketPrice: marketDump.price,
        marketPricePerVolume: marketDump.price / typeIdSde.volume,
        refinedPrice: refinedPrice,
        refinedPricePerVolume: refinedPrice / typeIdSde.volume,
        refineProfit: refinedPrice / marketDump.price,
      });
    }
    dateSource = _.sortBy(dateSource, t => 0 - t.typeId);
    return dateSource;
  }

  onCategoryChange = (e) => {
    this.setState({ categories: e });
  }

  onPressChange = (e) => {
    this.setState({ pressed: e })
  }

  render() {
    return (
      <div>
        <Modal title="矿物价值表" visible={this.props.show} onOk={this.onOK} onCancel={this.onCancel}
          okText={'好'} cancelText={'关闭'} width={'90%'}>
          <div>
            <div style={{ display: 'flex' }}>
              <div style={{ marginRight: '30px' }}>
                <Switch checkedChildren="压缩矿" unCheckedChildren="原矿" onChange={this.onPressChange} checked={this.state.pressed} />
              </div>
              <div>
                <Checkbox.Group options={categories} value={this.state.categories} onChange={this.onCategoryChange} />
              </div>
            </div>
            <div style={{ boxShadow: '0 0 9px 3px #999', marginTop: '20px' }}>
              <Table columns={columns} size='small' dataSource={this.getDataSource()} pagination={{ showSizeChanger: true, pageSize: 200 }} />
            </div>

          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    PriceDataReducer: state.PriceDataReducer,
    RefineReducer: state.RefineReducer,
    SettingReducer: state.SettingReducer
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    PriceDataAction: bindActionCreators(PriceDataAction, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MineralList);
