import * as Utils from '../common/Utils';

export default class BlueprintBuildApi {
  static getBlueprintBuildById(baseUrl, id) {
    return Utils.getRequest(`${baseUrl}/blueprintBuild/getBlueprintBuildById/${id}`).then((resp) => resp.json());
  }

  static deleteBlueprintBuildById(baseUrl, id) {
    return Utils.getRequest(`${baseUrl}/blueprintBuild/deleteBlueprintBuildById/${id}`);
  }

  static upsertBlueprintBuild(baseUrl, blueprintBuild) {
    return Utils.postRequest(`${baseUrl}/blueprintBuild/upsertBlueprintBuild`, blueprintBuild);
  }
}
