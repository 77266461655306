import * as Utils from "../common/Utils";
import store from '../store';
import typeIdSdeFile from '../resource/typeIdSde.json';

export default class PriceDataApi {
  static getAllBlueprints(baseUrl) {
    if (store.getState().SettingReducer.server === 'tranquility' || store.getState().SettingReducer.server === 'serenityPriceEUBlueprint' || store.getState().SettingReducer.server === 'infinityPriceEUBlueprint') {
      return Utils.getRequest(`${baseUrl}/pricedataEU/allBluePrintsEU`).then((r) =>
        r.json()
      );
    } else if (store.getState().SettingReducer.server === 'infinity') {
      return Utils.getRequest(`${baseUrl}/pricedataSG/allBluePrintsSG`).then((r) =>
        r.json()
      );
    } else {
      return Utils.getRequest(`${baseUrl}/pricedata/allBluePrints`).then((r) =>
        r.json()
      );
    }
  }

  static getAllTypeMaterials(baseUrl) {
    if (store.getState().SettingReducer.server === 'tranquility' || store.getState().SettingReducer.server === 'serenityPriceEUBlueprint' || store.getState().SettingReducer.server === 'infinityPriceEUBlueprint') {
      return Utils.getRequest(`${baseUrl}/pricedataEU/allTypeMaterialsEU`).then((r) =>
        r.json()
      );
    } else if (store.getState().SettingReducer.server === 'infinity') {
      return Utils.getRequest(`${baseUrl}/pricedataSG/allTypeMaterialsSG`).then((r) =>
        r.json()
      );
    } else {
      return Utils.getRequest(`${baseUrl}/pricedata/allTypeMaterials`).then((r) =>
        r.json()
      );
    }
  }

  static getAllTypeIdSde(baseUrl) {
    return Promise.resolve(typeIdSdeFile);
  }

  static getAllMarketDump(baseUrl) {
    if (store.getState().SettingReducer.server === 'tranquility') {
      return Utils.getRequest(`${baseUrl}/pricedataEU/allMarketDumpEU`).then((r) =>
        r.json()
      );
    } else if (store.getState().SettingReducer.server === 'infinity' || store.getState().SettingReducer.server === 'infinityPriceEUBlueprint') {
      return Utils.getRequest(`${baseUrl}/pricedataSG/allMarketDumpSG`).then((r) =>
        r.json()
      );
    } else {
      return Utils.getRequest(`${baseUrl}/pricedata/allMarketDump`).then((r) =>
        r.json()
      );
    }
  }

  static getDealPriceList(baseUrl) {
    if (store.getState().SettingReducer.server === 'tranquility') {
      return Utils.getRequest(`${baseUrl}/pricedataEU/dealPriceListEU`).then((r) =>
        r.json()
      );
      // return Promise.resolve([{ typeId: 0, price: 0 }]);
    } else if (store.getState().SettingReducer.server === 'infinity' || store.getState().SettingReducer.server === 'infinityPriceEUBlueprint') {
      return Utils.getRequest(`${baseUrl}/pricedataSG/dealPriceListSG`).then((r) =>
        r.json()
      );
    } else {
      return Utils.getRequest(`${baseUrl}/pricedata/dealPriceList`).then((r) =>
        r.json()
      );
    }
  }


  static getAllMarketHistory(baseUrl) {
    if (store.getState().SettingReducer.server === 'tranquility') {
      return Utils.getRequest(`${baseUrl}/pricedataEU/allMarketHistoryEU`).then((r) =>
        r.json()
      );
    } else if (store.getState().SettingReducer.server === 'infinity' || store.getState().SettingReducer.server === 'infinityPriceEUBlueprint') {
      return Utils.getRequest(`${baseUrl}/pricedataSG/allMarketHistorySG`).then((r) =>
        r.json()
      );
    } else {
      return Utils.getRequest(`${baseUrl}/pricedata/allMarketHistory`).then((r) =>
        r.json()
      );
    }
  }

  static getIndustryIndices(baseUrl) {
    if (store.getState().SettingReducer.server === 'tranquility') {
      return Utils.getRequest(`${baseUrl}/pricedataEU/industryIndicesEU`).then((r) =>
        r.json()
      );
    } else if (store.getState().SettingReducer.server === 'infinity' || store.getState().SettingReducer.server === 'infinityPriceEUBlueprint') {
      return Utils.getRequest(`${baseUrl}/pricedataSG/industryIndicesSG`).then((r) =>
        r.json()
      );
    } else {
      return Utils.getRequest(`${baseUrl}/pricedata/industryIndices`).then((r) =>
        r.json()
      );
    }
  }

  static getOrders(baseUrl, typeId) {
    if (store.getState().SettingReducer.server === 'tranquility') {
      return Utils.getRequest(`${baseUrl}/pricedataEU/orders/${typeId}`).then((r) =>
        r.json()
      );
    } else if (store.getState().SettingReducer.server === 'infinity' || store.getState().SettingReducer.server === 'infinityPriceEUBlueprint') {
      return Utils.getRequest(`${baseUrl}/pricedataSG/orders/${typeId}`).then((r) =>
        r.json()
      );
    } else {
      return Utils.getRequest(`${baseUrl}/pricedata/orders/${typeId}`).then((r) =>
        r.json()
      );
    }
  }

  static getPreviousMonthsMarketHistory(baseUrl, typeId) {
    if (store.getState().SettingReducer.server === 'tranquility') {
      return Utils.getRequest(`${baseUrl}/pricedataEU/marketHistoryDaysEU/${typeId}/6`).then((r) =>
        r.json()
      );
    } else if (store.getState().SettingReducer.server === 'infinity' || store.getState().SettingReducer.server === 'infinityPriceEUBlueprint') {
      return Utils.getRequest(`${baseUrl}/pricedataSG/marketHistoryDaysSG/${typeId}/6`).then((r) =>
        r.json()
      );
    } else {
      return Utils.getRequest(`${baseUrl}/pricedata/marketHistoryDays/${typeId}/6`).then((r) =>
        r.json()
      );
    }
  }

  static getLastOrderDate(baseUrl) {
    if (store.getState().SettingReducer.server === 'tranquility') {
      return Utils.getRequest(`${baseUrl}/pricedataEU/latestOrderEUDate`).then((r) =>
        r.text()
      );
    } else if (store.getState().SettingReducer.server === 'infinity' || store.getState().SettingReducer.server === 'infinityPriceEUBlueprint') {
      return Utils.getRequest(`${baseUrl}/pricedataSG/latestOrderSGDate`).then((r) =>
        r.text()
      );
    } else {
      return Utils.getRequest(`${baseUrl}/pricedata/latestOrderDate`).then((r) =>
        r.text()
      );
    }
  }

  static getEstimatedPriceList(baseUrl) {
    if (store.getState().SettingReducer.server === 'tranquility') {
      return Utils.getRequest(`${baseUrl}/pricedataEU/estimatedPriceListEU`).then((r) =>
        r.json()
      );
    } else if (store.getState().SettingReducer.server === 'infinity' || store.getState().SettingReducer.server === 'infinityPriceEUBlueprint') {
      return Utils.getRequest(`${baseUrl}/pricedataSG/estimatedPriceListSG`).then((r) =>
        r.json()
      );
    } else {
      return Utils.getRequest(`${baseUrl}/pricedata/estimatedPriceList`).then((r) =>
        r.json()
      );
    }
  }
}

