import React, { createRef } from "react";
import { Table, Switch, Tooltip, Menu, Button, Dropdown, message, Slider } from "antd";
import { SnippetsOutlined, FileImageOutlined, PlayCircleOutlined, UndoOutlined, CaretRightOutlined, StopOutlined, DoubleRightOutlined, CheckOutlined, AlipayOutlined, ToolOutlined, DownloadOutlined } from "@ant-design/icons";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import * as SettingAction from '../action/SettingAction';
import * as Utils from '../common/Utils';
import shoubing from "../resource/shoubing.jpg";
import ProfitHelper from '../common/ProfitHelper';
import copy from "copy-to-clipboard";
import { newInstance, ready } from '@jsplumb/browser-ui';
import html2canvas from 'html2canvas';
import { Canvg } from 'canvg';

const { SubMenu } = Menu;

class IndustryProcess extends React.Component {
    constructor(props) {
        super(props);
        this.plumbInstance = null;
        this.state = {

        };
    }

    componentDidMount() {
        this.drawLines();
    }

    componentDidUpdate() {
        this.drawLines();
    }


    drawLines = () => {
        let container = document.getElementById('chartContainer');
        if (this.plumbInstance != null) {
            this.plumbInstance.deleteEveryConnection();
        } else {
            this.plumbInstance = newInstance({ container: container });
        }
        let connector = {
            type: "Straight",
            options: {
                stub: 10
            },
        }

        if (this.props.context.processList.length < 1) {
            return;
        }
        let processList = this.props.context.processList;

        ready(() => {
            for (let blueprintSetting of this.props.blueprintSettings) {
                let blueprint = ProfitHelper.getBlueprintByTypeId(blueprintSetting.blueprintTypeId);
                let productId = blueprint.typeIdSde.typeId;
                let targetId = `root${productId}`;
                let materials = [];
                if (blueprint.activities.manufacturing != null) {
                    materials = blueprint.activities.manufacturing.materials;
                }

                let inventBaseBlueprint = ProfitHelper.getInventBaseBlueprint(blueprintSetting.blueprintTypeId);
                if (inventBaseBlueprint != null) {
                    materials = [...materials, ...inventBaseBlueprint.activities.invention.materials]
                }
                if (blueprintSetting.decoderId > 0) {
                    materials = [...materials, { typeId: blueprintSetting.decoderId }];
                }

                for (let item of processList[0]) {
                    if (_.find(materials, m => m.typeId === item.key) != null) {
                        this.plumbInstance.connect({
                            source: document.getElementById(item.chartKey),
                            target: document.getElementById(targetId),
                            // paintStyle: { stroke: 'forestgreen', strokeWidth: 2 },
                            anchors: ["Left", "Right"],
                            connector: connector
                        });
                    }
                }
            }

            for (let stepIndex = 0; stepIndex < processList.length - 1; stepIndex++) {
                let targetStep = processList[stepIndex];
                for (let targetItem of targetStep) {
                    if (!targetItem.isSelfProducted) {
                        continue;
                    }

                    for (let sourceItem of targetItem.sourceItems) {
                        this.plumbInstance.connect({
                            source: document.getElementById(sourceItem.chartKey),
                            target: document.getElementById(targetItem.chartKey),
                            anchors: ["Left", "Right"],
                            connector: connector
                        });
                    }
                }
            }

            if (this.props.showMiniMap != null) {
                this.props.showMiniMap();
            }
        })
    }

    getProcessCount = (typeId, quantity) => {
        let blueprint = ProfitHelper.getBlueprintByProductId(typeId);
        if (blueprint == null) {
            return 0;
        }
        let quantityPerProcess = blueprint.activities.manufacturing.products[0].quantity;
        return Math.ceil(quantity / quantityPerProcess);
    }

    getProcessText = (item) => {
        if (!this.isSelfProducted(item.key)) {
            return '';
        }
        let processCount = this.getProcessCount(item.key, item.quantity);
        let ceilProcessCount = this.getProcessCount(item.key, item.ceilQuantity);
        if (processCount == 0) {
            return '';
        } else if (processCount === ceilProcessCount) {
            return ` ${processCount}流程`;
        } else {
            return ` ${processCount}(${ceilProcessCount})流程`;
        }
    }

    getQuantityText = (item) => {
        let quantityText = this.formatQuantity(item.quantity);
        if (item.ceilQuantity === item.quantity) {
            return `${quantityText}个`
        } else {
            return `${quantityText}(${item.ceilQuantity})个`
        }
    }

    renderRoot = (blueprintSetting, index) => {
        let blueprint = ProfitHelper.getBlueprintByTypeId(blueprintSetting.blueprintTypeId);
        let typeIdSde = ProfitHelper.getProductByBlueprintId(blueprintSetting.blueprintTypeId);
        let itemPerProcess = blueprint.activities.manufacturing.products[0].quantity;
        let top = (index + 0) * 100;
        return (
            <div id={`root${typeIdSde.typeId}`} className='node' style={{ top: `${top}px`, display: 'flex' }}>
                <div style={{ display: 'flex', position: 'relative' }}>
                    <img src={`https://image.evepc.163.com/Type/${typeIdSde.typeId}_64.png`} style={{ width: '60px', height: '60px' }}></img>
                    <div style={{ margin: '0 10px', flexGrow: 1 }}>
                        <div style={{ whiteSpace: 'nowrap' }}>{typeIdSde.name}</div>
                        <div style={{ display: 'flex', height: '22px' }}>
                        </div>
                        <div style={{ whiteSpace: 'nowrap' }}>
                            {this.formatQuantity(blueprintSetting.productQuantity * itemPerProcess * blueprintSetting.blueprintCount) + '个'}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    shouldWarning = (chartItem, depositoryPercent) => {
        if (this.isSelfProducted(chartItem.key)) {
            if (depositoryPercent > 1) {
                return true;
            }
        }
        return false;
    }

    renderContainer = (processItems, chartItems) => {
        let maxItemCount = 0;
        processItems.forEach(l => {
            if (l.length > maxItemCount) {
                maxItemCount = l.length;
            }
        })
        let quantityMap = this.getTypeIdWithCeilQuantity();
        return (<div id='chartContainer' className='industry-process' style={{ position: 'relative', width: `${processItems.length * 400 + 400}px`, height: `${maxItemCount * 100}px` }}>
            {this.props.blueprintSettings.map((b, index) => {
                return this.renderRoot(b, index)
            })}

            {chartItems.map(item => {
                return this.renderChartItem(item, quantityMap);
            })}
        </div>)
    }


    getTypeIdWithCeilQuantity = () => {
        let result = new Map();
        for (let processStep of this.props.context.processList) {
            for (let dataSource of processStep) {
                if (dataSource.delay) {
                    continue;
                }
                if (result.has(dataSource.key)) {
                    result.set(dataSource.key, dataSource.ceilQuantity + result.get(dataSource.key));
                } else {
                    result.set(dataSource.key, dataSource.ceilQuantity);
                }
            }
        }
        return result;
    }

    isSelfProducted = (productId) => {
        let productBlueprint = ProfitHelper.getBlueprintByProductId(productId);
        if (productBlueprint == null) {
            return false;
        }
        let extractedBlueprintIds = this.props.getExtractBlueprintIds();
        return _.find(extractedBlueprintIds, b => b === productBlueprint.blueprintTypeId) != null;
    }

    onSwitchChange = (productId, checked, e) => {
        e.preventDefault();
        e.stopPropagation();

        let extractedBlueprintIds = this.props.getExtractBlueprintIds();
        let currentProductBlueprint = ProfitHelper.getBlueprintByProductId(productId);
        if (currentProductBlueprint == null) {
            return;
        }
        let bid = currentProductBlueprint.blueprintTypeId;
        let newExtractedBids = [];
        if (checked) {
            newExtractedBids = [...extractedBlueprintIds, bid];
        } else {
            newExtractedBids = _.filter(extractedBlueprintIds, b => b !== bid);
        }
        this.props.setExtractBlueprintIds(newExtractedBids);
    }

    formatQuantity = (quantity) => {
        if (quantity == null) {
            return '';
        }
        quantity = Number(quantity);
        let result = quantity.toFixed(0);
        return result;
    }

    getQuantityAndProcessText = (chartItem) => {
        let processText = this.getProcessText(chartItem);
        let quantityText = this.getQuantityText(chartItem);
        if (_.isEmpty(processText)) {
            return `${quantityText}`;
        } else {
            return `${quantityText} ${processText}`;
        }
    }

    clickPlanMenu = (e, item) => {
        switch (e.key) {
            case 'start':
                this.props.SettingAction.setPlanStatus({ stepIndex: item.stepIndex, key: item.key, status: '生产中' });
                break;
            case 'finish':
                this.props.SettingAction.setPlanStatus({ stepIndex: item.stepIndex, key: item.key, status: '已完成' });
                break;
            case 'reset':
                this.props.SettingAction.removePlanStatus({ stepIndex: item.stepIndex, key: item.key, status: '已完成' });
                break;

        }
        console.log(e)
    }

    planMenu = (status, item) => {
        if (status === '可以启动') {
            return (
                <Menu mode="vertical" onClick={(e) => this.clickPlanMenu(e, item)}>
                    <Menu.Item key='start' icon={<PlayCircleOutlined style={{ fontSize: '1.5em' }} />}>启动生产</Menu.Item>
                </Menu>
            )
        } else if (status === '生产中') {
            return (
                <Menu mode="vertical" onClick={(e) => this.clickPlanMenu(e, item)}>
                    <Menu.Item key='finish' icon={<DownloadOutlined style={{ fontSize: '1.5em' }} />}>交付</Menu.Item>
                    <Menu.Item key='reset' icon={<UndoOutlined style={{ fontSize: '1.5em' }} />}>撤销生产</Menu.Item>
                </Menu>
            )
        } else if (status === '已完成') {
            return (
                <Menu mode="vertical" onClick={(e) => this.clickPlanMenu(e, item)}>
                    <Menu.Item key='reset' icon={<UndoOutlined style={{ fontSize: '1.5em' }} />}>撤销生产</Menu.Item>
                </Menu>
            )
        }
    }

    getLastSameItem = (targetItem) => {
        let result = targetItem;
        while (result.sourceItems.length == 1 && result.sourceItems[0].key === result.key) {
            result = result.sourceItems[0];
        }
        return result;
    }


    alreadyProducted = (item) => {
        let planSetting = _.find(this.props.plans, p => p.key === item.key && p.stepIndex === item.stepIndex);
        if (planSetting == null) {
            return false;
        }
        return planSetting.status === '已完成';
    }

    generateStatus = (chartItem) => {
        for (let planSetting of this.props.plans) {
            if (planSetting.key === chartItem.key && planSetting.stepIndex === chartItem.stepIndex) {
                return planSetting.status;
            }
        }
        let canStart = true;
        for (let sourceItem of chartItem.sourceItems) {
            let lastSourceItem = this.getLastSameItem(sourceItem);
            if (lastSourceItem.isSelfProducted && !this.alreadyProducted(lastSourceItem)) {
                canStart = false;
                break;
            }
        }
        if (canStart) {
            return '可以启动';
        } else {
            return '不可启动';
        }
    }


    generateStatusTooltip = (item) => {
        let dependsOn = [];
        for (let sourceItem of item.sourceItems) {
            let lastSourceItem = this.getLastSameItem(sourceItem);
            if (lastSourceItem.isSelfProducted && !this.alreadyProducted(lastSourceItem)) {
                dependsOn.push(lastSourceItem);
            }
        }

        let dependsOnString = '';
        for (let depends of dependsOn) {
            dependsOnString += depends.name;
            dependsOnString += ',';
        }
        dependsOnString = dependsOnString.substr(0, dependsOnString.length - 1);
        return `需等待${dependsOnString}完成后才能开始制造。`
    }

    renderPlanStatus = (item) => {
        console.log(item)
        if (!item.isSelfProducted) {
            return ('');
        }
        if (item.delay) {
            return ('');
        }
        let status = this.generateStatus(item);
        if (status === '可以启动') {
            return (
                <Dropdown overlay={this.planMenu(status, item)}>
                    <Tooltip title={status}>
                        <Button shape="circle" size='small' type='primary' icon={<CaretRightOutlined />}></Button>
                    </Tooltip>
                </Dropdown>
            )
        } else if (status === '不可启动') {
            return (
                <Tooltip title={this.generateStatusTooltip(item)}>
                    <Button shape="circle" size='small' type='primary' icon={<StopOutlined />}></Button>
                </Tooltip>
            )
        } else if (status === '生产中') {
            return (
                <Dropdown overlay={this.planMenu(status, item)}>
                    <Tooltip title={status}>
                        <Button shape="circle" size='small' type='primary' icon={<DoubleRightOutlined />}></Button>
                    </Tooltip>
                </Dropdown>
            )
        } else if (status === '已完成') {
            return (
                <Dropdown overlay={this.planMenu(status, item)}>
                    <Tooltip title={status}>
                        <Button shape="circle" size='small' type='primary' icon={<CheckOutlined />}></Button>
                    </Tooltip>
                </Dropdown>
            )
        }
    }

    shouldHighlight = (item) => {
        if (!item.isSelfProducted) {
            if (_.isEmpty(item.targetItems)) {
                return false;
            }
            let shouldHighlight = true;
            for (let targetItem of item.targetItems) {
                if (!this.shouldHighlight(targetItem)) {
                    shouldHighlight = false;
                    break;
                }
            }
            return shouldHighlight;
        } else {
            item = this.getLastSameItem(item);
            if (this.generateStatus(item) === '已完成') {
                return true;
            }
            return false;
        }
    }

    renderChartItem = (item, quantityMap) => {
        let left = (item.stepIndex + 1) * 400;
        let top = item.typeIdIndex * 100;
        let depositoryPercent = ProfitHelper.getDepositoryQuantity(item.key) / quantityMap.get(item.key);
        let shouldWarning = this.shouldWarning(item, depositoryPercent);
        let shouldHighlight = this.shouldHighlight(item);
        let highlightColor = this.props.SettingReducer.antdColor == null ? 'royalblue' : this.props.SettingReducer.antdColor;
        return (
            <div id={item.chartKey} className='node' style={{ left: `${left}px`, top: `${top}px`, display: 'flex', flexDirection: 'column', borderColor: shouldHighlight ? highlightColor : '', boxShadow: shouldHighlight ? `inset 0px 0px 20px ${this.props.SettingReducer.antdColor}` : '' }}>
                <div style={{ display: 'flex', position: 'relative' }}>
                    <img src={`https://image.evepc.163.com/Type/${item.key}_64.png`} style={{ width: '60px', height: '60px' }}></img>
                    <div style={{ margin: '0 10px', flexGrow: 1 }}>
                        <div style={{ whiteSpace: 'nowrap', display: 'flex' }}>
                            <div>{item.name}</div>
                            <div style={{ marginLeft: 'auto' }}>{this.renderPlanStatus(item)}</div>
                        </div>
                        <div style={{ display: 'flex', height: '22px' }}>
                            <div style={{ position: 'relative', top: '-2px' }}>
                                <div style={{ filter: item.alreadyProducted ? 'grayscale(1)' : '' }}>
                                    <Switch size='small' checkedChildren={item.alreadyProducted ? "已自产" : '自产'} unCheckedChildren="收购" checked={this.isSelfProducted(item.key)}
                                        onChange={(checked, e) => this.onSwitchChange(item.key, checked, e)} />
                                </div>
                            </div>
                            <div style={{ display: 'flex', marginLeft: 'auto' }}>库存比例: <p style={{ marginLeft: '5px', color: shouldWarning ? 'orange' : '', fontWeight: shouldWarning ? 'bold' : '' }}>{Utils.toPercentString(depositoryPercent)}</p></div>
                        </div>
                        <div style={{ whiteSpace: 'nowrap' }}>
                            {this.getQuantityAndProcessText(item)}
                        </div>
                    </div>
                </div>

            </div>
        )
    }

    menu = () => {
        return (<Menu onClick={(e) => this.clickMenu(e)} mode="vertical"  >
            <Menu.ItemGroup title="自产/收购">
                <Menu.Item key='makeAll' icon={<ToolOutlined style={{ fontSize: '1.3em' }} />}>全部自产</Menu.Item>
                <SubMenu key="makeDepositoryMissing" icon={<ToolOutlined style={{ fontSize: '1.3em' }} />} title="自产库存数量低于..." >
                    <Menu.Item key='makeDepositoryMissing100' icon={<ToolOutlined style={{ fontSize: '1.3em' }} />}>100%的原料</Menu.Item>
                    <Menu.Item key='makeDepositoryMissing95' icon={<ToolOutlined style={{ fontSize: '1.3em' }} />}>95%的原料</Menu.Item>
                    <Menu.Item key='makeDepositoryMissing90' icon={<ToolOutlined style={{ fontSize: '1.3em' }} />}>90%的原料</Menu.Item>
                    <Menu.Item key='makeDepositoryMissing85' icon={<ToolOutlined style={{ fontSize: '1.3em' }} />}>85%的原料</Menu.Item>
                    <Menu.Item key='makeDepositoryMissing80' icon={<ToolOutlined style={{ fontSize: '1.3em' }} />}>80%的原料</Menu.Item>
                </SubMenu>
                <Menu.Item key='buyAll' icon={<AlipayOutlined style={{ fontSize: '1.3em' }} />}>全部收购</Menu.Item>
            </Menu.ItemGroup>
            <Menu.ItemGroup title="导出">
                <Menu.Item key='copyToExcel' icon={<SnippetsOutlined style={{ fontSize: '1.3em' }} />}>复制到Excel</Menu.Item>
                <Menu.Item key='saveToPNG' icon={<FileImageOutlined style={{ fontSize: '1.3em' }} />}>保存为图片(点击后请耐心等待最多2分钟)</Menu.Item>
            </Menu.ItemGroup>
        </Menu >
        );
    }

    generateExcelContent = () => {
        let content = '';

        let processList = this.props.context.processList;
        // this.setCeilQuantity(processList, ceilProcessList);

        let stepIndexTitle = '';
        let fieldsTitle = '';
        let emptyItem = `\t\t\t\t\t\t`;
        stepIndexTitle += `产品\t产品\t产品\t产品\t产品\t产品\t`;
        fieldsTitle += `物品名称\t来源\t数量\t启动制造所需数量\t流程\t启动制造所需流程\t`;
        for (let i = 0; i < processList.length; i++) {
            let stepIndex = processList.length - i;
            stepIndexTitle += `第${stepIndex}步\t第${stepIndex}步\t第${stepIndex}步\t第${stepIndex}步\t第${stepIndex}步\t第${stepIndex}步\t`;
            fieldsTitle += `物品名称\t来源\t数量\t启动制造所需数量\t流程\t启动制造所需流程\t`;
        }
        content += `${stepIndexTitle}\n${fieldsTitle}\n\n`;

        let maxItemCount = 0;
        processList.forEach(l => {
            if (l.length > maxItemCount) {
                maxItemCount = l.length;
            }
        })
        let sortedProcessList = [];
        processList.forEach(l => {
            sortedProcessList.push(_.sortBy(l, l => l.key));
        });
        processList = sortedProcessList;

        for (let i = 0; i < maxItemCount; i++) {
            for (let stepIndex = -1; stepIndex < processList.length; stepIndex++) {
                if (stepIndex === -1) {
                    //产品
                    if (i >= this.props.blueprintSettings.length) {
                        content += emptyItem;
                    } else {
                        let blueprintSetting = this.props.blueprintSettings[i];
                        let blueprint = ProfitHelper.getBlueprintByTypeId(blueprintSetting.blueprintTypeId);
                        let itemPerProcess = blueprint.activities.manufacturing.products[0].quantity;
                        let productTypeIdSde = ProfitHelper.getTypeIdSdeById(blueprint.typeIdSde.typeId);
                        let quantity = itemPerProcess * blueprintSetting.productQuantity * blueprintSetting.blueprintCount;
                        content += `${productTypeIdSde.name}\t自产\t${quantity}\t${quantity}\t${blueprintSetting.productQuantity * blueprintSetting.blueprintCount}\t${blueprintSetting.productQuantity * blueprintSetting.blueprintCount}\t`;
                    }
                } else {
                    let currStep = processList[stepIndex];
                    if (currStep.length - 1 < i) {
                        content += emptyItem;
                    } else {
                        let chartItem = currStep[i];
                        let quantity = Number(chartItem.quantity);
                        if (this.isSelfProducted(chartItem.key)) {
                            let origin = '自产';
                            if (chartItem.alreadyProducted) {
                                origin = '已经自产';
                            }
                            content += `${chartItem.name}\t${origin}\t${quantity.toFixed()}\t${chartItem.ceilQuantity}\t${this.getProcessCount(chartItem.key, quantity)}\t${this.getProcessCount(chartItem.key, chartItem.ceilQuantity)}\t`;
                        } else {
                            content += `${chartItem.name}\t${'收购'}\t${quantity.toFixed()}\t${chartItem.ceilQuantity}\t\t\t`;
                        }
                    }
                }
            }
            content += '\n';
        }
        return content;
    }

    clickMenu = (e) => {
        console.log(e);
        if (e.key === 'makeAll') {
            this.props.extractMaterialsRecursive();
        } else if (e.key === 'buyAll') {
            this.props.setExtractBlueprintIds([]);
        } else if (e.key === 'copyToExcel') {
            {
                let content = this.generateExcelContent();
                copy(content, { format: 'text/plain' });
                message.info(`生产步骤已经复制到剪贴板，可以去Excel粘贴了。`);
            }
        } else if (e.key === 'saveToPNG') {
            let promises = this.prepareImg();
            Promise.all(promises).then(() => {
                let container = document.getElementById('chartContainer');
                html2canvas(container, {
                    useCORS: true,
                    scale: 2,
                    allowTaint: true,
                    backgroundColor: this.props.SettingReducer.antdTheme === 0 ? 'white' : 'black'
                }).then(canvas => {
                    let pngImg = canvas.toDataURL("image/png");
                    this.downLoad(pngImg);
                    document.querySelectorAll("#chartContainer canvas").forEach(item => {
                        item.remove()
                    });
                })
            })
        } else if (e.key === 'makeDepositoryMissing100') {
            this.props.extractRequired(1);
        } else if (e.key === 'makeDepositoryMissing95') {
            this.props.extractRequired(0.95);
        } else if (e.key === 'makeDepositoryMissing90') {
            this.props.extractRequired(0.9);
        } else if (e.key === 'makeDepositoryMissing85') {
            this.props.extractRequired(0.85);
        } else if (e.key === 'makeDepositoryMissing80') {
            this.props.extractRequired(0.8);
        }
    }

    downLoad = (url) => {
        let typeIdSde = ProfitHelper.getTypeIdSdeById(this.props.productId);
        var oA = document.createElement("a");
        oA.download = `生产步骤`;// 设置下载的文件名，默认是'下载'
        oA.href = url;
        document.body.appendChild(oA);
        oA.click();
        oA.remove(); // 下载之后把创建的元素删除
    }

    prepareImg = () => {
        let el = document.getElementById('chartContainer');
        // const el = this.$refs.efContainer;
        //Svgdom数组
        let svgNodesToRemove = [];
        // 获取到所有的SVG 得到一个数组
        let svgElements = document.body.querySelectorAll('#chartContainer svg');
        let promises = [];
        // 遍历这个数组
        svgElements.forEach(function (item) {
            let children = item.childNodes
            // 处理 SVG子节点  children 透明度
            children.forEach(function (child) {
                //解决svg透明度问题
                child.style.opacity = 1;
            })
            //去除空白字符
            let svg = item.outerHTML.trim();
            // 创建一个 canvas DOM元素
            let canvas = document.createElement('canvas');
            //设置 canvas 元素的宽高 
            canvas.width = item.getBoundingClientRect().width;
            canvas.height = item.getBoundingClientRect().height;
            if (item.style.position) {
                canvas.style.position += item.style.position;
                canvas.style.left += item.style.left;
                canvas.style.top += item.style.top;
            }
            item.parentNode.appendChild(canvas);
            let ctx = canvas.getContext('2d')
            // 将 SVG转化 成 canvas
            let promise = Canvg.from(ctx, svg).then((Canvg) => {
                Canvg.start();
            });
            promises.push(promise);
        });
        return promises;
    }

    setCeilQuantity = (stepList, ceilStepList) => {
        for (let i = 0; i < stepList.length; i++) {
            let step = stepList[i];
            let ceilStep = ceilStepList[i];
            for (let resource of step) {
                let ceilResource = _.find(ceilStep, c => c.key === resource.key);
                if (ceilResource == null) {
                    console.log(`Resource ${resource.key} is in step list but not in ceil step list`);
                } else {
                    resource.ceilQuantity = ceilResource.quantity;
                }
            }
        }
    }

    setAlreadyProducted = (processList) => {
        for (let stepIndex = 0; stepIndex < processList.length - 1; stepIndex++) {
            let targetStep = processList[stepIndex];
            let sourceStep = processList[stepIndex + 1];
            for (let targetItem of targetStep) {
                if (!this.isSelfProducted(targetItem.key)) {
                    continue;
                }

                //延迟分解的物品，自己连接自己
                let sourceItem = _.find(sourceStep, s => s.key === targetItem.key);
                if (sourceItem != null) {
                    targetItem.alreadyProducted = true;
                }
            }
        }
    }

    render() {
        console.log('this.props.context')
        console.log(this.props.context)
        console.log(this.props.productId)
        console.log(this.props.resourceListObj)
        if (this.props.context == null) {
            return (<div></div>);
        }
        let chartItems = ProfitHelper.serializeResourceTree(this.props.context.processList);
        this.setAlreadyProducted(this.props.context.processList);
        // let [ceilProcessList, ceilChartItems] = ProfitHelper.serializeResourceTree(this.props.context.ceilDataTree);
        console.log('chartItems');
        console.log(chartItems)

        // this.setCeilQuantity(processList, ceilProcessList);

        return (
            <div>
                <Dropdown overlay={this.menu()} trigger={['contextMenu']}>
                    <div onClick={(e) => e.preventDefault()} >
                        {this.renderContainer(this.props.context.processList, chartItems)}
                    </div>
                </Dropdown>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        PriceDataReducer: state.PriceDataReducer,
        SettingReducer: state.SettingReducer,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        SettingAction: bindActionCreators(SettingAction, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(IndustryProcess);