import React from "react";
import { Layout, Modal, InputNumber, Divider, Button, Input } from "antd";
import * as PriceDataAction from '../action/PriceDataAction';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import ProfitHelper from '../common/ProfitHelper';

const { TextArea } = Input;

class PasteBinModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            textValue: ''
        };
    }

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.show !== this.props.show && nextProps.show === true) {
            this.setState({ textValue: '' });
        }
    }

    onTextAreaChange = (e) => {
        this.setState({ textValue: e.target.value });
    }

    onCancel = () => {
        this.props.onCancel();
    }


    onOK = () => {
        let lines = this.state.textValue.split('\n');
        let newItems = [];
        for (let line of lines) {
            let item = ProfitHelper.getTypeIdAndQuantityFromPasteBinLine(line);
            if (item != null) {
                newItems.push(item);
            }
        }
        this.props.onOK(newItems);
    }

    render() {
        return (
            <div>
                <Modal title={"从剪贴板导入"} visible={this.props.show} onOk={this.onOK} onCancel={this.onCancel} width={700} okText={'好'} cancelText={'关闭'}>
                    <div className="pastebin-help">
                        <div>
                            从合同中复制，或者在机库中选择[详情]或[列表]模式复制，并粘贴到下面：
                        </div>
                    </div>
                    <div>
                        <TextArea rows={15} value={this.state.textValue} onChange={(e) => this.onTextAreaChange(e)} />
                    </div>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        PriceDataReducer: state.PriceDataReducer,
        RefineReducer: state.RefineReducer,
        SettingReducer: state.SettingReducer
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        PriceDataAction: bindActionCreators(PriceDataAction, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PasteBinModal);