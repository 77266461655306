import React from "react";
import { Progress, Tooltip, Input, Divider, Button } from "antd";
import { HeartOutlined } from "@ant-design/icons";
import tesila from "../resource/tesila.PNG";

export default class VoteEntry extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() { }

    render() {
        return (
            <div style={{display: 'flex', margin: '10px 0'}}>
                <div style={{width: '30%'}}>{this.props.data.name}</div>
                <div style={{width: '50%'}}><Progress percent={this.props.data.percent} showInfo={false} /></div>
                <div>已有{this.props.data.count}人喜欢</div>
                <div style={{marginLeft: '10px'}}><Tooltip title="要这个!!!">
                    <Button type="primary" shape="circle" icon={<HeartOutlined  />} />
                </Tooltip></div>
            </div>
        );
    }
}
