
import { createStore, applyMiddleware, compose } from "redux";
import storage from 'redux-persist/lib/storage';
import allReducers from "./reducer";
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose;


const persistConfig = {
    storage: storage,
    key: "root",
    stateReconciler: autoMergeLevel2,
    // blacklist: [
    //   'PriceDataReducer',
    //   'MessageBoardReducer',
    //   'ItemInfoReducer',
    //   'RefineReducer'
    // ],
    whitelist: ['SettingReducer']
};

const persistedReducer = persistReducer(persistConfig, allReducers);
const middlewares = applyMiddleware(thunk);

const store = createStore(
    persistedReducer,
    composeEnhancers(middlewares)
);

export default store;