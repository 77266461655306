import React from "react";
import { Layout, Modal, Input, message, Button, Divider } from "antd";
import { FrownOutlined } from "@ant-design/icons";
import copy from "copy-to-clipboard";
import { bindActionCreators } from 'redux';
import { initialState } from '../reducer/SettingReducer';
import * as SettingAction from '../action/SettingAction';
import { connect } from 'react-redux';
const { TextArea } = Input;

class ErrorPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() { }

    onRefresh = () => {
        window.location = `http://${window.location.host}/#/`;
    }

    onClearSetting = () => {
        this.props.SettingAction.importSettings(initialState);
        window.location = `http://${window.location.host}/#/`;
    }

    render() {
        return (
            <div style={{ width: '60%', marginLeft: '20%', marginTop: '50px' }}>
                <div>
                    <div style={{ textAlign: 'center' }}>
                        <FrownOutlined style={{ fontSize: '300px', color: 'lightgray' }} />
                    </div>
                </div>
                <Divider style={{ background: 'lightgray' }} />
                <div>
                    <div>网页出现了异常。或许可以尝试以下方法：</div>
                    <ul style={{lineHeight: '4em'}}>
                        <li>
                            点击
                            <Button type='primary' onClick={this.onRefresh} style={{margin: '0 5px'}}>
                                全部刷新
                            </Button>
                        按钮刷新整个网站。
                        </li>
                        <li>
                            点击<Button type='primary' onClick={this.onClearSetting} style={{margin: '0 5px'}}>
                                还原设置
                            </Button>按钮还原所有设置。
                        </li>
                        <li>
                            <a href='tencent://AddContact/?fromId=45&fromSubId=1&subcmd=all&uin=597365344'>通过QQ</a>联系<span style={{ fontWeight: "bold", color: "orange" }}>特斯拉与你</span>。
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {

    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        SettingAction: bindActionCreators(SettingAction, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorPage);