import React from 'react';
import * as PriceDataAction from '../action/PriceDataAction';
import * as SettingAction from '../action/SettingAction';
import ProfitHelper from '../common/ProfitHelper';
import RankSpreadSheet from './RankSpreadSheet';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Checkbox, Divider, Table, Collapse, Spin, Tree, Input, Progress, Switch, Tooltip, Button, Radio, Row, Col } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';
import moment from 'moment';
import _ from 'lodash';
import * as XLSX from 'xlsx';
import * as Utils from '../common/Utils';
import * as ExcelUtil from '../common/ExcelUtil';
import Spreadsheet from "x-data-spreadsheet";

const { Panel } = Collapse;

const OPTIONS_ALL = '全部';

const TECH_OPTIONS = [
  { label: OPTIONS_ALL, value: OPTIONS_ALL },
  { label: 'T1', value: [0, 1, 54] },
  { label: 'T2', value: [2, 53] },
  { label: 'T3', value: [14] },
  { label: '故事线', value: [3] },
  { label: '势力', value: [4] },

  // { label: '官员', value: 5 },
  // { label: '死亡空间', value: 6 },
];


const columns = [
  {
    title: '产品名称',
    dataIndex: 'name',
    key: 'name',
    sorter: Utils.createStringSorter('name'),
  },
  {
    title: '单件成本',
    dataIndex: 'singleCost',
    key: 'singleCost',
    sorter: Utils.createNumericSorter('singleCost'),
    render: (text) => <span>{`${Utils.formatISK(text)} ISK`}</span>,
  },
  {
    title: '单件价格',
    dataIndex: 'singlePrice',
    key: 'singlePrice',
    sorter: Utils.createNumericSorter('singlePrice'),
    render: (text) => <span>{`${Utils.formatISK(text)} ISK`}</span>,
  },
  {
    title: '单件利润',
    dataIndex: 'singleProfit',
    key: 'singleProfit',
    sorter: Utils.createNumericSorter('singleProfit'),
    render: (value) => <span style={{ color: value > 0 ? 'green' : 'red', fontWeight: 'bold' }}>{`${Utils.formatISK(value)} ISK`}</span>,
  },
  {
    title: '利润率',
    dataIndex: 'profitPercent',
    key: 'profitPercent',
    sorter: Utils.createNumericSorter('profitPercent'),
    render: (value) => <span style={{ color: value > 0 ? 'green' : 'red', fontWeight: 'bold' }}>{Utils.toPercentString(value)}</span>,
  },
  {
    title: '全天利润',
    dataIndex: 'dailyProfit',
    key: 'dailyProfit',
    sorter: Utils.createNumericSorter('dailyProfit'),
    render: (value) => <span style={{ color: value > 0 ? 'green' : 'red', fontWeight: 'bold' }}>{`${Utils.formatISK(value)} ISK`}</span>,
  },
  {
    title: '全天产量',
    dataIndex: 'dailyQuantity',
    key: 'dailyQuantity',
    sorter: Utils.createNumericSorter('dailyQuantity'),
    render: (text) => <span>{`${Utils.formatISK(text)} 个`}</span>,
  },
  {
    title: '全天成交量',
    dataIndex: 'dailyVolume',
    key: 'dailyVolume',
    sorter: Utils.createNumericSorter('dailyVolume'),
    render: (text) => <span>{`${text} 个`}</span>,
  },
  {
    title: '出货效率',
    dataIndex: 'loopEffect',
    key: 'loopEffect',
    sorter: Utils.createNumericSorter('loopEffect'),
    render: (text) => <span>{Utils.toPercentString(text)}</span>,
  },
];

class ProfitPercentRank extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      marketGroups: [],
      techLevels: [],
      rankData: [],
      displaydColumns: [],
      expandedKeys: ['root'],
      selectedKeys: [],
      searchKey: '',
      blueprintCalculated: 0,
      rankDataWillLength: 0,
      spreadSheet: null,
      viewMode: 0
    };
    this.isCalculating = false;
  }

  componentDidMount() {
    this.setState({
      marketGroups: this.getAllMarketGroupOptions(),
      techLevels: TECH_OPTIONS.map((t) => t.value),
      displaydColumns: this.getColumnOptions().map((t) => t.value)
    }, () => {
      if (_.isEmpty(this.props.PriceDataReducer.rankData)) {
        setTimeout(() => {
          this.calcRankData(true);
        }, 500);
      } else {
        this.setState({ rankData: this.props.PriceDataReducer.rankData })
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(nextProps.SettingReducer, this.props.SettingReducer)) {
      this.calcRankData(true);
    }
    if (!_.isEqual(nextProps.PriceDataReducer.rankCheckedKeys, this.props.PriceDataReducer.rankCheckedKeys)) {
      setTimeout(() => {
        this.calcRankData(false);
      }, 0);
    }
  }

  calcRankData = (forceRefresh) => {
    if (this.isCalculating === false) {
      this.isCalculating = true;
      let rankData = [];
      if (!forceRefresh) {
        rankData = this.props.PriceDataReducer.rankData;
      }

      let checkedKeySet = new Set(this.props.PriceDataReducer.rankCheckedKeys.map(k => {
        if (k.indexOf('|') >= 0) {
          return parseInt(k.split('|')[1]);
        } else {
          return k;
        }
      }));
      let newKeySet = _.filter([...checkedKeySet], key => {
        return _.find(rankData, data => data.productId === key) == null;
      })
      newKeySet = new Set(newKeySet);
      let newCheckedBlueprints = this.props.PriceDataReducer.allBlueprint.filter(b => newKeySet.has(b.typeIdSde.typeId));

      this.setState({ rankData: [], rankDataWillLength: rankData.length + newCheckedBlueprints.length }, () => {
        this.calcRankDataRecursive(newCheckedBlueprints, rankData);
      })
    }
  };

  finishCalculating = () => {
    this.isCalculating = false;
    this.props.PriceDataAction.setRankData(this.state.rankData);
  }

  calcRankDataRecursive = (blueprints, allRankData) => {
    let batchSize = 100;
    let blueprintBatch = [];
    if (blueprints.length > batchSize) {
      blueprintBatch = _.slice(blueprints, 0, batchSize);
      blueprints = _.slice(blueprints, batchSize);
    } else {
      blueprintBatch = [...blueprints];
      blueprints = [];
    }
    let rankData = ProfitHelper.getBlueprintProfitSummary(blueprintBatch);
    allRankData = [...allRankData, ...rankData];
    if (_.isEmpty(blueprints)) {
      allRankData = allRankData
        .filter((r) => {
          return r.singleCost > 0;
        })
        .filter((r) => {
          return r.singlePrice > 0;
        });
      this.setState({ rankData: allRankData, blueprintCalculated: allRankData.length }, () => {
        this.finishCalculating();
      });
    } else {
      this.setState({ blueprintCalculated: allRankData.length }, () => {
        setTimeout(() => {
          this.calcRankDataRecursive(blueprints, allRankData);
        }, 100);
      });
    }
  }

  onMarketGroupChange = (e) => {
    if (e.indexOf(OPTIONS_ALL) >= 0 && this.state.marketGroups.indexOf(OPTIONS_ALL) < 0) {
      //OPTIONS_ALL is checked
      this.setState({ marketGroups: this.getAllMarketGroupOptions() });
    } else if (e.indexOf(OPTIONS_ALL) < 0 && this.state.marketGroups.indexOf(OPTIONS_ALL) >= 0) {
      //OPTIONS_ALL is un-checked
      this.setState({ marketGroups: [] });
    } else if (
      _.isEqual(
        e,
        _.filter(this.getAllMarketGroupOptions(), (item) => item !== OPTIONS_ALL)
      )
    ) {
      this.setState({ marketGroups: this.getAllMarketGroupOptions() });
    } else {
      this.setState({ marketGroups: _.filter(e, (item) => item !== OPTIONS_ALL) });
    }
  };

  onSelfProductChange = (e) => {
    this.props.SettingAction.setSelfProductedCategories(e);
  }

  onTechLevelChange = (e) => {
    if (e.indexOf(OPTIONS_ALL) >= 0 && this.state.techLevels.indexOf(OPTIONS_ALL) < 0) {
      //OPTIONS_ALL is checked
      this.setState({ techLevels: TECH_OPTIONS.map((t) => t.value) });
    } else if (e.indexOf(OPTIONS_ALL) < 0 && this.state.techLevels.indexOf(OPTIONS_ALL) >= 0) {
      //OPTIONS_ALL is un-checked
      this.setState({ techLevels: [] });
    } else if (
      _.isEqual(
        e,
        _.filter(
          TECH_OPTIONS.map((t) => t.value),
          (item) => item !== OPTIONS_ALL
        )
      )
    ) {
      this.setState({ techLevels: TECH_OPTIONS.map((t) => t.value) });
    } else {
      this.setState({ techLevels: _.filter(e, (item) => item !== OPTIONS_ALL) });
    }
  };

  onDisplayedColumnChange = (e) => {
    let columnOptions = this.getColumnOptions();
    if (e.indexOf(OPTIONS_ALL) >= 0 && this.state.displaydColumns.indexOf(OPTIONS_ALL) < 0) {
      //OPTIONS_ALL is checked
      this.setState({ displaydColumns: columnOptions.map((t) => t.value) });
    } else if (e.indexOf(OPTIONS_ALL) < 0 && this.state.displaydColumns.indexOf(OPTIONS_ALL) >= 0) {
      //OPTIONS_ALL is un-checked
      this.setState({ displaydColumns: [] });
    } else if (
      _.isEqual(
        e,
        _.filter(
          columnOptions.map((t) => t.value),
          (item) => item !== OPTIONS_ALL
        )
      )
    ) {
      this.setState({ displaydColumns: columnOptions.map((t) => t.value) });
    } else {
      this.setState({ displaydColumns: _.filter(e, (item) => item !== OPTIONS_ALL) });
    }
  };

  getAllMarketGroups = () => {
    let marketGroups = new Set();
    this.props.PriceDataReducer.allBlueprint.forEach((t) => {
      if (!_.isEmpty(t.typeIdSde.categories)) {
        marketGroups.add(t.typeIdSde.categories[0]);
      }
    });
    return [...marketGroups];
  };

  getAllMarketGroupOptions = () => {
    let marketGroups = this.getAllMarketGroups();
    return [OPTIONS_ALL, ...marketGroups];
  };

  filterRankData = () => {
    let checkedKeySet = new Set(this.props.PriceDataReducer.rankCheckedKeys.map(k => {
      if (k.indexOf('|') >= 0) {
        return parseInt(k.split('|')[1]);
      } else {
        return k;
      }
    }));
    return this.state.rankData
      .filter(d => checkedKeySet.has('root') || checkedKeySet.has(d.productId))
      .filter((d) => {
        let productId = d.productId;
        let typeIdSde = _.find(this.props.PriceDataReducer.allTypeIdSde, (t) => t.typeId === productId);
        if (typeIdSde == null) {
          return false;
        }

        let passTechLevelCheck = false;
        for (let techLevelArray of this.state.techLevels) {
          if (techLevelArray.indexOf(typeIdSde.metaGroupId) >= 0) {
            passTechLevelCheck = true;
            break;
          }
        }
        if (passTechLevelCheck === false) {
          return false;
        }

        if (!_.isEmpty(this.state.searchKey) && typeIdSde.name.toLowerCase().indexOf(this.state.searchKey.toLowerCase()) < 0) {
          return false;
        }

        return true;
      });
  };

  getColumnOptions = () => {
    let optionsAll = { label: OPTIONS_ALL, value: OPTIONS_ALL };
    return [
      optionsAll,
      ...columns.map((c) => {
        return { label: c.title, value: c.key };
      }),
    ];
  };

  getTableColumns = () => {
    return _.filter(columns, (c) => this.state.displaydColumns.indexOf(c.key) >= 0);
  };

  exportExcel = (filteredRankData) => {
    let wb = ExcelUtil.getExcel(filteredRankData);
    // 写文件(book,xlsx文件名称)
    let extractMaterials = this.props.SettingReducer.extractMaterials == null ? true : this.props.SettingReducer.extractMaterials;
    let fileName = `利润排行(${extractMaterials ? '' : '不'}分解原料) ${moment().format('yyyy-MM-DD HH:mm:ss')}.xlsx`;
    XLSX.writeFile(wb, fileName);
  }

  renderSpinOrTable = () => {
    if (_.isEmpty(this.state.rankData)) {
      return (
        <div style={{ position: 'absolute', left: '50%', top: '50%', display: 'flex' }}>
          <Progress type="circle" percent={this.state.blueprintCalculated / this.state.rankDataWillLength * 100} format={(percent) => `${this.state.blueprintCalculated}/${this.state.rankDataWillLength}`} />
        </div>
      );
    } else {
      let filteredRankData = this.filterRankData();
      if (this.state.viewMode === 0) {
        return (<div>
          <span style={{ marginTop: '10px', marginLeft: '10px', position: 'absolute', zIndex: 1 }}>
            {_.isEmpty(filteredRankData) ? '' : (
              <Tooltip title='保存为Excel'>
                <Button type="default" icon={<FileExcelOutlined />} size="Large" onClick={() => this.exportExcel(filteredRankData)} />
              </Tooltip>
            )}

          </span>
          <Table columns={this.getTableColumns()} dataSource={filteredRankData} size='small' pagination={{ position: ['topRight'] }} />
        </div>)
      } else {
        return (
          <div>
            <RankSpreadSheet filteredRankData={filteredRankData} />
          </div>
        )
      }
    };
  }


  getTreeData = () => {
    let allBlueprintList = this.props.PriceDataReducer.allBlueprint.filter((bp) => {
      if (bp.typeIdSde == null) {
        return false;
      }
      if (_.isEmpty(bp.typeIdSde.categories)) {
        return false;
      }
      if (!_.isEmpty(this.state.searchKey) && bp.typeIdSde.name.toLowerCase().indexOf(this.state.searchKey.toLowerCase()) < 0) {
        return false;
      }
      return true;
    });

    let result = this.generateTreeData([], allBlueprintList);
    let favouriteTree = this.getFavouriteTree();

    result = [...favouriteTree, ...result];
    let root = {
      title: '全部',
      key: 'root',
      children: result
    }
    return [root];
  };


  getFavouriteTree = () => {
    if (!_.isEmpty(this.props.SettingReducer.favourites)) {
      let favouriteBlueprintList = this.props.SettingReducer.favourites.sort().map(blueprintTypeId => {
        let blueprint = ProfitHelper.getBlueprintByTypeId(blueprintTypeId);
        if (blueprint == null) {
          return null;
        }
        blueprint = _.cloneDeep(blueprint);
        blueprint.typeIdSde.categories = ['收藏', blueprint.typeIdSde.categories[0]];
        return blueprint;
      });
      favouriteBlueprintList = _.filter(favouriteBlueprintList, b => {
        if (b == null) {
          return false;
        }
        if (!_.isEmpty(this.state.searchKey) && b.typeIdSde.name.indexOf(this.state.searchKey) < 0) {
          return false;
        }
        return true;
      });
      let favouriteTree = this.generateTreeData([], favouriteBlueprintList);
      return favouriteTree;
    } else {
      return [];
    }
  }

  generateTreeData = (parentList, allBlueprintList) => {
    let result = [];
    let childNameSet = new Set();
    allBlueprintList.forEach((bp) => {
      let isBelongsToParent = true;
      for (let i = 0; i < parentList.length; i++) {
        if (bp.typeIdSde.categories[i] !== parentList[i]) {
          isBelongsToParent = false;
        }
      }
      if (isBelongsToParent) {
        if (bp.typeIdSde.categories[parentList.length] != null) {
          childNameSet.add(bp.typeIdSde.categories[parentList.length]);
        } else {
          result.push({
            blueprintTypeId: bp.blueprintTypeId,
            key: parentList.join('-') + '|' + bp.typeIdSde.typeId,
            title: bp.typeIdSde.name
          });
        }
      }
    });
    childNameSet.forEach((name) => {
      result.push({
        key: parentList.join('-') + name,
        title: name,
        children: this.generateTreeData([...parentList, name], allBlueprintList),
      });
    });
    return result;
  };

  checkTreeNode = (keys, info) => {
    this.props.PriceDataAction.setRankCheckedKeys(keys);
    // this.setState({ checkedKeys: keys }, () => {
    //   this.calcRankData(false);
    // });
  };

  expandTreeNode = (keys, info) => {
    this.setState({ expandedKeys: keys });
  };

  getKeys = (treeData) => {
    let result = treeData.map(t => t.key);
    for (let node of treeData) {
      if (!_.isEmpty(node.children)) {
        result = [...result, ...this.getKeys(node.children)];
      }
    }
    return result;
  }

  viewModeChange = (e) => {
    this.setState({ viewMode: e.target.value });
  }

  selectTreeNode = (e) => {
    if (!isNaN(e[0])) {
      this.setState({ selectedKeys: [...e] })
    } else {
      if (_.find(this.state.expandedKeys, k => k === e[0]) != null) {
        this.setState({ expandedKeys: this.state.expandedKeys.filter(k => k !== e[0]) });
      } else {
        this.setState({ expandedKeys: [...this.state.expandedKeys, ...e] });
      }
    }
  };

  onSearch = (e) => {
    this.setState({ searchKey: e }, () => {
      let treeData = this.getTreeData();
      let expandedKeys = [];
      if (!_.isEmpty(this.state.searchKey)) {
        expandedKeys = this.getKeys(treeData);
        expandedKeys = expandedKeys.filter(k => isNaN(k));
      } else {
        expandedKeys = ['root'];
      }
      this.setState({ treeData, expandedKeys });
    });
  };

  treeNodeRender = (nodeData) => {
    if (isNaN(nodeData.key)) {
      return (<div>{nodeData.title}</div>);
    } else {
      return <div><img style={{ width: '25px', marginRight: '5px' }} nsrc={`https://image.evepc.163.com/Type/${nodeData.key}_64.png`} src={`https://images.evetech.net/types/${nodeData.key}/icon`}></img>{nodeData.title}</div>;
    }
  }

  render() {
    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ width: '300px', marginTop: '10px', overflowY: 'scroll', maxHeight: '700px' }}>
          <div style={{ margin: '10px 0', marginLeft: '40px' }}>
            <Radio.Group value={this.state.viewMode} onChange={this.viewModeChange}>
              <Radio value={0}>网页风格</Radio>
              <Radio value={1}>EXCEL风格</Radio>
            </Radio.Group>
          </div>
          <div style={{ marginBottom: '10px' }}>
            <Input.Search placeholder='输入物品名称' onSearch={this.onSearch} />
          </div>
          <Tree blockNode treeData={this.getTreeData()} onCheck={this.checkTreeNode} onSelect={this.selectTreeNode}
            checkedKeys={this.props.PriceDataReducer.rankCheckedKeys} checkable titleRender={this.treeNodeRender}
            onExpand={this.expandTreeNode} expandedKeys={this.state.expandedKeys} selectedKeys={this.state.selectedKeys} />
        </div>
        <div style={{ flex: '1' }}>
          <div style={{ marginTop: '15px' }}>
            <Collapse bordered={false}>
              <Panel header='过滤器' key='2'>
                <div className='flex'>
                  {/* <div className='rank-filter'>
                    <Tooltip title='除了在蓝图计算页面锁定了始终收购的原料以外，其他原料全部自产。会大幅延长计算时间，请耐心等待。'>
                      <div className='rank-filter-name'>全部原料自产</div>
                    </Tooltip>
                    <Switch checkedChildren="是" unCheckedChildren="否" checked={this.props.SettingReducer.extractMaterials == null ? true : this.props.SettingReducer.extractMaterials} onChange={() => this.props.SettingAction.toggleExtractMaterials()} />
                  </div> */}

                </div>
                <div className='rank-filter'>
                  <div className='rank-filter-name'>自产这些原料</div>
                  <div style={{ 'flex': 1 }}>
                    <Checkbox.Group style={{ width: '100%' }} value={this.props.SettingReducer.selfProductCategories} onChange={this.onSelfProductChange} >
                      <Row>
                        <Col span={6}>
                          <Checkbox value="加工过的行星材料">P1菜（加工过的行星材料）</Checkbox>
                        </Col>
                        <Col span={6}>
                          <Checkbox value="精炼过的行星材料">P2菜（精炼过的行星材料）</Checkbox>
                        </Col>
                        <Col span={6}>
                          <Checkbox value="特殊行星材料">P3菜（特殊行星材料）</Checkbox>
                        </Col>
                        <Col span={6}>
                          <Checkbox value="高级行星材料">P4菜（高级行星材料）</Checkbox>
                        </Col>
                        <Col span={8}>
                          <Checkbox value="加工过的卫星材料">P1月矿（加工过的卫星材料）</Checkbox>
                        </Col>
                        <Col span={8}>
                          <Checkbox value="高级卫星材料">P2月矿（高级卫星材料）</Checkbox>
                        </Col>
                        <Col span={8}>
                          <Checkbox value="燃料块">燃料块</Checkbox>
                        </Col>
                        <Col span={8}>
                          <Checkbox value="聚合物材料">聚合物材料</Checkbox>
                        </Col>
                        <Col span={8}>
                          <Checkbox value="增效剂材料">增效剂材料</Checkbox>
                        </Col>
                        <Col span={8}>
                          <Checkbox value="分子熔铸材料">分子熔铸材料</Checkbox>
                        </Col>
                        <Col span={4}>
                          <Checkbox value="标准旗舰组件">T1旗舰组件</Checkbox>
                        </Col>
                        <Col span={4}>
                          <Checkbox value="高级旗舰组件">T2旗舰组件</Checkbox>
                        </Col>
                        <Col span={4}>
                          <Checkbox value="高级组件">T2组件</Checkbox>
                        </Col>
                        <Col span={4}>
                          <Checkbox value="子系统组件">T3子系统组件</Checkbox>
                        </Col>
                        <Col span={4}>
                          <Checkbox value="建筑组件">建筑组件</Checkbox>
                        </Col>
                        <Col span={4}>
                          <Checkbox value="防护性组件">防护性组件</Checkbox>
                        </Col>
                        <Col span={12}>
                          <Checkbox value="舰船">T1舰船（选中后会变得很卡）</Checkbox>
                        </Col>
                        <Col span={12}>
                          <Checkbox value="舰船装备">T1装备（选中后会变得很卡）</Checkbox>
                        </Col>
                        <Col span={12}>
                          <Checkbox disabled={true} value="locked">自产所有锁定自产的物品</Checkbox>
                        </Col>
                        <Col span={12}>
                          <Checkbox disabled={true} value="locked">收购所有锁定收购的物品</Checkbox>
                        </Col>
                      </Row>
                    </Checkbox.Group>
                  </div>
                </div>
                <div className='rank-filter'>
                  <div className='rank-filter-name'>科技等级</div>
                  <Checkbox.Group options={TECH_OPTIONS} value={this.state.techLevels} onChange={this.onTechLevelChange} />
                </div>
                <div className='rank-filter'>
                  <div className='rank-filter-name'>显示/隐藏列</div>
                  <Checkbox.Group
                    options={this.getColumnOptions()}
                    value={this.state.displaydColumns}
                    onChange={this.onDisplayedColumnChange}
                  />
                </div>
              </Panel>
            </Collapse>
          </div>
          <div>
            {this.renderSpinOrTable()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    PriceDataReducer: state.PriceDataReducer,
    ItemInfoReducer: state.ItemInfoReducer,
    SettingReducer: state.SettingReducer
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    PriceDataAction: bindActionCreators(PriceDataAction, dispatch),
    SettingAction: bindActionCreators(SettingAction, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfitPercentRank);
