import React from 'react';
import * as PriceDataAction from '../action/PriceDataAction';
import * as ItemInfoAction from '../action/ItemInfoAction';
import * as SettingAction from '../action/SettingAction';
import MaterialList from './MaterialList';
import MaterialTree from './MaterialTree';
import IndustryProcess from './IndustryProcess';
import IndustryPlan from './IndustryPlan';
import Orders from './Orders.js';
import DecoderCompare from './DecoderCompare.js';
import T3InventCompare from './T3InventCompare.js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Tooltip, Menu, Select, Tabs, Radio, Statistic, Row, Col, Button, Spin, Table, Dropdown, InputNumber, message } from 'antd';
import { BulbOutlined, PlayCircleOutlined, ClockCircleOutlined, StarOutlined, StarFilled, EyeOutlined, CopyOutlined, EyeInvisibleOutlined, CheckOutlined, CloseOutlined, UnorderedListOutlined, BarChartOutlined, TableOutlined, DollarOutlined, SisternodeOutlined, ForwardOutlined, SettingOutlined } from '@ant-design/icons';
import copy from "copy-to-clipboard";
import _, { isUndefined } from 'lodash';
import * as Utils from '../common/Utils';
import * as T3Utils from '../common/T3Utils';
import * as UrlHelper from '../action/UrlHelper';
import ProfitHelper from '../common/ProfitHelper';
import moment from 'moment';
import Minimap from 'js-minimap';

const { TabPane } = Tabs;
const { SubMenu } = Menu;
const { Option } = Select;

class SingleBlueprintView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lastRowKey: 0,
      showOrders: false,
      showInventResource: false,
      showDecoderCompare: false,
      showT3Compare: false,
      editingTypeId: 0,
      editingValue: 0,
      extractMaterialHistory: []
    };
    this.ignoreNextOpenKeys = false;
    this.minimap = null;
    this.minimapContainer = null;
    this.minimapTarget = null;
  }

  componentDidMount() {
    // this.showMiniMap();
  }

  componentWillReceiveProps(nextProps) {
  }

  componentWillUpdate(nextProps, nextState) {

  }

  componentDidUpdate() {
    // this.showMiniMap();
  }

  showMiniMap = () => {
    if (this.getMaterialDisplayMode() !== 2) {
      return;
    }
    const container = document.getElementById('mmpContainer');
    const target = document.getElementById('minimap');

    if (container == null || target == null) {
      return;
    }

    if (this.minimap == null) {
      this.minimap = new Minimap({
        container,
        target,
        width: 200,
        observe: false
      });
      this.minimapContainer = container;
      this.minimapTarget = target;
    } else {
      if (this.minimapContainer === container && this.minimapTarget === target) {
        setTimeout(() => {
          this.minimap.reset();
        }, 1000);
      } else {
        this.minimap = new Minimap({
          container,
          target,
          width: 200,
          observe: false
        });
        this.minimapContainer = container;
        this.minimapTarget = target;
      }
    }
  }

  setExtractBlueprintIds = (extractedBids) => {
    let blueprintTypeId = this.props.blueprintSetting.blueprintTypeId;
    this.props.SettingAction.setExtract(blueprintTypeId, extractedBids);
  }

  getExtractBlueprintIds = () => {
    let blueprintTypeId = this.props.blueprintSetting.blueprintTypeId;
    let result = _.find(this.props.SettingReducer.extract, e => e.typeId === blueprintTypeId);
    result = _.cloneDeep(result);
    if (_.isEmpty(result)) {
      return [...this.props.SettingReducer.alwaysMakeBids];
    }
    result.extract = [...result.extract, ...this.props.SettingReducer.alwaysMakeBids];
    result.extract = _.without(result.extract, ...this.props.SettingReducer.alwaysBuyBids);
    return result.extract;
  }

  doEditPrice = () => {
    this.props.SettingAction.setUseCustomPrice(true);
    console.log('新的价格是' + this.state.editingValue)
    if (this.state.editingValue == null) {
      this.props.SettingAction.removeCustomPrice(this.state.editingTypeId);
    } else {
      this.props.SettingAction.setCustomPrice(this.state.editingTypeId, this.state.editingValue);
    }
    this.setState({ editingTypeId: 0 });
  }

  onPriceChange = (value) => {
    this.setState({ editingValue: value });
  }

  startEditPrice = (record) => {
    this.setState({ editingTypeId: record.key, editingValue: record.singlePrice });
  }

  cancelPriceChange = () => {
    this.setState({ editingTypeId: 0 });
  }

  cancelClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
  }

  getBlueprintConfigForCalc = () => {
    if (this.props.blueprintSetting.materialEffect != null) {
      return { materialEffect: this.props.blueprintSetting.materialEffect };
    }

    return { materialEffect: this.props.SettingReducer.materialEffect };
  };

  toLocaleString = (number) => {
    if (number == null) {
      return '0';
    } else {
      return number.toLocaleString();
    }
  };


  extractMaterialsRecursive = (productIdList) => {
    let materialBlueprintIdList = ProfitHelper.getAllMaterialBlueprintIdRecursive(productIdList, 0, false);
    if (!_.isEmpty(materialBlueprintIdList)) {
      this.setState({ extractMaterialHistory: [materialBlueprintIdList, ...this.state.extractMaterialHistory] })
    }

    materialBlueprintIdList = Array.from(new Set([...materialBlueprintIdList, ...this.getExtractBlueprintIds()]));
    // this.setState({ selectedRowKeys: [] });
    this.setExtractBlueprintIds(materialBlueprintIdList);
  };


  onProductQuantChange = (e) => {
    let blueprintSetting = _.clone(this.props.blueprintSetting);
    blueprintSetting.productQuantity = e;
    this.props.SettingAction.setBlueprintSetting(blueprintSetting);
  };

  onBlueprintCountChange = (e) => {
    let blueprintSetting = _.clone(this.props.blueprintSetting);
    blueprintSetting.blueprintCount = e;
    this.props.SettingAction.setBlueprintSetting(blueprintSetting);
  };

  onMaterialEffectChange = (e) => {
    let blueprintSetting = _.clone(this.props.blueprintSetting);
    blueprintSetting.materialEffect = e;
    this.props.SettingAction.setBlueprintSetting(blueprintSetting);
  };

  getInventBaseBlueprint = () => {
    return ProfitHelper.getInventBaseBlueprint(this.props.blueprintSetting.blueprintTypeId);
  }

  onT3InventChange = (decoderId, ancientId) => {
    let blueprintSetting = _.clone(this.props.blueprintSetting);
    blueprintSetting.decoderId = decoderId;
    blueprintSetting.ancientId = ancientId;
    T3Utils.generateT3BlueprintSetting(blueprintSetting);
    this.props.SettingAction.setBlueprintSetting(blueprintSetting);
  }

  onInventChange = (decoderId) => {
    let blueprintSetting = _.clone(this.props.blueprintSetting);
    blueprintSetting.decoderId = decoderId;

    let inventBaseBlueprint = ProfitHelper.getInventBaseBlueprint(blueprintSetting.blueprintTypeId);
    let product = _.find(inventBaseBlueprint.activities.invention.products, p => p.typeId === blueprintSetting.blueprintTypeId);

    let quantity = 1;
    let materialEffect = 2;
    if (decoderId < 0) {
      //免费发明
      quantity = blueprintSetting.productQuantity;
    } else if (decoderId == 0) {
      quantity = product.quantity;
    } else {
      let decoder = _.find(this.props.ItemInfoReducer.allDecoders, d => d.typeId === decoderId);
      quantity = product.quantity + decoder.quantity;
      materialEffect += decoder.materialEffect;
    }

    blueprintSetting.materialEffect = materialEffect;
    blueprintSetting.productQuantity = quantity;
    this.props.SettingAction.setBlueprintSetting(blueprintSetting);
  }


  getDecoderOptions = () => {
    if (_.isEmpty(this.props.ItemInfoReducer.allDecoders)) {
      return [];
    }
    let decoderOptions = this.props.ItemInfoReducer.allDecoders.map(d => {
      let label = this.getDecoderDescription(d.typeId);
      return { key: d.typeId, label: label }
    });
    decoderOptions = [
      {
        key: 0,
        label: '不使用解码器'
      },
      ...decoderOptions,
      {
        key: -1,
        label: '发明成本为0'
      },
    ];
    return decoderOptions;
  }

  getDecoderDescription = (decoderId) => {
    if (decoderId < 0) {
      return '发明成本为0';
    } else if (decoderId == 0) {
      return '不使用解码器';
    } else {
      let decoder = _.find(this.props.ItemInfoReducer.allDecoders, d => d.typeId === decoderId);
      if (decoder == null) {
        return '';
      }
      let marketDump = ProfitHelper.getMarketDumpById(decoder.typeId);
      let price = marketDump == null ? 0 : marketDump.price;
      return `${decoder.name} ${decoder.materialEffect}材料 ${decoder.timeEffect}时间 ${decoder.quantity}流程 成功率*${(decoder.probability + 1)} 价格${Utils.formatISK(price)}ISK`;
    }
  }

  onDecoderClick = (e) => {
    console.log(e.key);
    this.onInventChange(Number(e.key));
  };

  getFinalMaterialPercent = () => {
    let blueprintPercent = 0.9 + 0.01 * (10 - this.getBlueprintConfigForCalc().materialEffect);
    let blueprint = ProfitHelper.getBlueprintByTypeId(this.props.blueprintSetting.blueprintTypeId);
    let productId = blueprint == null ? -1 : blueprint.typeIdSde.typeId;
    let archPercent = ProfitHelper.getMaterialEffectByProduct(productId);
    return { blueprintPercent, archPercent };
  }

  toggleShowOrders = () => {
    this.setState({ showOrders: !this.state.showOrders });
  }

  toggleShowInventResource = () => {
    this.setState({ showInventResource: !this.state.showInventResource });
  }

  toggleShowDecoderCompare = () => {
    this.setState({ showDecoderCompare: !this.state.showDecoderCompare });
  }

  toggleShowT3Compare = () => {
    this.setState({ showT3Compare: !this.state.showT3Compare });
  }

  toggleFavourite = () => {
    this.props.SettingAction.toggleFavourite(this.props.blueprintSetting.blueprintTypeId);
  }

  isFavourite = () => {
    return _.find(this.props.SettingReducer.favourites, f => f === this.props.blueprintSetting.blueprintTypeId) != null;
  }

  renderStatisticText = (text, suffix) => {
    return (<div style={{ fontSize: '20px' }}>
      {text + (suffix == null ? '' : ' ' + suffix)}
    </div>)
  }

  generateInventCeilQuantity = (dataSources) => {
    let ceilCount = 1;
    while (true) {
      let enough = true;
      dataSources.forEach(ds => {
        if (ds.ceilQuantity * ceilCount < ds.quantity) {
          enough = false;
        }
      })
      if (enough) {
        break;
      } else {
        ceilCount++;
      }
    }
    return dataSources.map(ds => {
      ds.ceilQuantity = ds.ceilQuantity * ceilCount;
      return ds;
    });
  }

  generateResource = (typeId, quantity, ceilQuantity) => {
    let typeIdSde = ProfitHelper.getTypeIdSdeById(typeId);
    let marketDump = ProfitHelper.getMarketDumpById(typeId);
    let price = _.isEmpty(marketDump) ? 0 : marketDump.price;
    return {
      category: ProfitHelper.getCategory(typeId),
      ceilQuantity: ceilQuantity,
      key: typeId,
      typeId: typeId,
      name: typeIdSde.name,
      singlePrice: price,
      totalPrice: price * quantity,
      quantity: quantity,
      volume: typeIdSde.volume * quantity
    }
  }

  calcProfitPercent = (resources) => {
    let totalProfit = 0;
    resources.forEach(r => {
      totalProfit += r.totalPrice;
    });
    resources.forEach(r => {
      r.profitPercent = Utils.toPercentString(r.totalPrice / totalProfit);
    })
  }

  setMaterialDisplayMode = (mode) => {
    this.props.SettingAction.setViewMode(mode);
  }

  getMaterialDisplayMode = () => {
    if (this.props.tabInfo.viewMode == null) {
      return 1;
    } else {
      return this.props.tabInfo.viewMode;
    }
  }


  getDecoderName = (item) => {
    if (item.decoderId === -1) {
      return '免费';
    } else if (item.decoderId === 0) {
      return '无解码器';
    } else {
      let decoder = _.find(this.props.ItemInfoReducer.allDecoders, d => Number(d.typeId) === item.decoderId);
      if (decoder == null) {
        return '';
      } else {
        return decoder.name.substr(0, 2);
      }
    }
  }

  renderIndustryProcess = (resourceListObj) => {
    let context = resourceListObj == null ? {} : resourceListObj.context;
    let blueprintSettings = [this.props.blueprintSetting];
    let productId = ProfitHelper.getProductByBlueprintId(this.props.blueprintSetting.blueprintTypeId);
    return (
      <IndustryProcess context={context} blueprintSettings={blueprintSettings}
        getExtractBlueprintIds={this.getExtractBlueprintIds}
        setExtractBlueprintIds={this.setExtractBlueprintIds} resourceListObj={resourceListObj}
        extractMaterialsRecursive={() => this.extractMaterialsRecursive([productId.typeId])}
        extractRequired={this.extractRequired} plans={this.props.plans} showMiniMap={this.showMiniMap} />
    );
  }

  renderIndustryPlan = (resourceListObj) => {
    let context = resourceListObj == null ? {} : resourceListObj.context;
    return (
      <IndustryPlan context={context} blueprintSettings={[this.props.blueprintSetting]} plans={this.props.plans} />
    );
  }

  renderTreeView = (resourceListObj) => {
    let context = resourceListObj == null ? {} : resourceListObj.context;
    let productId = ProfitHelper.getProductByBlueprintId(this.props.blueprintSetting.blueprintTypeId);
    return (
      <MaterialTree context={context} getExtractBlueprintIds={this.getExtractBlueprintIds}
        setExtractBlueprintIds={this.setExtractBlueprintIds} resourceListObj={resourceListObj}
        extractMaterialsRecursive={() => this.extractMaterialsRecursive([productId.typeId])}
        extractRequired={this.extractRequired} tabInfo={this.props.tabInfo} />
    );
  }

  renderListView = (resourceListObj, resourceWithInventMaterials) => {
    let product = ProfitHelper.getProductByBlueprintId(this.props.blueprintSetting.blueprintTypeId);
    return <MaterialList resourceListObj={resourceListObj} resourceWithInventMaterials={resourceWithInventMaterials}
      getExtractBlueprintIds={this.getExtractBlueprintIds} setExtractBlueprintIds={this.setExtractBlueprintIds}
      extractMaterialsRecursive={() => this.extractMaterialsRecursive([product.typeId])}
      extractRequired={this.extractRequired} />
  }

  extractRequired = (threshold) => {
    let blueprint = ProfitHelper.getBlueprintByTypeId(this.props.blueprintSetting.blueprintTypeId);
    let blueprintSettingWithBlueprint = _.clone(this.props.blueprintSetting);
    blueprintSettingWithBlueprint.blueprint = blueprint;

    let product = ProfitHelper.getProductByBlueprintId(this.props.blueprintSetting.blueprintTypeId);
    let materialBlueprintIdList = ProfitHelper.getAllMaterialBlueprintIdRecursive([product.typeId], 0, false);

    let selfProductBlueprintIdList = [...materialBlueprintIdList];
    let buyBlueprintIdList = [];
    for (let round = 0; round < 20; round++) {
      let shoudBreak = true;
      let [resourceList, context] = ProfitHelper.getResourceList([blueprintSettingWithBlueprint], selfProductBlueprintIdList);
      console.log('extractRequired');
      console.log(materialBlueprintIdList);
      console.log(context);
      for (let step of context.processList) {
        for (let resource of step) {
          let depositoryQuantity = ProfitHelper.getDepositoryQuantity(resource.key);
          if (resource.ceilQuantity * threshold <= depositoryQuantity) {
            let blueprint = ProfitHelper.getBlueprintByProductId(resource.key);
            if (blueprint == null) {
              continue;
            }
            if (buyBlueprintIdList.includes(blueprint.blueprintTypeId)) {
              continue;
            }
            buyBlueprintIdList.push(blueprint.blueprintTypeId);
            shoudBreak = false;
            console.log(`${resource.name}: 启动制造需要${resource.ceilQuantity}个，库存${depositoryQuantity}个,所以收购${resource.name}`)
          }
        }
      }
      selfProductBlueprintIdList = selfProductBlueprintIdList.filter(id => !buyBlueprintIdList.includes(id));
      if (shoudBreak) {
        console.log('最终自产的原料是：');
        console.log(selfProductBlueprintIdList);
        this.setExtractBlueprintIds(selfProductBlueprintIdList);
        break;
      }
    }
  }

  isT3Product = () => {
    let blueprintTypdId = this.props.blueprintSetting.blueprintTypeId;
    let t3List = T3Utils.getT3List();
    for (let ancient of t3List) {
      if (ancient.products.includes(blueprintTypdId)) {
        return true;
      }
    }
    return false;
  }

  selectAncient = (e) => {
    let ancientId = Number(e.key);
    let newBlueprintSetting = _.clone(this.props.blueprintSetting);
    newBlueprintSetting.ancientId = ancientId;
    T3Utils.generateT3BlueprintSetting(newBlueprintSetting);
    this.props.SettingAction.setBlueprintSetting(newBlueprintSetting);
  }


  ancientManu = (ancients) => {
    return (
      <Menu mode="vertical" onClick={(e) => this.selectAncient(e)} >
        {ancients.map(a => {
          let marketDump = ProfitHelper.getMarketDumpById(a.ancientId);
          let price = marketDump == null ? 0 : marketDump.price;
          let ancientDescription = `${a.ancientName} 成功率:${Math.round(a.probability * 100)}% 流程:${a.productQuantity} 价格:${Utils.formatISK(price)} ISK`;
          return (<Menu.Item key={a.ancientId} icon={<img style={{ width: '24px' }}
            src={`https://image.evepc.163.com/Type/${a.ancientId}_64.png`} />}>{ancientDescription}</Menu.Item>);
        })}
      </Menu>
    )
  }


  selectDecoder = (e) => {
    let decoderId = Number(e.key);
    let newBlueprintSetting = _.clone(this.props.blueprintSetting);
    newBlueprintSetting.decoderId = decoderId;
    T3Utils.generateT3BlueprintSetting(newBlueprintSetting);
    this.props.SettingAction.setBlueprintSetting(newBlueprintSetting);
  }

  decoderMenu = () => {
    return (
      <Menu mode="vertical" onClick={(e) => this.selectDecoder(e)} >
        <Menu.Item key={0} icon={<img style={{ width: '24px' }}
          src={ProfitHelper.getTypeImage(34201)} />}>不使用解码器</Menu.Item>
        {this.props.ItemInfoReducer.allDecoders.map(d => {
          let marketDump = ProfitHelper.getMarketDumpById(d.typeId);
          let price = marketDump == null ? 0 : marketDump.price;
          let decoderDescription = `${d.name} 成功率*${Math.round((1 + d.probability) * 100)}% 材料效率${d.materialEffect >= 0 ? `+${d.materialEffect}` : d.materialEffect} 流程+${d.quantity} 价格:${Utils.formatISK(price)} ISK`;
          return (<Menu.Item key={d.typeId} icon={<img style={{ width: '24px' }}
            src={ProfitHelper.getTypeImage(34201)} />}>{decoderDescription}</Menu.Item>);
        })}
        <Menu.Item key={-1} icon={<img style={{ width: '24px' }}
          src={ProfitHelper.getTypeImage(34201)} />}>发明成本为0</Menu.Item>
      </Menu>
    )
  }

  getDecoderShortName = (decoderId) => {
    if (decoderId < 0) {
      return '0成本';
    } else if (decoderId === 0) {
      return '不使用';
    } else {
      let decoder = ProfitHelper.getDecoderById(decoderId);
      return decoder.name.substr(0, 2);
    }
  }

  renderT3Panel = () => {
    let availableAncient = T3Utils.getT3List().filter(a => a.products.includes(this.props.blueprintSetting.blueprintTypeId));
    let ancient = availableAncient[0];
    if (this.props.blueprintSetting.ancientId != null) {
      ancient = _.find(T3Utils.getT3List(), a => a.ancientId === this.props.blueprintSetting.ancientId);
    }

    let decoderId = 0;
    if (this.props.blueprintSetting.decoderId != null) {
      decoderId = this.props.blueprintSetting.decoderId;
    }

    let decoderDescripton = this.getDecoderDescription(decoderId);
    let decoderShortName = this.getDecoderShortName(decoderId);
    return (
      <div>
        <div>
          <div style={{ display: 'flex' }}>
            <Tooltip title={'显示发明材料'}>
              <Button type="default" style={{ borderRadius: '2px', marginRight: '5px' }} icon={this.state.showInventResource ? <EyeOutlined /> : <EyeInvisibleOutlined />} onClick={() => this.toggleShowInventResource()} size='middle' />
            </Tooltip>
            <Dropdown overlay={this.ancientManu(availableAncient)}>
              <Tooltip title={ancient.ancientName}>
                <Button type="default" icon={<img style={{ width: '24px', position: 'relative', left: '-5px', top: '-2px' }}
                  src={`https://image.evepc.163.com/Type/${ancient.ancientId}_64.png`} />}>{ancient.ancientName.substr(0, 3)}</Button>
              </Tooltip>
            </Dropdown>
          </div>

          <div style={{ display: 'flex' }}>
            <Tooltip title={'对比解码器'}>
              <Button type="default" style={{ borderRadius: '2px', marginRight: '5px' }} icon={<BulbOutlined />} onClick={() => this.toggleShowT3Compare()} size='middle' />
            </Tooltip>
            <Dropdown overlay={this.decoderMenu()}>
              <Tooltip title={decoderDescripton}>
                <Button type="default" icon={<img style={{ width: '24px', position: 'relative', left: '-5px', top: '-2px' }}
                  src={ProfitHelper.getTypeImage(34201)} />}>{decoderShortName}</Button>
              </Tooltip>
            </Dropdown>
          </div>
        </div>
      </div>)
  }

  isReady = () => {
    return !_.isEmpty(this.props.PriceDataReducer.allBlueprint) && !_.isEmpty(this.props.PriceDataReducer.allTypeIdSde)
      && !_.isEmpty(this.props.PriceDataReducer.allMarketDump) && !_.isEmpty(this.props.PriceDataReducer.allMarketHistory)
      && !_.isEmpty(this.props.PriceDataReducer.industryIndices);
  }

  render() {
    if (this.props.tabKey !== this.props.SettingReducer.activeTabKey) {
      return ''
    }
    if (this.props.blueprintSetting == null) {
      return '标签页已失效';
    }

    if (!this.isReady()) {
      return (
        <div style={{ position: 'absolute', left: '50%', top: '50vh' }}>
          <Spin size='large' />
        </div>
      )
    }

    let blueprint = ProfitHelper.getBlueprintByTypeId(this.props.blueprintSetting.blueprintTypeId);
    if (blueprint == null) {
      return (
        <div style={{ position: 'absolute', left: '50%', top: 'calc(50vh - 100px)' }}>
          <Spin size='large' />
        </div>
      );
    }


    let blueprintSettingWithBlueprint = _.clone(this.props.blueprintSetting);
    blueprintSettingWithBlueprint.blueprint = blueprint;
    let [resourceList, context] = ProfitHelper.getResourceList([blueprintSettingWithBlueprint], this.getExtractBlueprintIds());
    console.log(context)
    let resourceListObj = { resourceList, context };

    let inventDataSource = context.inventDataSource;
    let resourceWithInventMaterials = resourceList == null ? [] : [...resourceList];
    if (this.state.showInventResource) {
      let inventResources = inventDataSource.map(m => {
        return this.generateResource(m.typeId, m.quantity, m.ceilQuantity);
      });
      console.log('inventResources');
      console.log(inventResources);
      inventResources = this.generateInventCeilQuantity(inventResources);
      resourceWithInventMaterials.push(...inventResources);
      context.dataTree.push(...inventResources);
      this.calcProfitPercent(resourceWithInventMaterials);
    }

    // let [context, context] = this.calcBlueprintProfitSummary(resourceListObj);
    let isWorth = context.singleProfit >= 0;
    let blueprintConfig = this.getBlueprintConfigForCalc();

    let productQuantityPerBatch = blueprint.activities.manufacturing.products[0].quantity;
    let finalMaterialEffect = this.getFinalMaterialPercent();

    let isT3Product = this.isT3Product();
    return (
      <div>
        <Orders show={this.state.showOrders} onOK={() => this.toggleShowOrders()} typeIdSde={blueprint.typeIdSde} />
        <DecoderCompare show={this.state.showDecoderCompare} onOK={() => this.toggleShowDecoderCompare()}
          blueprintSetting={this.props.blueprintSetting} blueprint={blueprint} getExtractBlueprintIds={this.getExtractBlueprintIds}
          onInventChange={this.onInventChange} />
        <T3InventCompare show={this.state.showT3Compare} onOK={() => this.toggleShowT3Compare()}
          blueprintSetting={this.props.blueprintSetting} blueprint={blueprint} getExtractBlueprintIds={this.getExtractBlueprintIds}
          onInventChange={this.onT3InventChange} />
        <div style={{ margin: '5px 0px' }} className='flex'>
          <div style={{ display: 'flex' }}>
            <span>
              <img src={ProfitHelper.getTypeImage(blueprint.typeIdSde.typeId)} />
            </span>
            <span style={{ fontSize: '2em', marginLeft: '10px', minWidth: '150px' }}>{context.name}{productQuantityPerBatch === 1 ? '' : ' ×' + productQuantityPerBatch}</span>
            <span style={{ marginLeft: '10px', position: 'relative', top: '5px' }}>
              <Tooltip title={'查看市场订单'}>
                <Button type="default" style={{ borderRadius: '2px' }} icon={<BarChartOutlined />} size="Large" onClick={() => this.toggleShowOrders()} />
              </Tooltip>
            </span>
            <span style={{ marginLeft: '10px', position: 'relative', top: '5px' }}>
              <Tooltip title={this.isFavourite() ? '已收藏' : '未收藏'}>
                {this.isFavourite() ? <Button type="default" style={{ borderRadius: '2px' }} icon={<StarFilled style={{ color: 'orange' }} />} size="Large" onClick={() => this.toggleFavourite()} />
                  : <Button type="default" style={{ borderRadius: '2px' }} icon={<StarOutlined style={{ color: 'orange' }} />} size="Large" onClick={() => this.toggleFavourite()} />
                }
              </Tooltip>
            </span>
            <span style={{ display: 'flex', marginLeft: '50px', position: 'relative', top: '5px' }}>
              <Tooltip title={'分解视图'}>
                <Button type="default" icon={<SisternodeOutlined />} style={{ borderRadius: '2px', boxShadow: this.getMaterialDisplayMode() === 1 ? '0px 0px 10px gray inset' : '' }} size="Large" onClick={() => this.setMaterialDisplayMode(1)} />
              </Tooltip>
              <Tooltip title={'原料列表'}>
                <Button type="default" icon={<UnorderedListOutlined />} style={{ borderRadius: '2px', boxShadow: this.getMaterialDisplayMode() === 0 ? '0px 0px 10px gray inset' : '' }} size="Large" onClick={() => this.setMaterialDisplayMode(0)} />
              </Tooltip>
              <Tooltip title={'生产步骤'}>
                <Button type="default" icon={<ForwardOutlined />} style={{ borderRadius: '2px', boxShadow: this.getMaterialDisplayMode() === 2 ? '0px 0px 10px gray inset' : '' }} size="Large" onClick={() => this.setMaterialDisplayMode(2)} />
              </Tooltip>
              <Tooltip title={'生产计划'}>
                <Button type="default" icon={<ClockCircleOutlined />} style={{ borderRadius: '2px', boxShadow: this.getMaterialDisplayMode() === 3 ? '0px 0px 10px gray inset' : '' }} size="Large" onClick={() => this.setMaterialDisplayMode(3)} />
              </Tooltip>
            </span>
          </div>

          <div style={{ marginLeft: 'auto', display: 'flex', marginTop: '5px' }}>
            {isT3Product ? (this.renderT3Panel()) : ''}
            {this.getInventBaseBlueprint() == null ? '' : (
              <div style={{ display: this.getInventBaseBlueprint() == null ? 'none' : 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex' }}>
                  <div>
                    <Dropdown menu={{ items: this.getDecoderOptions(), onClick: (e) => this.onDecoderClick(e) }} placement="bottom" trigger={['click']} arrow>
                      <Tooltip title={this.getDecoderDescription(this.props.blueprintSetting.decoderId)}>
                        <Button type="default" icon={<img style={{ width: '30px', marginTop: '-6px', marginLeft: '-3px' }} src={ProfitHelper.getTypeImage(34201)}></img>} style={{ borderRadius: '2px', boxShadow: this.state.leftDisplayMode === 0 ? '0px 0px 10px gray inset' : '' }} size="Large">{this.getDecoderName(this.props.blueprintSetting)}</Button>
                      </Tooltip>
                    </Dropdown>
                  </div>
                  <div style={{ marginLeft: '10px', display: 'flex' }}>
                    <Tooltip title={'显示发明材料'}>
                      <Button type="default" style={{ borderRadius: '2px' }} icon={this.state.showInventResource ? <EyeOutlined /> : <EyeInvisibleOutlined />} size="Large" onClick={() => this.toggleShowInventResource()} />
                    </Tooltip>
                    <Tooltip title={'对比解码器'}>
                      <Button type="default" icon={<BulbOutlined />} style={{ borderRadius: '2px' }} size="Large" onClick={() => this.toggleShowDecoderCompare()} />
                    </Tooltip>
                  </div>
                </div>
                <div style={{ marginTop: '5px', marginLeft: '10px' }}>发明成本：{Utils.formatISK(context.singleInventFee)} ISK</div>
              </div>
            )}


            <div style={{ display: 'flex', height: '32px', margin: '0 20px' }}>
              <div style={{ display: 'flex' }}>
                <div style={{ margin: '0 5px', fontSize: '1.5em', position: 'relative', top: '-3px', cursor: 'pointer' }}>
                  <Tooltip title={`${this.props.blueprintSetting.blueprintCount} 张蓝图`}><CopyOutlined /></Tooltip>
                </div>
                <InputNumber size="middle" style={{ width: '50px' }} min={1} value={this.props.blueprintSetting.blueprintCount} onChange={this.onBlueprintCountChange} />
              </div>
              <div style={{ display: 'flex' }}>
                <div style={{ margin: '0 5px', fontSize: '1.5em', position: 'relative', top: '-3px', cursor: 'pointer' }}>
                  <Tooltip title={`每张蓝图 ${this.props.blueprintSetting.productQuantity} 流程`}><ForwardOutlined /></Tooltip>
                </div>
                <InputNumber size="middle" style={{ width: '50px' }} min={1} value={this.props.blueprintSetting.productQuantity} onChange={this.onProductQuantChange} />
              </div>
              <div style={{ display: 'flex' }}>
                <div style={{ margin: '0 5px', fontSize: '1.5em', position: 'relative', top: '-3px', cursor: 'pointer' }}>
                  <Tooltip title={`最终材料效率：${(finalMaterialEffect.blueprintPercent * finalMaterialEffect.archPercent * 100).toFixed(4)}% （蓝图 ${finalMaterialEffect.blueprintPercent * 100}% * 建筑 ${(finalMaterialEffect.archPercent * 100).toFixed(4)}%）`}>
                    <SettingOutlined />
                  </Tooltip>
                </div>
                <InputNumber size="middle" style={{ width: '50px' }} min={0} max={10} value={blueprintConfig.materialEffect} onChange={this.onMaterialEffectChange} />
              </div>
            </div>
          </div>
        </div>
        <div style={{ paddingRight: '5px' }}>
          <div style={{ marginRight: '15px' }}>
            <Row gutter={10}>
              <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                <Tooltip placement="left" title={() => {
                  let materialCost = context.singleMaterialCost;
                  let solarSystemFee = context.singleSolarSystemFee;
                  let safetyCommitteeFee = context.singleSafetyCommitteeFee;
                  let singleInventFee = context.singleInventFee;
                  let customsFee = context.singleCustomsFee;
                  let transportFee = context.singleTransportFee;
                  let singleMarketFee = context.singleMarketFee;
                  return (<div>
                    {materialCost == 0 ? '' : <div style={{ display: 'flex' }}><span>原料成本：</span><span style={{ marginLeft: 'auto' }}>{Utils.formatISK(materialCost)}</span></div>}
                    {solarSystemFee == 0 ? '' : <div style={{ display: 'flex' }}><span>星系成本：</span><span style={{ marginLeft: 'auto' }}>{Utils.formatISK(solarSystemFee)}</span></div>}
                    {safetyCommitteeFee == 0 ? '' : <div style={{ display: 'flex' }}><span>商业安全委员会：</span><span style={{ marginLeft: 'auto' }}>{Utils.formatISK(safetyCommitteeFee)}</span></div>}
                    {singleInventFee == 0 ? '' : <div style={{ display: 'flex' }}><span>发明成本：</span><span style={{ marginLeft: 'auto' }}>{Utils.formatISK(singleInventFee)}</span></div>}
                    {singleMarketFee == 0 ? '' : <div style={{ display: 'flex' }}><span>市场税：</span><span style={{ marginLeft: 'auto' }}>{Utils.formatISK(singleMarketFee)}</span></div>}
                    {customsFee == 0 ? '' : <div style={{ display: 'flex' }}><span>海关税: </span><span style={{ marginLeft: 'auto' }}>{Utils.formatISK(customsFee)}</span></div>}
                    {transportFee == 0 ? '' : <div style={{ display: 'flex' }}><span>原料运费: </span><span style={{ marginLeft: 'auto' }}>{Utils.formatISK(transportFee)}</span></div>}
                  </div>)
                }}>
                  <Statistic title='单件成本' value={`${Utils.formatISK(context.singleCost)}`} formatter={(text) => this.renderStatisticText(text, 'ISK')} />
                </Tooltip></Col>
              <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                <Statistic title='单件价格' value={`${Utils.formatISK(context.singlePrice)}`} formatter={(text) => this.renderStatisticText(text, 'ISK')} />
              </Col>
              <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                <Statistic
                  title='20天平均成交价格'
                  value={`${Utils.formatISK(context.dailyPrice == null ? 0 : context.dailyPrice)}`}
                  formatter={(text) => this.renderStatisticText(text, 'ISK')}
                />
              </Col>
              <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                <Statistic
                  title='单件利润'
                  valueStyle={{ color: isWorth ? 'green' : 'red' }}
                  value={`${Utils.formatISK(context.singleProfit)}`}
                  formatter={(text) => this.renderStatisticText(text, 'ISK')}
                />
              </Col>
              <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                <Statistic
                  title='利润率'
                  valueStyle={{ color: isWorth ? 'green' : 'red' }}
                  value={`${Utils.toPercentString(context.profitPercent)}`}
                  formatter={(text) => this.renderStatisticText(text)}
                />
              </Col>
            </Row>
          </div>
          <div id='minimap' style={{ display: this.getMaterialDisplayMode() === 2 ? '' : 'none', zIndex: 3, position: 'fixed', bottom: '60px', boxShadow: 'grey 0px 0px 5px 5px' }}></div>
          <div id='mmpContainer' style={{ height: 'calc(100vh - 260px)', overflowY: 'scroll', overflowX: 'auto', paddingRight: '5px' }}>
            <Row gutter={10}>
              <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                <Statistic title='总成本' value={`${Utils.formatISK(context.totalCost)}`} formatter={(text) => this.renderStatisticText(text, 'ISK')} />
              </Col>
              <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                <Statistic
                  title='总价格'
                  value={`${Utils.formatISK(context.totalPrice == null ? 0 : context.totalPrice)}`}
                  formatter={(text) => this.renderStatisticText(text, 'ISK')}
                />
              </Col>
              <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                <Statistic title='总利润' valueStyle={{ color: isWorth ? 'green' : 'red' }} value={`${Utils.formatISK(context.totalProfit)}`} formatter={(text) => this.renderStatisticText(text, 'ISK')} />
              </Col>
              <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                <Statistic
                  title='全天利润'
                  valueStyle={{ color: isWorth ? 'green' : 'red' }}
                  value={`${Utils.formatISK(context.dailyProfit)}`}
                  formatter={(text) => this.renderStatisticText(text, 'ISK')}
                />
              </Col>
              <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                <Statistic
                  title='生产用时'
                  value={Utils.formatSeconds(context.time * 0.544)}
                  formatter={(text) => this.renderStatisticText(text)}
                />
              </Col>
              <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                <Statistic
                  title='全天产量'
                  value={`${context.dailyQuantity == null ? 0 : context.dailyQuantity}`}
                  formatter={(text) => this.renderStatisticText(Utils.formatISK(text), '个')}
                />
              </Col>
              <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                <Statistic
                  title='20天日均成交数量'
                  value={`${context.dailyVolume == null ? 0 : context.dailyVolume.toFixed(2)}`}
                  formatter={(text) => this.renderStatisticText(text, '个')}
                />
              </Col>
              <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                <Statistic
                  title='出货效率'
                  value={`${Utils.toPercentString(context.dailyVolume / context.dailyQuantity)}`}
                  formatter={(text) => this.renderStatisticText(text)}
                />
              </Col>
              <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                <Statistic
                  title='原料体积'
                  value={`${context.resourceVolume == null ? 0 : context.resourceVolume}`}
                  formatter={(text) => this.renderStatisticText(Utils.formatISK(text), 'm³')}
                />
              </Col>
              <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                <Statistic
                  title='产物体积'
                  value={`${context.productVolume == null ? 0 : context.productVolume}`}
                  formatter={(text) => this.renderStatisticText(text, 'm³')}
                />
              </Col>
            </Row>
            {
              (() => {
                if (this.getMaterialDisplayMode() === 0) {
                  return this.renderListView(resourceListObj, resourceWithInventMaterials);
                } else if (this.getMaterialDisplayMode() === 1) {
                  return this.renderTreeView(resourceListObj);
                } else if (this.getMaterialDisplayMode() === 2) {
                  return this.renderIndustryProcess(resourceListObj);
                } else {
                  return this.renderIndustryPlan(resourceListObj);
                }
              })()
            }
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    PriceDataReducer: state.PriceDataReducer,
    ItemInfoReducer: state.ItemInfoReducer,
    SettingReducer: state.SettingReducer,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    PriceDataAction: bindActionCreators(PriceDataAction, dispatch),
    ItemInfoAction: bindActionCreators(ItemInfoAction, dispatch),
    SettingAction: bindActionCreators(SettingAction, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleBlueprintView);
