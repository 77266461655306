import * as ActionTypes from '../action/ActionTypes';
import update from 'immutability-helper';

const initialState = {
  typeIdSde: {},
  iconSde: {},
  allDecoders: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_TYPEID_SDE_SUCCESS:
      return update(state, { typeIdSde: { $set: action.data } });
    case ActionTypes.GET_ICON_SDE_SUCCESS:
      return update(state, { iconSde: { $set: action.data } });
    case ActionTypes.GET_ALL_DECODERS_SUCCESS:
      return update(state, { allDecoders: { $set: action.data } });
    default:
      return state;
  }
};
