import * as ActionTypes from "../action/ActionTypes";
import update from "immutability-helper";

const initialState = {
    qqUser: {},
    settings: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.GET_QQUSER_BY_OPENID_SUCCESS:
            return update(state, { qqUser: { $set: action.data } });
        case ActionTypes.LIST_USER_SETTING_SUCCESS:
            return update(state, { settings: { $set: action.data } });
        default:
            return state;
    }
};
