import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as QQUserAction from '../action/QQUserAction';
import { Descriptions, Modal, Input, message, Button, Tooltip } from "antd";
import { EditOutlined, CloseOutlined, CheckOutlined } from "@ant-design/icons";
import _ from 'lodash';

class QQUserProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editing: false,
            newUserName: ''
        };
    }

    componentDidMount() {
        this.setState({ newUserName: this.props.QQUserReducer.qqUser.name })
    }

    onOK = () => {
        this.props.onCancel();
    };

    onCancel = () => {
        this.props.onCancel();
    };

    epochToText = (epoch) => {
        if (isNaN(epoch)) {
            return '';
        } else {
            return new Date(epoch).toISOString().replace('T', ' ').replace('Z', '');
        }
    }

    commitNewUserName = () => {
        let newUserName = this.state.newUserName.trim();
        if (_.isEmpty(newUserName)) {
            message.info('用户名不能为空。')
            return;
        }
        if (newUserName.length > 7) {
            message.info('用户名不能长于7个字。')
            return;
        }
        this.props.QQUserAction.isValidName(newUserName).then(text => {
            console.log('isValidName');
            console.log(text);
            console.log(text === 'true');
            if (text === 'true') {
                let loginStatus = window.QC.Login.check();
                if (loginStatus == true) {
                    window.QC.Login.getMe((openId, accessToken) => {
                        this.props.QQUserAction.setUserName(openId, newUserName);
                        message.info('改名成功！')
                        this.setState({ editing: false });
                    })
                }else{
                    message.info('遇到了奇怪的问题。建议联系开发者特斯拉与你。')
                }
            } else {
                message.info('昵称已存在。')
            }
        })
    }

    onNewNameChange = (e) => {
        this.setState({ newUserName: e.target.value });
    }

    renderUserName = () => {
        let qqUser = this.props.QQUserReducer.qqUser;
        return (
            <div style={{ display: 'flex' }}>
                <div style={{ lineHeight: '32px' }}>{qqUser.name}</div>
                <div style={{ marginLeft: '20px' }}>
                    <Tooltip title='修改昵称'>
                        <Button type="default" style={{ borderRadius: '2px' }} icon={<EditOutlined />} size="middle" onClick={() => this.setState({ editing: true })} />
                    </Tooltip>
                </div>
            </div>
        )
    }

    renderEditUserName = () => {
        return (
            <div style={{ display: 'flex' }}>
                <Input placeholder="输入昵称(最长7个字)" onChange={this.onNewNameChange} value={this.state.newUserName} />
                <div style={{ marginLeft: '20px' }}>
                    <Tooltip title='确定'>
                        <Button type="default" style={{ borderRadius: '2px' }} icon={<CheckOutlined />} size="middle" onClick={() => this.commitNewUserName()} />
                    </Tooltip>
                </div>
                <div style={{ marginLeft: '10px' }}>
                    <Tooltip title='取消'>
                        <Button type="default" style={{ borderRadius: '2px' }} icon={<CloseOutlined />} size="middle" onClick={() => this.setState({ editing: false })} />
                    </Tooltip>
                </div>
            </div>
        )
    }

    render() {
        let qqUser = this.props.QQUserReducer.qqUser;
        return (
            <div>
                <Modal title="账号设置" visible={this.props.show} onOk={this.onOK} onCancel={this.onCancel} okText={'好'} cancelText={'关闭'}>
                    <div style={{}}>
                        <Descriptions bordered={true}>
                            <Descriptions.Item span={3} label="昵称">
                                {this.state.editing ? this.renderEditUserName() : this.renderUserName()}
                            </Descriptions.Item>
                            <Descriptions.Item span={3} label="创建时间">{this.epochToText(qqUser.createAt)}</Descriptions.Item>
                            <Descriptions.Item span={3} label="最近登录时间">{this.epochToText(qqUser.lastLoginAt)}</Descriptions.Item>
                        </Descriptions>
                    </div>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        QQUserReducer: state.QQUserReducer,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        QQUserAction: bindActionCreators(QQUserAction, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(QQUserProfile);
