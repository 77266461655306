import * as ActionTypes from "../action/ActionTypes";
import update from "immutability-helper";

const initialState = {
  preRefineItems: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_PRE_REFINE_ITEMS_SUCCESS:
      return update(state, { preRefineItems: { $set: [...action.data] } });
    default:
      return state;
  }
};
