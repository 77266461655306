import React from "react";
import { Layout, Modal, Input, message, Button } from "antd";
import * as SettingAction from '../action/SettingAction';
import { initialState } from '../reducer/SettingReducer';
import { ExclamationCircleOutlined } from "@ant-design/icons";
import copy from "copy-to-clipboard";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
const { TextArea } = Input;

class ImportSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newSetting: ''
        };
    }

    componentDidMount() { }

    back = () => {
        this.props.setStatus('DEFAULT');
    }

    onChangeSetting = (e) => {
        this.setState({ newSetting: e.target.value });
    }

    isJSON = (str) => {
        if (typeof str == 'string') {
            try {
                var obj = JSON.parse(str);
                if (typeof obj == 'object' && obj) {
                    return true;
                } else {
                    return false;
                }

            } catch (e) {
                console.log('error：' + str + '!!!' + e);
                return false;
            }
        }
        return false;
    }

    showWarning = (title, message) => {
        return new Promise((resolve, reject) => {
            Modal.confirm({
                title: title,
                icon: <ExclamationCircleOutlined />,
                content: message,
                okText: '是',
                okType: 'danger',
                cancelText: '否',
                onOk: () => {
                    resolve();
                }
                ,
                onCancel: () => {
                    reject();
                },
            });
        })
    }

    onImport = () => {
        let base64Str = this.state.newSetting;
        if (_.isEmpty(base64Str)) {
            base64Str = 'e30=';
        }
        try {
            if (!this.isJSON(window.decodeURIComponent(atob(base64Str)))) {
                message.info('请不要输入一些奇怪的东西(°ー°〃)');
                return;
            }
        } catch (err) {
            message.info('请不要输入一些奇怪的东西(°ー°〃)');
            return;
        }
        let newSetting = JSON.parse(window.decodeURIComponent(atob(base64Str)));
        if (_.isEmpty(newSetting)) {
            this.showWarning('导入空设置', '导入空设置将会清空所有当前设置。请问确定要这么做吗？').then(() => {
                return this.showWarning('导入空设置', '除非网页崩溃或出现严重错误，此外并不建议通过导入空设置来清空现有设置。请问确定要清空现有设置吗？');
            }).then(() => {
                return this.showWarning('导入空设置', '请问你真的真的真的要清空现有设置吗？');
            }).then(() => {
                this.props.SettingAction.importSettings(initialState);
                message.info('已清空设置。')
            }).catch(() => {
                message.info('中止导入空设置。')
            });
        } else {
            this.showWarning('导入新设置', '导入新设置将会覆盖所有当前设置。请问确定要这么做吗？')
                .then(() => {
                    this.props.SettingAction.importSettings(newSetting);
                    message.info('已导入新设置。')
                }).catch(() => {
                    message.info('中止导入新设置。')
                });
        }
    }

    render() {
        return (
            <div>
                <div style={{ height: '300px', marginBottom: '10px' }}>
                    <TextArea style={{ height: '100%', resize: 'none' }} value={this.state.newSetting}
                        onChange={this.onChangeSetting} placeholder='把之前导出的配置粘贴到这里' />
                </div>
                <div style={{ display: 'flex' }}>
                    <div style={{ position: 'absolute', left: '25%' }}>
                        <Button type='primary' onClick={this.onImport}>
                            确认导入
                        </Button>
                    </div>
                    <div style={{ position: 'absolute', right: '25%' }}>
                        <Button type='primary' onClick={this.back}>
                            返回
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        SettingReducer: state.SettingReducer
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        SettingAction: bindActionCreators(SettingAction, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportSettings);