import * as ActionTypes from "./ActionTypes";
import MessageBoardApi from "../api/MessageBoardApi";
import * as UrlHelper from './UrlHelper';

const host = UrlHelper.getHost();
const hostAndPort = `http://${host}:8080`;

export const createMessage = (message, authCodeId, authCodeInput, openId) => {
  return function (dispatch, getState) {
    return MessageBoardApi.createMessage(hostAndPort, message, authCodeId, authCodeInput, openId)
      .then((text) => {
        return dispatch({ type: ActionTypes.CREATE_MESSAGE_SUCCESS, data: text });
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const getMessage = (pageSize, pageNum) => {
  return function (dispatch, getState) {
    return MessageBoardApi.getMessage(hostAndPort, pageSize, pageNum)
      .then((json) => {
        return dispatch({ type: ActionTypes.GET_MESSAGE_SUCCESS, data: { pageSize, pageNum, pageData: json } });
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const getMessageCount = () => {
  return function (dispatch, getState) {
    return MessageBoardApi.getMessageCount(hostAndPort)
      .then((text) => {
        return dispatch({ type: ActionTypes.GET_MESSAGE_COUNT_SUCCESS, data: text });
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const uploadAttachment = (base64) => {
  return function (dispatch, getState) {
    return MessageBoardApi.uploadAttachment(hostAndPort, base64);
  };
};

export const getIP = () => {
  return function (dispatch, getState) {
    return MessageBoardApi.getIP(hostAndPort)
      .then((text) => {
        return dispatch({ type: ActionTypes.GET_IP_SUCCESS, data: text });
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const getAuthCode = () => {
  return function (dispatch, getState) {
    return MessageBoardApi.getAuthCode(hostAndPort)
      .then((authCode) => {
        return dispatch({ type: ActionTypes.GET_AUTH_CODE_SUCCESS, data: authCode });
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const removeMessage = (messageId, openId) => {
  return function (dispatch, getState) {
    return MessageBoardApi.removeMessage(hostAndPort, messageId, openId)
      .then((text) => {
        return dispatch({ type: ActionTypes.REMOVE_MESSAGE_SUCCESS });
      })
      .catch((err) => {
        throw err;
      });
  };
};