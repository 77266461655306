import React from "react";
import { Layout, Modal, Input, message, Button } from "antd";
import { LineChartOutlined } from "@ant-design/icons";
import copy from "copy-to-clipboard";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
const { TextArea } = Input;

class ExportSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() { }

    back = () => {
        this.props.setStatus('DEFAULT');
    }

    onCopy = () => {
        copy(this.getBase64Setting(), { format: 'text/plain' });
        message.info('所有设置已经复制到剪贴板。');
    }

    getBase64Setting = () => {
        return btoa(window.encodeURIComponent(JSON.stringify(this.props.SettingReducer)));
    }

    render() {
        return (
            <div>
                <div style={{ height: '300px', marginBottom: '10px' }}>
                    <TextArea style={{ height: '100%', resize: 'none' }} readonly="readonly" value={this.getBase64Setting()} />
                </div>
                <div style={{ display: 'flex' }}>
                    <div style={{ position: 'absolute', left: '25%' }}>
                        <Button type='primary' onClick={this.onCopy}>
                            复制到剪贴板
                        </Button>
                    </div>
                    <div style={{ position: 'absolute', right: '25%' }}>
                        <Button type='primary' onClick={this.back}>
                            返回
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        SettingReducer: state.SettingReducer
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExportSettings);