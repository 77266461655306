import React from "react";
import { Table, Switch, Tooltip, Menu, Button, Dropdown, message, InputNumber, Radio } from "antd";
import { UnlockOutlined, CheckOutlined, StopOutlined, LockFilled, PlusSquareOutlined, MinusSquareOutlined, CopyOutlined, FileExcelOutlined, AlipayOutlined, ToolOutlined } from "@ant-design/icons";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import * as SettingAction from '../action/SettingAction';
import * as Utils from '../common/Utils';
import shoubing from "../resource/shoubing.jpg";
import ProfitHelper from '../common/ProfitHelper';
import copy from "copy-to-clipboard";
import * as IDUtils from '../common/IDUtils';

const { SubMenu } = Menu;

class MaterialTree extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editingTypeId: 0,
            editingValue: 0,
        };
    }

    columns = [
        {
            title: '',
            dataIndex: 'typeId',
            sorter: Utils.createNumericSorter('typeId'),
            render: (text, record, index) => {
                return (<div style={{ display: 'flex', top: '5px' }}><img style={{ width: '25px' }} src={ProfitHelper.getTypeImage(record.typeId)}></img></div>)
            },
        },
        {
            title: '原料名称',
            dataIndex: 'name',
            key: 'name',
            sorter: Utils.createStringSorter('name'),
        },
        {
            title: '原料类别',
            dataIndex: 'category',
            key: 'category',
            sorter: Utils.createStringSorter('category'),
        },
        {
            title: '来源',
            dataIndex: 'typeId',
            render: (text, record, index) => {
                let currentProductBlueprint = ProfitHelper.getBlueprintByProductId(text);
                let productBid = currentProductBlueprint == null ? null : currentProductBlueprint.blueprintTypeId;
                let isSelfProducted = this.isSelfProducted(productBid);
                let isAlwaysBuy = this.isAlwaysBuy(productBid);
                let isAlwaysMake = this.isAlwaysMake(productBid);
                return (<div>
                    <Switch checkedChildren="自产" unCheckedChildren="收购" checked={isSelfProducted}
                        onChange={(checked, e) => this.onSwitchChange(record.typeId, checked, e)} />
                    <span style={{ marginLeft: '10px', display: productBid == null ? 'none' : (isSelfProducted ? '' : 'none') }}>
                        <Tooltip title={'始终自产'}>
                            <span style={{ display: isAlwaysMake ? 'none' : '' }}>
                                <Button type="default" icon={<UnlockOutlined />} style={{ color: 'gray' }} size="small" onClick={(e) => this.setAlwaysMake(productBid, e)} />
                            </span>
                            <span style={{ display: isAlwaysMake ? '' : 'none' }}>
                                <Button type="default" icon={<LockFilled />} style={{ color: 'orange' }} size="small" onClick={(e) => this.removeAlwaysMake(productBid, e)} />
                            </span>
                        </Tooltip>
                    </span>
                    <span style={{ marginLeft: '10px', display: productBid == null ? 'none' : (isSelfProducted ? 'none' : '') }}>
                        <Tooltip title={'始终收购'}>
                            <span style={{ display: isAlwaysBuy ? 'none' : '' }}>
                                <Button type="default" icon={<UnlockOutlined />} style={{ color: 'gray' }} size="small" onClick={(e) => this.setAlwaysBuy(productBid, e)} />
                            </span>
                            <span style={{ display: isAlwaysBuy ? '' : 'none' }}>
                                <Button type="default" icon={<LockFilled />} style={{ color: 'orange' }} size="small" onClick={(e) => this.removeAlwaysBuy(productBid, e)} />
                            </span>
                        </Tooltip>
                    </span>
                </div>)
            },
        },
        {
            title: '数量',
            dataIndex: 'quantity',
            key: 'quantity',
            sorter: Utils.createNumericSorter('quantity'),
            render: (value, record) => {
                return Utils.formatISK(value, 0)
            },
            align: 'right',
        },
        {
            title: '库存比例%',
            dataIndex: 'depositoryPercent',
            sorter: Utils.createNumericSorter('depositoryPercent'),
            render: (value, record) => {
                let blueprint = ProfitHelper.getBlueprintByProductId(record.typeId);
                let bid = blueprint == null ? 0 : blueprint.blueprintTypeId;
                let isSelfProducted = this.isSelfProducted(bid);
                let isWarning = isSelfProducted && (value > 1);
                return <div style={{ color: isWarning ? 'orange' : '', fontWeight: isWarning ? 'bold' : '' }}>{Utils.toPercentString(value)}</div>
            },
            align: 'right',
        },
        {
            title: '单价',
            dataIndex: 'singlePrice',
            key: 'singlePrice',
            sorter: Utils.createNumericSorter('singlePrice'),
            render: (value, record) => {
                return this.renderPrice(value, record);
            },
            align: 'right',
        },
        {
            title: '总价',
            dataIndex: 'totalPrice',
            key: 'totalPrice',
            sorter: Utils.createNumericSorter('totalPrice'),
            render: (value, record) => {
                return Utils.formatISK(value, 2) + ' ISK';
            },
            align: 'right',
        },
        {
            title: '体积（m³）',
            dataIndex: 'volume',
            sorter: Utils.createNumericSorter('volume'),
            render: (value, record) => {
                return Utils.formatISK(value, 2).replace('.00', '');
            },
            align: 'right',
        },
    ]

    setAlwaysBuy = (bid, e) => {
        e.preventDefault();
        e.stopPropagation();
        if (bid == null) {
            return;
        }
        this.props.SettingAction.setAlwaysBuy(bid);
    }

    onPriceChange = (value) => {
        this.setState({ editingValue: value });
    }

    cancelPriceChange = () => {
        this.setState({ editingTypeId: 0 });
    }

    renderPrice = (value, record) => {
        if (record.typeId === this.state.editingTypeId) {
            return (
                <div style={{ display: 'flex', justifyContent: 'right' }}>
                    <InputNumber controls={false} value={value} onChange={(value) => this.onPriceChange(value)} />
                    <div style={{ marginLeft: '5px' }}>
                        <Tooltip title='确定 (删除输入框内文本再点确定，可以还原成市场价)' style={{ marginLeft: '5px' }}>
                            <Button type="default" icon={<CheckOutlined />} onClick={() => this.doEditPrice()} size="Small" />
                        </Tooltip>
                    </div>
                    <div style={{ marginLeft: '5px' }}>
                        <Tooltip title='取消' >
                            <Button type="default" icon={<StopOutlined />} onClick={() => this.cancelPriceChange()} size="Large" />
                        </Tooltip>
                    </div>
                </div >
            )
        } else {
            return (
                <Tooltip title='双击修改物品价格' placement='right'>
                    <div style={{ cursor: 'pointer' }} onDoubleClick={() => this.startEditPrice(record)}>
                        {
                            (() => {
                                let customPrice = ProfitHelper.getCustomPriceById(record.typeId);
                                if (this.props.SettingReducer.useCustomPrice && !_.isEmpty(customPrice)) {
                                    return (
                                        <div style={{ color: 'darkorange', fontWeight: 'bold' }}>
                                            {Utils.formatISK(customPrice.price, 2) + ' ISK'}
                                        </div>
                                    );
                                } else {
                                    return Utils.formatISK(value, 2) + ' ISK';
                                }
                            })()
                        }
                    </div>
                </Tooltip>
            )
        }
    }

    doEditPrice = () => {
        this.props.SettingAction.setUseCustomPrice(true);
        console.log('新的价格是' + this.state.editingValue)
        if (this.state.editingValue == null) {
            this.props.SettingAction.removeCustomPrice(this.state.editingTypeId);
        } else {
            this.props.SettingAction.setCustomPrice(this.state.editingTypeId, this.state.editingValue);
        }
        this.setState({ editingTypeId: 0 });
    }

    startEditPrice = (record) => {
        this.setState({ editingTypeId: record.typeId, editingValue: record.singlePrice });
    }

    removeAlwaysBuy = (bid, e) => {
        e.preventDefault();
        e.stopPropagation();
        if (bid == null) {
            return;
        }
        this.props.SettingAction.removeAlwaysBuy(bid);
    }

    setAlwaysMake = (bid, e) => {
        e.preventDefault();
        e.stopPropagation();
        if (bid == null) {
            return;
        }
        this.props.SettingAction.setAlwaysMake(bid);
    }

    removeAlwaysMake = (bid, e) => {
        e.preventDefault();
        e.stopPropagation();
        if (bid == null) {
            return;
        }
        this.props.SettingAction.removeAlwaysMake(bid);
    }

    onSwitchChange = (productId, checked, e) => {
        e.preventDefault();
        e.stopPropagation();

        let extractedBlueprintIds = this.props.getExtractBlueprintIds();
        let currentProductBlueprint = ProfitHelper.getBlueprintByProductId(productId);
        if (currentProductBlueprint == null) {
            return;
        }
        let bid = currentProductBlueprint.blueprintTypeId;
        let newExtractedBids = [];
        if (checked) {
            newExtractedBids = [...extractedBlueprintIds, bid];
        } else {
            newExtractedBids = _.filter(extractedBlueprintIds, b => b !== bid);
        }
        this.props.setExtractBlueprintIds(newExtractedBids);
    }

    getDataSource = () => {
        if (_.isEmpty(this.props.context)) {
            return [];
        }
        let tree = _.cloneDeep(this.props.context.dataTree);
        tree = this.generateKey(tree, '');
        let quantityMap = this.getTypeIdWithCeilQuantity();
        this.setDepositoryPercent(tree, quantityMap);
        return tree;
    }

    getTypeIdWithCeilQuantity = () => {
        let result = new Map();
        for (let processStep of this.props.context.processList) {
            for (let dataSource of processStep) {
                if (dataSource.delay) {
                    continue;
                }
                if (result.has(dataSource.key)) {
                    result.set(dataSource.key, dataSource.ceilQuantity + result.get(dataSource.key));
                } else {
                    result.set(dataSource.key, dataSource.ceilQuantity);
                }
            }
        }
        return result;
    }

    setDepositoryPercent = (dataTree, quantityMap) => {
        for (let dataSource of dataTree) {
            let quantity = quantityMap.has(dataSource.typeId) ? quantityMap.get(dataSource.typeId) : 0;
            let depositoryQuantity = ProfitHelper.getDepositoryQuantity(dataSource.typeId);
            let depositoryPercent = 0;
            if (quantity > 0) {
                depositoryPercent = depositoryQuantity / quantity;
            }
            dataSource.depositoryPercent = depositoryPercent;
            if (!_.isEmpty(dataSource.materials)) {
                this.setDepositoryPercent(dataSource.materials, quantityMap);
            }
        }
    }

    isSelfProducted = (bid) => {
        if (bid == null) {
            return false;
        }
        let extractedBlueprintIds = this.props.getExtractBlueprintIds();

        return _.find(extractedBlueprintIds, b => b === bid) != null;
    }

    isAlwaysBuy = (bid) => {
        if (bid == null) {
            return false;
        }
        return this.props.SettingReducer.alwaysBuyBids.indexOf(bid) >= 0;
    }

    isAlwaysMake = (bid) => {
        if (bid == null) {
            return false;
        }
        return this.props.SettingReducer.alwaysMakeBids.indexOf(bid) >= 0;
    }

    generateKey = (dataSources, prefix) => {
        for (let dataSource of dataSources) {
            let typeIdSde = ProfitHelper.getTypeIdSdeById(dataSource.typeId);
            dataSource.volume = typeIdSde.packagedVolume * dataSource.quantity;
            dataSource.depositoryQuantity = ProfitHelper.getDepositoryQuantity(dataSource.typeId);
            // if (isNaN(dataSource.singlePrice)) {
            //     dataSource.singlePrice = Number(dataSource.singlePrice.replace(/,/g, ''));
            // }
            // if (isNaN(dataSource.totalPrice)) {
            //     dataSource.totalPrice = Number(dataSource.totalPrice.replace(/,/g, ''));
            // }
            dataSource.category = ProfitHelper.getCategory(dataSource.typeId);
            dataSource.key = prefix + '_' + dataSource.typeId;
            if (!_.isEmpty(dataSource.materials)) {
                this.generateKey(dataSource.materials, dataSource.key);
                dataSource.materials = _.sortBy(dataSource.materials, m => m.typeId);
            }
        }

        return _.sortBy(dataSources, m => m.typeId);
    }

    clickMenu = (e) => {
        console.log(e);
        if (e.key === 'makeAll') {
            this.props.extractMaterialsRecursive();
        } else if (e.key === 'buyAll') {
            this.props.setExtractBlueprintIds([]);
        } else if (e.key === 'collapseAll') {
            this.collapseAll();
        } else if (e.key === 'expandAll') {
            this.expandAll();
        } else if (e.key === 'makeDepositoryMissing100') {
            this.props.extractRequired(1);
        } else if (e.key === 'makeDepositoryMissing95') {
            this.props.extractRequired(0.95);
        } else if (e.key === 'makeDepositoryMissing90') {
            this.props.extractRequired(0.9);
        } else if (e.key === 'makeDepositoryMissing85') {
            this.props.extractRequired(0.85);
        } else if (e.key === 'makeDepositoryMissing80') {
            this.props.extractRequired(0.8);
        } else if (e.key === 'expandMake') {
            this.expandMake();
        } else if (e.key === 'copyToGame') {
            this.copyToGame();
        } else if (e.key === 'copyDepositoryMissingToGame') {
            this.copyDepositoryMissingToGame();
        } else if (e.key === 'copyToExcel') {
            this.copyToExcel();
        } else if (e.key === 'copyDepositoryMissingToExcel') {
            this.copyDepositoryMissingToExcel();
        }
    }

    getExpandedRowKeys = () => {
        if (this.props.tabInfo.expandedRowKeys == null) {
            return [];
        } else {
            return this.props.tabInfo.expandedRowKeys;
        }
    }

    collapseAll = () => {
        this.props.SettingAction.setExpandedRowKeys([]);
    }

    expandAll = () => {
        let dataTree = this.getDataSource();
        let keys = this.getAllKeys(dataTree, []);
        this.props.SettingAction.setExpandedRowKeys(keys);
    }

    expandMake = () => {
        let dataTree = this.getDataSource();
        let keys = this.getAllKeysContainsMake(dataTree, []);
        this.props.SettingAction.setExpandedRowKeys(keys);
    }

    getAllKeys = (dataTree, keys) => {
        for (let row of dataTree) {
            if (!_.isEmpty(row.materials)) {
                keys.push(row.key);
                this.getAllKeys(row.materials, keys)
            }
        }
        return _.uniq(keys);
    }

    getAllKeysContainsMake = (dataTree, keys) => {
        let extractedBlueprintIds = this.props.getExtractBlueprintIds();
        for (let row of dataTree) {
            if (!_.isEmpty(row.materials)) {
                for (let material of row.materials) {
                    let materialTypeId = material.typeId;
                    let materialBlueprint = ProfitHelper.getBlueprintByProductId(materialTypeId);
                    if (materialBlueprint == null) {
                        continue;
                    }
                    if (extractedBlueprintIds.indexOf(materialBlueprint.blueprintTypeId) >= 0) {
                        keys.push(row.key);
                    }
                }
                this.getAllKeysContainsMake(row.materials, keys)
            }
        }
        return _.uniq(keys);
    }

    onSelfProductThresholdChange = (e) => {
        console.log('onSelfProductThresholdChange')
        this.props.SettingAction.setSelfProductThreshold(e.target.value);
        e.preventDefault();
        e.stopPropagation();
    };

    menu = () => {
        return (<Menu onClick={(e) => this.clickMenu(e)} mode="vertical"  >
            <Menu.ItemGroup title="展开/收起">
                <Menu.Item key='expandAll' icon={<PlusSquareOutlined style={{ fontSize: '1.3em' }} />}>展开所有</Menu.Item>
                <Menu.Item key='expandMake' icon={<PlusSquareOutlined style={{ fontSize: '1.3em' }} />}>显示所有自产原料</Menu.Item>
                <Menu.Item key='collapseAll' icon={<MinusSquareOutlined style={{ fontSize: '1.3em' }} />}>收起所有</Menu.Item>
            </Menu.ItemGroup>
            <Menu.ItemGroup title="自产/收购">
                <Menu.Item key='makeAll' icon={<ToolOutlined style={{ fontSize: '1.3em' }} />}>全部自产</Menu.Item>
                <SubMenu key="makeDepositoryMissing" icon={<ToolOutlined style={{ fontSize: '1.3em' }} />} title="自产库存数量低于..." >
                    <Menu.Item key='makeDepositoryMissing100' icon={<ToolOutlined style={{ fontSize: '1.3em' }} />}>100%的原料</Menu.Item>
                    <Menu.Item key='makeDepositoryMissing95' icon={<ToolOutlined style={{ fontSize: '1.3em' }} />}>95%的原料</Menu.Item>
                    <Menu.Item key='makeDepositoryMissing90' icon={<ToolOutlined style={{ fontSize: '1.3em' }} />}>90%的原料</Menu.Item>
                    <Menu.Item key='makeDepositoryMissing85' icon={<ToolOutlined style={{ fontSize: '1.3em' }} />}>85%的原料</Menu.Item>
                    <Menu.Item key='makeDepositoryMissing80' icon={<ToolOutlined style={{ fontSize: '1.3em' }} />}>80%的原料</Menu.Item>
                </SubMenu>
                <Menu.Item key='buyAll' icon={<AlipayOutlined style={{ fontSize: '1.3em' }} />}>全部收购</Menu.Item>
            </Menu.ItemGroup>
            <Menu.ItemGroup title="剪贴板">
                <SubMenu key="jiantieban" icon={<CopyOutlined style={{ fontSize: '1.3em' }} />} title="复制..." >
                    <Menu.Item key='copyToGame' icon={<img src={shoubing} style={{ width: "1.5em" }} />}>复制收购的原料到游戏市场</Menu.Item>
                    <Menu.Item key='copyDepositoryMissingToGame' icon={<img src={shoubing} style={{ width: "1.5em" }} />}>复制库存缺少的原料到游戏市场</Menu.Item>
                    <Menu.Item key='copyToExcel' icon={<FileExcelOutlined style={{ fontSize: '1.3em' }} />}>复制收购的原料到Excel</Menu.Item>
                    <Menu.Item key='copyDepositoryMissingToExcel' icon={<FileExcelOutlined style={{ fontSize: '1.3em' }} />}>复制库存缺少的原料到Excel</Menu.Item>
                </SubMenu>
            </Menu.ItemGroup>
        </Menu >
        );
    }

    copyToGame = () => {
        let order = '';
        for (let resource of this.props.resourceListObj.resourceList) {
            order += `${resource.name}\t${resource.ceilQuantity}\n`;
        }
        copy(order);
        message.info(`${this.props.resourceListObj.resourceList.length}件物品订单已经复制到剪贴板。粘贴到游戏市场中来批量购买这些物品。`);
    }

    copyDepositoryMissingToGame = () => {
        this.appendDepositoryQuantity(this.props.resourceListObj.resourceList);
        let order = '';
        let count = 0;
        for (let resource of this.props.resourceListObj.resourceList) {
            if (resource.depositoryMissingCeilQuantity > 0) {
                count++;
                order += `${resource.name}\t${resource.depositoryMissingCeilQuantity}\n`;
            }
        }
        copy(order);
        message.info(`${count}件物品订单已经复制到剪贴板。粘贴到游戏市场中来批量购买这些物品。`);
    }

    copyDepositoryMissingToExcel = () => {
        this.appendDepositoryQuantity(this.props.resourceListObj.resourceList);
        let order = '';
        order += `物品ID\t物品名称\t类别\t数量\t启动制造所需数量\t库存数量\t库存缺口\t启动制造所需库存缺口\t单价\t总价\t总体积\t成本占比\n`;
        for (let resource of this.props.resourceListObj.resourceList) {
            if (resource.depositoryMissingCeilQuantity > 0) {
                order += `${resource.key}\t${resource.name}\t${resource.category}\t${Utils.formatISK(resource.quantity, 0)}\t${resource.ceilQuantity}\t${resource.depositoryQuantity}\t${Utils.formatISK(resource.depositoryMissingQuantity, 0)}\t${resource.depositoryMissingCeilQuantity}\t${resource.singlePrice}\t${resource.totalPrice}\t${resource.volume}\t${resource.profitPercent}\n`;
            }
        }
        copy(order, { format: 'text/plain' });
        message.info(`所有库存缺少的原料已经复制到剪贴板，可以去Excel粘贴了。`);
    }

    copyToExcel = () => {
        this.appendDepositoryQuantity(this.props.resourceListObj.resourceList);
        let order = '';
        order += `物品ID\t物品名称\t类别\t数量\t启动制造所需数量\t库存数量\t库存缺口\t启动制造所需库存缺口\t单价\t总价\t总体积\t成本占比\n`;
        for (let resource of this.props.resourceListObj.resourceList) {
            order += `${resource.key}\t${resource.name}\t${resource.category}\t${Utils.formatISK(resource.quantity, 0)}\t${resource.ceilQuantity}\t${resource.depositoryQuantity}\t${Utils.formatISK(resource.depositoryMissingQuantity, 0)}\t${resource.depositoryMissingCeilQuantity}\t${resource.singlePrice}\t${resource.totalPrice}\t${resource.volume}\t${resource.profitPercent}\n`;
        }
        copy(order, { format: 'text/plain' });
        message.info(`所有需要收购的原料已经复制到剪贴板，可以去Excel粘贴了。`);
    }

    onExpandedRowsChange = (rows) => {
        this.props.SettingAction.setExpandedRowKeys(rows);
    }

    appendDepositoryQuantity = (dataSources) => {
        for (let dataSource of dataSources) {
            let depositoryQuantity = ProfitHelper.getDepositoryQuantity(dataSource.key);
            dataSource.depositoryQuantity = depositoryQuantity;
            let depositoryMissingQuantity = dataSource.quantity - depositoryQuantity;
            if (depositoryMissingQuantity < 0) {
                depositoryMissingQuantity = 0;
            }
            dataSource.depositoryMissingQuantity = depositoryMissingQuantity;

            let depositoryMissingCeilQuantity = dataSource.ceilQuantity - depositoryQuantity;
            if (depositoryMissingCeilQuantity < 0) {
                depositoryMissingCeilQuantity = 0;
            }
            dataSource.depositoryMissingCeilQuantity = depositoryMissingCeilQuantity;
        }
    }

    componentDidMount() { }
    render() {
        console.log(this.props.context);
        return (
            <div>
                <Dropdown overlay={this.menu()} trigger={['contextMenu']}>
                    <div onClick={(e) => e.preventDefault()} >
                        <Table size="small" childrenColumnName='materials'
                            columns={this.columns} onExpand={(e) => console.log(e)}
                            dataSource={this.getDataSource()} expandRowByClick={true}
                            expandedRowKeys={this.getExpandedRowKeys()}
                            onExpandedRowsChange={this.onExpandedRowsChange}
                            pagination={{ showSizeChanger: true, pageSize: 500 }}
                        />
                    </div>
                </Dropdown>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        PriceDataReducer: state.PriceDataReducer,
        SettingReducer: state.SettingReducer,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        SettingAction: bindActionCreators(SettingAction, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MaterialTree);