import React from 'react';
import * as PriceDataAction from '../action/PriceDataAction';
import * as SettingAction from '../action/SettingAction';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Dropdown, Input, Spin, Menu, Table, Statistic, Tooltip, Button, message, InputNumber } from 'antd';
import * as Utils from '../common/Utils';
import ProfitHelper from '../common/ProfitHelper';
import Initializer from '../common/Initializer';
import copy from "copy-to-clipboard";
import PasteBinModal from './PasteBinModal';
import UpsertRefineItemModal from './UpsertRefineItemModal';
import { PlusOutlined, DeleteOutlined, EditOutlined, CheckOutlined, CloseOutlined, SnippetsOutlined, BarChartOutlined, TableOutlined } from '@ant-design/icons';
const { TextArea } = Input;
const { SubMenu } = Menu;

class Depository extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showPasteBinModal: false,
            showUpsertItemModal: false,
            editingItems: []
        };
    }

    componentDidMount() {
        Initializer.initPriceData();
    }

    componentWillReceiveProps(nextProps) {

    }

    getColumns = () => {
        return [
            {
                title: '',
                dataIndex: 'typeId',
                sorter: Utils.createNumericSorter('typeId'),
                render: (text, record, index) => {
                    return (<div><img style={{ width: '25px' }} src={ProfitHelper.getTypeImage(record.typeId)}></img></div>);
                },
                width: '50px'
            },
            {
                title: '物品名称',
                dataIndex: 'name',
                sorter: Utils.createStringSorter('name'),
                render: (text) => <span>{text}</span>,
            },
            {
                title: `数量`,
                dataIndex: 'quantity',
                sorter: Utils.createNumericSorter('quantity'),
                render: (value, record) => this.renderQuantity(record),
                align: 'right',
            },
            {
                title: '单价(最低卖价)',
                dataIndex: 'sellPrice',
                sorter: Utils.createNumericSorter('sellPrice'),
                render: (text) => <span>{`${Utils.formatISK(text)} ISK`}</span>,
                align: 'right',
            },
            {
                title: '单价(最高买价)',
                dataIndex: 'buyPrice',
                sorter: Utils.createNumericSorter('buyPrice'),
                render: (text) => <span>{`${Utils.formatISK(text)} ISK`}</span>,
                align: 'right',
            },
            ,
            {
                title: '总价(最低卖价)',
                dataIndex: 'totalSellPrice',
                sorter: Utils.createNumericSorter('sellPrice'),
                render: (text) => <span>{`${Utils.formatISK(text)} ISK`}</span>,
                align: 'right',
            },
            {
                title: '总价(最高买价)',
                dataIndex: 'totalBuyPrice',
                sorter: Utils.createNumericSorter('buyPrice'),
                render: (text) => <span>{`${Utils.formatISK(text)} ISK`}</span>,
                align: 'right',
            },
            {
                title: '操作',
                dataIndex: 'typeId',
                sorter: Utils.createStringSorter('typeId'),
                render: (typeId, record) => {
                    if (_.find(this.state.editingItems, ei => ei.typeId === typeId) == null) {
                        //not editing
                        return (
                            <div style={{ display: 'flex' }}>
                                <div>
                                    <Tooltip title={'编辑物品'}>
                                        <Button type="default" icon={<EditOutlined />} style={{ borderRadius: '2px' }} onClick={() => this.startEdit(record)} />
                                    </Tooltip>
                                </div>
                                <div style={{ marginLeft: '5px' }}>
                                    <Tooltip title={'删除物品'}>
                                        <Button type="default" icon={<DeleteOutlined />} style={{ borderRadius: '2px' }} onClick={() => this.deleteFromDepository(record)} />
                                    </Tooltip>
                                </div>
                            </div>
                        )
                    } else {
                        return (
                            <div style={{ display: 'flex' }}>
                                <div>
                                    <Tooltip title={'确定'}>
                                        <Button type="default" icon={<CheckOutlined />} style={{ borderRadius: '2px' }} onClick={() => this.finishEdit(record)} />
                                    </Tooltip>
                                </div>
                                <div style={{ marginLeft: '5px' }}>
                                    <Tooltip title={'取消'}>
                                        <Button type="default" icon={<CloseOutlined />} style={{ borderRadius: '2px' }} onClick={() => this.cancelEdit(record)} />
                                    </Tooltip>
                                </div>
                            </div>
                        )
                    }

                }
            }
        ]
    }

    startEdit = (item) => {
        let editingItems = [...this.state.editingItems, _.clone(item)];
        this.setState({ editingItems });
    }

    finishEdit = (item) => {
        let newDepository = _.filter(this.props.SettingReducer.depository, d => d.typeId !== item.typeId);
        let editingItem = _.find(this.state.editingItems, ei => ei.typeId === item.typeId);
        newDepository = [...newDepository, editingItem];
        this.props.SettingAction.setDepository(newDepository);
        this.cancelEdit(item);
    }

    cancelEdit = (item) => {
        let editingItems = _.filter(this.state.editingItems, ei => ei.typeId !== item.typeId);
        this.setState({ editingItems });
    }

    deleteFromDepository = (item) => {
        let newDepository = _.filter(this.props.SettingReducer.depository, d => d.typeId !== item.typeId);
        this.props.SettingAction.setDepository(newDepository);
    }

    onQuantityChange = (value, item) => {
        let editingItems = this.state.editingItems.map(i => {
            if (i.typeId === item.typeId) {
                item.quantity = value;
            }
            return i;
        })
        this.setState({ editingItems })
    }

    renderQuantity = (record) => {
        let editingRecord = _.find(this.state.editingItems, e => e.typeId === record.typeId);
        if (editingRecord != null) {
            return (
                <div style={{ display: 'flex', justifyContent: 'right' }}>
                    <InputNumber controls={false} value={editingRecord.quantity} onChange={(value) => this.onQuantityChange(value, editingRecord)} />
                </div >
            )
        } else {
            return (<span>{`${Utils.formatISK(record.quantity, 0)} 个`}</span>)
        }
    }

    menu = () => {
        return (<Menu  onClick={(e) => this.clickMenu(e)} mode="vertical"  >
            <Menu.ItemGroup title="复制">
                <Menu.Item key='copyToExcel' icon={<SnippetsOutlined style={{ fontSize: '1.3em' }} />}>复制到Excel</Menu.Item>
            </Menu.ItemGroup>
        </Menu >
        );
    }

    clickMenu = (e) => {
        console.log(e);
        if (e.key === 'copyToExcel') {
            {
                let content = this.generateExcelContent();
                copy(content, { format: 'text/plain' });
                message.info(`物品列表已经复制到剪贴板，可以去Excel粘贴了。`);
            }
        }
    }

    generateExcelContent = () => {
        let content = '';
        content += `物品ID\t物品名称\t数量\t单价(最低卖价)\t单价(最高买价)\t总价(最低卖价)\t总价(最高买价)\t`;
        content += '\n';
        this.getDataSource().forEach(ds => {
            content += `${ds.typeId}\t${ds.name}\t${ds.quantity}\t${ds.sellPrice}\t${ds.buyPrice}\t${ds.totalSellPrice}\t${ds.totalBuyPrice}\t`;
            content += '\n';
        })
        return content;
    }

    togglePasteBinModal = () => {
        this.setState({ showPasteBinModal: !this.state.showPasteBinModal });
    }

    toggleUpsertItemModal = () => {
        this.setState({ showUpsertItemModal: !this.state.showUpsertItemModal });
    }

    onPasteBinOK = (newItems) => {
        console.log(newItems);
        this.appendDepository(newItems);
        this.togglePasteBinModal();
    }

    appendDepository = (newItems) => {
        let newDepository = [];
        for (let item of [...this.props.SettingReducer.depository, ...newItems]) {
            let existingItem = _.find(newDepository, d => d.typeId === item.typeId);
            if (existingItem == null) {
                newDepository.push(item);
            } else {
                existingItem.quantity += item.quantity;
            }
        }
        this.props.SettingAction.setDepository(newDepository);
    }

    cleanDepository = () => {
        this.props.SettingAction.setDepository([]);
    }

    getDataSource = () => {
        let result = this.props.SettingReducer.depository.map(d => {
            let typeIdSde = ProfitHelper.getTypeIdSdeById(d.typeId);
            if (_.isEmpty(typeIdSde)) {
                return {};
            }
            let isMineral = false;
            let isBlueprint = false;

            for (let category of ['蓝图和反应']) {
                if (typeIdSde.categories.includes(category)) {
                    isBlueprint = true;
                    break;
                }
            }

            for (let category of ['标准矿石', '冰矿', '卫星矿石', '合金和复合物']) {
                if (typeIdSde.categories.includes(category)) {
                    isMineral = true;
                    break;
                }
            }

            let buyPrice = 0;
            let sellPrice = 0;
            if (!isBlueprint) {
                if (isMineral) {
                    let refinedItems = this.getRefinedItems(d.typeId, d.quantity);
                    refinedItems.forEach(item => {
                        let marketDumpSell = ProfitHelper.getMarketDumpById(item.typeId, { isResource: true, vendor: 0 });
                        sellPrice += (marketDumpSell == null ? 0 : marketDumpSell.price) * item.quantity;

                        let marketDumpBuy = ProfitHelper.getMarketDumpById(item.typeId, { isResource: true, vendor: 1 });
                        buyPrice += (marketDumpBuy == null ? 0 : marketDumpBuy.price) * item.quantity;
                    })
                } else {
                    let marketDumpSell = ProfitHelper.getMarketDumpById(d.typeId, { isResource: true, vendor: 0 });
                    sellPrice += (marketDumpSell == null ? 0 : marketDumpSell.price);

                    let marketDumpBuy = ProfitHelper.getMarketDumpById(d.typeId, { isResource: true, vendor: 1 });
                    buyPrice += (marketDumpBuy == null ? 0 : marketDumpBuy.price);
                }
            }

            let volume = typeIdSde.packagedVolume > 0 ? typeIdSde.packagedVolume * d.quantity : typeIdSde.volume * d.quantity;
            return {
                typeId: d.typeId,
                quantity: d.quantity,
                name: typeIdSde.name,
                sellPrice: sellPrice,
                buyPrice: buyPrice,
                totalSellPrice: sellPrice * d.quantity,
                totalBuyPrice: buyPrice * d.quantity,
                volume
            }
        });
        return _.sortBy(result, r => r.typeId);
    }

    getRefinePercent = (typeId) => {
        let sde = _.find(this.props.PriceDataReducer.allTypeIdSde, sde => sde.typeId === typeId);
        if (sde == null) {
            return 0;
        }
        let categories = ['标准矿石', '冰矿', '卫星矿石'];
        if (sde.categories.length > 3 && categories.indexOf(sde.categories[3]) >= 0) {
            return this.props.SettingReducer.mineralRefinePercent;
        } else {
            return this.props.SettingReducer.equipRefinePercent;
        }
    }


    getRefinedItems = (typeId, quantity) => {
        let refinedItems = new Map();
        let material = _.find(this.props.PriceDataReducer.allTypeMaterials, m => m.typeId === typeId);
        if (material == null) {
            return [{ typeId, quantity }];
        }
        let typeIdSde = _.find(this.props.PriceDataReducer.allTypeIdSde, sde => sde.typeId === typeId);
        if (typeIdSde == null) {
            return [{ typeId, quantity }];
        }
        for (let materialItem of material.items) {
            let refinedItem = { typeId: materialItem.materialTypeId, quantity: materialItem.quantity };
            let preRefineItemPortion = Math.floor(quantity / typeIdSde.portionSize);
            refinedItem.quantity = Math.floor(materialItem.quantity * preRefineItemPortion * this.getRefinePercent(typeId));
            if (!refinedItems.has(materialItem.materialTypeId)) {
                refinedItems.set(materialItem.materialTypeId, refinedItem);
            } else {
                refinedItems.get(materialItem.materialTypeId).quantity += refinedItem.quantity;
            }
        }
        return [...refinedItems.values()];
    }

    onItemUpserted = (newItem) => {
        this.appendDepository([newItem]);
        this.toggleUpsertItemModal();
    }

    getSummaryStats = (dataSource) => {
        let sellPrice = 0;
        let buyPrice = 0;
        let volume = 0;
        for (let data of dataSource) {
            sellPrice += data.totalSellPrice;
            buyPrice += data.totalBuyPrice;
            volume += data.volume;
        }
        return {
            sellPrice,
            buyPrice,
            volume,
            middlePrice: (sellPrice + buyPrice) / 2
        }
    }

    renderSpin = () => {
        return (<div style={{ position: 'absolute', left: '50vw', top: '50vh' }}>
            <Spin size='large' />
        </div>)
    }

    renderUI = () => {
        let dataSource = this.getDataSource();
        let summary = this.getSummaryStats(dataSource);
        return (
            <div style={{ margin: '0px 20px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px' }}>
                    <Statistic title='最低卖价' value={`${Utils.formatISK(summary.sellPrice)} ISK`} />
                    <Statistic title='中间价' value={`${Utils.formatISK(summary.middlePrice)} ISK`} />
                    <Statistic title='最高买价' value={`${Utils.formatISK(summary.buyPrice)} ISK`} />
                    <Statistic title='体积' value={`${Utils.formatISK(summary.volume)} m³`} />
                </div>
                <PasteBinModal show={this.state.showPasteBinModal} onCancel={() => this.togglePasteBinModal()} onOK={(e) => this.onPasteBinOK(e)} />
                <UpsertRefineItemModal show={this.state.showUpsertItemModal} isAdd={true} onOK={(e) => this.onItemUpserted(e)} onCancel={() => this.toggleUpsertItemModal()} />
                <div style={{ display: 'flex', padding: '10px 0px' }}>
                    <Tooltip title={'从剪贴板导入'}>
                        <Button type="default" icon={<SnippetsOutlined />} style={{ borderRadius: '2px' }} size="large" onClick={() => this.togglePasteBinModal()} />
                    </Tooltip>
                    <Tooltip title={'添加物品'}>
                        <Button type="default" icon={<PlusOutlined />} style={{ borderRadius: '2px' }} size="large" onClick={() => this.toggleUpsertItemModal()} />
                    </Tooltip>
                    <Tooltip title={'清空库存'}>
                        <Button type="default" icon={<DeleteOutlined />} style={{ borderRadius: '2px' }} size="large" onClick={() => this.cleanDepository()} />
                    </Tooltip>
                </div>
                <div>
                    <Dropdown overlay={this.menu()} trigger={['contextMenu']}>
                        <div onClick={(e) => e.preventDefault()} >
                            <Table columns={this.getColumns()} size='small' pagination={{ showSizeChanger: true, pageSize: 500 }}
                                dataSource={dataSource} />
                        </div>
                    </Dropdown>
                </div>
            </div>
        )
    }

    isReady = () => {
        return !_.isEmpty(this.props.PriceDataReducer.allBlueprint) && !_.isEmpty(this.props.PriceDataReducer.allTypeIdSde)
            && !_.isEmpty(this.props.PriceDataReducer.allMarketDump) && !_.isEmpty(this.props.PriceDataReducer.allMarketHistory)
            && !_.isEmpty(this.props.PriceDataReducer.industryIndices);
    }

    render() {
        if (this.isReady()) {
            return this.renderUI();
        } else {
            return this.renderSpin();
        }
    }
}

const mapStateToProps = (state) => {
    return {
        PriceDataReducer: state.PriceDataReducer,
        RefineReducer: state.RefineReducer,
        SettingReducer: state.SettingReducer
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        PriceDataAction: bindActionCreators(PriceDataAction, dispatch),
        SettingAction: bindActionCreators(SettingAction, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Depository);
