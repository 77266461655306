import * as ActionTypes from "./ActionTypes";

export const setRefineItems = (newItems) => {
    return{type: ActionTypes.SET_PRE_REFINE_ITEMS_SUCCESS, data: newItems};
};

export const setMineralRefinePercent = (newPercent) => {
    return{type: ActionTypes.SET_MINERAL_REFINE_PERCENT_SUCCESS, data: newPercent};
};

export const setEquipRefinePercent = (newPercent) => {
    return{type: ActionTypes.SET_EQUIP_REFINE_PERCENT_SUCCESS, data: newPercent};
};