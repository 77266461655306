import React from 'react';
import logo from './logo.svg';
import qqlogin from "./resource/qqlogin.png";
import { ProSettings } from '@ant-design/pro-components';
import {
  PageContainer,
  ProCard,
  ProConfigProvider,
  ProLayout,
  DefaultFooter,
} from '@ant-design/pro-components';
import './App.css';
import './style/App.less';
import 'antd/dist/reset.css';
import * as PriceDataAction from './action/PriceDataAction';
import * as SettingAction from './action/SettingAction';
import * as QQUserAction from './action/QQUserAction';
import ErrorPage from './component/ErrorPage';
import About from './component/About';
import LoginBenefit from './component/LoginBenefit';
import Settings from './component/Settings';
import QQUserProfile from './component/QQUserProfile';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Layout, Menu, notification, Modal, message, Button, Dropdown, ConfigProvider, theme } from 'antd';
import { Link, BrowserRouter, Route, withRouter } from 'react-router-dom';
import { ExclamationCircleOutlined, LogoutOutlined, InfoCircleFilled, QuestionCircleFilled, GithubFilled } from "@ant-design/icons";
import { Redirect } from 'react-router';
import ProfitCalc from './component/ProfitCalc';
import MessageBoard from './component/MessageBoard';
import Rank from './component/Rank';
import Refine from './component/Refine';
import Asset from './component/Asset';
import Depository from './component/Depository';
import CallBack from './component/CallBack';
import ProfitHelper from './common/ProfitHelper';
import * as Utils from './common/Utils';
import TextScroller from './component/TextScroller';
import { LineChartOutlined } from '@ant-design/icons';
import { themes, getCustomTheme, getOpColor } from './common/Theme';
import _ from 'lodash';
import moment from 'moment';

const { SubMenu } = Menu;
const { Header, Content, Sider, Footer } = Layout;


const serverOptions = [
  {
    key: 'tranquility',
    label: '欧服'
  },
  {
    key: 'serenity',
    label: '国服经典'
  },
  {
    key: 'infinity',
    label: '国服曙光'
  },
  {
    key: 'serenityPriceEUBlueprint',
    label: '国服经典价格+欧服蓝图'
  },
  {
    key: 'infinityPriceEUBlueprint',
    label: '国服曙光价格+欧服蓝图'
  },
];

const languageOptions = [
  {
    key: 'chinese',
    label: '中文'
  },
  {
    key: 'english',
    label: '英文'
  },
];


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currPath: '/',
      showAbout: false,
      showSettings: false,
      showUserProfile: false,
      showLoginBenefit: false,
    };
  }

  componentDidMount() {
    this.props.PriceDataAction.getLastOrderDate();
    if (window.location.host.indexOf('112.124.29.148') >= 0) {
      // if (window.location.host.indexOf('localhost') >= 0) {
      alert('本网站已启用最新域名 http://www.ceve-industry.cn/，即将跳转到最新域名。')
      window.location.href = 'http://www.ceve-industry.cn/';
    }

    let openNotification = () => {
      const args = {
        message: '通知',
        description:
          `建了个交流群：439584671，欢迎点击上方'关于'，扫码加群聊天！`,
      };
      notification.open(args);
    };
    openNotification();

    this.getUserByOpenId();
    // window.QC.Login({
    //   btnId: "qqLoginBtn",	//插入按钮的节点id
    //   size: 'B_M',
    //   showModal: true,
    // });

    // this.refreshTheme();
  }

  componentDidCatch(error, info) {

  }

  componentWillReceiveProps(nextProps) {

  }

  componentDidUpdate(prevProps) {

  }

  getUserByOpenId = () => {
    let loginStatus = window.QC.Login.check();
    if (loginStatus == true) {
      window.QC.Login.getMe((openId, accessToken) => {
        this.props.QQUserAction.getUserByOpenId(openId);
      })
    }
  }

  getLoginMenuOptions = () => {
    if (this.checkLoginStatus()) {
      return [
        {
          key: 'setting',
          label: '账号设置'
        },
        {
          key: 'logout',
          label: '登出'
        },
      ];
    } else {
      return [
        {
          key: 'benefit',
          label: '为什么要登录？'
        },
      ];
    }
  }

  menuClick = (e) => {
    switch (e.toLowerCase()) {
      case 'about':
        this.setState({ showAbout: true });
        break;
      case 'settings':
        this.setState({ showSettings: true });
        break;
      default:
        break;
    }
  };

  getServerNameByEng = (eng) => {
    switch (eng) {
      case 'tranquility':
        return '欧服';
      case 'serenity':
        return '国服经典';
      case 'infinity':
        return '国服曙光';
      case 'serenityPriceEUBlueprint':
        return '国服经典价格+欧服蓝图';
      case 'infinityPriceEUBlueprint':
        return '国服曙光价格+欧服蓝图';
    }
  }

  refreshTheme = () => {
    let primaryColor = 'black';
    let secondaryColor = 'orange';
    let textColor = 'black';
    let primaryOpColor = 'white';

    let theme = _.find(themes, t => t.name === this.props.SettingReducer.themeName);
    if (this.props.SettingReducer.themeName === '自定义') {
      theme = getCustomTheme();
    }
    if (theme != null) {
      primaryColor = theme.primaryColor;
      secondaryColor = theme.secondaryColor;
      textColor = theme.textColor;
      primaryOpColor = getOpColor(primaryColor);
    }
    document.documentElement.style.setProperty('--theme-primary-color', primaryColor);
    document.documentElement.style.setProperty('--theme-secondary-color', secondaryColor);
    document.documentElement.style.setProperty('--theme-primary-op-color', primaryOpColor);
    document.documentElement.style.setProperty('--theme-text-color', textColor);
  }

  checkLoginStatus = () => {
    return window.QC.Login.check();
  }

  showWarning = (title, message) => {
    return new Promise((resolve, reject) => {
      Modal.confirm({
        title: title,
        icon: <ExclamationCircleOutlined />,
        content: message,
        okText: '是',
        okType: 'danger',
        cancelText: '否',
        onOk: () => {
          resolve();
        }
        ,
        onCancel: () => {
          reject();
        },
      });
    })
  }

  getStats = () => {
    return '';
    // if (!window.location.href.endsWith('/#/')) {
    //   return '';
    // }
    // let activeTabKey = this.props.SettingReducer.activeTabKey;
    // let stats = this.props.PriceDataReducer.selectedMaterialStats;
    // let activeStats = _.find(stats, s => s.activeTabKey === activeTabKey);
    // if (activeStats == null || _.isEmpty(activeStats.stats)) {
    //   return '';
    // } else {
    //   let totalPrice = 0;
    //   let totalPercent = 0;
    //   activeStats.stats.forEach(s => {
    //     totalPrice += s.totalPrice;
    //     totalPercent += Number(s.profitPercent.replace(/ %/g, ''));
    //   });
    //   return `求和 = ${Utils.formatISK(totalPrice)} ISK\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0占总成本 = ${Utils.toPercentString(totalPercent / 100)}`;
    // }
  }

  getPriceDate = () => {
    let dateStr = this.props.PriceDataReducer.lastOrderDate;
    if(_.isEmpty(dateStr)){
      return '';
    }
    dateStr = dateStr.replace('T', ' ').replace('Z', '');
    return `北京时间${dateStr}`;
    // let date = new moment(this.props.PriceDataReducer.lastOrderDate);
    // date.add(4, 'h');
    // return date.format('北京时间YYYY年MM月DD日 HH时mm分');
  }

  onServerDropdownClick = ({ key }) => {
    this.showWarning('切换服务器', `即将切换到${this.getServerNameByEng(key)}`).then(() => {
      this.props.SettingAction.setServer(key);
    }).then(() => {
      return new Promise((res, rej) => {
        message.info('5秒后切换服务器');
        setTimeout(() => res(), 1000);
      })
    }).then(() => {
      return new Promise((res, rej) => {
        message.info('4秒后切换服务器');
        setTimeout(() => res(), 1000);
      })
    }).then(() => {
      return new Promise((res, rej) => {
        message.info('3秒后切换服务器');
        setTimeout(() => res(), 1000);
      })
    }).then(() => {
      return new Promise((res, rej) => {
        message.info('2秒后切换服务器');
        setTimeout(() => res(), 1000);
      })
    }).then(() => {
      return new Promise((res, rej) => {
        message.info('1秒后切换服务器');
        setTimeout(() => res(), 1000);
      })
    }).then(() => {
      if (window.location.host !== 'localhost') {
        window.location.href = 'http://www.ceve-industry.cn/#/';
        window.location.reload(true);
      } else {
        window.location.href = 'http://localhost:3000/';
        window.location.reload(true);
      }
    }).catch(() => {
      message.info('中止切换服务器。')
    });
  };

  onLoginDropdownClick = ({ key }) => {
    if (key === 'logout') {
      if (window.QC.Login.check() == true) {
        window.QC.Login.signOut();
      }
      if (window.location.host !== 'localhost') {
        window.location.href = 'http://www.ceve-industry.cn/#/';
        window.location.reload(true);
      } else {
        window.location.href = 'http://localhost:3000/';
        window.location.reload(true);
      }
    } else if (key === 'setting') {
      this.setState({ showUserProfile: true });
    } else if (key === 'benefit') {
      this.setState({ showLoginBenefit: true });
    }
  }

  onLangDropdownClick = ({ key }) => {
    this.showWarning('切换语言', `即将切换到${key == 'english' ? '英文' : '中文'}`).then(() => {
      this.props.SettingAction.setLanguage(key);
    }).then(() => {
      return new Promise((res, rej) => {
        message.info('5秒后切换语言');
        setTimeout(() => res(), 5000);
      })
    }).then(() => {
      if (window.location.host !== 'localhost') {
        window.location.href = 'http://www.ceve-industry.cn/#/';
        window.location.reload(true);
      } else {
        window.location.href = 'http://localhost:3000/';
        window.location.reload(true);
      }
    }).catch(() => {
      console.log(window.QC.Login.check());
      message.info('中止切换语言。')
    });
  };

  qqlogin = () => {
    window.QC.Login.showPopup({
      appId: "102050315",
      redirectURI: "http://www.ceve-industry.cn/#/Callback"
    });
  }

  renderIframe = () => {
    return (
      <iframe src="https://graph.qq.com/oauth2.0/authorize?response_type=token&client_id=102050315&redirect_uri=http%3A%2F%2Fwww.ceve-industry.cn%2F%23%2FCallback"></iframe>
    )
  }

  getRoutes = () => {
    return {
      path: '/',
      routes: [
        {
          path: '/',
          name: '蓝图计算',
          isLink: true
        },
        {
          path: '/Rank',
          name: '利润排行',
          isLink: true
        },
        {
          path: '/Message',
          name: '留言板',
          isLink: true
        }, {
          path: '/Refine',
          name: '化矿',
          isLink: true
        }, {
          path: '/Asset',
          name: '估价',
          isLink: true
        }, {
          path: '/Depository',
          name: '库存',
          isLink: true
        }, {
          path: '/Settings',
          name: '设置',
          isLink: false
        }, {
          path: '/About',
          name: '关于',
          isLink: false
        },
      ]
    };
  }

  menuItemRender = (item, dom) => {
    return (
      <div onClick={() => {
        console.log(item);
        if (!item.isLink) {
          console.log(this.state.currPath)
          this.menuClick(item.path.substr(1));
        } else {
          this.setState({ currPath: item.path });
        }
      }}>
        {item.isLink ? (<Link to={item.path}>
          {dom}
        </Link>) : (<div>{dom}</div>)}

      </div>
    )
  }

  renderServerSelect = () => {
    return (
      <Dropdown menu={{ items: serverOptions, onClick: this.onServerDropdownClick }} placement="bottom" arrow>
        <div>
          <Button type="primary" value="large">{`${this.getServerNameByEng(this.props.SettingReducer.server)}`}</Button>
        </div>
      </Dropdown>
    )
  }

  renderLanguageSelect = () => {
    return (
      <Dropdown menu={{ items: languageOptions, onClick: this.onLangDropdownClick }} placement="bottom" arrow>
        <div>
          <Button type="primary" value="large">{(this.props.SettingReducer.language == 'english' ? '英文' : '中文')}</Button>
        </div>
      </Dropdown>
    )
  }

  getAuthorImg = () => {
    if(_.isEmpty(this.props.QQUserReducer.qqUser)){
      return `https://xsgames.co/randomusers/avatar.php?g=pixel&key=1`;
    }
    
    switch (this.props.QQUserReducer.qqUser.openId) {
      case 'D5CF418ED58FB0A800A0B4852B971217':
        return 'touxiang/touxiang2.png';
      case 'D861D803906FA10F15BCBF5D4BFA594F':
        return 'touxiang/touxiang1.png';
      default:
        return `https://xsgames.co/randomusers/avatar.php?g=pixel&key=${this.props.QQUserReducer.qqUser.openId}`;
    }
  }

  renderAvator = () => {
    let alreadyLogin = this.checkLoginStatus();
    if (!alreadyLogin) {
      return {
        render: (props, dom) => {
          return (
            <Dropdown menu={{ items: this.getLoginMenuOptions(), onClick: this.onLoginDropdownClick }} placement="bottomRight" arrow>
              <img src={qqlogin} style={{ position: 'relative', top: '2px', width: '80px' }} onClick={() => this.qqlogin()} />
            </Dropdown>
          )
        }
      };
    } else {
      let username = this.props.QQUserReducer.qqUser.name;
      // let username = '克隆飞行员123456789'
      return {
        src: this.getAuthorImg(),
        size: 'small',
        title: username,
        render: (props, dom) => {
          return (
            <Dropdown menu={{ items: this.getLoginMenuOptions(), onClick: this.onLoginDropdownClick }} placement="bottomRight" arrow>
              {dom}
            </Dropdown>
          );
        },
      }
    }
  }

  actionsRender = (props) => {
    return [
      this.renderLanguageSelect(),
      this.renderServerSelect(),
    ];
  };


  render() {
    if (this.state.hasError) {
      return (<ErrorPage />);
    }
    return (
      <ConfigProvider theme={{ algorithm: this.props.SettingReducer.antdTheme === 0 ? theme.defaultAlgorithm : theme.darkAlgorithm, token: { colorPrimary: this.props.SettingReducer.antdColor } }}>
        <QQUserProfile show={this.state.showUserProfile} onCancel={() => this.setState({ showUserProfile: false })} />
        <LoginBenefit show={this.state.showLoginBenefit} onCancel={() => this.setState({ showLoginBenefit: false })} />
        <About show={this.state.showAbout} onCancel={() => this.setState({ showAbout: false })} />
        <Settings show={this.state.showSettings} onCancel={() => this.setState({ showSettings: false })} />
        <ProLayout layout='top' route={this.getRoutes()} menuItemRender={this.menuItemRender}
          title='EVE蓝图计算器' suppressSiderWhenMenuEmpty={true} avatarProps={this.renderAvator()}
          actionsRender={this.actionsRender} location={{ pathname: this.state.currPath }}>
          <div>
            <Route path='/' component={ProfitCalc} exact />
            <Route path='/Rank' component={Rank} />
            <Route path='/Message' component={MessageBoard} />
            <Route path='/Refine' component={Refine} />
            <Route path='/Asset' component={Asset} />
            <Route path='/Depository' component={Depository} />
            <Route path='/Callback' component={CallBack} />
            <Route path='/Error' component={ErrorPage} />
          </div>
          <div style={{ padding: '3px 20px', position: 'fixed', bottom: '0px', width: '100%', display: 'flex', background: ProfitHelper.getBackgroundColor() }}>
            <a href="https://beian.miit.gov.cn/" target="_blank" style={{ wordBreak: 'keep-all', color: ProfitHelper.getTextColor() }}>浙ICP备2021035947号-1</a>
            <div style={{ marginLeft: 'auto', display: 'flex', color: ProfitHelper.getTextColor() }}>
              <div>价格更新时间：{this.getPriceDate()}</div>
              <div style={{ marginLeft: '20px' }}>{this.getStats()}</div>
            </div>
          </div>
        </ProLayout>
      </ConfigProvider >
    );
  }
}

const mapStateToProps = (state) => {
  return {
    PriceDataReducer: state.PriceDataReducer,
    SettingReducer: state.SettingReducer,
    QQUserReducer: state.QQUserReducer,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    PriceDataAction: bindActionCreators(PriceDataAction, dispatch),
    SettingAction: bindActionCreators(SettingAction, dispatch),
    QQUserAction: bindActionCreators(QQUserAction, dispatch),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
