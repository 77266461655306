import * as Utils from './Utils';
import * as IDUtils from './IDUtils';
import store from '../store';
import * as T3Utils from './T3Utils';
import _, { isNull } from 'lodash';
import moment from 'moment';

class ProfitHelper {
  constructor() {
    this.productId = 0;
    this.blueprintsNeedConsiderBlueprintCopy = new Set();
    this.typeIdWithTypeObj = new Map();
    this.typeIdWithMarketDump = new Map();
    this.systemIdWithManuIndustryIndex = new Map();
    this.systemIdWithReactIndustryIndex = new Map();
    this.blueprintIdWithProduct = new Map();
    this.blueprintIdWithBlueprint = new Map();
    this.typeIdWithEiv = new Map();
    this.getDataSourceByBlueprintCache = new Map();
  }

  getBlueprintProfitSummary(blueprintList) {
    let materialsNeedsExtract = [...store.getState().SettingReducer.alwaysMakeBids];
    materialsNeedsExtract = [...materialsNeedsExtract, ...this.getBlueprintByProductCategories()];
    materialsNeedsExtract = materialsNeedsExtract.filter(m => store.getState().SettingReducer.alwaysBuyBids.indexOf(m) < 0);
    materialsNeedsExtract = _.uniq(materialsNeedsExtract);

    return blueprintList.map((t) => {
      let blueprintSetting = { blueprintTypeId: t.blueprintTypeId, blueprint: t, productQuantity: 1, blueprintCount: 1, decoderId: 0 };
      let [resourceList, context] = this.getResourceList([blueprintSetting], materialsNeedsExtract);
      return context;
    });
    // return blueprintList.map((t) => this.getProfitSummary(t, productIdWithDataSourcesCache));
  }

  getTypeImage = (typeId) => {
    return `https://images.evetech.net/types/${typeId}/icon`;
    // return `https://image.evepc.163.com/Type/${typeId}_64.png`;
  }

  getAncientImage = (typeId) => {
    return `https://image.evepc.163.com/Type/${typeId}_64.png`;
  }


  getBlueprintByProductCategories = () => {
    let products = [];
    let categories = store.getState().SettingReducer.selfProductCategories;
    let set = new Set(categories);
    for (let typeIdSde of store.getState().PriceDataReducer.allTypeIdSde) {
      for (let c1 of typeIdSde.categories) {
        if (set.has(c1)) {
          products.push(typeIdSde);
          break;
        }
      }
    }
    let blueprints = products.map(r => {
      return this.getBlueprintByProductId(r.typeId);
    });

    let blueprintIds = blueprints.filter(b => b != null).map(b => b.blueprintTypeId);
    return blueprintIds;
  }

  generateContextSummary(context) {
    let totalCost = 0;
    let materialCost = 0;
    context.flatDataSources.forEach((d) => {
      materialCost += d.totalPrice;
    });
    totalCost += materialCost;
    totalCost += context.customsFee;
    totalCost += context.marketFee;
    totalCost += context.solarSystemFee;
    totalCost += context.safetyCommitteeFee;
    totalCost += context.inventFee;
    totalCost += context.transportFee;
    context.totalCost = totalCost;
    context.materialCost = materialCost;

    context.transportFee = this.getTransportCost(context);

    context.totalPrice = 0;
    context.productVolume = 0;
    for (let blueprintSetting of context.params.blueprintSettings) {
      let product = this.getProductByBlueprintId(blueprintSetting.blueprintTypeId);
      let productQuantityPerBatch = blueprintSetting.blueprint.activities.manufacturing.products[0].quantity;
      let quantity = blueprintSetting.blueprintCount * blueprintSetting.productQuantity * productQuantityPerBatch;
      let productMarketDump = this.getMarketDumpById(product.typeId, { isResource: false });
      if (productMarketDump != null) {
        context.totalPrice += productMarketDump.price * quantity;
        context.productVolume += product.packagedVolume * quantity;
      }
    }
    context.totalProfit = context.totalPrice - context.totalCost;
    context.profitPercent = context.totalProfit / context.totalCost;
    context.dailyProfit = context.totalProfit / (context.time * 0.544) * 86400;

    context.resourceVolume = 0;
    context.flatDataSources.forEach((d) => {
      context.resourceVolume += d.volume;
    });

    if (context.params.blueprintSettings.length === 1) {
      let blueprintSetting = context.params.blueprintSettings[0];
      let productQuantityPerBatch = blueprintSetting.blueprint.activities.manufacturing.products[0].quantity;
      let quantity = blueprintSetting.blueprintCount * blueprintSetting.productQuantity * productQuantityPerBatch;
      context.singleCost = totalCost / quantity;
      context.singleMaterialCost = context.materialCost / quantity;
      context.singleCustomsFee = context.customsFee / quantity;
      context.singleMarketFee = context.marketFee / quantity;
      context.singleSolarSystemFee = context.solarSystemFee / quantity;
      context.singleSafetyCommitteeFee = context.safetyCommitteeFee / quantity;
      context.singleInventFee = context.inventFee / quantity;
      context.singleTransportFee = context.transportFee / quantity;
      context.quantity = quantity;

      context.singlePrice = context.totalPrice / quantity;
      context.singleProfit = context.totalProfit / quantity;

      let product = this.getProductByBlueprintId(blueprintSetting.blueprintTypeId);
      let marketHistory = this.getMarketHistory(product.typeId);
      context.dailyQuantity = context.quantity / (context.time * 0.544) * 86400;
      context.dailyVolume = marketHistory.averageVolume;
      context.dailyPrice = marketHistory.averagePrice;
      context.loopEffect = context.dailyVolume / context.dailyQuantity;
      context.productId = product.typeId;
      context.name = product.name;
    }

    return context;
  }

  getTransportCost = (context) => {
    let transportFee = parseInt(store.getState().SettingReducer.transportFee);
    let transportUnit = parseInt(store.getState().SettingReducer.transportUnit);
    if (isNaN(transportFee) || isNaN(transportUnit) || transportFee <= 0 || transportUnit <= 0) {
      return 0;
    }

    let cost = 0;
    for (let resource of context.flatDataSources) {
      let typeIdSde = this.getTypeIdSdeById(resource.key);
      let category = typeIdSde.categories[typeIdSde.categories.length - 1];
      let resourceVolume = resource.volume;
      if (category === '增效剂气云' || category === '富勒烯') {
        cost += resourceVolume / 10 / transportUnit * transportFee;
        cost += resource.totalPrice * 0.05;
      } else if (category === '矿物') {
        cost += resourceVolume / 6 / transportUnit * transportFee;
      } else {
        cost += resourceVolume / transportUnit * transportFee;
      }
    }

    return cost;
  }

  getRegionByGalaxy = (galaxyId) => {
    if (galaxyId === 30001363) {
      return "10000016";
    } else if (galaxyId === 30003488) {
      return "10000043";
    } else {
      return "";
    }
  }

  getGalaxyByRegion = (regionId) => {
    regionId = regionId.toString();
    if (store.getState().SettingReducer.server === 'infinity') {
      if (regionId === "10000016") {
        return 30001363;
      } else if (regionId === "10000043") {
        return 30003488;
      } else {
        return 0;
      }
    } else {
      if (regionId === "10000002") {
        return 30000142;
      } else {
        return 0;
      }
    }
  }

  getDefaultDecoder = () => {
    let defaultDecoderId = store.getState().SettingReducer.defaultDecoderId;
    return _.find(store.getState().ItemInfoReducer.allDecoders, d => d.typeId === defaultDecoderId);
  }

  getDecoderById = (decoderId) => {
    return _.find(store.getState().ItemInfoReducer.allDecoders, d => d.typeId === decoderId);
  }

  getMarketHistory = (typeId) => {
    let allMarketHistory = store.getState().PriceDataReducer.allMarketHistory;
    let histories = _.filter(allMarketHistory, (h) => h.id === typeId || h.typeId === typeId);
    let SettingReducer = store.getState().SettingReducer;
    let server = SettingReducer.server;
    if (server === 'infinity') {
      let galaxyList = _.find(SettingReducer.priceGalaxy, g => g.server === 'infinity').galaxyProduct;
      let regionList = galaxyList.map(g => {
        return this.getRegionByGalaxy(g);
      })
      histories = _.filter(histories, h => regionList.includes(h.regionId));
    }
    if (_.isEmpty(histories)) {
      return { averageVolume: 0, typeId: typeId, averagePrice: 0 };
    }
    let totalPrice = 0;
    let totalVolume = 0;
    histories.forEach(h => {
      totalPrice += h.averagePrice;
      totalVolume += h.averageVolume;
    });

    let averagePrice = totalPrice / histories.length;
    return { averageVolume: totalVolume, typeId: typeId, averagePrice: averagePrice }
  };


  getBlueprintCopyDataSource = (blueprint, quantity) => {
    let key = blueprint.blueprintTypeId;
    let typeIdSde = this.getTypeIdSdeById(key);
    if (_.isEmpty(typeIdSde)) {
      return null;
    }
    let name = this.getTypeIdSdeById(key).name;
    let category = '蓝图';
    let marketDump = this.getContractMarketDumpById(key);
    let singlePrice = 0;
    let totalPrice = 0;
    if (!_.isEmpty(marketDump)) {
      singlePrice = marketDump.price;
      totalPrice = singlePrice * quantity;
    }
    return { key, name, category, quantity, singlePrice, totalPrice };
  }

  getAllMaterialBlueprintIdRecursive = (productIdList, maxRound, isContainsP0) => {
    let products = new Set(productIdList);
    let materialBlueprintIds = new Set();
    let needExtract = true;
    let currentRound = 0;
    while (needExtract === true) {
      currentRound++;
      if (maxRound > 0 && currentRound > maxRound) {
        break;
      }
      needExtract = false;
      let newProducts = new Set();
      for (let product of products) {
        for (let blueprint of store.getState().PriceDataReducer.allBlueprint) {
          if (product === blueprint.typeIdSde.typeId && !materialBlueprintIds.has(blueprint.blueprintTypeId)) {
            blueprint.activities.manufacturing.materials.forEach(m => newProducts.add(m.typeId));
            materialBlueprintIds.add(blueprint.blueprintTypeId);
            needExtract = true;
          }
        }
      }
      products = new Set([...products, ...newProducts]);
    }

    //分解到p1为止
    if (isContainsP0 === false) {
      materialBlueprintIds = [...materialBlueprintIds].filter(b => {
        let blueprint = this.getBlueprintByTypeId(b);
        return blueprint.typeIdSde.categories[blueprint.typeIdSde.categories.length - 1] !== '加工过的行星材料';
      })
    }

    return [...materialBlueprintIds];
  }

  getUsedTypeIds = () => {
    let typeIdList = new Set();
    for (let blueprint of store.getState().PriceDataReducer.allBlueprint) {
      if (blueprint.activities.manufacturing != null) {
        blueprint.activities.manufacturing.products.forEach(p => typeIdList.add(p.typeId));
        blueprint.activities.manufacturing.materials.forEach(m => typeIdList.add(m.typeId));
      }
      if (blueprint.activities.invention != null) {
        blueprint.activities.invention.products.forEach(m => typeIdList.add(m.typeId));
        blueprint.activities.invention.materials.forEach(m => typeIdList.add(m.typeId));
      }
    }
    return _.sortBy([...typeIdList.values()]);
  }

  getBlueprintByTypeId = (blueprintTypeId) => {
    if (this.blueprintIdWithBlueprint.has(blueprintTypeId)) {
      return this.blueprintIdWithBlueprint.get(blueprintTypeId);
    } else {
      let blueprint = _.find(store.getState().PriceDataReducer.allBlueprint, b => b.blueprintTypeId === blueprintTypeId);
      if (blueprint != null) {
        this.blueprintIdWithBlueprint.set(blueprintTypeId, blueprint);
      }
      return blueprint;
    }
  }

  getBlueprintByProductId = (productId) => {
    return _.find(store.getState().PriceDataReducer.allBlueprint, b => b.typeIdSde.typeId === productId);
  }

  getEiv = (typeId) => {
    if (this.typeIdWithEiv.has(typeId)) {
      return this.typeIdWithEiv.get(typeId);
    } else {
      let blueprint = this.getBlueprintByProductId(typeId);
      if (blueprint == null) {
        return 0;
      } else {
        let eiv = 0;
        for (let material of blueprint.activities.manufacturing.materials) {
          eiv += this.getEstimatedPrice(material.typeId) * material.quantity;
        }
        let productQuantityPerBatch = blueprint.activities.manufacturing.products[0].quantity;
        eiv = eiv / productQuantityPerBatch;
        if (eiv > 0) {
          this.typeIdWithEiv.set(typeId, eiv);
        }
        let typeIdSde = this.getTypeIdSdeById(typeId);
        console.log(`${typeIdSde.name} 的 eiv 是 ${Utils.formatISK(eiv)} ISK`)
        return eiv;
      }
    }
  }

  // getMaterialDatasources = (extractMaterialBlueprintIds) => {
  //   let productIdWithDataSources = new Map();
  //   for (let blueprintId of extractMaterialBlueprintIds) {
  //     let blueprint = this.getBlueprintByTypeId(blueprintId);
  //     if (blueprint == null) {
  //       continue;
  //     }

  //     let dataSources = [];
  //     if (productIdWithDataSourcesCache.has(blueprint.typeIdSde.typeId)) {
  //       dataSources = productIdWithDataSourcesCache.get(blueprint.typeIdSde.typeId);
  //     } else {
  //       dataSources = this.getDataSourceByBlueprint(blueprint, 1, 1);
  //       productIdWithDataSourcesCache.set(blueprint.typeIdSde.typeId, dataSources);
  //     }

  //     productIdWithDataSources.set(blueprint.typeIdSde.typeId, dataSources);
  //   }
  //   return productIdWithDataSources;
  // };

  hasResourceRecycle = (blueprint) => {
    if (_.isEmpty(blueprint.activities.manufacturing.materials)) {
      return false;
    }
    let productId = blueprint.typeIdSde.typeId;
    let resourceId = blueprint.activities.manufacturing.materials[0].typeId;
    return productId === resourceId;
  }

  generatePlans = (context) => {
    let plans = [];

    if (_.isEmpty(context.processList)) {
      return context.processList;
    }

    let lastProcessStep = context.params.blueprintSettings.map(bs => {
      let product = this.getProductByBlueprintId(bs.blueprintTypeId);
      let blueprint = this.getBlueprintByTypeId(bs.blueprintTypeId);
      let quantityPerProcess = blueprint.activities.manufacturing.products[0].quantity;
      let quantity = bs.blueprintCount * bs.productQuantity * quantityPerProcess;
      let sourceItems = [];
      for (let item of context.processList[0]) {
        for (let material of blueprint.activities.manufacturing.materials) {
          if (item.key === material.typeId) {
            sourceItems.push(item);
            break;
          }
        }
      }
      return {
        key: product.typeId,
        name: product.name,
        category: this.getCategory(product.typeId),
        quantity: quantity,
        ceilQuantity: quantity,
        sourceItems: sourceItems,
        targetItems: [],
        isSelfProducted: true
      }
    });

    let processListWithFinalProduct = [lastProcessStep, ...context.processList];

    for (let processStep of processListWithFinalProduct) {
      for (let targetItem of processStep) {
        if (!targetItem.isSelfProducted) {
          continue;
        }

        if (targetItem.sourceItems.length === 1 && targetItem.sourceItems[0].key === targetItem.key) {
          continue;
        }
        let plan = { ...targetItem };
        plan.id = IDUtils.guid();
        plan.createAt = moment().format('YYYY-MM-DD-HH:mm:SS');
        plan.targetItem = targetItem;
        let marketDump = this.getMarketDumpById(targetItem.key, { isResource: false });
        plan.price = marketDump == null ? 0 : marketDump.price;
        // plan.status = this.generateStatus(plan);
        // this.generateProfit(plan);
        plans.push(plan);
      }
    }
    plans = _.reverse(plans)
    for (let plan of plans) {
      plan.indexNumber = plans.indexOf(plan);
    }
    context.plans = plans;
  }

  //blueprint, quantity, blueprintCount
  getResourceList = (blueprintSettings, materialsNeedsExtract) => {
    let context = {
      solarSystemFee: 0,
      safetyCommitteeFee: 0,
      customsFee: 0,
      time: 0,
      inventDataSource: [],
      inventFee: 0,
      marketFee: 0,
      transportFee: 0,
      dataTree: [],
      processList: [],
      params: {
        blueprintSettings, materialsNeedsExtract
      }
    };

    if (_.isEmpty(blueprintSettings)) {
      return [[], context];
    }

    for (let blueprintSetting of blueprintSettings) {
      if (_.isEmpty(blueprintSetting.blueprint)) {
        return [[], context];
      }
    }

    for (let blueprintSetting of blueprintSettings) {
      if (this.hasResourceRecycle(blueprintSetting.blueprint)) {
        //有无限循环
        materialsNeedsExtract = [];
      }
    }

    // let materialMap = new Map();
    // if (!_.isEmpty(materialsNeedsExtract)) {
    //   materialMap = this.getMaterialDatasources(materialsNeedsExtract, productIdWithDataSourcesCache);
    // }

    let firstLevelDataSources = [];
    for (let blueprintSetting of blueprintSettings) {
      let blueprint = blueprintSetting.blueprint;
      let productQuantity = blueprintSetting.productQuantity;
      let blueprintCount = blueprintSetting.blueprintCount;

      let productQuantityPerBatch = blueprint.activities.manufacturing.products[0].quantity;
      let productMarketCount = productQuantity * productQuantityPerBatch;

      let dataSourcesForOneBp = this.getDataSourceByBlueprint(blueprint, productMarketCount, productMarketCount, blueprintCount, blueprintSetting.materialEffect);
      if (store.getState().SettingReducer.considerBlueprintCopyCost == null || store.getState().SettingReducer.considerBlueprintCopyCost === true) {
        let blueprintCopyDataSource = this.getBlueprintCopyDataSource(blueprint, productQuantity * blueprintCount);
        if (!_.isEmpty(blueprintCopyDataSource)) {
          if (this.needConsiderBlueprintCopyCost(blueprint.blueprintTypeId)) {
            dataSourcesForOneBp = [blueprintCopyDataSource, ...dataSourcesForOneBp]
          }
        }
      }
      firstLevelDataSources = this.combineDataSource(firstLevelDataSources, dataSourcesForOneBp);

      let categories = ['高级行星材料', '特殊行星材料', '精炼过的行星材料', '加工过的行星材料'];

      if (categories.indexOf(this.getCategory(blueprint.typeIdSde.typeId)) >= 0) {
        context.customsFee += this.getCustomsFee([{key: blueprint.typeIdSde.typeId, quantity: productMarketCount * blueprintSetting.blueprintCount}]);
        context.customsFee += this.getCustomsFee(dataSourcesForOneBp);
      }

      //第一层星系成本
      let industryIndex = this.getIndustryCostIndex(blueprint);
      context.solarSystemFee += industryIndex * this.getEiv(blueprint.typeIdSde.typeId) * productQuantity * blueprintCount * productQuantityPerBatch;

      let safetyCommitteePercent = 0.0075;
      if (store.getState().SettingReducer.server === 'tranquility') {
        safetyCommitteePercent = 0.015;
      }
      context.safetyCommitteeFee += safetyCommitteePercent * this.getEiv(blueprint.typeIdSde.typeId) * productQuantity * blueprintCount * productQuantityPerBatch;

      let marketFeeRate = (store.getState().SettingReducer.marketFee == null ? 0 : store.getState().SettingReducer.marketFee) / 100;
      let productMarketDump = this.getMarketDumpById(blueprint.typeIdSde.typeId, { isResource: false });
      if (productMarketDump == null) {
        context.marketFee = 0;
      } else {
        context.marketFee = marketFeeRate * productMarketDump.price * productQuantity * blueprintCount * productQuantityPerBatch;
      }

      //第一层制造时间
      let manufact = blueprint.activities.manufacturing;
      let time = manufact.time * productQuantity * blueprintCount;
      context.time += time;

      let inventCost = null;
      if (T3Utils.isT3Blueprint(blueprintSetting.blueprintTypeId)) {
        inventCost = this.getT3InventCost(blueprintSetting);
      } else {
        inventCost = this.getInventCost(blueprintSetting);
      }
      if (!_.isEmpty(inventCost.materials)) {
        context.inventDataSource = this.combineDataSource(context.inventDataSource, inventCost.materials);
      }
      context.inventFee += inventCost.cost;
    }
    // let firstLevelDataSources = this.getDataSourceByBlueprint(blueprint, quantity, blueprintCount);

    let dataSources = this.extractDataSources(firstLevelDataSources, materialsNeedsExtract, context);

    dataSources = dataSources.sort((d1, d2) => {
      if (d1.category > d2.category) {
        return -1;
      } else if (d1.category < d2.category) {
        return 1;
      } else {
        return d1.key - d2.key;
      }
    });

    let totalCost = 0;
    dataSources.forEach((ds) => {
      totalCost += ds.totalPrice;
      let typeIdSde = this.getTypeIdSdeById(ds.key);
      let singleProductVolume = typeIdSde.packagedVolume == null ? typeIdSde.volume : typeIdSde.packagedVolume;
      ds.volume = ds.quantity * singleProductVolume;
    });
    dataSources.forEach((ds) => {
      let blueprint = _.find(store.getState().PriceDataReducer.allBlueprint, b => b.typeIdSde.typeId === ds.key);
      if (blueprint != null) {
        ds.procedure = ds.quantity / blueprint.activities.manufacturing.products[0].quantity;
      }
      ds.profitPercent = ds.totalPrice / totalCost;
    });

    context = this.generateContextSummary(context);
    this.generatePlans(context);
    return [dataSources, context];
  }

  getIndustryCostIndex = (blueprint) => {
    let priceDataReducer = store.getState().PriceDataReducer;
    let settingReducer = store.getState().SettingReducer;

    let manuCostIndex = 0;
    if (settingReducer.manuSystemId === -1) {
      manuCostIndex = settingReducer.customManuIndex == null ? 0 : settingReducer.customManuIndex / 100;
    } else if (!_.isEmpty(settingReducer.manuSystemId)) {
      if (this.systemIdWithManuIndustryIndex.has(settingReducer.manuSystemId)) {
        manuCostIndex = this.systemIdWithManuIndustryIndex.get(settingReducer.manuSystemId);
      } else {
        for (let industryIndex of priceDataReducer.industryIndices) {
          if (industryIndex.systemId === settingReducer.manuSystemId) {
            manuCostIndex = industryIndex.manuCostIndex;
            this.systemIdWithManuIndustryIndex.set(settingReducer.manuSystemId, manuCostIndex);
          }
        }
      }
    }

    let reactCostIndex = 0;
    if (settingReducer.reactSystemId === -1) {
      reactCostIndex = settingReducer.customReactIndex == null ? 0 : settingReducer.customReactIndex / 100;
    } else if (!_.isEmpty(settingReducer.reactSystemId)) {
      if (this.systemIdWithReactIndustryIndex.has(settingReducer.reactSystemId)) {
        reactCostIndex = this.systemIdWithReactIndustryIndex.get(settingReducer.reactSystemId);
      } else {
        for (let industryIndex of priceDataReducer.industryIndices) {
          if (industryIndex.systemId === settingReducer.reactSystemId) {
            reactCostIndex = industryIndex.reactCostIndex;
            this.systemIdWithReactIndustryIndex.set(settingReducer.manuSystemId, reactCostIndex);
          }
        }
      }
    }

    let industryIndex = 0;
    if (_.isEmpty(blueprint)) {
      industryIndex = 0;
    } else if (blueprint.blueprintType === 'REACTION') {
      industryIndex = reactCostIndex;
    } else if (blueprint.blueprintType === 'PLANET') {
      industryIndex = 0;
    } else {
      industryIndex = manuCostIndex;
    }
    return industryIndex;
  }

  getTextColor = () => {
    let antdTheme = store.getState().SettingReducer.antdTheme;
    if (antdTheme === 0) {
      return 'black';
    } else {
      return 'white';
    }
  }

  getBackgroundColor = () => {
    let antdTheme = store.getState().SettingReducer.antdTheme;
    if (antdTheme === 0) {
      return 'white';
    } else {
      return 'black';
    }
  }

  getCustomsFee = (dataSources) => {
    let customsFeeRate = store.getState().SettingReducer.customsFee;
    if (customsFeeRate == null) {
      customsFeeRate = 0;
    }

    let customsFee = 0;
    dataSources.forEach(ds => {
      let typeIdSde = this.getTypeIdSdeById(ds.key);
      if (typeIdSde == null) {
        return;
      }

      let category = typeIdSde.categories[typeIdSde.categories.length - 1];
      switch (category) {
        case '加工过的行星材料':
          customsFee += 200 * customsFeeRate * ds.quantity;
          break;
        case '精炼过的行星材料':
          customsFee += 3600 * customsFeeRate * ds.quantity;
          break;
        case '特殊行星材料':
          customsFee += 30000 * customsFeeRate * ds.quantity;
          break;
        case '高级行星材料':
          customsFee += 600000 * customsFeeRate * ds.quantity;
          break;
      }
    });

    return customsFee / 100;
  }

  flatDataTree = (dataTree, flatMap) => {
    for (let data of dataTree) {
      if (_.isEmpty(data.materials)) {
        if (!flatMap.has(data.key)) {
          flatMap.set(data.key, _.clone(data));
        } else {
          let existingData = flatMap.get(data.key);
          existingData.quantity += data.quantity;
        }
      } else {
        this.flatDataTree(data.materials, flatMap);
      }
    }
    return [...flatMap.values()];
  }

  canExtract = (dataSources, bidsNeedsExtract) => {
    let materialIdNeedsExtract = new Set(bidsNeedsExtract.map(bid => {
      let typeIdSde = this.getProductByBlueprintId(bid);
      return typeIdSde == null ? null : typeIdSde.typeId;
    }).filter(id => id != null));
    for (let product of dataSources) {
      if (materialIdNeedsExtract.has(product.key)) {
        return true;
      }
    }
    return false;
  }

  getDelayExtractCategories = (lastProcessStep, bidsNeedsExtract) => {
    let farmLevels = [];
    farmLevels.push('not a category');
    farmLevels.push(this.getCategory(2875));//无菌管道
    farmLevels.push(this.getCategory(2345));//监控无人机
    farmLevels.push(this.getCategory(9838));//超导体
    farmLevels.push(this.getCategory(3645));//水
    farmLevels.push(this.getCategory(4051));//燃料块

    let reactLevels = [];
    reactLevels.push('not a category');
    reactLevels.push(this.getCategory(16670));//碳化晶体
    reactLevels.push(this.getCategory(16658));//二硼硅
    reactLevels.push(this.getCategory(4051));//燃料块

    let minFarmLevel = 999;
    let minReactLevel = 999;
    //999表示都分解

    let materialIdNeedsExtract = new Set(bidsNeedsExtract.map(bid => {
      let typeIdSde = this.getProductByBlueprintId(bid);
      return typeIdSde == null ? null : typeIdSde.typeId;
    }).filter(id => id != null));

    for (let product of lastProcessStep) {
      if (materialIdNeedsExtract.has(product.key)) {
        let productCategory = this.getCategory(product.key);
        let farmLevel = farmLevels.indexOf(productCategory);
        let reactLevel = reactLevels.indexOf(productCategory);
        if (farmLevel < 0 && reactLevel < 0) {
          console.log(`存在需要分解，并且不属于菜，不属于反应产物的原料：${product.key}${product.name}, 所以延迟分解所有菜和反应物`);
          minFarmLevel = 0;
          minReactLevel = 0;
        } else if (farmLevel > 0 && farmLevel < minFarmLevel) {
          //有高级菜需要分解，所以不分解低级菜
          minFarmLevel = farmLevel;
        } else if (reactLevel > 0 && reactLevel < minReactLevel) {
          //有高级反应物需要分解，所以不分解低级反应物
          minReactLevel = reactLevel;
        }
      }
    }

    let delayExtractCategories = [..._.slice(farmLevels, minFarmLevel + 1), ..._.slice(reactLevels, minReactLevel + 1)];
    return delayExtractCategories;
  }

  extractDataSourcesLoop = (dataSources, bidsNeedsExtract, context) => {
    let flatDataSources = [];
    let lastProcessStep = dataSources;
    context.processList.push(dataSources);

    let materialIdNeedsExtract = new Set(bidsNeedsExtract.map(bid => {
      let typeIdSde = this.getProductByBlueprintId(bid);
      return typeIdSde == null ? null : typeIdSde.typeId;
    }).filter(id => id != null));

    while (this.canExtract(lastProcessStep, bidsNeedsExtract)) {
      let processStep = [];

      let delayExtractCategories = this.getDelayExtractCategories(lastProcessStep, bidsNeedsExtract);
      for (let product of lastProcessStep) {
        if (!materialIdNeedsExtract.has(product.key)) {
          //收购的材料
          flatDataSources.push(product);
        }
        if (materialIdNeedsExtract.has(product.key)) {
          let productCategory = this.getCategory(product.key);
          if (delayExtractCategories.includes(productCategory)) {
            let delayedProduct = _.clone(product);
            product.delay = true;
            processStep.push(delayedProduct);
            //延迟到下一步再分解的材料
            continue;
          }

          let materialBlueprint = this.getBlueprintByProductId(product.key);
          // let materialDataSources = _.cloneDeep(productIdWithDataSources.get(product.key));
          let productQuantityPerBatch = materialBlueprint.activities.manufacturing.products[0].quantity;
          // let productBatchCount = Math.ceil(product.ceilQuantity / productQuantityPerBatch);

          let materialDataSources = this.getDataSourceByBlueprint(materialBlueprint, product.quantity, product.ceilQuantity, 1);

          // for (let materialDataSource of materialDataSources) {
          //   materialDataSource.ceilQuantity = materialDataSource.quantity * productBatchCount;
          //   materialDataSource.quantity = materialDataSource.quantity * (product.quantity * 1 / productQuantityPerBatch);
          //   let marketDump = this.getMarketDumpById(materialDataSource.key);
          //   let price = marketDump == null ? 0 : marketDump.price;
          //   materialDataSource.singlePrice = price;
          //   materialDataSource.totalPrice = price * materialDataSource.quantity;
          // }
          let categories = ['高级行星材料', '特殊行星材料', '精炼过的行星材料', '加工过的行星材料'];

          if (categories.indexOf(product.category) >= 0) {
            context.customsFee += this.getCustomsFee([product]);
            context.customsFee += this.getCustomsFee(materialDataSources);
          }

          let industryIndex = this.getIndustryCostIndex(materialBlueprint);
          context.solarSystemFee += industryIndex * this.getEiv(product.key) * product.quantity;

          let safetyCommitteePercent = 0.0075;
          if (store.getState().SettingReducer.server === 'tranquility') {
            safetyCommitteePercent = 0.015;
          }
          context.safetyCommitteeFee += safetyCommitteePercent * this.getEiv(product.key) * product.quantity;

          //材料自产时间
          let manufact = materialBlueprint.activities.manufacturing;
          let time = manufact.time / manufact.products[0].quantity * product.quantity;
          // console.log(`${product.quantity}流程${materialBlueprint.typeIdSde.name}需要${time}秒`);
          context.time += time;

          processStep = [...processStep, ...materialDataSources];
        }
      }

      let uniqProcessSteps = this.getUniqProcessSteps(processStep);
      lastProcessStep = uniqProcessSteps;
      context.processList.push(uniqProcessSteps);
    }

    flatDataSources = [...flatDataSources, ...lastProcessStep];
    flatDataSources = this.getUniqProcessSteps(flatDataSources);
    context.flatDataSources = flatDataSources;
    this.setRelationshipBetweenProcessSteps(context, materialIdNeedsExtract);
    return context;
  }

  isSelfProducted = (productId) => {
    let productBlueprint = ProfitHelper.getBlueprintByProductId(productId);
    if (productBlueprint == null) {
      return false;
    }
    let extractedBlueprintIds = this.props.getExtractBlueprintIds();
    return _.find(extractedBlueprintIds, b => b === productBlueprint.blueprintTypeId) != null;
  }

  setRelationshipBetweenProcessSteps = (context, materialIdNeedsExtract) => {
    let processList = context.processList;
    for (let processStep of processList) {
      let stepIndex = processList.indexOf(processStep);
      for (let item of processStep) {
        item.stepIndex = stepIndex;
        item.targetItems = [];
        item.sourceItems = [];
        if (materialIdNeedsExtract.has(item.key)) {
          item.isSelfProducted = true;
        } else {
          item.isSelfProducted = false;
        }
      }
    }

    for (let stepIndex = 0; stepIndex < processList.length - 1; stepIndex++) {
      let targetStep = processList[stepIndex];
      let sourceStep = processList[stepIndex + 1];
      for (let targetItem of targetStep) {
        if (!targetItem.isSelfProducted) {
          //收购的物品必定没有往右的连线
          continue;
        }

        let blueprint = this.getBlueprintByProductId(targetItem.key);
        let materials = [];
        if (blueprint != null && blueprint.activities.manufacturing != null) {
          materials = blueprint.activities.manufacturing.materials;
        }

        for (let material of materials) {
          let sourceItem = _.find(sourceStep, s => s.key === material.typeId);
          if (sourceItem == null) {
            console.log(`Warn: ${targetItem.name} has matarial ${material.typeId}, but cannot find material in right side`)
            continue;
          }
          targetItem.sourceItems.push(sourceItem);
          sourceItem.targetItems.push(targetItem);
        }

        //延迟分解的物品，自己连接自己
        let sourceItem = _.find(sourceStep, s => s.key === targetItem.key);
        if (sourceItem != null) {
          targetItem.sourceItems.push(sourceItem);
          sourceItem.targetItems.push(targetItem);
        }
      }
    }
  }

  getUniqProcessSteps = (processSteps) => {
    let uniqProcessSteps = [];
    for (let dataSource of processSteps) {
      let uniqDataSource = _.find(uniqProcessSteps, u => u.key === dataSource.key);
      if (uniqDataSource != null) {
        uniqDataSource.quantity += dataSource.quantity;
        uniqDataSource.ceilQuantity += dataSource.ceilQuantity;
        uniqDataSource.totalPrice += dataSource.totalPrice;
      } else {
        uniqProcessSteps.push(dataSource);
      }
    }
    uniqProcessSteps = _.sortBy(uniqProcessSteps, u => u.key);
    return uniqProcessSteps;
  }


  extractDataSourcesRecursive = (dataSources, bidsNeedsExtract) => {
    let materialIdNeedsExtract = new Set(bidsNeedsExtract.map(bid => {
      let typeIdSde = this.getProductByBlueprintId(bid);
      return typeIdSde == null ? null : typeIdSde.typeId;
    }).filter(id => id != null));
    for (let product of dataSources) {
      if (product.key != null) {
        product.typeId = product.key;
      }
      if (materialIdNeedsExtract.has(product.key)) {
        let materialBlueprint = this.getBlueprintByProductId(product.key);
        let productQuantityPerBatch = materialBlueprint.activities.manufacturing.products[0].quantity;
        let materialDataSources = _.cloneDeep(materialBlueprint.activities.manufacturing.materials);
        for (let materialDataSource of materialDataSources) {
          let materialTypeIdSde = this.getTypeIdSdeById(materialDataSource.typeId);
          materialDataSource.key = materialTypeIdSde.typeId;
          materialDataSource.name = materialTypeIdSde.name;
          if (materialTypeIdSde.categories != null) {
            materialDataSource.category = this.getCategory(materialTypeIdSde.typeId);
          }
          materialDataSource.quantity = materialDataSource.quantity * (product.quantity * 1 / productQuantityPerBatch);
          let marketDump = this.getMarketDumpById(materialDataSource.typeId);
          materialDataSource.singlePrice = marketDump == null ? 0 : marketDump.price;
          materialDataSource.totalPrice = materialDataSource.quantity * materialDataSource.singlePrice;
        }

        product.materials = materialDataSources;
        this.extractDataSourcesRecursive(materialDataSources, bidsNeedsExtract);
      }
    }
    return dataSources;
  }


  serializeResourceTreeRec = (nodes, result) => {
    let currLevel = new Map();
    let haveNextLevel = false;
    let nextLevelNodes = [];
    for (let node of nodes) {
      if (currLevel.has(node.key)) {
        currLevel.get(node.key).quantity += node.quantity;
      } else {
        currLevel.set(node.key, _.clone(node));
      }
      if (!_.isEmpty(node.materials)) {
        haveNextLevel = true;
        nextLevelNodes.push(...node.materials);
      }
    }
    result.push([...currLevel.values()]);
    if (haveNextLevel) {
      return this.serializeResourceTreeRec(nextLevelNodes, result);
    } else {
      return result;
    }
  }

  serializeResourceTree = (processList) => {
    let chartItems = [];
    processList.forEach((stepItems, stepIndex) => {
      let sortedStepItems = _.sortBy(stepItems, i => i.key);
      sortedStepItems.forEach((item, typeIdIndex) => {
        item.stepIndex = stepIndex;
        item.typeIdIndex = typeIdIndex;
        item.chartKey = `${stepIndex}_${typeIdIndex}_${item.key}`;
        chartItems.push(item);
      })
    })
    return chartItems;
  }

  getEstimatedPrice = (typeId) => {
    let estimatedPrice = _.find(store.getState().PriceDataReducer.estimatedPriceList, p => p.type_id === typeId);
    if (estimatedPrice == null) {
      return 0;
    } else {
      return estimatedPrice.adjusted_price;
    }
  }

  extractDataSources = (dataSourcesInput, bidsNeedsExtract, context) => {
    let dataSources = dataSourcesInput;

    for (let dataSource of dataSources) {
      if (dataSource.ceilQuantity == null) {
        dataSource.ceilQuantity = dataSource.quantity;
      }
    }

    this.extractDataSourcesLoop(_.cloneDeep(dataSources), bidsNeedsExtract, context);
    let dataTree = this.extractDataSourcesRecursive(_.cloneDeep(dataSources), bidsNeedsExtract);
    context.dataTree = dataTree;
    return context.flatDataSources;
  };

  multiplyDataSource = (dataSources, quantity) => {
    let newDataSources = [];
    for (let dataSource of dataSources) {
      let newDataSource = _.cloneDeep(dataSource);
      newDataSource.quantity = dataSource.quantity * quantity / dataSource.productQuantity;
      newDataSource.totalPrice = newDataSource.quantity * dataSource.singlePrice;
      newDataSources.push(newDataSource);
    }
    return newDataSources;
  };

  combineDataSource = (d1, d2) => {
    let resultMap = new Map();
    for (let dataSource of [...d1, ...d2]) {
      if (resultMap.has(dataSource.key)) {
        let existingSource = resultMap.get(dataSource.key);
        existingSource.quantity += dataSource.quantity;
        existingSource.ceilQuantity += dataSource.ceilQuantity;
        existingSource.totalPrice += dataSource.totalPrice;
      } else {
        resultMap.set(dataSource.key, dataSource);
      }
    }
    return [...resultMap.values()];
  };

  isProducedByBlueprintType(productId, blueprintType) {
    let mockBlueprints = store.getState().PriceDataReducer.allBlueprint.filter(v => {
      return v.blueprintType === blueprintType;
    });
    for (let mockBlueprint of mockBlueprints) {
      if (mockBlueprint.typeIdSde.typeId === productId) {
        return true;
      }
    }
    return false;
  }

  getMaterialEffectByProduct(productId) {
    let archMaterialEffect = null;
    if (this.isProducedByBlueprintType(productId, 'PLANET')) {
      archMaterialEffect = 1;
    } else if (this.isProducedByBlueprintType(productId, 'REACTION')) {
      archMaterialEffect = this.getReactMaterialPercent();
    } else {
      archMaterialEffect = this.getArchMaterialPercent();
    }
    return archMaterialEffect;
  }

  getInventBaseBlueprint = (t2BlueprintId) => {
    let result = _.find(store.getState().PriceDataReducer.allBlueprint, b => {
      if (b.activities.invention == null || _.isEmpty(b.activities.invention.products)) {
        return false;
      }
      for (let product of b.activities.invention.products) {
        if (product.typeId === t2BlueprintId) {
          return true;
        }
      }
      return false;
    });
    return result;
  }

  getBlueprintConfig(productId, materialEffectForSpecBp) {
    //种菜的默认材料效率是0
    if (this.isProducedByBlueprintType(productId, 'PLANET')) {
      return { materialEffect: 1 };
    }

    //建筑材料效率加成
    let archMaterialEffect = this.getMaterialEffectByProduct(productId);

    if (materialEffectForSpecBp != null) {
      return {
        materialEffect: (0.9 + 0.01 * (10 - materialEffectForSpecBp)) * archMaterialEffect
      };
    } else {
      let blueprint = this.getBlueprintByProductId(productId);
      let isTier2 = true;
      if (blueprint == null || blueprint.blueprintType !== 'NORMAL') {
        isTier2 = false;
      } else {
        isTier2 = this.getInventBaseBlueprint(blueprint.blueprintId) != null;
      }

      if (isTier2) {
        return {
          //t2蓝图默认材料效率是2，不用任何解码器直接发明就是2
          materialEffect: (0.9 + 0.01 * (10 - 2)) * archMaterialEffect
        };
      } else if (this.isProducedByBlueprintType(productId, 'NORMAL')) {
        //常规蓝图默认材料效率是设置界面的材料效率
        return {
          materialEffect: (0.9 + 0.01 * (10 - store.getState().SettingReducer.materialEffect)) * archMaterialEffect
        };
      } else {
        //反应公式只受建筑材料效率影响
        return {
          materialEffect: archMaterialEffect
        };
      }
    }
  }

  getCategory(typeId) {
    let typeIdSde = this.getTypeIdSdeById(typeId);
    let category = typeIdSde.categories[typeIdSde.categories.length - 1];
    switch (category) {
      case '艾玛':
      case '盖伦特':
      case '米玛塔尔':
      case '加达里':
      case 'Amarr':
      case 'Gallente':
      case 'Minmatar':
      case 'Caldari':
        category = typeIdSde.categories[typeIdSde.categories.length - 2];
        break;
      default:
        break;
    }
    return category;
  }

  getDataSourceByBlueprint(blueprint, productMarketCount, productCeilQuantity, blueprintCount, materialEffect) {
    if (blueprint == null) {
      return [];
    }
    let dataSources = [];
    let typeIdSdeList = store.getState().PriceDataReducer.allTypeIdSde;
    let productQuantityPerBatch = blueprint.activities.manufacturing.products[0].quantity;
    let productCeilProcessCount = Math.ceil(productCeilQuantity / productQuantityPerBatch);
    let productProcessCount = productMarketCount / productQuantityPerBatch;
    blueprint.activities.manufacturing.materials.forEach((m) => {
      let dataSource = {};
      let typeIdSde = this.getTypeIdSdeById(m.typeId, typeIdSdeList);
      dataSource.key = m.typeId;
      dataSource.name = typeIdSde.name;
      if (typeIdSde.categories != null) {
        dataSource.category = this.getCategory(typeIdSde.typeId);
      }
      if (m.quantity === 1) {
        dataSource.quantity = productProcessCount * blueprintCount;
        dataSource.ceilQuantity = dataSource.quantity;
      } else {
        dataSource.quantity = Math.ceil(
          m.quantity *
          productProcessCount *
          this.getBlueprintConfig(blueprint.typeIdSde.typeId, materialEffect).materialEffect
        ) * blueprintCount;
        dataSource.ceilQuantity = Math.ceil(
          m.quantity *
          productCeilProcessCount *
          this.getBlueprintConfig(blueprint.typeIdSde.typeId, materialEffect).materialEffect
        ) * blueprintCount;
      }
      let marketDump = this.getMarketDumpById(m.typeId);
      dataSource.singlePrice = marketDump == null ? 0 : marketDump.price;
      dataSource.totalPrice = dataSource.quantity * dataSource.singlePrice;
      dataSource.productQuantity = blueprint.activities.manufacturing.products[0].quantity;
      dataSources.push(dataSource);
    });
    return dataSources;
  }

  getTypeIdSdeById(id, typeIdSdeList) {
    if (typeIdSdeList == null) {
      typeIdSdeList = store.getState().PriceDataReducer.allTypeIdSde;
    }
    if (this.typeIdWithTypeObj.has(id)) {
      return this.typeIdWithTypeObj.get(id);
    }
    let typeIdSde = _.find(typeIdSdeList, (m) => m.typeId === id);
    if (!_.isEmpty(typeIdSde)) {
      this.typeIdWithTypeObj.set(id, typeIdSde);
    }
    return typeIdSde == null ? {} : typeIdSde;
  }


  getTypeIdAndQuantityFromPasteBinLine = (line) => {
    // line = line.replace(/星币/g, '').trim();
    line = line.replace(/\*/g, '').trim();
    let possibleSdeList = _.filter(store.getState().PriceDataReducer.allTypeIdSde, sde => {
      if (sde.marketGroupId === 0 && sde.metaGroupId === 0) {
        return false;
      }
      return line.indexOf(sde.name) >= 0;
    });
    if (_.isEmpty(possibleSdeList)) {
      return null;
    }
    let actualSde = null;
    for (let sde of possibleSdeList) {
      if (line.indexOf(sde.name + "\t") === 0 || line.indexOf(sde.name + " \t") === 0) {
        actualSde = sde;
      }
    }
    if (actualSde == null) {
      return null;
    }

    let columns = line.substr(actualSde.name.length).trim().replace(/,/g, '').split(/\t/g);
    let quantity = 1;
    for (let cell of columns) {
      if (cell.includes('%')) {
        continue;
      }
      if (isNaN(cell)) {
        continue;
      }
      if (_.isEmpty(cell)) {
        continue;
      }
      quantity = Number(cell);
      break;
    }
    // let quantityColumn = line.substr(actualSde.name.length).trim().split(/\t/g)[0].replace(/,/g, '');
    // if (quantityColumn.includes('%')) {
    //   quantityColumn = line.substr(actualSde.name.length).trim().split(/\t/g)[1].replace(/,/g, '');
    // }

    // let quantity = Number(quantityColumn);
    // if (Number.isNaN(quantity)) {
    //   quantity = 1;
    // }
    return { typeId: actualSde.typeId, quantity: quantity };
  }

  getDepositoryQuantity(typeId) {
    let depositoryItem = _.find(store.getState().SettingReducer.depository, d => d.typeId === typeId);
    return _.isEmpty(depositoryItem) ? 0 : depositoryItem.quantity;
  }

  getReactMaterialPercent = () => {
    let archTypeOption = store.getState().SettingReducer.reactArchType == null ? 1 : store.getState().SettingReducer.reactArchType;
    let archTierOption = store.getState().SettingReducer.reactArchTier == null ? 1 : store.getState().SettingReducer.reactArchTier;
    let archSecurityOption = store.getState().SettingReducer.reactArchSecurity == null ? 1 : store.getState().SettingReducer.reactArchSecurity;

    if (archTypeOption === 1) {
      //非工业建筑
      return 1;
    } else if (archTypeOption === 3) {
      //势力铁壁
      return 1;
    }

    if (archTierOption === 4) {
      //图克尔固定3.7*1.9=7.03
      return 1 - 7.03 / 100;
    }

    let result = 0;
    switch (archTierOption) {
      case 1:
        result = 2;
        break;
      case 2:
        result = 2.4;
        break;
      case 4:
        result = 3.7;
        break;
      default:
        break;
    }
    switch (archSecurityOption) {
      case 1:
        result *= 1;
        break;
      case 2:
        result *= 1;
        break;
      case 3:
        result *= 1.1;
        break;
      default:
        break;
    }

    return (1 - result / 100);
  }

  getArchMaterialPercent = () => {
    let archTypeOption = store.getState().SettingReducer.archType == null ? 1 : store.getState().SettingReducer.archType;
    let archTierOption = store.getState().SettingReducer.archTier == null ? 1 : store.getState().SettingReducer.archTier;
    let archSecurityOption = store.getState().SettingReducer.archSecurity == null ? 1 : store.getState().SettingReducer.archSecurity;

    if (archTypeOption === 1) {
      //非工业建筑
      return 1;
    } else if (archTypeOption === 3) {
      //势力铁壁
      if (archTierOption === 10) {
        return 1;
      } else if (archTierOption === 11) {
        return 0.99;
      } else if (archTierOption === 12) {
        return 0.98;
      } else if (archTierOption === 13) {
        return 0.97;
      } else if (archTierOption === 15) {
        return 0.95;
      } else {
        return 1;
      }
    }

    if (archTierOption === 4) {
      //图克尔固定3.7*1.9=7.03
      return (1 - 7.03 / 100) * 0.99;
    }

    let result = 0;
    switch (archTierOption) {
      case 1:
        result = 2;
        break;
      case 2:
        result = 2.4;
        break;
      case 4:
        result = 3.7;
        break;
      default:
        break;
    }
    switch (archSecurityOption) {
      case 1:
        result *= 1;
        break;
      case 2:
        result *= 1.9;
        break;
      case 3:
        result *= 2.1;
        break;
      default:
        break;
    }

    return (1 - result / 100) * 0.99;
  }

  getContractMarketDumpById(id) {
    let marketPrice = _.find(store.getState().PriceDataReducer.allMarketDump, m => m.isContract === true && m.typeId === id);

    if (store.getState().SettingReducer.useCustomPrice) {
      let customPrice = this.getCustomPriceById(id);
      if (!_.isEmpty(customPrice)) {
        return { ...customPrice, marketPrice: marketPrice == null ? 0 : marketPrice.price, origin: 'CUSTOM_PRICE' };
      }
    }
    return marketPrice;
  }

  getT3InventCost = (blueprintSetting) => {
    let blueprint = blueprintSetting.blueprint;
    let result = { materials: [], cost: 0 };

    if (_.isEmpty(store.getState().PriceDataReducer.allMarketDump) || _.isEmpty(blueprint) || blueprintSetting.decoderId === -1) {
      return result;
    }

    let ancient = T3Utils.getAncientById(blueprintSetting.ancientId);
    if (ancient == null) {
      return result;
    }

    let decoder = this.getDecoderById(blueprintSetting.decoderId);

    result.materials = _.cloneDeep(ancient.materials);
    result.materials.push({ typeId: blueprintSetting.ancientId, quantity: 1 });
    result.materials.forEach(m => {
      m.ceilQuantity = m.quantity;
    });

    for (let material of result.materials) {
      let marketDump = this.getMarketDumpById(material.typeId);
      if (marketDump != null) {
        result.cost += marketDump.price * material.quantity;
      }
    }

    let inventAddition = store.getState().SettingReducer.inventAddition;
    result.probability = ancient.probability * (inventAddition == null ? 1 : inventAddition);
    let inventionProductQuantity = ancient.productQuantity;

    //把解码器添加到原料列表，根据解码器计算发明后蓝图的流程数
    if (decoder != null) {
      inventionProductQuantity += decoder.quantity;
      result.materials.push({ typeId: decoder.typeId, quantity: 1, ceilQuantity: 1 });
      result.probability = result.probability * (1 + decoder.probability);
      let decoderMarketDump = this.getMarketDumpById(decoder.typeId);
      result.cost += decoderMarketDump == null ? 0 : decoderMarketDump.price;
    }

    //发明一次的成本、材料 * 要发明的数量 = 总发明成本、材料
    let multipleTimes = blueprintSetting.productQuantity * blueprintSetting.blueprintCount / (inventionProductQuantity * result.probability);
    result.cost *= multipleTimes;
    result.materials.forEach(m => {
      m.key = m.typeId;
      m.ceilQuantity *= Math.ceil(multipleTimes);
      m.quantity *= multipleTimes;
    });
    return result;
  }

  getInventCost = (blueprintSetting) => {
    let blueprint = blueprintSetting.blueprint;
    let blueprintTypeId = blueprint.blueprintTypeId;
    let result = { materials: [], cost: 0, probability: 0 };
    if (_.isEmpty(store.getState().PriceDataReducer.allMarketDump) || _.isEmpty(blueprint) || blueprintSetting.decoderId === -1) {
      return result;
    }
    let inventBaseBlueprint = this.getInventBaseBlueprint(blueprintTypeId);
    if (inventBaseBlueprint == null) {
      return result;
    }
    let decoder = _.find(store.getState().ItemInfoReducer.allDecoders, d => Number(d.typeId) === Number(blueprintSetting.decoderId));

    result.materials = _.cloneDeep(inventBaseBlueprint.activities.invention.materials);
    result.materials.forEach(m => {
      m.ceilQuantity = m.quantity;
    });
    for (let material of inventBaseBlueprint.activities.invention.materials) {
      let marketDump = this.getMarketDumpById(material.typeId);
      if (marketDump != null) {
        result.cost += marketDump.price * material.quantity;
      }
    }
    let inventionProduct = _.find(inventBaseBlueprint.activities.invention.products, p => p.typeId === blueprintTypeId);
    let inventAddition = store.getState().SettingReducer.inventAddition;
    result.probability = inventionProduct.probability * (inventAddition == null ? 1 : inventAddition);
    let inventionProductQuantity = inventionProduct.quantity;

    let inventBaseBlueprintType = this.getTypeIdSdeById(inventBaseBlueprint.blueprintTypeId);
    if (inventBaseBlueprintType.marketGroupId <= 0) {
      //发明用拷贝图无法通过原图拷贝得到，比如阿三发明，把t1拷贝图添加到原料列表
      result.materials.push({ typeId: inventBaseBlueprintType.typeId, quantity: 1, ceilQuantity: 1 });
      let inventBaseBlueprintMarketDump = this.getMarketDumpById(inventBaseBlueprintType.typeId);
      result.cost += inventBaseBlueprintMarketDump == null ? 0 : inventBaseBlueprintMarketDump.price;
    }

    //把解码器添加到原料列表，根据解码器计算发明后蓝图的流程数
    if (inventBaseBlueprint) {
      if (decoder != null) {
        inventionProductQuantity += decoder.quantity;
        result.materials.push({ typeId: decoder.typeId, quantity: 1, ceilQuantity: 1 });
        result.probability = result.probability * (1 + decoder.probability);
        let decoderMarketDump = this.getMarketDumpById(decoder.typeId);
        result.cost += decoderMarketDump == null ? 0 : decoderMarketDump.price;
      }
    }
    //发明一次的成本、材料 * 要发明的数量 = 总发明成本、材料
    let multipleTimes = blueprintSetting.productQuantity * blueprintSetting.blueprintCount / (inventionProductQuantity * result.probability);
    result.cost *= multipleTimes;
    result.materials.forEach(m => {
      m.key = m.typeId;
      m.ceilQuantity *= Math.ceil(multipleTimes);
      m.quantity *= multipleTimes;
    });
    return result;
  }

  getProductByBlueprintId = (blueprintId) => {
    if (isNaN(blueprintId)) {
      return null;
    }
    if (this.blueprintIdWithProduct.has(blueprintId)) {
      return this.blueprintIdWithProduct.get(blueprintId);
    }
    let blueprint = _.find(store.getState().PriceDataReducer.allBlueprint, b => b.blueprintTypeId === blueprintId);
    if (blueprint != null) {
      this.blueprintIdWithProduct.set(blueprintId, blueprint.typeIdSde);
    }
    return blueprint == null ? null : blueprint.typeIdSde;
  }

  getCustomPriceById = (typeId) => {
    let customPrice = _.find(store.getState().SettingReducer.customPrices, p => p.typeId === typeId);
    if (customPrice == null) {
      return null;
    }
    return customPrice;
  }

  getMarketDumpById(id, params = {}) {
    let isResource = true;
    if (params.isResource != null) {
      isResource = params.isResource;
    }

    let vendor = 0;
    if (isResource) {
      vendor = store.getState().SettingReducer.resourcePriceVendor;
    } else {
      vendor = store.getState().SettingReducer.productPriceVendor;
    }
    if (params.vendor != null) {
      vendor = params.vendor;
    }

    let result = null;

    let marketDumps = [];
    let galaxy = _.find(store.getState().SettingReducer.priceGalaxy, g => g.server === store.getState().SettingReducer.server);
    if (galaxy == null) {
      galaxy = [30000142];
    } else {
      if (isResource) {
        galaxy = galaxy.galaxyResource;
      } else {
        galaxy = galaxy.galaxyProduct;
      }
    }

    marketDumps = _.filter(store.getState().PriceDataReducer.allMarketDump, (m) => m.typeId === id);
    marketDumps = _.filter(marketDumps, (m) => {
      return galaxy.includes(Number(m.systemId));
    });

    //计算最低卖价
    let sellMarketDumps = _.filter(marketDumps, (m) => {
      return m.isBuy === false;
    });

    let latestPriceDate = "1995-12-25";
    let latestSellMarketDumps = [];
    for (let marketDump of sellMarketDumps) {
      let createAt = moment(marketDump.createAt);
      let priceDate = createAt.format("YYYY-MM-DD");
      if (priceDate > latestPriceDate) {
        latestPriceDate = priceDate;
        latestSellMarketDumps = [marketDump];
      } else if (priceDate === latestPriceDate) {
        latestSellMarketDumps.push(marketDump);
      }
    }

    let lowestSellPrice = 0;
    for (let md of latestSellMarketDumps) {
      if (lowestSellPrice === 0) {
        lowestSellPrice = md.price;
      } else if (md.price < lowestSellPrice) {
        lowestSellPrice = md.price;
      }
    }

    //计算最高买价
    let buyMarketDumps = _.filter(marketDumps, (m) => {
      return m.isBuy === true;
    });

    latestPriceDate = "1995-12-25";
    let latestBuyMarketDumps = [];
    for (let marketDump of buyMarketDumps) {
      let createAt = moment(marketDump.createAt);
      let priceDate = createAt.format("YYYY-MM-DD");
      if (priceDate > latestPriceDate) {
        latestPriceDate = priceDate;
        latestBuyMarketDumps = [marketDump];
      } else if (priceDate === latestPriceDate) {
        latestBuyMarketDumps.push(marketDump);
      }
    }

    let highestBuyPrice = 0;
    for (let md of latestBuyMarketDumps) {
      if (highestBuyPrice === 0) {
        highestBuyPrice = md.price;
      } else if (md.price > highestBuyPrice) {
        highestBuyPrice = md.price;
      }
    }

    if (vendor === 0) {
      //0: 最低卖价
      result = { typeId: id, price: lowestSellPrice };
    } else if (vendor === 1) {
      //1：最高买价
      result = { typeId: id, price: highestBuyPrice };
    } else if (vendor === 2) {
      //1天内平均成交价
      let dealPrices = _.filter(store.getState().PriceDataReducer.dealPriceList, d => d.typeId === id);
      if (dealPrices.length > 0) {
        dealPrices = _.sortBy(dealPrices, d => d.regionId);
        result = { typeId: id, price: dealPrices[0].lastDealPrice };
      }
    } else if (vendor === 3) {
      //20天内平均成交价
      let dealPrices = _.filter(store.getState().PriceDataReducer.dealPriceList, d => d.typeId === id);
      if (dealPrices.length > 0) {
        dealPrices = _.sortBy(dealPrices, d => d.regionId);
        result = { typeId: id, price: dealPrices[0].quarterDealPrice };
      }
    } else if (vendor === 4) {
      //买卖中间价
      result = { typeId: id, price: (highestBuyPrice + lowestSellPrice) / 2 };
    }

    // if (vendor == 0 || vendor == 1) {
    //   //0: 最低卖价 1：最高买价
    //   marketDumps = _.filter(store.getState().PriceDataReducer.allMarketDump, (m) => m.typeId === id);
    //   marketDumps = _.filter(marketDumps, (m) => {
    //     if (vendor === 1 && m.isBuy === true) {
    //       return true;
    //     } else if (vendor === 0 && m.isBuy === false) {
    //       return true;
    //     }
    //     return false;
    //   });
    // } else if (vendor === 2) {
    //   //1天内平均成交价
    //   let dealPrices = _.filter(store.getState().PriceDataReducer.dealPriceList, d => d.typeId === id);
    //   marketDumps = dealPrices.map(d => {
    //     return { typeId: d.typeId, price: d.lastDealPrice, systemId: 30001363 };
    //   });
    // } else if (vendor === 3) {
    //   //20天内平均成交价
    //   let dealPrices = _.filter(store.getState().PriceDataReducer.dealPriceList, d => d.typeId === id);
    //   marketDumps = dealPrices.map(d => {
    //     return { typeId: d.typeId, price: d.quarterDealPrice, systemId: 30001363 };
    //   });
    // } else if (vendor === 4) {
    //   //买卖中间价
    //   marketDumps = _.filter(store.getState().PriceDataReducer.allMarketDump, (m) => m.typeId === id);
    // }

    // let server = store.getState().SettingReducer.server;

    // marketDumps = _.filter(marketDumps, (m) => {
    //   return galaxy.includes(Number(m.systemId));
    // });

    // if (_.isEmpty(marketDumps)) {
    //   return null;
    // }

    // if (vendor === 2 || vendor === 3) {
    //   marketDumps = _.sortBy(marketDumps, m => m.systemId);
    //   result = marketDumps[0];
    // } else if (vendor === 0 || vendor === 1) {
    //   result = marketDumps[0];
    //   for (let marketDump of marketDumps) {
    //     if (vendor === 1 && marketDump.price > result.price) {
    //       result = marketDump;
    //     } else if (vendor === 0 && marketDump.price < result.price) {
    //       result = marketDump;
    //     }
    //   }
    // } else {
    //   let highestBuy = _.find(marketDumps, m => m.isBuy === true);
    //   let lowestSell = _.find(marketDumps, m => m.isBuy === false);
    //   for (let marketDump of marketDumps) {
    //     if (marketDump.isBuy === false && marketDump.price < lowestSell.price) {
    //       lowestSell = marketDump;
    //     } else if (marketDump.isBuy === true && marketDump.price > highestBuy.price) {
    //       highestBuy = marketDump;
    //     }
    //   }
    //   let highestBuyPrice = highestBuy == null ? 0 : highestBuy.price;
    //   let lowestSellPrice = lowestSell == null ? 0 : lowestSell.price;
    //   result = { typeId: marketDumps[0].typeId, price: (highestBuyPrice + lowestSellPrice) / 2, systemId: marketDumps[0].systemId }
    // }

    if (result != null) {
      let priceOff = isResource ? store.getState().SettingReducer.resourceOff : store.getState().SettingReducer.productOff;
      result = _.clone(result);
      result.price = result.price * priceOff / 100;
    }

    if (store.getState().SettingReducer.useCustomPrice) {
      let customPrice = this.getCustomPriceById(id);
      if (!_.isEmpty(customPrice)) {
        return { ...customPrice, marketPrice: result.price, origin: 'CUSTOM_PRICE' };
      }
    }

    // if (store.getState().SettingReducer.useCustomPrice) {
    //   let customPrice = this.getCustomPriceById(id);
    //   if (!_.isEmpty(customPrice)) {
    //     return { ...customPrice, marketPrice: result.price, origin: 'CUSTOM_PRICE' };
    //   }
    // }

    return result;
  }

  needConsiderBlueprintCopyCost(blueprintId) {
    if (_.isEmpty(store.getState().PriceDataReducer.allBlueprint) || _.isEmpty(store.getState().PriceDataReducer.allTypeIdSde)) {
      return false;
    }
    if (this.blueprintsNeedConsiderBlueprintCopy.size === 0) {
      this.blueprintsNeedConsiderBlueprintCopy = new Set(this.getBlueprintsNeedConsiderBlueprintCopy().map(b => b.blueprintTypeId));
    }
    return this.blueprintsNeedConsiderBlueprintCopy.has(blueprintId);
  }

  getBlueprintsNeedConsiderBlueprintCopy() {
    let result = [...store.getState().PriceDataReducer.allBlueprint];
    //如果这个蓝图原本能在市场上出售，就不考虑拷贝图成本
    result = result.filter(b => {
      let blueprintTypeSde = this.getTypeIdSdeById(b.blueprintTypeId);
      if (blueprintTypeSde.marketGroupId !== 0) {
        return false;
      } else {
        return true;
      }
    });
    //如果这个蓝图能被发明出来，就不考虑拷贝图成本
    let inventResults = new Set();
    store.getState().PriceDataReducer.allBlueprint.forEach(b => {
      if (b.activities.invention == null) {
        return;
      }
      b.activities.invention.products.forEach(p => {
        inventResults.add(p.typeId);
      })
    })
    result = result.filter(b => {
      return !inventResults.has(b.blueprintTypeId);
    })

    //如果这个蓝图是T3，就不考虑拷贝图成本
    result = result.filter(b => {
      return T3Utils.isT3Blueprint(b.blueprintTypeId) === false;
    })
    return result;
  }
}

const instance = new ProfitHelper();

export default instance;