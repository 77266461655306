import store from '../store';
import _ from 'lodash';
import toHex from 'colornames';

export const themes = [{
    name: '米玛塔尔',
    primaryColor: 'sienna',
    secondaryColor: 'burlywood',
    textColor: 'sienna',
}, {
    name: '罗亚果实',
    primaryColor: 'green',
    secondaryColor: 'crimson',
    textColor: 'green',
}, {
    name: '高安',
    primaryColor: 'skyblue',
    secondaryColor: 'limegreen',
    textColor: 'forestgreen',
}, {
    name: '蛋',
    primaryColor: 'whitesmoke',
    secondaryColor: 'orange',
    textColor: 'black'
}, {
    name: 'A.E.I.O.U.',
    primaryColor: 'black',
    secondaryColor: 'orange',
    textColor: 'black',
}]


export const getCustomTheme = () => {
    let customTheme = store.getState().SettingReducer.customTheme;
    if (_.isEmpty(customTheme)) {
        return {
            name: '自定义',
            primaryColor: 'whitesmoke',
            secondaryColor: 'orange',
            textColor: 'black'
        }
    } else {
        return {
            name: '自定义',
            primaryColor: customTheme.primaryColor == null ? 'whitesmoke' : customTheme.primaryColor,
            secondaryColor: customTheme.secondaryColor == null ? 'orange' : customTheme.secondaryColor,
            textColor: customTheme.textColor == null ? 'black' : customTheme.textColor,
        };
    }
}

export const getOpColor = (vgaColor) => {
    let primaryColorHex = vgaColor;
    if (!primaryColorHex.startsWith('#')) {
        primaryColorHex = toHex(vgaColor);
    }
    primaryColorHex = primaryColorHex.replace('#', '');
    let darkScore = parseInt(primaryColorHex.substr(0, 2), 16) + parseInt(primaryColorHex.substr(2, 2), 16) + parseInt(primaryColorHex.substr(4, 2), 16);
    if (darkScore > 384) {
        return 'black';
    } else {
        return 'white';
    }
}