import { combineReducers } from 'redux';
import PriceDataReducer from './PriceDataReducer';
import MessageBoardReducer from './MessageBoardReducer';
import ItemInfoReducer from './ItemInfoReducer';
import RefineReducer from './RefineReducer';
import SettingReducer from './SettingReducer';
import QQUserReducer from './QQUserReducer';

const allReducers = combineReducers({
    PriceDataReducer,
    MessageBoardReducer,
    ItemInfoReducer,
    RefineReducer,
    SettingReducer,
    QQUserReducer
});


export default allReducers