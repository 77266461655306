import React from "react";
import ExportSettings from '../component/ExportSettings';
import ImportSettings from '../component/ImportSettings';
import { Menu, Dropdown, message, Table, Button, Tooltip, Modal } from "antd";
import * as SettingAction from '../action/SettingAction';
import * as QQUserAction from '../action/QQUserAction';
import { CloudDownloadOutlined, CheckCircleFilled, DeleteOutlined, SaveOutlined, FileAddOutlined, ExclamationCircleOutlined, UndoOutlined } from "@ant-design/icons";
import { initialState } from '../reducer/SettingReducer';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ProfitHelper from '../common/ProfitHelper';
import _ from 'lodash';
import * as IDUtils from '../common/IDUtils';
import * as Utils from '../common/Utils';
import qqlogin from "../resource/qqlogin.png";

class BackupTableSetting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        this.props.QQUserAction.listUserSetting();
    }

    getSettingList = () => {
        console.log('getSettingList')
        let isNewSetting = _.find(this.props.QQUserReducer.settings, s => s.settingId === this.props.SettingReducer.settingId) == null;
        if (isNewSetting) {
            return [{ settingName: '未命名' }, ...this.props.QQUserReducer.settings];
        } else {
            return this.props.QQUserReducer.settings;
        }
    }

    getColumns = () => {
        return [
            {
                title: '使用中',
                dataIndex: 'settingId',
                sorter: Utils.createNumericSorter('settingId'),
                render: (text, record, index) => {
                    if (_.isEmpty(record.settingId) || record.settingId === this.props.SettingReducer.settingId) {
                        return (<div style={{ color: "green", fontSize: '1.5em', display: 'flex', justifyContent: 'center' }}><CheckCircleFilled /></div>);
                    } else {
                        return '';
                    }
                },
                width: '80px',
                align: 'right',
            },
            {
                title: '名称',
                dataIndex: 'settingName',
                sorter: Utils.createStringSorter('settingName'),
            },
            {
                title: `操作`,
                dataIndex: 'settingId',
                width: '100px',
                sorter: Utils.createNumericSorter('settingId'),
                render: (typeId, record) => {
                    let isCurrentSetting = _.isEmpty(record.settingId) || record.settingId === this.props.SettingReducer.settingId;
                    let isNewSetting = _.isEmpty(record.settingId);
                    return (
                        <div style={{ display: 'flex' }}>
                            {isCurrentSetting ? '' : (
                                <div style={{ marginRight: '5px' }}>
                                    <Tooltip title={'加载'}>
                                        <Button type="default" icon={<CloudDownloadOutlined />} style={{ borderRadius: '2px' }} size='middle' onClick={(e) => this.loadUserSetting(record.settingId)} />
                                    </Tooltip>
                                </div>
                            )}

                            {isCurrentSetting && !isNewSetting ? (
                                <div style={{ marginRight: '5px' }}>
                                    <Tooltip title={'保存'}>
                                        <Button type="default" icon={<SaveOutlined />} style={{ borderRadius: '2px' }} size='middle' onClick={(e) => this.saveUserSetting()} />
                                    </Tooltip>
                                </div>
                            ) : ''}

                            {isCurrentSetting ? (
                                <div style={{ marginRight: '5px' }}>
                                    <Tooltip title={'另存为'}>
                                        <Button type="default" icon={<FileAddOutlined />} style={{ borderRadius: '2px' }} size='middle' onClick={(e) => this.saveUserSettingAs()} />
                                    </Tooltip>
                                </div>
                            ) : ''}

                            {isCurrentSetting ? '' : (
                                <div style={{ marginRight: '5px' }}>
                                    <Tooltip title={'删除'}>
                                        <Button type="default" icon={<DeleteOutlined />} style={{ borderRadius: '2px' }} size='middle' onClick={(e) => this.removeUserSetting(record.settingId)} />
                                    </Tooltip>
                                </div>
                            )}

                            {isCurrentSetting ? (
                                <div style={{ marginRight: '5px' }}>
                                    <Tooltip title={'还原初始设置'}>
                                        <Button type="default" icon={<UndoOutlined />} style={{ borderRadius: '2px' }} size='middle' onClick={(e) => this.resetUserSetting()} />
                                    </Tooltip>
                                </div>
                            ) : ''}
                        </div>
                    )
                }
            }
        ]
    }

    loadUserSetting = (settingId) => {
        this.props.QQUserAction.getUserSetting(settingId);
    }

    saveUserSetting = () => {
        this.props.QQUserAction.saveUserSetting();
    }

    resetUserSetting = () => {
        this.showWarning('还原初始设置', `确定要放弃当前设置，并且还原成初始设置吗？`).then(() => {
            this.props.SettingAction.importSettings(initialState);
        });
    }

    saveUserSettingAs = () => {
        let settingName = prompt('给新设置取一个名字')
        if (_.isEmpty(settingName)) {
            message.warning('配置名称不能为空。')
            return;
        }
        if ('未命名' === settingName) {
            message.warning('你发现了华点。')
            return;
        }
        if (this.props.QQUserReducer.settings.length >= 20) {
            message.warning('每个人最多拥有20个设置。')
            return;
        }
        this.props.SettingAction.setSettingName(settingName);
        this.props.SettingAction.setSettingId(IDUtils.guid());
        this.props.QQUserAction.saveUserSetting();
    }

    removeUserSetting = (settingId) => {
        this.showWarning('删除用户设置', `真的要删除这个设置吗？`).then(() => {
            this.props.QQUserAction.removeUserSetting(settingId);
        });
    }

    showWarning = (title, message) => {
        return new Promise((resolve, reject) => {
            Modal.confirm({
                title: title,
                icon: <ExclamationCircleOutlined />,
                content: message,
                okText: '是',
                okType: 'danger',
                cancelText: '否',
                onOk: () => {
                    resolve();
                }
                ,
                onCancel: () => {
                    reject();
                },
            });
        })
    };

    render() {
        let loginStatus = window.QC.Login.check();
        if (loginStatus == false || this.props.QQUserReducer.qqUser == null || this.props.QQUserReducer.qqUser.openId == null) {
            return this.renderLogin();
        } else {
            return this.renderBackup();
        }
    }

    renderLogin() {
        return <div style={{ display: 'flex', flexDirection: 'column', height: '300px' }}>
            <div style={{ position: 'relative', top: '20%' }}>
                <div style={{ textAlign: 'center', marginBottom: '20px' }}>使用备份功能需要先登录</div>
                <div>
                    <Button style={{ fontWeight: 'bold', left: '50%', transform: 'translateX(-50%)' }} type="primary" value="large" ghost>
                        <img src={qqlogin} style={{ position: 'relative', top: '-2px' }} onClick={() => this.qqlogin()} />
                    </Button>
                </div>
            </div>
        </div>
    }

    renderBackup() {
        return (
            <div >
                <Table columns={this.getColumns()} size='small' pagination={{ showSizeChanger: true, pageSize: 500 }}
                    bordered dataSource={this.getSettingList()} />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        SettingReducer: state.SettingReducer,
        QQUserReducer: state.QQUserReducer
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        SettingAction: bindActionCreators(SettingAction, dispatch),
        QQUserAction: bindActionCreators(QQUserAction, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BackupTableSetting);