import React from "react";
import { Tabs, Modal, Table, Tooltip, Button, Spin } from "antd";
import { TrophyOutlined } from '@ant-design/icons';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Utils from '../common/Utils';
import * as T3Utils from '../common/T3Utils';
import * as PriceDataAction from '../action/PriceDataAction';
import ProfitHelper from '../common/ProfitHelper';
import PriceHistory from './PriceHistory';
import _ from 'lodash';
import moment from 'moment';

const { TabPane } = Tabs;


class T3InventCompare extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            t3InventStats: [],
            loading: true
        };
    }

    componentDidMount() { }

    componentWillReceiveProps(nextProps) {
        if (nextProps.show !== this.props.show && nextProps.show === true) {
            this.setState({ loading: true }, () => {
                setTimeout(() => {
                    let t3InventStats = this.getT3InventStats();
                    this.setState({ t3InventStats, loading: false });
                }, 100);
            });

        } else if (nextProps.show !== this.props.show && nextProps.show === false) {
            this.setState({ t3InventStats: [], loading: true });
        }
    }

    getColumns = () => {
        return [
            {
                title: '',
                dataIndex: 'typeId',
                key: 'typeId',
                sorter: Utils.createNumericSorter('typeId'),
                render: (text, record) => {
                    return (
                        <div style={{ fontSize: 'x-large', color: 'orange', display: 'flex' }}>
                            <div style={{ margin: '0px 5px', display: record.isMaxProfit ? '' : 'none' }}>
                                <Tooltip title='总利润最高'>
                                    <TrophyOutlined />
                                </Tooltip>
                            </div>
                            <div style={{ margin: '0px 5px', display: record.isMaxProfitPercent ? '' : 'none' }}>
                                <Tooltip title='利润率最高'>
                                    <TrophyOutlined />
                                </Tooltip>
                            </div>
                            <div style={{ margin: '0px 5px', display: record.isMaxDailyProfit ? '' : 'none' }}>
                                <Tooltip title='日均利润最高'>
                                    <TrophyOutlined />
                                </Tooltip>
                            </div>
                        </div>
                    )
                }
            },
            {
                title: '古文物',
                dataIndex: 'ancientName',
                key: 'ancientName',
                sorter: Utils.createStringSorter('ancientName'),
                render: (value, record) => {
                    return <div style={{ display: 'flex' }}><img style={{ width: '24px', width: '24px', marginTop: '-2px', marginRight: '5px' }} src={ProfitHelper.getAncientImage(record.ancientId)}></img><div>{value}</div></div>
                },
            },
            {
                title: '解码器',
                dataIndex: 'decoderName',
                key: 'decoderName',
                sorter: Utils.createStringSorter('decoderName'),
                render: (value, record) => {
                    return <div style={{ display: 'flex' }}><img style={{ width: '30px', marginTop: '-6px', marginLeft: '-3px' }} src={ProfitHelper.getTypeImage(34201)}></img><div>{value}</div></div>
                },
            },
            {
                title: '蓝图流程',
                dataIndex: 'productQuantity',
                key: 'productQuantity',
                sorter: Utils.createStringSorter('productQuantity')
            },
            {
                title: '发明成本',
                dataIndex: 'inventFee',
                key: 'inventFee',
                render: (value, record) => {
                    return Utils.formatISK(value, 0) + ' ISK';
                },
                sorter: Utils.createNumericSorter('inventFee')
            },
            {
                title: '总利润',
                dataIndex: 'totalProfit',
                key: 'totalProfit',
                render: (value, record) => {
                    return <div style={{ color: value > 0 ? 'green' : 'red', fontWeight: 'bold' }}>{Utils.formatISK(value, 0) + ' ISK'}</div>;
                },
                sorter: Utils.createNumericSorter('totalProfit')
            },
            {
                title: '生产用时',
                dataIndex: 'time',
                key: 'time',
                render: (value, record) => {
                    return Utils.formatSeconds(value);
                },
                sorter: Utils.createNumericSorter('time')
            },
            {
                title: '利润率',
                dataIndex: 'profitPercent',
                key: 'profitPercent',
                render: (value, record) => {
                    return <div style={{ color: value > 0 ? 'green' : 'red', fontWeight: 'bold' }}>{Utils.toPercentString(value)}</div>;
                },
                sorter: Utils.createNumericSorter('profitPercent')
            },
            {
                title: '日均利润',
                dataIndex: 'dailyProfit',
                key: 'dailyProfit',
                render: (value, record) => {
                    return <div style={{ color: value > 0 ? 'green' : 'red', fontWeight: 'bold' }}>{Utils.formatISK(value, 0) + ' ISK'}</div>;
                },
                sorter: Utils.createNumericSorter('dailyProfit')
            },
            {
                title: '操作',
                dataIndex: 'typeId',
                key: 'typeId',
                render: (value, record) => <Button type='primary' size='small' onClick={() => {
                    this.props.onInventChange(record.decoderId, record.ancientId);
                    this.onOK();
                }
                }>采用</Button>,
            }
        ];
    }

    getDecoders = () => {
        return [
            { typeId: 0 },
            ...this.props.ItemInfoReducer.allDecoders,
        ]
    }

    getT3InventStats = () => {
        let decoders = this.getDecoders();
        let ancients = T3Utils.getAncientsByBlueprintId(this.props.blueprintSetting.blueprintTypeId);
        let t3InventStats = [];
        for (let ancient of ancients) {
            for (let decoder of decoders) {
                t3InventStats.push(this.getT3InventStat(decoder, ancient));
            }
        }
        if (!_.isEmpty(t3InventStats)) {
            let maxProfit = t3InventStats[0];
            let maxProfitPercent = t3InventStats[0];
            let maxDailyProfit = t3InventStats[0];
            for (let decoderStat of t3InventStats) {
                if (decoderStat.totalProfit > maxProfit.totalProfit) {
                    maxProfit = decoderStat;
                }
                if (decoderStat.profitPercent > maxProfitPercent.profitPercent) {
                    maxProfitPercent = decoderStat;
                }
                if (decoderStat.dailyProfit > maxDailyProfit.dailyProfit) {
                    maxDailyProfit = decoderStat;
                }
            }
            maxProfit.isMaxProfit = true;
            maxProfitPercent.isMaxProfitPercent = true;
            maxDailyProfit.isMaxDailyProfit = true;
        }
        return t3InventStats;
    }

    getT3InventStat = (decoder, ancient) => {
        let blueprintSetting = _.clone(this.props.blueprintSetting);
        blueprintSetting.decoderId = decoder.typeId;
        blueprintSetting.ancientId = ancient.ancientId;

        let quantity = ancient.productQuantity;
        let materialEffect = 2;
        if (decoder.typeId == 0) {
            quantity = ancient.productQuantity;
        } else {
            quantity = ancient.productQuantity + decoder.quantity;
            materialEffect += decoder.materialEffect;
        }

        blueprintSetting.productQuantity = quantity;
        blueprintSetting.materialEffect = materialEffect;
        blueprintSetting.blueprint = this.props.blueprint;
        let [resourceList, context] = ProfitHelper.getResourceList([blueprintSetting], this.props.getExtractBlueprintIds());


        // let totalCost = 0;
        // let product = ProfitHelper.getProductByBlueprintId(this.props.blueprintSetting.blueprintTypeId);
        // let productMarketDump = ProfitHelper.getMarketDumpById(product.typeId, { isResource: false });
        // let productPrice = productMarketDump == null ? 0 : productMarketDump.price;
        // for (let resource of resourceList) {
        //     totalCost += resource.totalPrice;
        // }
        // totalCost = totalCost + context.inventFee + context.solarSystemFee + context.customsFee;
        // let totalPrice = productPrice * blueprintSetting.blueprintCount * blueprintSetting.productQuantity;

        // let totalProfit = totalPrice - totalCost;
        // let profitPercent = totalProfit / totalCost;
        // let dailyProfit = totalProfit / (context.time * 0.544 / 86400);
        return {
            ...decoder,
            decoderName: this.getDecoderName(decoder),
            ancientName: ancient.ancientName,
            ancientId: ancient.ancientId,
            time: context.time * 0.544,
            productQuantity: blueprintSetting.productQuantity,
            totalProfit: context.totalProfit,
            profitPercent: context.profitPercent,
            dailyProfit: context.dailyProfit,
            inventFee: context.singleInventFee
        }
    }

    getDecoderName = (decoder) => {
        if (decoder.typeId === 0) {
            return '不使用解码器';
        } else {
            return decoder.name;
        }
    }

    onOK = () => {
        this.props.onOK();
    };

    onCancel = () => {
        this.props.onOK();
    };

    renderSpin = () => {
        return (<div style={{ position: 'absolute', left: '50%', top: '50%' }}>
            <Spin size='large' />
        </div>)
    }

    render() {
        let product = ProfitHelper.getProductByBlueprintId(this.props.blueprintSetting.blueprintTypeId);
        let productMarketDump = ProfitHelper.getMarketDumpById(product.typeId, { isResource: false });
        let productPrice = productMarketDump == null ? 0 : productMarketDump.price;
        return (
            <div>
                <Modal title={product.name} visible={this.props.show} onOk={this.onOK} onCancel={this.onCancel}
                    okText={'好'} cancelText={'关闭'} width={'90%'} wrapClassName='orders-modal'>
                    <div style={{ fontSize: 'x-large', display: 'flex' }}>
                        <img style={{ width: '50px' }} src={ProfitHelper.getTypeImage(product.typeId)} />
                        <div style={{ marginLeft: '20px' }}>{product.name}</div>
                        <div style={{ marginLeft: '20px' }}>{Utils.formatISK(productPrice, 0)} ISK</div>
                    </div>
                    {this.state.loading ? this.renderSpin() : <Table columns={this.getColumns()} dataSource={this.state.t3InventStats} size='small' pagination={{ showSizeChanger: true, pageSize: 200 }} />}
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        PriceDataReducer: state.PriceDataReducer,
        ItemInfoReducer: state.ItemInfoReducer,
        SettingReducer: state.SettingReducer
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        PriceDataAction: bindActionCreators(PriceDataAction, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(T3InventCompare);
