import React from 'react';
import * as PriceDataAction from '../action/PriceDataAction';
import * as ItemInfoAction from '../action/ItemInfoAction';
import * as SettingAction from '../action/SettingAction';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Layout, Spin, Radio, Statistic, Row, Col, Button, Divider, Table, Dropdown, InputNumber, Tabs } from 'antd';
import _ from 'lodash';
import * as IDUtils from '../common/IDUtils';
import * as Utils from '../common/Utils';

import BlueprintTree from './BlueprintTree';
import SingleBlueprintView from './SingleBlueprintView';
import MultiBlueprintView from './MultiBlueprintView';
import SelectProfitCalcType from './SelectProfitCalcType';
import * as UrlHelper from '../action/UrlHelper';
import Initializer from '../common/Initializer';
import ProfitHelper from '../common/ProfitHelper';

const { Header, Content, Sider } = Layout;
const { TabPane } = Tabs;

class ProfitCalc extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      productQuant: 1,
      showSelectProfitCalcType: false,
      // tabInfoList: [{key: "0", blueprintTypeId: 0}],
      // activeTabKey: "0",
    };
  }

  componentDidMount() {
    Initializer.initPriceData();
  }

  onChange = activeKey => {
    this.props.SettingAction.setActiveTabKey(activeKey);
  };

  onEdit = (targetKey, action) => {
    console.log('onEdit')
    console.log(targetKey)
    console.log(action)
    if (action === 'add') {
      this.toggleShowSelectProfitCalcType();
      // let newTabInfo = { key: this.getNextTabKey(), blueprintTypeId: 0 }
      // let newTabInfoList = [...this.getCurrentTabInfoList()];
      // newTabInfoList.push(newTabInfo);
      // this.props.SettingAction.setTabInfoList(newTabInfoList);
      // this.props.SettingAction.setActiveTabKey(newTabInfo.key);
    } else if (action === 'remove') {
      let newTabInfoList = _.filter(this.getCurrentTabInfoList(), info => info.key !== targetKey);
      this.props.SettingAction.setTabInfoList(newTabInfoList);
    }
  };

  addNewTab = (blueprintTypeId) => {
    let newTabInfo = null;
    if (blueprintTypeId instanceof Array) {
      //add new multi blueprint tab
      newTabInfo = { key: this.getNextTabKey(), blueprintTypeId: [] }
    } else {
      newTabInfo = { key: this.getNextTabKey(), blueprintSetting: { blueprintTypeId: 0 } }
    }
    let newTabInfoList = [...this.getCurrentTabInfoList()];
    newTabInfoList.push(newTabInfo);
    this.props.SettingAction.setTabInfoList(newTabInfoList);
    this.props.SettingAction.setActiveTabKey(newTabInfo.key);
    this.toggleShowSelectProfitCalcType();
  }

  toggleShowSelectProfitCalcType = () => {
    this.setState({ showSelectProfitCalcType: !this.state.showSelectProfitCalcType });
  }

  getCurrentTabInfoList = () => {
    if (this.props.SettingReducer.tabInfoList == null) {
      return [];
    } else {
      return this.props.SettingReducer.tabInfoList;
    }
  }

  getNextTabKey = () => {
    let nextKey = 0;
    for (let tabInfo of this.getCurrentTabInfoList()) {
      if (parseInt(tabInfo.key) >= nextKey) {
        nextKey = parseInt(tabInfo.key) + 1;
      }
    }
    return nextKey.toString();
  }

  getTabName = (tabInfo) => {
    if (tabInfo.blueprintTypeId instanceof Array) {
      return '多种产品';
    } else if (tabInfo.blueprintSetting == null) {
      return '标签已失效';
    }

    let blueprint = ProfitHelper.getBlueprintByTypeId(tabInfo.blueprintSetting.blueprintTypeId);
    if (blueprint == null) {
      return '新标签';
    } else {
      return blueprint.typeIdSde.name;
    }
  }

  renderSingleBlueprintView = (tabInfo) => {
    let plans = _.isEmpty(tabInfo.plans) ? [] : tabInfo.plans;
    return (
      <SingleBlueprintView blueprintSetting={tabInfo.blueprintSetting} tabKey={tabInfo.key} plans={plans} tabInfo={tabInfo} />
    )
  }

  renderMultiBlueprintView = (tabInfo) => {
    let plans = _.isEmpty(tabInfo.plans) ? [] : tabInfo.plans;
    return (
      <MultiBlueprintView blueprintTypeId={tabInfo.blueprintTypeId} tabKey={tabInfo.key} plans={plans} tabInfo={tabInfo} />
    )
  }

  render() {
    return (
      <div style={{ display: 'flex', width: '100%', paddingBottom: '10px' }}>
        <SelectProfitCalcType onSingleBlueprint={() => this.addNewTab(0)} onMultiBlueprint={() => this.addNewTab([])}
          show={this.state.showSelectProfitCalcType} onCancel={this.toggleShowSelectProfitCalcType} />
        <div style={{ width: '300px', height: 'calc(100vh - 80px', overflowY: 'scroll', overflowX: 'hidden' }}>
          <BlueprintTree />
        </div>
        <Layout style={{ paddingLeft: '24px' }}>
          <Content>
            <div style={{ width: 'calc(100vw - 280px)', minWidth: '800px' }}>
              <Tabs
                type="editable-card" size='small'
                onChange={this.onChange} forceRender={false}
                activeKey={this.props.SettingReducer.activeTabKey}
                onEdit={this.onEdit} tabPosition='top'
              >
                {this.getCurrentTabInfoList().map((tabInfo) => {
                  if (this.props.SettingReducer.activeTabKey !== tabInfo.key) {
                    return (
                      <TabPane tab={this.getTabName(tabInfo)} key={tabInfo.key} closable={true}></TabPane>
                    );
                  } else {
                    return (
                      <TabPane tab={this.getTabName(tabInfo)} key={tabInfo.key} closable={true}>
                        {tabInfo.blueprintTypeId instanceof Array ? this.renderMultiBlueprintView(tabInfo) : this.renderSingleBlueprintView(tabInfo)}
                      </TabPane>
                    )
                  }
                }
                )}
              </Tabs>
            </div>
          </Content>
        </Layout>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    PriceDataReducer: state.PriceDataReducer,
    ItemInfoReducer: state.ItemInfoReducer,
    SettingReducer: state.SettingReducer
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    PriceDataAction: bindActionCreators(PriceDataAction, dispatch),
    ItemInfoAction: bindActionCreators(ItemInfoAction, dispatch),
    SettingAction: bindActionCreators(SettingAction, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfitCalc);
