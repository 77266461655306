import React from "react";
import { Layout, Modal, InputNumber, Divider, Button, Cascader } from "antd";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import * as UrlHelper from '../action/UrlHelper';

class UpsertRefineItemModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: [],
            quantity: 0,
            options: []
        };
    }

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps) {
        if (_.isEmpty(this.state.options)) {
            this.setState({ options: this.getOptions() })
        }
        if (this.props.show != nextProps.show && !nextProps.isAdd) {
            this.setState({ type: this.getTypeById(nextProps.editingItem.typeId), quantity: nextProps.editingItem.quantity });
        }
    }

    getTypeById = (typeId) => {
        let result = [];
        let typeIdSde = _.find(this.props.PriceDataReducer.allTypeIdSde, sde => sde.typeId === typeId);
        let categories = typeIdSde.categories;
        let parentCategories = [];
        for (let category of categories) {
            result.push([...parentCategories, category].toString());
            parentCategories.push(category);
        }
        return [...result, typeId];
    }

    onOK = () => {
        this.props.onOK({ typeId: this.getPreRefineItemTypeId(), quantity: this.state.quantity });
        this.setState({ type: [], quantity: 0 });
    };

    onCancel = () => {
        this.props.onCancel();
    };

    onChange = (value, selectedOptions) => {
        console.log(value, selectedOptions);
        if (_.isEmpty(selectedOptions)) {
            this.setState({ type: [] });
        } else {
            this.setState({ type: value });
        }
    }

    onQuantityChange = (value) => {
        this.setState({ quantity: value });
    }

    showSearch = () => {
        return {
            filter: this.filter,
            render: (text, path) => {
                return path[path.length - 1].label;
            }
        }
    }

    filter = (inputValue, path) => {
        return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    }

    getOptions = () => {
        let options = [];
        for (let typeIdSde of this.props.PriceDataReducer.allTypeIdSde) {
            let categories = typeIdSde.categories;
            if (_.isEmpty(categories)) {
                continue;
            }
            // if (_.find(this.props.PriceDataReducer.allTypeMaterials, m => m.typeId === typeIdSde.typeId) == null) {
            //     continue;
            // }
            let parentOption = {};
            for (let i = 0; i < categories.length; i++) {
                let option = this.getOptionByCategories(options, categories.slice(0, i + 1));
                if (option == null) {
                    option = { value: categories.slice(0, i + 1).toString(), label: categories[i], children: [] };
                    if (_.isEmpty(parentOption)) {
                        options.push(option);
                    } else {
                        parentOption.children.push(option);
                    }
                }
                parentOption = option;
            }
            parentOption.children.push({ value: typeIdSde.typeId, label: typeIdSde.name });
        }
        return options;
    }

    getOptionByCategories = (allOptions, categories) => {
        if (_.isEmpty(categories)) {
            return null;
        }

        let optionForFirstCategory = _.find(allOptions, o => o.label === categories[0]);
        if (categories.length === 1) {
            return optionForFirstCategory
        } else {
            if (optionForFirstCategory == null) {
                return null;
            } else {
                return this.getOptionByCategories(optionForFirstCategory.children, categories.slice(1));
            }
        }
    }

    displayRender = (label, selectedOptions) => {
        if (!_.isEmpty(selectedOptions)) {
            return selectedOptions[selectedOptions.length - 1].label;
        } else {
            return '';
        }
    }

    getPreRefineItemTypeId = () => {
        if (_.isEmpty(this.state.type)) {
            return 0;
        } else {
            return this.state.type[this.state.type.length - 1];
        }
    }

    render() {
        return (
            <div>
                <Modal title={this.props.isAdd ? "添加物品" : "编辑化矿物品"} visible={this.props.show} onOk={this.onOK} onCancel={this.onCancel} okText={'好'} cancelText={'关闭'}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div>                        
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                <div style={{ width: '100px' }}>物品：</div>
                                <div><Cascader style={{ width: '200px' }} options={this.state.options} onChange={this.onChange} placeholder="选择物品"
                                showSearch={this.showSearch()} displayRender={this.displayRender} value={this.state.type} /></div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ width: '100px' }}>数量：</div>
                                <div><InputNumber style={{ width: 200 }} min={0} defaultValue={0} onChange={(e) => this.onQuantityChange(e)} value={this.state.quantity} /></div>
                            </div>
                        </div>
                        <div style={{ marginLeft: '50px' }}>
                            <img style={{ left: '50%', position: 'relative', transform: 'translate(-50%, 0%)' }}  nsrc={`https://image.evepc.163.com/Type/${this.getPreRefineItemTypeId()}_64.png`} src={`https://images.evetech.net/types/${this.getPreRefineItemTypeId()}/icon`} />
                        </div>

                    </div>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        PriceDataReducer: state.PriceDataReducer,
        ItemInfoReducer: state.ItemInfoReducer,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpsertRefineItemModal);