import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal } from "antd";

export function postRequest(url, item) {
  return fetch(url, {
    mode: 'cors',
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(item),
  });
}

export function getRequest(url) {
  return fetch(url, {
    mode: 'cors',
    method: 'get',
    headers: {
      Accept: 'application/json',
      Expires: 0,
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      Pragma: 'no-cache',
    },
    credentials: 'include',
  });
}

export function formatISK(s, n) {
  if (isNaN(s)) {
    return '0.00';
  }
  let isNegtiveNumber = false;
  n = n >= 0 && n <= 20 ? n : 2;
  if (parseFloat(s) < 0) {
    s = -parseFloat(s);
    isNegtiveNumber = true;
  }
  s = parseFloat((s + '').replace(/[^\d\.-]/g, '')).toFixed(n) + '';
  let l = s.split('.')[0].split('').reverse();
  let r = s.split('.')[1];
  let t = '';
  for (let i = 0; i < l.length; i++) {
    t += l[i] + ((i + 1) % 3 == 0 && i + 1 != l.length ? ',' : '');
  }
  let result = '';
  if (isNegtiveNumber) {
    result += '-';
  }
  let integerPart = t.split('').reverse().join('');
  let floatPart = r;
  if (n == 0) {
    result += integerPart;
  } else {
    result += integerPart + '.' + floatPart;
  }
  return result;
}

export function UnicodeToUtf8(unicode) {
  var uchar;
  var utf8str = '';
  var i;
  for (i = 0; i < unicode.length; i += 2) {
    uchar = (unicode[i] << 8) | unicode[i + 1]; //UNICODE为2字节编码，一次读入2个字节
    utf8str = utf8str + String.fromCharCode(uchar); //使用String.fromCharCode强制转换
  }
  return utf8str;
}
export function Utf8ToUnicode(strUtf8) {
  var i, j;
  var uCode;
  var temp = new Array();
  for (i = 0, j = 0; i < strUtf8.length; i++) {
    uCode = strUtf8.charCodeAt(i);
    if (uCode < 0x100) {
      //ASCII字符
      temp[j++] = 0x00;
      temp[j++] = uCode;
    } else if (uCode < 0x10000) {
      temp[j++] = (uCode >> 8) & 0xff;
      temp[j++] = uCode & 0xff;
    } else if (uCode < 0x1000000) {
      temp[j++] = (uCode >> 16) & 0xff;
      temp[j++] = (uCode >> 8) & 0xff;
      temp[j++] = uCode & 0xff;
    } else if (uCode < 0x100000000) {
      temp[j++] = (uCode >> 24) & 0xff;
      temp[j++] = (uCode >> 16) & 0xff;
      temp[j++] = (uCode >> 8) & 0xff;
      temp[j++] = uCode & 0xff;
    } else {
      break;
    }
  }
  temp.length = j;
  return temp;
}

export function getHashCode(str, caseSensitive) {
  if (str == null) {
    str = '';
  }
  if (!caseSensitive) {
    str = str.toLowerCase();
  }
  var hash = 1315423911,
    i,
    ch;
  for (i = str.length - 1; i >= 0; i--) {
    ch = str.charCodeAt(i);
    hash ^= (hash << 5) + ch + (hash >> 2);
  }
  return hash & 0x7fffffff;
}


export function createStringSorter(field) {
  return (a, b) => {
    if (a[field] === b[field]) {
      return 0;
    } else if (a[field] < b[field]) {
      return -1;
    } else {
      return 1;
    }
  };
};

export function createNumericSorter(field) {
  return (a, b) => {
    if (a[field] == null && b[field] == null) {
      return 0;
    } else if (a[field] == null && b[field] != null) {
      return -1;
    } else if (a[field] != null && b[field] == null) {
      return 1;
    }
    let valueA = parseFloat(a[field].toString().replace(/,/g, ''));
    let valueB = parseFloat(b[field].toString().replace(/,/g, ''));
    if (valueA == valueB) {
      return 0;
    } else if (valueA < valueB) {
      return -1;
    } else {
      return 1;
    }
  };
};

export function toPercentString(number) {
  if (number == null) {
    return '0';
  } else {
    return (number * 100).toFixed(2) + ' %';
  }
}

export function formatSeconds(second) {
  if (second == null) {
    return '';
  }
  second = Math.round(second);
  let minute = 0;
  let hour = 0;
  let day = 0;
  if (second < 60) {
    return `${second}秒`;
  } else {
    minute = Math.floor(second / 60);
    second = second % 60;
    if (minute < 60) {
      return `${minute}分 ${second}秒`;
    } else {
      hour = Math.floor(minute / 60);
      minute = minute % 60;
      if (hour < 24) {
        return `${hour}小时 ${minute}分 ${second} 秒`;
      } else {
        day = Math.floor(hour / 24);
        hour = hour % 24;
        return `${day}天 ${hour}小时 ${minute}分`;
      }
    }
  }
}