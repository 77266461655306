import React from "react";
import * as UrlHelper from '../action/UrlHelper';
import ProfitHelper from '../common/ProfitHelper';
import { StarOutlined, CloseCircleOutlined, EditOutlined, EditFilled, CloseCircleFilled } from '@ant-design/icons';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class RefineItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() { }

    getItemName = () => {
        let typeIdSde = _.find(this.props.PriceDataReducer.allTypeIdSde, t => t.typeId === this.props.item.typeId);
        return typeIdSde == null ? "" : typeIdSde.name;
    }

    render() {
        return (
            <div className='refine-item' style={{ margin: '10px', position: 'relative' }}>
                <img style={{ left: '50%', position: 'relative', transform: 'translate(-50%, 0%)' }}  nsrc={`https://image.evepc.163.com/Type/${this.props.item.typeId}_64.png`} src={`https://images.evetech.net/types/${this.props.item.typeId}/icon`} />
                <div style={{ width: '80px', textAlign: 'center' }}>{this.getItemName()}</div>
                <div style={{ position: 'absolute', top: '45px', right: '10px', fontWeight: 'bold', background: ProfitHelper.getBackgroundColor()}}>{this.props.item.quantity}</div>
                <div className='refine-hover'>

                </div>
                {
                    this.props.isSource ? <div className='hover-edit-button' style={{color: ProfitHelper.getTextColor()}} onClick={() => this.props.onEdit()}>
                        <EditFilled />
                    </div> : ''
                }
                {
                    this.props.isSource ? <div className='hover-close-button' style={{color: ProfitHelper.getTextColor()}} onClick={() => this.props.onDelete()}>
                        <CloseCircleOutlined />
                    </div> : ''
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        PriceDataReducer: state.PriceDataReducer,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RefineItem);