import React from "react";
import ExportSettings from '../component/ExportSettings';
import ImportSettings from '../component/ImportSettings';
import { Select, Input, message, Divider, Button, Modal } from "antd";
import * as SettingAction from '../action/SettingAction';
import * as QQUserAction from '../action/QQUserAction';
import { LineChartOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { initialState } from '../reducer/SettingReducer';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ProfitHelper from '../common/ProfitHelper';
import _ from 'lodash';
import * as IDUtils from '../common/IDUtils';
import qqlogin from "../resource/qqlogin.png";

class BackupSetting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newSettingName: '',
            loadSettingId: '',
            removeSettingId: ''
        };
    }

    componentDidMount() {
        this.props.QQUserAction.listUserSetting();
    }

    saveUserSettingAs = () => {
        if (_.isEmpty(this.state.newSettingName)) {
            message.warning('配置名称不能为空。')
            return;
        }
        if ('未命名' === this.state.newSettingName) {
            message.warning('你发现了华点。')
            return;
        }
        if (this.props.QQUserReducer.settings.length >= 20) {
            message.warning('每个人最多拥有20个设置。')
            return;
        }
        this.props.SettingAction.setSettingName(this.state.newSettingName);
        this.props.SettingAction.setSettingId(IDUtils.guid());
        this.props.QQUserAction.saveUserSetting();
    }

    saveUserSetting = () => {
        this.props.QQUserAction.saveUserSetting();
    }

    qqlogin = () => {
        window.QC.Login.showPopup({
            appId: "102050315",
            redirectURI: "http://www.ceve-industry.cn/#/Callback"
        });
    }

    getSavedSettingOptions = () => {
        let options = this.props.QQUserReducer.settings.map(s => {
            return { label: s.settingName, value: s.settingId };
        })
        return options;
    }

    canSave = () => {
        if (_.isEmpty(this.props.SettingReducer.settingId)) {
            return false;
        }
        let settingInfo = _.find(this.props.QQUserReducer.settings, s => s.settingId === this.props.SettingReducer.settingId);
        if (settingInfo == null) {
            return false;
        }
        return true;
        // return settingInfo.settingVersion !== this.props.SettingReducer.settingVersion;
    }


    onLoadSettingSelectChange = (e) => {
        this.setState({ loadSettingId: e });
    }

    onRemoveSettingSelectChange = (e) => {
        this.setState({ removeSettingId: e });
    }

    getUserSetting = () => {
        this.props.QQUserAction.getUserSetting(this.state.loadSettingId);
    }

    removeUserSetting = () => {
        this.showWarning('删除用户设置', `真的要删除这个设置吗？`).then(() => {
            this.props.QQUserAction.removeUserSetting(this.state.removeSettingId);
        });
    }

    initialUserSetting = () => {
        this.showWarning('删除用户设置', `真的要还原初始设置吗？`).then(() => {
            this.props.SettingAction.importSettings(initialState);
        });
    }

    showWarning = (title, message) => {
        return new Promise((resolve, reject) => {
            Modal.confirm({
                title: title,
                icon: <ExclamationCircleOutlined />,
                content: message,
                okText: '是',
                okType: 'danger',
                cancelText: '否',
                onOk: () => {
                    resolve();
                }
                ,
                onCancel: () => {
                    reject();
                },
            });
        })
    };

    render() {
        let loginStatus = window.QC.Login.check();
        if (loginStatus == true) {
            return this.renderBackup();
        } else {
            return this.renderLogin();
        }
    }

    renderLogin() {
        return <div style={{ display: 'flex', flexDirection: 'column', height: '300px' }}>
            <div style={{ position: 'relative', top: '20%' }}>
                <div style={{ textAlign: 'center', marginBottom: '20px' }}>使用备份功能需要先登录</div>
                <div>
                    <Button style={{ fontWeight: 'bold', left: '50%', transform: 'translateX(-50%)' }} type="primary" value="large" ghost>
                        <img src={qqlogin} style={{ position: 'relative', top: '-2px' }} onClick={() => this.qqlogin()} />
                    </Button>
                </div>
            </div>
        </div>
    }

    renderBackup() {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px', marginTop: '10px' }}>
                <div style={{ display: 'flex', marginTop: '10px' }}>
                    <div style={{ width: '100px' }}>当前设置： </div>
                    <div>{this.props.SettingReducer.settingName}</div>
                </div>
                <Divider style={{ background: ProfitHelper.getTextColor() }} />
                <div style={{ display: 'flex' }}>
                    <div style={{ width: '100px' }}>加载设置</div>
                    <Select showSearch style={{ width: 150 }} options={this.getSavedSettingOptions()} onChange={this.onLoadSettingSelectChange} />
                    <Button type='primary' style={{ marginLeft: '20px' }} onClick={() => this.getUserSetting()}>加载</Button>
                </div>
                <Divider style={{ background: ProfitHelper.getTextColor() }} />
                <div style={{ display: 'flex' }}>
                    <div style={{ width: '100px' }}>保存设置</div>
                    <Button type='primary' disabled={!this.canSave()} onClick={() => this.saveUserSetting()}>保存</Button>
                </div>
                <Divider style={{ background: ProfitHelper.getTextColor() }} />
                <div style={{ display: 'flex' }}>
                    <div style={{ width: '100px' }}>另存为设置</div>
                    <Input style={{ width: 150 }} placeholder='取一个名字' value={this.state.newSettingName} onChange={e => this.setState({ newSettingName: e.target.value })} />
                    <Button type='primary' style={{ marginLeft: '20px' }} onClick={() => this.saveUserSettingAs()}>保存</Button>
                </div>
                <Divider style={{ background: ProfitHelper.getTextColor() }} />
                <div style={{ display: 'flex' }}>
                    <div style={{ width: '100px' }}>删除设置</div>
                    <Select showSearch style={{ width: 150 }} options={this.getSavedSettingOptions()} onChange={this.onRemoveSettingSelectChange} />
                    <Button type='primary' style={{ marginLeft: '20px' }} onClick={() => this.removeUserSetting()}>删除</Button>
                    <Button type='primary' style={{ marginLeft: '20px' }} onClick={() => this.initialUserSetting()}>恢复初始设置</Button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        SettingReducer: state.SettingReducer,
        QQUserReducer: state.QQUserReducer
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        SettingAction: bindActionCreators(SettingAction, dispatch),
        QQUserAction: bindActionCreators(QQUserAction, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BackupSetting);