import * as ActionTypes from "./ActionTypes";
import BlueprintBuildApi from "../api/BlueprintBuildApi";
import * as UrlHelper from './UrlHelper';

const host = UrlHelper.getHost();
const hostAndPort = `http://${host}:8080`;

export const getBlueprintBuildById = (id) => {
  return function (dispatch, getState) {
    return BlueprintBuildApi.getBlueprintBuildById(hostAndPort, id)
      .then((json) => {
        return dispatch({ type: ActionTypes.GET_BLUEPRINT_BUILD_SUCCESS, data: json });
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const upsertBlueprintBuild = (blueprintBuild) => {
  return function (dispatch, getState) {
    return BlueprintBuildApi.upsertBlueprintBuild(hostAndPort, blueprintBuild)
      .then(() => {
        return dispatch({ type: ActionTypes.UPSERT_BLUEPRINT_BUILD_SUCCESS, data: blueprintBuild });
      })
      .catch((err) => {
        throw err;
      });
  };
};
