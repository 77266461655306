import React from 'react';
import * as PriceDataAction from '../action/PriceDataAction';
import * as RefineAction from '../action/RefineAction';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Dropdown, Input, Spin, Menu, Table, Statistic, Row, Col, message } from 'antd';
import * as Utils from '../common/Utils';
import ProfitHelper from '../common/ProfitHelper';
import Initializer from '../common/Initializer';
import copy from "copy-to-clipboard";
import { PlusOutlined, DeleteOutlined, UnorderedListOutlined, StarFilled, SaveOutlined, SnippetsOutlined, BarChartOutlined, TableOutlined } from '@ant-design/icons';
const { TextArea } = Input;
const { SubMenu } = Menu;

const columns = [
    {
        title: '',
        dataIndex: 'typeId',
        sorter: Utils.createNumericSorter('typeId'),
        render: (text, record, index) => {
            return (<div><img style={{ width: '25px' }} src={ProfitHelper.getTypeImage(record.typeId)}></img></div>);
        },
        width: '50px'
    },
    {
        title: '物品名称',
        dataIndex: 'name',
        sorter: Utils.createStringSorter('name'),
        render: (text) => <span>{text}</span>,
    },
    {
        title: `数量`,
        dataIndex: 'quantity',
        sorter: Utils.createNumericSorter('quantity'),
        render: (text) => <span>{`${Utils.formatISK(text, 0)} 个`}</span>,
        align: 'right',
    },
    {
        title: '最低卖价',
        dataIndex: 'sellPrice',
        sorter: Utils.createNumericSorter('sellPrice'),
        render: (text) => <span>{`${Utils.formatISK(text)} ISK`}</span>,
        align: 'right',
    },
    {
        title: '最高买价',
        dataIndex: 'buyPrice',
        sorter: Utils.createNumericSorter('buyPrice'),
        render: (text) => <span>{`${Utils.formatISK(text)} ISK`}</span>,
        align: 'right',
    },
    {
        title: '化矿后最低卖价',
        dataIndex: 'refinedSellPrice',
        sorter: Utils.createNumericSorter('refinedSellPrice'),
        render: (text) => <span>{`${Utils.formatISK(text)} ISK`}</span>,
        align: 'right',
    },
    {
        title: '化矿后最高买价',
        dataIndex: 'refinedBuyPrice',
        sorter: Utils.createNumericSorter('refinedBuyPrice'),
        render: (text) => <span>{`${Utils.formatISK(text)} ISK`}</span>,
        align: 'right',
    },
    {
        title: '建议操作',
        dataIndex: 'suggestion',
        sorter: Utils.createStringSorter('suggestion'),
    }
]

class Asset extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            assetText: '',
            assetList: []
        };
    }

    componentDidMount() {
        Initializer.initPriceData();
    }

    componentWillReceiveProps(nextProps) {

    }

    isReady = () => {
        return !_.isEmpty(this.props.PriceDataReducer.allTypeIdSde) &&
            !_.isEmpty(this.props.PriceDataReducer.allTypeMaterials) &&
            !_.isEmpty(this.props.PriceDataReducer.allMarketDump)
    }

    onAssetTextChange = (e) => {
        let assetText = e.target.value;
        let lines = assetText.split('\n');
        let items = new Map();
        for (let line of lines) {
            let item = ProfitHelper.getTypeIdAndQuantityFromPasteBinLine(line);
            if (item != null) {
                if (items.has(item.typeId)) {
                    items.get(item.typeId).quantity += item.quantity;
                } else {
                    items.set(item.typeId, item);
                }
            }
        }
        this.setState({ assetList: this.getDataSource([...items.values()]), assetText: assetText });
    }

    getDataSource = (assetList) => {
        return assetList.map(value => {
            let typeIdSde = ProfitHelper.getTypeIdSdeById(value.typeId);
            let refinedItems = this.getRefinedItems(value.typeId, value.quantity);
            let refinedBuyPrice = 0;
            let refinedSellPrice = 0;
            let refinedVolume = 0;
            refinedItems.forEach(item => {
                refinedBuyPrice += this.getPrice(item.typeId, true) * item.quantity;
                refinedSellPrice += this.getPrice(item.typeId, false) * item.quantity;
                let refinedTypeIdSde = ProfitHelper.getTypeIdSdeById(item.typeId);
                refinedVolume += refinedTypeIdSde.packagedVolume * item.quantity;
            })
            let buyPrice = this.getPrice(value.typeId, true) * value.quantity;
            let sellPrice = this.getPrice(value.typeId, false) * value.quantity;
            let suggestion = '';
            if (buyPrice + sellPrice - refinedBuyPrice - refinedSellPrice < 0) {
                suggestion = '化矿';
            } else if (buyPrice * 1.5 < sellPrice) {
                suggestion = '挂卖单出售';
            } else {
                suggestion = '按收购价出售';
            }
            return {
                typeId: value.typeId,
                quantity: value.quantity,
                name: typeIdSde.name,
                buyPrice: buyPrice,
                sellPrice: sellPrice,
                refinedBuyPrice,
                refinedSellPrice,
                volume: typeIdSde.packagedVolume * value.quantity,
                refinedVolume,
                suggestion: suggestion
            }
        });
    }

    getPrice = (typeId, isBuy) => {
        let typeIdSde = ProfitHelper.getTypeIdSdeById(typeId);
        if (_.find(typeIdSde.categories, c => c === '蓝图和反应') != null) {
            //蓝图，拷贝图估价为0
            return 0;
        }
        let marketDump = ProfitHelper.getMarketDumpById(typeId, { vendor: isBuy ? 1 : 0 });
        return marketDump == null ? 0 : marketDump.price;
    }

    getRefinedItems = (typeId, quantity) => {
        let refinedItems = new Map();
        let material = _.find(this.props.PriceDataReducer.allTypeMaterials, m => m.typeId === typeId);
        if (material == null) {
            return [{ typeId, quantity }];
        }
        let typeIdSde = _.find(this.props.PriceDataReducer.allTypeIdSde, sde => sde.typeId === typeId);
        if (typeIdSde == null) {
            return [{ typeId, quantity }];
        }
        for (let materialItem of material.items) {
            let refinedItem = { typeId: materialItem.materialTypeId, quantity: materialItem.quantity };
            let preRefineItemPortion = Math.floor(quantity / typeIdSde.portionSize);
            refinedItem.quantity = Math.floor(materialItem.quantity * preRefineItemPortion * this.getRefinePercent(typeId));
            if (!refinedItems.has(materialItem.materialTypeId)) {
                refinedItems.set(materialItem.materialTypeId, refinedItem);
            } else {
                refinedItems.get(materialItem.materialTypeId).quantity += refinedItem.quantity;
            }
        }
        return [...refinedItems.values()];
    }

    getRefinePercent = (typeId) => {
        let sde = _.find(this.props.PriceDataReducer.allTypeIdSde, sde => sde.typeId === typeId);
        if (sde == null) {
            return 0;
        }
        let categories = ['标准矿石', '冰矿', '卫星矿石'];
        if (sde.categories.length > 3 && categories.indexOf(sde.categories[3]) >= 0) {
            return this.props.SettingReducer.mineralRefinePercent;
        } else {
            return this.props.SettingReducer.equipRefinePercent;
        }
    }

    getAssetStat = () => {
        let totalBuyPrice = 0;
        let totalSellPrice = 0;
        let totalRefinedBuyPrice = 0;
        let totalRefinedSellPrice = 0;
        let totalVolume = 0;
        let totalRefinedVolume = 0;
        this.state.assetList.forEach(asset => {
            totalBuyPrice += asset.buyPrice;
            totalSellPrice += asset.sellPrice;
            totalRefinedBuyPrice += asset.refinedBuyPrice;
            totalRefinedSellPrice += asset.refinedSellPrice;
            totalVolume += asset.volume;
            totalRefinedVolume += asset.refinedVolume;
        });
        return {
            totalBuyPrice: Utils.formatISK(totalBuyPrice),
            totalMidPrice: Utils.formatISK((totalBuyPrice + totalSellPrice) / 2),
            totalSellPrice: Utils.formatISK(totalSellPrice),
            totalRefinedBuyPrice: Utils.formatISK(totalRefinedBuyPrice),
            totalRefinedMidPrice: Utils.formatISK((totalRefinedBuyPrice + totalRefinedSellPrice) / 2),
            totalRefinedSellPrice: Utils.formatISK(totalRefinedSellPrice),
            totalVolume: Utils.formatISK(totalVolume),
            totalRefinedVolume: Utils.formatISK(totalRefinedVolume),
        }
    }

    render() {
        if (this.isReady()) {
            return this.renderGUI();
        } else {
            return (
                <div style={{ position: 'absolute', left: '50%', top: '50%' }}>
                    <Spin size='large' />
                </div>
            )
        }
    }

    renderStatisticText = (text, suffix) => {
        return (<div style={{ fontSize: '20px' }}>
            {text + (suffix == null ? '' : ' ' + suffix)}
        </div>)
    }

    menu = () => {
        return (<Menu onClick={(e) => this.clickMenu(e)} mode="vertical"  >
            <Menu.ItemGroup title="复制">
                <Menu.Item key='copyToExcel' icon={<SnippetsOutlined style={{ fontSize: '1.3em' }} />}>复制到Excel</Menu.Item>
            </Menu.ItemGroup>
        </Menu >
        );
    }


    clickMenu = (e) => {
        console.log(e);
        if (e.key === 'copyToExcel') {
            {
                let content = this.generateExcelContent();
                copy(content, { format: 'text/plain' });
                message.info(`物品列表已经复制到剪贴板，可以去Excel粘贴了。`);
            }
        }
    }


    generateExcelContent = () => {
        let content = '';
        content += `物品ID\t物品名称\t数量\t最低卖价\t最高买价\t化矿后最低卖价\t化矿后最高买价\t建议操作\t`;
        content += '\n';
        this.state.assetList.forEach(asset => {
            content += `${asset.typeId}\t${asset.name}\t${asset.quantity}\t${asset.sellPrice}\t${asset.buyPrice}\t${asset.refinedSellPrice}\t${asset.refinedBuyPrice}\t${asset.suggestion}\t`;
            content += '\n';
        })
        return content;
    }

    renderGUI() {
        let assetStat = this.getAssetStat();
        return (
            <div style={{ display: 'flex', height: 'calc( 100% - 5px )' }}>
                <div style={{ width: 'calc( 30% - 20px)', boxShadow: '0 0 5px black', margin: '10px' }}>
                    <TextArea style={{ height: '100%', resize: 'none' }} value={this.state.assetText} onChange={this.onAssetTextChange}
                        placeholder='打开一张合同或者机库, 选中需要的物品, 猛敲 CTRL + A, 再敲 CTRL + C, 最后在这里用力敲 CTRL + V' />
                </div>
                <div style={{ width: '70%' }}>
                    <div style={{ margin: '10px' }}>
                        <div>
                            <Row gutter={10}>
                                <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                    <Statistic title='市场最低卖价' value={assetStat.totalSellPrice} formatter={(text) => this.renderStatisticText(text, 'ISK')} />
                                </Col>
                                <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                    <Statistic title='市场中间价' value={assetStat.totalMidPrice} formatter={(text) => this.renderStatisticText(text, 'ISK')} />
                                </Col>
                                <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                    <Statistic title='市场最高买价' value={assetStat.totalBuyPrice} formatter={(text) => this.renderStatisticText(text, 'ISK')} />
                                </Col>
                                <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                    <Statistic title='化矿后市场最低卖价' value={assetStat.totalRefinedSellPrice} formatter={(text) => this.renderStatisticText(text, 'ISK')} />
                                </Col>
                                <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                    <Statistic title='化矿后市场中间价' value={assetStat.totalRefinedMidPrice} formatter={(text) => this.renderStatisticText(text, 'ISK')} />
                                </Col>
                                <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                    <Statistic title='化矿后市场最高买价' value={assetStat.totalRefinedBuyPrice} formatter={(text) => this.renderStatisticText(text, 'ISK')} />
                                </Col>
                                <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                    <Statistic title='体积' value={assetStat.totalVolume} formatter={(text) => this.renderStatisticText(text, "m³")} />
                                </Col>
                                <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                    <Statistic title='化矿后体积' value={assetStat.totalRefinedVolume} formatter={(text) => this.renderStatisticText(text, "m³")} />
                                </Col>
                            </Row>
                        </div>
                        <div style={{ height: 'calc(100vh - 240px)', boxShadow: '0 0 5px black', marginTop: '10px' }}>
                            <Dropdown overlay={this.menu()} trigger={['contextMenu']}>
                                <div onClick={(e) => e.preventDefault()} >
                                    <Table columns={columns} size='small' pagination={{ showSizeChanger: true, pageSize: 500 }}
                                        dataSource={this.state.assetList} scroll={{ y: 'calc(100vh - 330px)' }} />
                                </div>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </div>);
    }
}

const mapStateToProps = (state) => {
    return {
        PriceDataReducer: state.PriceDataReducer,
        RefineReducer: state.RefineReducer,
        SettingReducer: state.SettingReducer
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        PriceDataAction: bindActionCreators(PriceDataAction, dispatch),
        RefineAction: bindActionCreators(RefineAction, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Asset);
