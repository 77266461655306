import React from 'react';
import * as PriceDataAction from '../action/PriceDataAction';
import * as RefineAction from '../action/RefineAction';
import * as SettingAction from '../action/SettingAction';
import RefineItem from './RefineItem';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Row, Col, Button, FloatButton, Tooltip, Spin, Statistic, Dropdown, message, InputNumber, Menu } from 'antd';
import UpsertRefineItemModal from './UpsertRefineItemModal';
import PasteBinModal from './PasteBinModal';
import * as Utils from '../common/Utils';
import * as T3Utils from '../common/T3Utils';
import ProfitHelper from '../common/ProfitHelper';
import MaterialTree from './MaterialTree';
import MaterialList from './MaterialList';
import IndustryProcess from './IndustryProcess';
import IndustryPlan from './IndustryPlan';
import { CopyOutlined, ForwardOutlined, SettingOutlined, CloseOutlined, SisternodeOutlined, UnorderedListOutlined, EyeOutlined, EyeInvisibleOutlined, ClockCircleOutlined } from '@ant-design/icons';
import Initializer from '../common/Initializer';
import copy from "copy-to-clipboard";
import Minimap from 'js-minimap';

class MultiBlueprintView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
    this.minimap = null;
    this.minimapContainer = null;
    this.minimapTarget = null;
  }

  componentDidMount() {
    // this.showMiniMap();
  }

  componentWillReceiveProps(nextProps) {

  }


  componentDidUpdate() {
    // this.showMiniMap();
  }

  showMiniMap = () => {
    if (this.getMaterialDisplayMode() !== 2) {
      return;
    }
    const container = document.getElementById('mmpContainer');
    const target = document.getElementById('minimap');

    if (container == null || target == null) {
      return;
    }

    if (this.minimap == null) {
      this.minimap = new Minimap({
        container,
        target,
        width: 200,
        observe: false
      });
      this.minimapContainer = container;
      this.minimapTarget = target;
    } else {
      if (this.minimapContainer === container && this.minimapTarget === target) {
        setTimeout(() => {
          this.minimap.reset();
        }, 1000);
      } else {
        this.minimap = new Minimap({
          container,
          target,
          width: 200,
          observe: false
        });
        this.minimapContainer = container;
        this.minimapTarget = target;
      }
    }
  }

  changeBlueprintCount = (item, value) => {
    let blueprintSetting = _.clone(item);
    blueprintSetting.blueprintCount = value;
    this.props.SettingAction.setBlueprintSetting(blueprintSetting);
  }

  changeBlueprintDecoder = (item, decoderId) => {
    let blueprintSetting = _.clone(item);
    blueprintSetting.decoderId = decoderId;

    let inventBaseBlueprint = ProfitHelper.getInventBaseBlueprint(item.blueprintTypeId);
    let product = _.find(inventBaseBlueprint.activities.invention.products, p => p.typeId === item.blueprintTypeId);

    let quantity = 1;
    let materialEffect = 2;
    if (decoderId < 0) {
      //免费发明
      quantity = item.productQuantity;
    } else if (decoderId == 0) {
      quantity = product.quantity;
    } else {
      let decoder = _.find(this.props.ItemInfoReducer.allDecoders, d => Number(d.typeId) === decoderId);
      quantity = product.quantity + decoder.quantity;
      materialEffect += decoder.materialEffect;
    }

    blueprintSetting.materialEffect = materialEffect;
    blueprintSetting.productQuantity = quantity;
    this.props.SettingAction.setBlueprintSetting(blueprintSetting);
  }

  changeProductQuantity = (item, value) => {
    let blueprintSetting = _.clone(item);
    blueprintSetting.productQuantity = value;
    this.props.SettingAction.setBlueprintSetting(blueprintSetting);
  }

  toggetEnableBlueprintSetting = (item) => {
    let blueprintSetting = _.clone(item);
    let disabled = blueprintSetting.disabled == null ? false : blueprintSetting.disabled;
    blueprintSetting.disabled = !disabled;
    this.props.SettingAction.setBlueprintSetting(blueprintSetting);
  }

  removeBlueprintSetting = (item) => {
    this.props.SettingAction.removeBlueprintSetting(item.blueprintTypeId);
  }

  changeMaterialEffect = (item, value) => {
    let blueprintSetting = _.clone(item);
    blueprintSetting.materialEffect = value;
    this.props.SettingAction.setBlueprintSetting(blueprintSetting);
  }

  getMaterialEffect = (item) => {
    if (item.materialEffect != null) {
      return item.materialEffect;
    } else {
      return this.props.SettingReducer.materialEffect;
    }
  }

  getDecoderDescription = (decoderId) => {
    if (decoderId < 0) {
      return '发明成本为0';
    } else if (decoderId == 0) {
      return '不使用解码器';
    } else {
      let decoder = _.find(this.props.ItemInfoReducer.allDecoders, d => d.typeId === decoderId);
      if (decoder == null) {
        return '';
      }
      let marketDump = ProfitHelper.getMarketDumpById(decoder.typeId);
      let price = marketDump == null ? 0 : marketDump.price;
      return `${decoder.name} ${decoder.materialEffect}材料 ${decoder.timeEffect}时间 ${decoder.quantity}流程 成功率*${(decoder.probability + 1)} 价格${Utils.formatISK(price)}ISK`;
    }
  }

  getDecoderOptions = () => {
    if (_.isEmpty(this.props.ItemInfoReducer.allDecoders)) {
      return [];
    }
    let decoderOptions = this.props.ItemInfoReducer.allDecoders.map(d => {
      let label = this.getDecoderDescription(d.typeId);
      return { key: d.typeId, label: label }
    });
    decoderOptions = [
      {
        key: 0,
        label: '不使用解码器'
      },
      ...decoderOptions,
      {
        key: -1,
        label: '发明成本为0'
      },
    ];
    return decoderOptions;
  }

  getDecoderName = (item) => {
    if (item.decoderId === -1) {
      return '免费';
    } else if (item.decoderId === 0) {
      return '无';
    } else {
      let decoder = _.find(this.props.ItemInfoReducer.allDecoders, d => Number(d.typeId) === item.decoderId);
      if (decoder == null) {
        return '';
      } else {
        return decoder.name.substr(0, 2);
      }
    }
  }

  onDecoderClick = (item, e) => {
    console.log(e.key);
    this.changeBlueprintDecoder(item, Number(e.key));
  };

  isT3Blueprint = (item) => {
    return T3Utils.isT3Blueprint(item.blueprintTypeId);
  }

  getDecoderShortName = (decoderId) => {
    if (decoderId < 0) {
      return '0成本';
    } else if (decoderId === 0) {
      return '不使用';
    } else {
      let decoder = ProfitHelper.getDecoderById(decoderId);
      return decoder.name.substr(0, 2);
    }
  }

  selectAncient = (e, item) => {
    let ancientId = Number(e.key);
    let newBlueprintSetting = _.clone(item);
    newBlueprintSetting.ancientId = ancientId;
    T3Utils.generateT3BlueprintSetting(newBlueprintSetting);
    this.props.SettingAction.setBlueprintSetting(newBlueprintSetting);
  }

  ancientManu = (ancients, item) => {
    return (
      <Menu mode="vertical" onClick={(e) => this.selectAncient(e, item)} >
        {ancients.map(a => {
          let marketDump = ProfitHelper.getMarketDumpById(a.ancientId);
          let price = marketDump == null ? 0 : marketDump.price;
          let ancientDescription = `${a.ancientName} 成功率:${Math.round(a.probability * 100)}% 流程:${a.productQuantity} 价格:${Utils.formatISK(price)} ISK`;
          return (<Menu.Item key={a.ancientId} icon={<img style={{ width: '24px' }}
            src={`https://image.evepc.163.com/Type/${a.ancientId}_64.png`} />}>{ancientDescription}</Menu.Item>);
        })}
      </Menu>
    )
  }

  selectT3Decoder = (e, item) => {
    let decoderId = Number(e.key);
    let newBlueprintSetting = _.clone(item);
    newBlueprintSetting.decoderId = decoderId;
    T3Utils.generateT3BlueprintSetting(newBlueprintSetting);
    this.props.SettingAction.setBlueprintSetting(newBlueprintSetting);
  }

  decoderMenu = (item) => {
    return (
      <Menu mode="vertical" onClick={(e) => this.selectT3Decoder(e, item)} >
        <Menu.Item key={0} icon={<img style={{ width: '24px' }}
          src={ProfitHelper.getTypeImage(34201)} />}>不使用解码器</Menu.Item>
        {this.props.ItemInfoReducer.allDecoders.map(d => {
          let marketDump = ProfitHelper.getMarketDumpById(d.typeId);
          let price = marketDump == null ? 0 : marketDump.price;
          let decoderDescription = `${d.name} 成功率*${Math.round((1 + d.probability) * 100)}% 材料效率${d.materialEffect >= 0 ? `+${d.materialEffect}` : d.materialEffect} 流程+${d.quantity} 价格:${Utils.formatISK(price)} ISK`;
          return (<Menu.Item key={d.typeId} icon={<img style={{ width: '24px' }}
            src={ProfitHelper.getTypeImage(34201)} />}>{decoderDescription}</Menu.Item>);
        })}
        <Menu.Item key={-1} icon={<img style={{ width: '24px' }}
          src={ProfitHelper.getTypeImage(34201)} />}>发明成本为0</Menu.Item>
      </Menu>
    )
  }

  renderT3Panel = (item) => {
    let availableAncient = T3Utils.getT3List().filter(a => a.products.includes(item.blueprintTypeId));
    let ancient = availableAncient[0];
    if (item.ancientId != null) {
      ancient = _.find(T3Utils.getT3List(), a => a.ancientId === item.ancientId);
    }

    let decoderId = 0;
    if (item.decoderId != null) {
      decoderId = item.decoderId;
    }

    let decoderDescripton = this.getDecoderDescription(decoderId);
    let decoderShortName = this.getDecoderShortName(decoderId);
    return (
      <div>
        <Dropdown overlay={this.ancientManu(availableAncient, item)}>
          <Tooltip title={ancient.ancientName}>
            <Button size='small' type="default" icon={<img style={{ width: '22px', position: 'relative', left: '-5px', top: '-1px' }}
              src={`https://image.evepc.163.com/Type/${ancient.ancientId}_64.png`} />}>{ancient.ancientName.substr(0, 3)}</Button>
          </Tooltip>
        </Dropdown>

        <Dropdown overlay={this.decoderMenu(item)}>
          <Tooltip title={decoderDescripton}>
            <Button size='small' type="default" icon={<img style={{ width: '30px', position: 'relative', left: '-5px', top: '-5px' }}
              src={ProfitHelper.getTypeImage(34201)} />}>{decoderShortName}</Button>
          </Tooltip>
        </Dropdown>
      </div>)
  }

  renderCard = (item) => {
    let productTypeIdSde = ProfitHelper.getProductByBlueprintId(item.blueprintTypeId);
    if (productTypeIdSde == null) {
      return '';
    }
    let inventBaseBlueprint = ProfitHelper.getInventBaseBlueprint(item.blueprintTypeId);
    return (
      <div className='multi-blueprint-card' style={{ display: 'flex', margin: '5px 10px', padding: '5px', borderRadius: '5px', filter: item.disabled ? 'contrast(0.5)' : '' }}>
        <img style={{ width: '50px', height: '50px' }} src={ProfitHelper.getTypeImage(productTypeIdSde.typeId)}></img>
        <div style={{ flex: 'auto' }}>
          <div style={{ display: 'flex', marginLeft: '10px' }}>
            <div> {productTypeIdSde.name}</div>
            <div style={{ marginLeft: 'auto', marginRight: '5px', position: 'relative', top: '-3px', display: 'flex' }}>
              {this.isT3Blueprint(item) ? this.renderT3Panel(item) : ''}
              <div style={{ marginRight: '10px', display: inventBaseBlueprint == null ? 'none' : '' }}>
                <Dropdown menu={{ items: this.getDecoderOptions(), onClick: (e) => this.onDecoderClick(item, e) }} placement="bottom" trigger={['click']} arrow>
                  <Tooltip title={this.getDecoderDescription(item.decoderId)}>
                    <Button type="default" icon={<img style={{ width: '30px', marginTop: '-6px', marginLeft: '-3px' }} src={ProfitHelper.getTypeImage(34201)}></img>} style={{ borderRadius: '2px', boxShadow: this.state.leftDisplayMode === 0 ? '0px 0px 10px gray inset' : '' }} size="small">{this.getDecoderName(item)}</Button>
                  </Tooltip>
                </Dropdown>
              </div>
              <div>
                <Tooltip title={item.disabled ? '禁用中' : '启用中'}>
                  <Button type="default" style={{ borderRadius: '10px', width: '20px', height: '20px', fontSize: '11px', marginRight: '10px' }} icon={item.disabled ? <EyeInvisibleOutlined /> : <EyeOutlined />} size="small" onClick={() => this.toggetEnableBlueprintSetting(item)} />
                </Tooltip>
                <Tooltip title={'删除'}>
                  <Button type="default" style={{ borderRadius: '10px', width: '20px', height: '20px', fontSize: '11px' }} icon={<CloseOutlined />} size="small" onClick={() => this.removeBlueprintSetting(item)} />
                </Tooltip>
              </div>
            </div>
          </div>

          <div style={{ display: 'flex', height: '25px', margin: '0 5px', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex' }}>
              <div style={{ margin: '0 5px', fontSize: '1.5em', position: 'relative', top: '-5px', cursor: 'pointer' }}>
                <Tooltip title={`${item.blueprintCount} 张蓝图`}>
                  <CopyOutlined style={{ fontSize: '1em' }} />
                </Tooltip>
              </div>
              <InputNumber size="small" style={{ width: '50px' }} min={1} value={item.blueprintCount} onChange={v => this.changeBlueprintCount(item, v)} />
            </div>
            <div style={{ display: 'flex' }}>
              <div style={{ margin: '0 5px', fontSize: '1.5em', position: 'relative', top: '-5px', cursor: 'pointer' }}>
                <Tooltip title={`每张蓝图 ${item.productQuantity} 流程`}>
                  <ForwardOutlined style={{ fontSize: '1em' }} />
                </Tooltip>
              </div>
              <InputNumber size="small" style={{ width: '50px' }} min={1} value={item.productQuantity} onChange={v => this.changeProductQuantity(item, v)} />
            </div>
            <div style={{ display: 'flex' }}>
              <div style={{ margin: '0 5px', fontSize: '1.5em', position: 'relative', top: '-5px', cursor: 'pointer' }}>
                <Tooltip title={`材料效率等级：${this.getMaterialEffect(item)}`}>
                  <SettingOutlined style={{ fontSize: '1em' }} />
                </Tooltip>
              </div>
              <InputNumber size="small" style={{ width: '50px' }} min={0} max={10} value={this.getMaterialEffect(item)} onChange={v => this.changeMaterialEffect(item, v)} />
            </div>
          </div>
        </div>

      </div>
    )
  }

  getExtractBlueprintIds = () => {
    let result = [];
    for (let blueprintTypeId of this.props.blueprintTypeId) {
      let bid = Number(blueprintTypeId.blueprintTypeId);
      let extract = _.find(this.props.SettingReducer.extract, e => e.typeId === bid);
      if (!_.isEmpty(extract)) {
        result = [...result, ...extract.extract];
      }
    }
    result = [...result, ...this.props.SettingReducer.alwaysMakeBids];
    result = _.without(result, ...this.props.SettingReducer.alwaysBuyBids);
    result = _.uniq(result);
    return result;
  }

  setExtractBlueprintIds = (extractedBids) => {
    for (let blueprintTypeId of this.props.blueprintTypeId) {
      this.props.SettingAction.setExtract(Number(blueprintTypeId.blueprintTypeId), extractedBids);
    }
  }

  generateBlueprintSettings = () => {
    let blueprintSettings = [];
    for (let blueprintTypeId of this.props.blueprintTypeId) {
      if (blueprintTypeId.disabled) {
        continue;
      }
      let blueprint = ProfitHelper.getBlueprintByTypeId(Number(blueprintTypeId.blueprintTypeId));
      let blueprintSetting = _.clone(blueprintTypeId);
      blueprintSetting.blueprint = blueprint;
      blueprintSettings.push(blueprintSetting);
    }
    return blueprintSettings;
  }

  renderTreeView = (resourceListObj) => {
    let context = resourceListObj == null ? {} : resourceListObj.context;
    return (
      <MaterialTree context={context} getExtractBlueprintIds={this.getExtractBlueprintIds}
        setExtractBlueprintIds={this.setExtractBlueprintIds} resourceListObj={resourceListObj}
        extractMaterialsRecursive={() => this.extractMaterialsRecursive()}
        extractRequired={this.extractRequired} tabInfo={this.props.tabInfo} />
    );
  }

  renderListView = (resourceListObj) => {
    return <MaterialList resourceListObj={resourceListObj} resourceWithInventMaterials={resourceListObj.resourceList}
      getExtractBlueprintIds={this.getExtractBlueprintIds} setExtractBlueprintIds={this.setExtractBlueprintIds}
      extractMaterialsRecursive={() => this.extractMaterialsRecursive()}
      extractRequired={this.extractRequired} />
  }

  renderIndustryProcess = (resourceListObj) => {
    let context = resourceListObj == null ? {} : resourceListObj.context;
    return (
      <IndustryProcess context={context} blueprintSettings={this.props.blueprintTypeId}
        getExtractBlueprintIds={this.getExtractBlueprintIds}
        setExtractBlueprintIds={this.setExtractBlueprintIds} resourceListObj={resourceListObj}
        extractMaterialsRecursive={() => this.extractMaterialsRecursive()}
        extractRequired={this.extractRequired} plans={this.props.plans} showMiniMap={this.showMiniMap} />
    );
  }

  renderIndustryPlan = (resourceListObj) => {
    let context = resourceListObj == null ? {} : resourceListObj.context;
    return (
      <IndustryPlan context={context} blueprintSettings={this.props.blueprintTypeId} plans={this.props.plans} />
    );
  }

  isReady = () => {
    return !_.isEmpty(this.props.PriceDataReducer.allBlueprint) && !_.isEmpty(this.props.PriceDataReducer.allTypeIdSde)
      && !_.isEmpty(this.props.PriceDataReducer.allMarketDump) && !_.isEmpty(this.props.PriceDataReducer.allMarketHistory)
      && !_.isEmpty(this.props.PriceDataReducer.industryIndices);
  }

  getMultiBlueprintSummary = (resourceListObj) => {
    let context = {
      cost: 0,
      price: 0,
      profit: 0,
      profitPercent: 0
    }
    for (let dataSource of resourceListObj.resourceList) {
      context.cost += dataSource.totalPrice;
    }
    context.cost += resourceListObj.context.solarSystemFee;
    context.cost += resourceListObj.context.customsFee;
    context.cost += resourceListObj.context.inventFee;
    context.cost += resourceListObj.context.marketFee;

    for (let blueprintTypeId of this.props.blueprintTypeId) {
      if (blueprintTypeId.disabled) {
        continue;
      }
      let product = ProfitHelper.getProductByBlueprintId(Number(blueprintTypeId.blueprintTypeId));
      if (_.isEmpty(product)) {
        continue;
      }
      let blueprint = ProfitHelper.getBlueprintByTypeId(blueprintTypeId.blueprintTypeId);
      if (blueprint == null) {
        continue;
      }
      let productQuantityPerBatch = blueprint.activities.manufacturing.products[0].quantity;
      let marketDump = ProfitHelper.getMarketDumpById(product.typeId, { isResource: false });
      if (marketDump != null) {
        context.price += marketDump.price * blueprintTypeId.productQuantity * blueprintTypeId.blueprintCount * productQuantityPerBatch;
      }
    }

    context.profit = context.price - context.cost;
    if (context.cost == 0) {
      context.profitPercent = 0;
    } else {
      context.profitPercent = context.profit / context.cost;
    }
    return context;
  }

  extractRequired = (threshold) => {
    let blueprintSettingWithBlueprint = this.generateBlueprintSettings();

    let productIdList = [];
    for (let blueprintTypeId of this.props.blueprintTypeId) {
      let bid = Number(blueprintTypeId.blueprintTypeId);
      let product = ProfitHelper.getProductByBlueprintId(bid);
      productIdList.push(product.typeId);
    }
    let materialBlueprintIdList = ProfitHelper.getAllMaterialBlueprintIdRecursive(productIdList, 0, false);

    let selfProductBlueprintIdList = [...materialBlueprintIdList];
    let buyBlueprintIdList = [];
    for (let round = 0; round < 20; round++) {
      let shoudBreak = true;
      let [resourceList, context] = ProfitHelper.getResourceList(blueprintSettingWithBlueprint, selfProductBlueprintIdList);
      console.log('extractRequired');
      console.log(materialBlueprintIdList);
      console.log(context);
      for (let step of context.processList) {
        for (let resource of step) {
          let depositoryQuantity = ProfitHelper.getDepositoryQuantity(resource.key);
          if (resource.ceilQuantity * threshold <= depositoryQuantity) {
            let blueprint = ProfitHelper.getBlueprintByProductId(resource.key);
            if (blueprint == null) {
              continue;
            }
            if (buyBlueprintIdList.includes(blueprint.blueprintTypeId)) {
              continue;
            }
            buyBlueprintIdList.push(blueprint.blueprintTypeId);
            shoudBreak = false;
            console.log(`${resource.name}: 启动制造需要${resource.ceilQuantity}个，库存${depositoryQuantity}个,所以收购${resource.name}`)
          }
        }
      }
      selfProductBlueprintIdList = selfProductBlueprintIdList.filter(id => !buyBlueprintIdList.includes(id));
      if (shoudBreak) {
        console.log('最终自产的原料是：');
        console.log(selfProductBlueprintIdList);
        this.setExtractBlueprintIds(selfProductBlueprintIdList);
        break;
      }
    }
  }

  extractMaterialsRecursive = () => {
    let productIdList = [];
    for (let blueprintTypeId of this.props.blueprintTypeId) {
      let bid = Number(blueprintTypeId.blueprintTypeId);
      let product = ProfitHelper.getProductByBlueprintId(bid);
      productIdList.push(product.typeId);
    }
    let materialBlueprintIdList = ProfitHelper.getAllMaterialBlueprintIdRecursive(productIdList, 0, false);
    this.setExtractBlueprintIds(materialBlueprintIdList);
  };

  getMaterialDisplayMode = () => {
    if (this.props.tabInfo.viewMode == null) {
      return 1;
    } else {
      return this.props.tabInfo.viewMode;
    }
  }

  setMaterialDisplayMode = (value) => {
    this.props.SettingAction.setViewMode(value);
  }

  renderFloatButton = () => {
    let materialDisplayMode = this.getMaterialDisplayMode();
    return (
      <div>
        <FloatButton tooltip='分解视图' style={{ top: 94, right: 80 }} type={materialDisplayMode === 1 ? 'primary' : ''}
          icon={<SisternodeOutlined />} onClick={() => this.setMaterialDisplayMode(1)} />
        <FloatButton tooltip='原料列表' style={{ top: 94 }} type={materialDisplayMode === 0 ? 'primary' : ''}
          icon={<UnorderedListOutlined />} onClick={() => this.setMaterialDisplayMode(0)} />
        <FloatButton tooltip='生产步骤' style={{ top: 150, right: 80 }} type={materialDisplayMode === 2 ? 'primary' : ''}
          icon={<ForwardOutlined />} onClick={() => this.setMaterialDisplayMode(2)} />
        <FloatButton tooltip='生产计划' style={{ top: 150 }} type={materialDisplayMode === 3 ? 'primary' : ''}
          icon={<ClockCircleOutlined />} onClick={() => this.setMaterialDisplayMode(3)} />
      </div>
    )
  }

  renderMaterialView = (resourceListObj) => {
    let materialDisplayMode = this.getMaterialDisplayMode();
    if (materialDisplayMode === 0) {
      return this.renderListView(resourceListObj);
    } else if (materialDisplayMode === 2) {
      return this.renderIndustryProcess(resourceListObj);
    } else if (materialDisplayMode === 1) {
      return this.renderTreeView(resourceListObj);
    } else {
      return this.renderIndustryPlan(resourceListObj);
    }
  }

  generateResource = (typeId, quantity, ceilQuantity) => {
    let typeIdSde = ProfitHelper.getTypeIdSdeById(typeId);
    let marketdump = ProfitHelper.getMarketDumpById(typeId);
    let price = marketdump == null ? 0 : ProfitHelper.getMarketDumpById(typeId).price;
    return {
      category: ProfitHelper.getCategory(typeId),
      ceilQuantity: ceilQuantity,
      typeId: typeId,
      key: typeId,
      name: typeIdSde.name,
      singlePrice: price,
      totalPrice: price * quantity,
      quantity: quantity,
      volume: typeIdSde.volume * quantity
    }
  }

  render() {
    if (!this.isReady()) {
      return (
        <div style={{ position: 'absolute', left: '50%', top: '50vh' }}>
          <Spin size='large' />
        </div>
      )
    }

    let [resourceList, context] = ProfitHelper.getResourceList(this.generateBlueprintSettings(), this.getExtractBlueprintIds());

    let resourceListObj = { resourceList, context };
    console.log(resourceListObj);
    if (this.props.blueprintTypeId == null) {
      return '';
    }
    // let context = this.getMultiBlueprintSummary(resourceListObj);

    let inventDataSource = context.inventDataSource;
    let inventResources = inventDataSource.map(m => {
      return this.generateResource(m.typeId, m.quantity, m.ceilQuantity);
    });
    resourceList = [...resourceList, ...inventResources];
    context.dataTree.push(...inventResources);

    return (<div>
      <div style={{ margin: '5px' }}>
        <Row gutter={12}>
          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
            <Tooltip placement="left" title={() => {
              let materialCost = context.materialCost;
              let solarSystemFee = context.solarSystemFee;
              let safetyCommitteeFee = context.safetyCommitteeFee;
              let inventFee = context.inventFee;
              let customsFee = context.customsFee;
              let transportFee = context.transportFee;
              let marketFee = context.marketFee;
              return (<div>
                {materialCost == 0 ? '' : <div style={{ display: 'flex' }}><span>原料成本：</span><span style={{ marginLeft: 'auto' }}>{Utils.formatISK(materialCost)}</span></div>}
                {solarSystemFee == 0 ? '' : <div style={{ display: 'flex' }}><span>星系成本：</span><span style={{ marginLeft: 'auto' }}>{Utils.formatISK(solarSystemFee)}</span></div>}
                {safetyCommitteeFee == 0 ? '' : <div style={{ display: 'flex' }}><span>商业安全委员会：</span><span style={{ marginLeft: 'auto' }}>{Utils.formatISK(safetyCommitteeFee)}</span></div>}
                {inventFee == 0 ? '' : <div style={{ display: 'flex' }}><span>发明成本：</span><span style={{ marginLeft: 'auto' }}>{Utils.formatISK(inventFee)}</span></div>}
                {marketFee == 0 ? '' : <div style={{ display: 'flex' }}><span>市场税：</span><span style={{ marginLeft: 'auto' }}>{Utils.formatISK(marketFee)}</span></div>}
                {customsFee == 0 ? '' : <div style={{ display: 'flex' }}><span>海关税: </span><span style={{ marginLeft: 'auto' }}>{Utils.formatISK(customsFee)}</span></div>}
                {transportFee == 0 ? '' : <div style={{ display: 'flex' }}><span>原料运费: </span><span style={{ marginLeft: 'auto' }}>{Utils.formatISK(transportFee)}</span></div>}
              </div>)
            }}>
              <Statistic title='成本' value={`${Utils.formatISK(context.totalCost)} ISK`} />
            </Tooltip>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
            <Statistic title='价格' value={`${Utils.formatISK(context.totalPrice)} ISK`} />
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
            <Statistic title='利润' valueStyle={{ color: context.totalProfit < 0 ? 'red' : 'green' }} value={`${Utils.formatISK(context.totalProfit)} ISK`} />
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
            <Statistic title='利润率' valueStyle={{ color: context.profitPercent < 0 ? 'red' : 'green' }} value={`${Utils.toPercentString(context.profitPercent)}`} />
          </Col>
        </Row>
      </div>
      <div id='minimap' style={{ display: this.getMaterialDisplayMode() === 2 ? '' : 'none', zIndex: 3, position: 'fixed', bottom: '60px', boxShadow: 'grey 0px 0px 5px 5px' }}></div>
      <div id='mmpContainer' style={{ height: 'calc(100vh - 180px)', overflow: 'scroll' }}>
        <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '10px' }}>
          {this.props.blueprintTypeId.map(item => {
            return this.renderCard(item);
          })}
        </div>
        <div>
          {this.renderFloatButton()}

          {this.renderMaterialView(resourceListObj)}
        </div>
      </div>
    </div >);
  }
}

const mapStateToProps = (state) => {
  return {
    PriceDataReducer: state.PriceDataReducer,
    RefineReducer: state.RefineReducer,
    SettingReducer: state.SettingReducer,
    ItemInfoReducer: state.ItemInfoReducer
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    PriceDataAction: bindActionCreators(PriceDataAction, dispatch),
    RefineAction: bindActionCreators(RefineAction, dispatch),
    SettingAction: bindActionCreators(SettingAction, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MultiBlueprintView);
