import * as XLSX from 'xlsx';
import store from '../store';
import ProfitHelper from '../common/ProfitHelper';
import _ from 'lodash';

export function stox(wb) {
  var out = [];
  wb.SheetNames.forEach(function (name) {
    var o = { name: name, rows: {} };
    var ws = wb.Sheets[name];
    var range = XLSX.utils.decode_range(ws['!ref']);
    // sheet_to_json will lost empty row and col at begin as default
    range.s = { r: 0, c: 0 };
    var aoa = XLSX.utils.sheet_to_json(ws, {
      raw: false,
      header: 1,
      range: range
    });

    aoa.forEach(function (r, i) {
      var cells = {};
      r.forEach(function (c, j) {
        cells[j] = { text: c };

        var cellRef = XLSX.utils.encode_cell({ r: i, c: j });

        if (ws[cellRef] != null && ws[cellRef].f != null) {
          cells[j].text = "=" + ws[cellRef].f;
        }
      });
      o.rows[i] = { cells: cells };
    });

    o.merges = [];
    (ws["!merges"] || []).forEach(function (merge, i) {
      //Needed to support merged cells with empty content
      if (o.rows[merge.s.r] == null) {
        o.rows[merge.s.r] = { cells: {} };
      }
      if (o.rows[merge.s.r].cells[merge.s.c] == null) {
        o.rows[merge.s.r].cells[merge.s.c] = {};
      }

      o.rows[merge.s.r].cells[merge.s.c].merge = [
        merge.e.r - merge.s.r,
        merge.e.c - merge.s.c
      ];

      o.merges[i] = XLSX.utils.encode_range(merge);
    });

    out.push(o);
  });

  return out;
}

/**
 * Converts data from x-spreadsheet to SheetJS
 *
 * @param  {Object[]} sdata An x-spreadsheet data object
 *
 * @returns {Object} A SheetJS workbook object
 */
export function xtos(sdata) {
  var out = XLSX.utils.book_new();
  sdata.forEach(function (xws) {
    var ws = {};
    var rowobj = xws.rows;
    for (var ri = 0; ri < rowobj.len; ++ri) {
      var row = rowobj[ri];
      if (!row) continue;

      var minCoord, maxCoord;
      Object.keys(row.cells).forEach(function (k) {
        var idx = +k;
        if (isNaN(idx)) return;

        var lastRef = XLSX.utils.encode_cell({ r: ri, c: idx });
        if (minCoord == null) {
          minCoord = { r: ri, c: idx };
        } else {
          if (ri < minCoord.r) minCoord.r = ri;
          if (idx < minCoord.c) minCoord.c = idx;
        }
        if (maxCoord == undefined) {
          maxCoord = { r: ri, c: idx };
        } else {
          if (ri > maxCoord.r) maxCoord.r = ri;
          if (idx > maxCoord.c) maxCoord.c = idx;
        }

        var cellText = row.cells[k].text, type = "s";
        if (!cellText) {
          cellText = "";
          type = "z";
        } else if (!isNaN(parseFloat(cellText))) {
          cellText = parseFloat(cellText);
          type = "n";
        } else if (cellText.toLowerCase() === "true" || cellText.toLowerCase() === "false") {
          cellText = Boolean(cellText);
          type = "b";
        }

        ws[lastRef] = { v: cellText, t: type };

        if (type == "s" && cellText[0] == "=") {
          ws[lastRef].f = cellText.slice(1);
        }

        if (row.cells[k].merge != null) {
          if (ws["!merges"] == null) ws["!merges"] = [];

          ws["!merges"].push({
            s: { r: ri, c: idx },
            e: {
              r: ri + row.cells[k].merge[0],
              c: idx + row.cells[k].merge[1]
            }
          });
        }
      });

      ws["!ref"] = XLSX.utils.encode_range({
        s: { r: minCoord.r, c: minCoord.c },
        e: { r: maxCoord.r, c: maxCoord.c }
      });
    }

    XLSX.utils.book_append_sheet(out, ws, xws.name);
  });

  return out;
}

export function getCustomPriceExcel() {
  let priceData = ProfitHelper.getUsedTypeIds().map(typeId => {
    let typeIdSde = ProfitHelper.getTypeIdSdeById(typeId);
    let marketDump = ProfitHelper.getMarketDumpById(typeIdSde.typeId);
    let marketPrice = 0;
    if (marketDump != null) {
      marketPrice = marketDump.marketPrice == null ? marketDump.price : marketDump.marketPrice;
    }
    let customPrice = _.find(store.getState().SettingReducer.customPrices, p => p.typeId === typeId);
    return {
      '物品ID': typeIdSde.typeId,
      '物品名称': typeIdSde.name,
      '市场价格': marketPrice,
      '自定义价格': customPrice == null ? '无' : customPrice.price
    }
  })
  const priceWs = XLSX.utils.json_to_sheet(priceData)
  priceWs['!cols'] = [
    { wch: 10 },
    { wch: 30 },
    { wch: 30 },
    { wch: 30 }
  ];

  let typeData = ProfitHelper.getUsedTypeIds().map(typeId => {
    let typeIdSde = ProfitHelper.getTypeIdSdeById(typeId);
    return {
      '物品ID': typeIdSde.typeId,
      '物品名称': typeIdSde.name,
    }
  });
  // 新建空workbook，然后加入worksheet
  const typeWs = XLSX.utils.json_to_sheet(typeData)
  // 设置每列的列宽，10代表10个字符，注意中文占2个字符
  typeWs['!cols'] = [
    { wch: 10 },
    { wch: 30 }
  ];

  // 新建book
  let wb = XLSX.utils.book_new();
  // 生成xlsx文件(book,sheet数据,sheet命名)
  XLSX.utils.book_append_sheet(wb, priceWs, '自定义价格');
  // XLSX.utils.book_append_sheet(wb, typeWs, '物品列表');
  return wb;
}

export function getExcel(filteredRankData) {

  let data = filteredRankData.map(rankData => {
    return {
      '产品ID': rankData.productId,
      '产品名称': rankData.name,
      '单件成本': rankData.singleCost,
      '单件价格': rankData.singlePrice,
      '单件利润': rankData.singleProfit,
      '利润率': rankData.profitPercent,
      '全天利润': rankData.dailyProfit,
      '全天产量': parseFloat(rankData.dailyQuantity),
      '全天成交量': rankData.dailyVolume,
      '出货效率': rankData.loopEffect
    };
  });

  console.log('getExcel(filteredRankData)')
  console.log(data)
  console.log(JSON.stringify(data))

  // 新建空workbook，然后加入worksheet
  const ws = XLSX.utils.json_to_sheet(data)
  // 设置每列的列宽，10代表10个字符，注意中文占2个字符
  ws['!cols'] = [
    { wch: 10 },
    { wch: 30 },
    { wch: 16 },
    { wch: 16 },
    { wch: 16 },
    { wch: 16 },
    { wch: 16 },
    { wch: 16 },
    { wch: 16 },
    { wch: 16 }
  ];
  // 新建book
  let wb = XLSX.utils.book_new();
  // 生成xlsx文件(book,sheet数据,sheet命名)
  XLSX.utils.book_append_sheet(wb, ws, '利润排行');
  return wb;
}