import React from "react";
import { Table, Switch, Tooltip, Menu, Button, Dropdown, message, InputNumber } from "antd";
import { StopOutlined, UndoOutlined, ApartmentOutlined, StarOutlined, StarFilled, EyeOutlined, CopyOutlined, EyeInvisibleOutlined, CheckOutlined, CloseOutlined, UnorderedListOutlined, BarChartOutlined, TableOutlined, DollarOutlined, SisternodeOutlined, ForwardOutlined, SettingOutlined } from '@ant-design/icons';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import * as SettingAction from '../action/SettingAction';
import * as Utils from '../common/Utils';
import shoubing from "../resource/shoubing.jpg";
import ProfitHelper from '../common/ProfitHelper';
import copy from "copy-to-clipboard";

const { SubMenu } = Menu;

class MaterialList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            extractMaterialHistory: [],
            selectedRowKeys: [],
            menuVisible: false,
            openKeys: [],
        };
    }

    componentDidMount() { }


    getColumns = () => {
        return [
            {
                title: '',
                dataIndex: 'key',
                sorter: Utils.createNumericSorter('key'),
                render: (text, record, index) => {
                    return (<div><img style={{ width: '25px' }} src={ProfitHelper.getTypeImage(record.key)}></img></div>)
                }
            },
            {
                title: '原料名称',
                dataIndex: 'name',
                render: (text, record, index) => {
                    return (<div style={{ cursor: 'copy' }} onClick={(e) => this.onResourceNameClick(e, record)}>{text}</div>);
                },
                sorter: Utils.createStringSorter('name')
            },
            {
                title: '原料类别',
                dataIndex: 'category',
                sorter: Utils.createStringSorter('category'),
                render: (text, record, index) => {
                    return (<div>{text}</div>);
                }
            },
            {
                title: `数量`,
                dataIndex: 'quantity',
                sorter: Utils.createNumericSorter('quantity'),
                render: (value, record) => {
                    if (record.quantity === record.ceilQuantity) {
                        return Utils.formatISK(record.quantity, 0);
                    } else {
                        return `${Utils.formatISK(record.quantity, 0)} (${Utils.formatISK(record.ceilQuantity, 0)})`;
                    }
                },
                align: 'right',
            },
            {
                title: `库存数量`,
                dataIndex: 'depositoryQuantity',
                sorter: Utils.createNumericSorter('depositoryQuantity'),
                render: (value, record) => Utils.formatISK(record.depositoryQuantity, 0),
                align: 'right',
            },
            {
                title: `库存缺口`,
                dataIndex: 'depositoryMissingQuantity',
                sorter: Utils.createNumericSorter('depositoryMissingQuantity'),
                render: (value, record) => {
                    let text = '';
                    if (record.depositoryMissingQuantity === record.depositoryMissingCeilQuantity) {
                        text = Utils.formatISK(record.depositoryMissingQuantity, 0);
                    } else {
                        text = `${Utils.formatISK(record.depositoryMissingQuantity, 0)} (${Utils.formatISK(record.depositoryMissingCeilQuantity, 0)})`;
                    }

                    return (<div>{text}</div>);
                },
                align: 'right',
            },
            {
                title: '单价',
                dataIndex: 'singlePrice',
                key: 'singlePrice',
                sorter: Utils.createNumericSorter('singlePrice'),
                render: (value, record) => {
                    return this.renderPrice(value, record);
                },
                align: 'right',
            },
            {
                title: '总价',
                dataIndex: 'totalPrice',
                sorter: Utils.createNumericSorter('totalPrice'),
                render: (value, record) => {
                    return Utils.formatISK(value);
                },
                align: 'right',
            },
            {
                title: '体积（m³）',
                dataIndex: 'volume',
                sorter: Utils.createNumericSorter('volume'),
                render: (value, record) => {
                    return Utils.formatISK(value).replace('.00', '');
                },
                align: 'right',
            },
            {
                title: '占总成本%',
                dataIndex: 'profitPercent',
                sorter: Utils.createNumericSorter('profitPercent'),
                render: (value, record) => {
                    return Utils.toPercentString(value);
                },
                align: 'right',
            },
        ];
    };


    renderPrice = (value, record) => {
        if (record.key === this.state.editingTypeId) {
            return (
                <div style={{ display: 'flex', justifyContent: 'right' }} onClick={(e) => this.cancelClick(e)}>
                    <InputNumber controls={false} value={value} onChange={(value) => this.onPriceChange(value)} />
                    <div style={{ marginLeft: '5px' }}>
                        <Tooltip title='确定 (删除输入框内文本再点确定，可以还原成市场价)' style={{ marginLeft: '5px' }}>
                            <Button type="default" icon={<CheckOutlined />} onClick={() => this.doEditPrice()} size="Small" />
                        </Tooltip>
                    </div>
                    <div style={{ marginLeft: '5px' }}>
                        <Tooltip title='取消' >
                            <Button type="default" icon={<StopOutlined />} onClick={() => this.cancelPriceChange()} size="Large" />
                        </Tooltip>
                    </div>
                </div >
            )
        } else {
            return (
                <Tooltip title='双击修改物品价格' placement='right'>
                    <div style={{ cursor: 'pointer' }} onDoubleClick={() => this.startEditPrice(record)}>
                        {
                            (() => {
                                let customPrice = ProfitHelper.getCustomPriceById(record.key);
                                if (this.props.SettingReducer.useCustomPrice && !_.isEmpty(customPrice)) {
                                    return (
                                        <div style={{ color: 'darkorange', fontWeight: 'bold' }}>
                                            {Utils.formatISK(customPrice.price, 2) + ' ISK'}
                                        </div>
                                    );
                                } else {
                                    return Utils.formatISK(value, 2) + ' ISK';
                                }
                            })()
                        }
                    </div>
                </Tooltip>
            )
        }
    }


    getExtractResultWithAllRound = (materials) => {
        if (_.isEmpty(materials)) {
            return [];
        }
        let roundWithProductList = new Map();
        let round = 0;
        let maxProductList = ProfitHelper.getAllMaterialBlueprintIdRecursive(materials, 0, true);
        while (true) {
            round++;
            let productList = ProfitHelper.getAllMaterialBlueprintIdRecursive(materials, round, true);
            roundWithProductList.set(round, productList);
            if (_.isEqual(maxProductList, productList)) {
                break;
            }
        }
        return [...roundWithProductList.values()]
    }


    onMenuVisibleChange = (e) => {
        this.setState({ menuVisible: e })
    }

    onMenuOpenChange = (e) => {
        console.log("onMenuOpenChange")
        console.log(e)
        if (this.ignoreNextOpenKeys === true) {
            this.ignoreNextOpenKeys = false;
        } else {
            this.setState({ openKeys: e })
        }
    }

    menu = (resourceListObj) => {
        let extractResultWithAllRound = this.getExtractResultWithAllRound(this.state.selectedRowKeys);
        let { resourceList, context } = resourceListObj;
        let resourceCategorySet = resourceList == null ? new Set() : new Set(resourceList.map(r => r.category));
        let extractedMaterialCategorySet = new Set(this.props.getExtractBlueprintIds().map(bid => {
            let blueprint = ProfitHelper.getBlueprintByTypeId(bid);
            if (blueprint == null) {
                return '';
            } else {
                return ProfitHelper.getCategory(blueprint.typeIdSde.typeId);
            }
        }));
        return (<Menu onClick={(e) => this.clickMenu(e, resourceListObj)} mode="vertical" subMenuCloseDelay={0.3}
            openKeys={this.state.openKeys} onOpenChange={this.onMenuOpenChange} >
            <Menu.ItemGroup title="分解原料">
                <Menu.Item key='extractAllMaterialsRecursive' icon={<ApartmentOutlined style={{ fontSize: '1.3em' }} />}>一键分解所有原料</Menu.Item>
                <SubMenu key="sub1" title="分解选中的原料..." icon={<ApartmentOutlined style={{ fontSize: '1.3em' }} />}>
                    {extractResultWithAllRound.map((bids, index) => {
                        let products = new Set();
                        let materials = new Set();
                        for (let bid of bids) {
                            let blueprint = ProfitHelper.getBlueprintByTypeId(bid);
                            products.add(blueprint.typeIdSde.typeId);
                            if (blueprint.activities.manufacturing != null) {
                                blueprint.activities.manufacturing.materials.forEach(m => materials.add(m.typeId));
                            }
                            if (blueprint.activities.invention != null) {
                                blueprint.activities.invention.materials.forEach(m => materials.add(m.typeId));
                            }
                        }
                        let leafMaterials = [...materials].filter(typeId => !products.has(typeId));

                        return (
                            <SubMenu key={`extractSelectedSub${index}`} title={`连续分解${index + 1}次`} icon={<ApartmentOutlined style={{ fontSize: '1.3em' }} />}>
                                <Menu.Item key={`extractSelected${index}`}>确定</Menu.Item>
                                <Menu.ItemGroup title="预览">
                                    {leafMaterials.map((typeId => {
                                        return (<Menu.Item key={'extractSelectedPreview' + typeId} icon={<img style={{ width: '25px', marginRight: '5px' }} src={ProfitHelper.getTypeImage(typeId)}></img>}>{ProfitHelper.getTypeIdSdeById(typeId).name}</Menu.Item>)
                                    }))}
                                </Menu.ItemGroup>
                            </SubMenu>
                        )
                    })}
                </SubMenu>
            </Menu.ItemGroup>
            <Menu.ItemGroup key="撤销分解" title="撤销分解">
                {(() => {
                    if (!_.isEmpty(this.state.extractMaterialHistory)) {
                        let lastHistory = this.state.extractMaterialHistory[0];
                        let blueprint = _.find(this.props.PriceDataReducer.allBlueprint, b => b.blueprintTypeId === lastHistory[0]);
                        let menuText = '';
                        if (lastHistory.length === 1) {
                            menuText = (<span style={{ display: 'flex' }}>撤销最近一次分解的<img style={{ width: '25px', marginRight: '5px' }} src={ProfitHelper.getTypeImage(blueprint.typeIdSde.typeId)}></img>{blueprint.typeIdSde.name}</span>);
                        } else {
                            menuText = (<span style={{ display: 'flex' }}>撤销最近一次分解的<img style={{ width: '25px', marginRight: '5px' }} src={ProfitHelper.getTypeImage(blueprint.typeIdSde.typeId)}></img>{blueprint.typeIdSde.name} 等 {lastHistory.length} 种材料</span>);
                        }
                        return (<Menu.Item key='undoLast' icon={<UndoOutlined style={{ fontSize: '1.3em' }} />}>{menuText}</Menu.Item>);
                    }
                })()
                }

                <Menu.Item key='undoAll' icon={<UndoOutlined style={{ fontSize: '1.3em' }} />}>撤销所有分解操作</Menu.Item>
                <SubMenu key="subUndo" title="撤销分解..." icon={<UndoOutlined style={{ fontSize: '1.3em' }} />}>
                    {
                        [...extractedMaterialCategorySet].map(category => {
                            return (
                                <SubMenu key={`unExtract${category}Sub`} icon={<UnorderedListOutlined style={{ fontSize: '1.3em' }} />} style={{ fontSize: '1.3em' }} title={category} >
                                    <Menu.Item key={`unExtract${category}`} icon={<UnorderedListOutlined style={{ fontSize: '1.3em' }} />}>撤销分解所有的 {category}</Menu.Item>
                                    {
                                        this.props.getExtractBlueprintIds().filter(bid => {
                                            let blueprint = ProfitHelper.getBlueprintByTypeId(bid);
                                            if (blueprint == null) {
                                                return false;
                                            }
                                            let cat = ProfitHelper.getCategory(blueprint.typeIdSde.typeId);
                                            return cat === category;
                                        }).map((bid) => {
                                            let blueprint = ProfitHelper.getBlueprintByTypeId(bid);
                                            let menuText = (<span style={{ display: 'flex' }}><img style={{ width: '25px', marginRight: '5px' }} src={ProfitHelper.getTypeImage(blueprint.typeIdSde.typeId)}></img>{blueprint.typeIdSde.name}</span>);
                                            return (<Menu.Item key={`unExtract${bid}`}>{menuText}</Menu.Item>);
                                        })
                                    }
                                </SubMenu>
                            );
                        })
                    }
                </SubMenu>
            </Menu.ItemGroup>
            <Menu.ItemGroup title="批量购买">
                <SubMenu key="subBuy" icon={<DollarOutlined style={{ fontSize: '1.3em' }} />} title="批量购买..." >
                    <Menu.Item key='generateOrderSelected' icon={<DollarOutlined style={{ fontSize: '1.3em' }} />}>购买选中的原料</Menu.Item>
                    <Menu.Item key='generateOrderDepositoryMissing' icon={<DollarOutlined style={{ fontSize: '1.3em' }} />}>购买库存缺少的原料</Menu.Item>
                    <Menu.Item key='generateOrderAll' icon={<DollarOutlined style={{ fontSize: '1.3em' }} />}>购买所有原料</Menu.Item>
                    <Menu.Item key='generateExcelSelected' icon={<TableOutlined style={{ fontSize: '1.3em' }} />}>复制选中的原料到Excel</Menu.Item>
                    <Menu.Item key='generateExcelDepositoryMissing' icon={<TableOutlined style={{ fontSize: '1.3em' }} />}>复制库存缺少的原料到Excel</Menu.Item>
                    <Menu.Item key='generateExcelAll' icon={<TableOutlined style={{ fontSize: '1.3em' }} />}>复制所有原料到Excel</Menu.Item>
                </SubMenu>
            </Menu.ItemGroup>
            <Menu.ItemGroup title="批量选择">
                <Menu.Item key='selectAll' icon={<CheckOutlined style={{ fontSize: '1.3em' }} />}>全选</Menu.Item>
                <SubMenu icon={<UnorderedListOutlined style={{ fontSize: '1.3em' }} />} key="selectCategory" title="按类别选择..." >
                    {
                        [...resourceCategorySet].map(cat => {
                            return (
                                <SubMenu key={`selectCategorySub${cat}`} icon={<UnorderedListOutlined style={{ fontSize: '1.3em' }} />} style={{ fontSize: '1.3em' }} title={`选择所有的${cat}`} >
                                    <Menu.Item key={`selectCategory${cat}`}>确定</Menu.Item>
                                    <Menu.ItemGroup title="预览">
                                        {resourceList.filter(r => r.category === cat).map((resource => {
                                            return (<Menu.Item key={`previewSelectCategory${resource.key}`} icon={<img style={{ width: '25px', marginRight: '5px' }} src={ProfitHelper.getTypeImage(resource.key)}></img>}>{ProfitHelper.getTypeIdSdeById(resource.key).name}</Menu.Item>)
                                        }))}
                                    </Menu.ItemGroup>
                                </SubMenu>
                            );
                        })
                    }
                </SubMenu>
                <Menu.Item key='selectNone' icon={<CloseOutlined style={{ fontSize: '1.3em' }} />}>全不选</Menu.Item>
            </Menu.ItemGroup>
        </Menu >
        );
    }


    clickMenu = (e, resourceListObj) => {
        console.log(e)
        let { resourceList, context } = resourceListObj;
        let shouldCloseMenu = true;
        if (e.key.startsWith('extractSelected')) {
            let round = Number(e.key.substr(15));
            let extractResultWithAllRound = this.getExtractResultWithAllRound(this.state.selectedRowKeys);
            let materialBlueprintIdList = extractResultWithAllRound[round];
            this.setState({ extractMaterialHistory: [materialBlueprintIdList, ...this.state.extractMaterialHistory] });
            materialBlueprintIdList = Array.from(new Set([...materialBlueprintIdList, ...this.props.getExtractBlueprintIds()]));
            this.props.setExtractBlueprintIds(materialBlueprintIdList);
            this.setState({ selectedRowKeys: [] });
        } else if (e.key.startsWith('selectCategory')) {
            this.ignoreNextOpenKeys = true;
            shouldCloseMenu = false;
            let category = e.key.substr(14);
            console.log('category is' + category);
            let currentSelection = this.state.selectedRowKeys;
            let newSelection = new Set([...currentSelection, ...resourceList.filter(r => r.category === category).map(r => r.key)]);
            this.setState({ selectedRowKeys: [...newSelection] });
        } else if (e.key === 'makeDepositoryMissing100') {
            this.props.extractRequired(1);
        } else if (e.key === 'makeDepositoryMissing95') {
            this.props.extractRequired(0.95);
        } else if (e.key === 'makeDepositoryMissing90') {
            this.props.extractRequired(0.9);
        } else if (e.key === 'makeDepositoryMissing85') {
            this.props.extractRequired(0.85);
        } else if (e.key === 'makeDepositoryMissing80') {
            this.props.extractRequired(0.8);
        }

        switch (e.key) {
            case 'extractMaterial':
                this.extractMaterial();
                break;
            case 'extractAllMaterialsRecursive':
                this.props.extractMaterialsRecursive();
                break;
            case 'undoAll':
                this.props.setExtractBlueprintIds([]);
                this.setState({ extractMaterialHistory: [] });
                break;
            case 'undoLast':
                {
                    let newMaterialBlueprintIds = this.props.getExtractBlueprintIds().filter(bid => {
                        if (_.find(this.state.extractMaterialHistory[0], id => id === bid) != null) {
                            return false;
                        } else {
                            return true;
                        }
                    });
                    let newExtractMaterialHistory = [...this.state.extractMaterialHistory];
                    newExtractMaterialHistory.splice(0, 1);
                    this.props.setExtractBlueprintIds(newMaterialBlueprintIds);
                    this.setState({ extractMaterialHistory: newExtractMaterialHistory })
                }
                break;
            case 'generateOrderSelected':
                {
                    let order = '';
                    for (let resource of resourceList) {
                        if (_.find(this.state.selectedRowKeys, k => k === resource.key) != null) {
                            order += `${resource.name}\t${resource.ceilQuantity}\n`;
                        }
                    }
                    copy(order);
                    message.info(`${this.state.selectedRowKeys.length}件物品订单已经复制到剪贴板。粘贴到游戏市场中来批量购买这些物品。`);
                }
                break;
            case 'generateOrderDepositoryMissing':
                {
                    let order = '';
                    let count = 0;
                    for (let resource of resourceList) {
                        if (resource.depositoryMissingCeilQuantity > 0) {
                            count++;
                            order += `${resource.name}\t${resource.depositoryMissingCeilQuantity}\n`;
                        }
                    }
                    copy(order);
                    message.info(`${count}件物品订单已经复制到剪贴板。粘贴到游戏市场中来批量购买这些物品。`);
                }
                break;
            case 'generateOrderAll':
                {
                    let order = '';
                    for (let resource of resourceList) {
                        order += `${resource.name}\t${resource.ceilQuantity}\n`;
                    }
                    copy(order);
                    message.info(`${resourceList.length}件物品订单已经复制到剪贴板。粘贴到游戏市场中来批量购买这些物品。`);
                }
                break;
            case 'generateExcelSelected':
                {
                    let order = '';
                    order += `物品ID\t物品名称\t类别\t数量\t启动制造所需数量\t库存数量\t库存缺口\t启动制造所需库存缺口\t单价\t总价\t总体积\t成本占比\n`;
                    for (let resource of resourceList) {
                        if (_.find(this.state.selectedRowKeys, k => k === resource.key) != null) {
                            order += `${resource.key}\t${resource.name}\t${resource.category}\t${Utils.formatISK(resource.quantity, 0)}\t${resource.ceilQuantity}\t${resource.depositoryQuantity}\t${Utils.formatISK(resource.depositoryMissingQuantity, 0)}\t${resource.depositoryMissingCeilQuantity}\t${resource.singlePrice}\t${resource.totalPrice}\t${resource.volume}\t${resource.profitPercent}\n`;
                        }
                    }
                    if (_.isEmpty(order)) {
                        message.info(`还没有选择任何原料。`);
                    } else {
                        copy(order, { format: 'text/plain' });
                        message.info(`选中的原料已经复制到剪贴板，可以去Excel粘贴了。`);
                    }
                }
                break;
            case 'generateExcelDepositoryMissing':
                {
                    let order = '';
                    order += `物品ID\t物品名称\t类别\t数量\t启动制造所需数量\t库存数量\t库存缺口\t启动制造所需库存缺口\t单价\t总价\t总体积\t成本占比\n`;
                    for (let resource of resourceList) {
                        if (resource.depositoryMissingCeilQuantity > 0) {
                            order += `${resource.key}\t${resource.name}\t${resource.category}\t${Utils.formatISK(resource.quantity, 0)}\t${resource.ceilQuantity}\t${resource.depositoryQuantity}\t${Utils.formatISK(resource.depositoryMissingQuantity, 0)}\t${resource.depositoryMissingCeilQuantity}\t${resource.singlePrice}\t${resource.totalPrice}\t${resource.volume}\t${resource.profitPercent}\n`;
                        }
                    }
                    copy(order, { format: 'text/plain' });
                    message.info(`库存缺少的原料已经复制到剪贴板，可以去Excel粘贴了。`);
                }
                break;
            case 'generateExcelAll':
                {
                    let order = '';
                    order += `物品ID\t物品名称\t类别\t数量\t启动制造所需数量\t库存数量\t库存缺口\t启动制造所需库存缺口\t单价\t总价\t总体积\t成本占比\n`;
                    for (let resource of resourceList) {
                        order += `${resource.key}\t${resource.name}\t${resource.category}\t${Utils.formatISK(resource.quantity, 0)}\t${resource.ceilQuantity}\t${resource.depositoryQuantity}\t${Utils.formatISK(resource.depositoryMissingQuantity, 0)}\t${resource.depositoryMissingCeilQuantity}\t${resource.singlePrice}\t${resource.totalPrice}\t${resource.volume}\t${resource.profitPercent}\n`;
                    }
                    copy(order, { format: 'text/plain' });
                    message.info(`所有原料已经复制到剪贴板，可以去Excel粘贴了。`);
                }
                break;
            case 'selectNone':
                {
                    this.setState({ selectedRowKeys: [] });
                }
                break;
            case 'selectAll':
                {
                    this.setState({ selectedRowKeys: resourceList.map(i => i.key) });
                }
                break;
            default:
                break;
        }

        if (e.key.startsWith('unExtract')) {
            this.ignoreNextOpenKeys = true;
            shouldCloseMenu = false;
            let unExtractTarget = e.key.substr(9);

            if (isNaN(unExtractTarget)) {
                let category = unExtractTarget;
                let materialBlueprintIds = this.props.getExtractBlueprintIds().filter(bid => {
                    let blueprint = ProfitHelper.getBlueprintByTypeId(bid);
                    let cat = ProfitHelper.getCategory(blueprint.typeIdSde.typeId);
                    return cat !== category;
                });
                this.props.setExtractBlueprintIds(materialBlueprintIds);
            } else {
                let bid = Number(unExtractTarget);
                let materialBlueprintIds = this.props.getExtractBlueprintIds().filter(id => id !== bid);
                this.props.setExtractBlueprintIds(materialBlueprintIds);
            }
        }

        if (shouldCloseMenu) {
            this.setState({ menuVisible: false });
        }
    };


    extractMaterial = () => {
        let materialBlueprintIds = this.props.getExtractBlueprintIds();
        if (this.state.selectedRowKeys == null) {
            return;
        }
        let extractedBid = [];
        for (let rowKey of this.state.selectedRowKeys) {
            let materialId = rowKey;
            let materialBlueprint = _.find(
                this.props.PriceDataReducer.allBlueprint,
                (info) => info.typeIdSde.typeId === materialId
            );
            if (materialBlueprint != null) {
                materialBlueprintIds.push(materialBlueprint.blueprintTypeId);
                extractedBid.push(materialBlueprint.blueprintTypeId);
            }
        }
        if (!_.isEmpty(extractedBid)) {
            this.setState({ extractMaterialHistory: [extractedBid, ...this.state.extractMaterialHistory] })
        }
        this.setState({ selectedRowKeys: [] });
        this.setExtractBlueprintIds(materialBlueprintIds);
    };


    onResourceNameClick = (e, record) => {
        this.showCopyMsg(e, record);
        e.preventDefault();
        e.stopPropagation();
    }

    onResourceClick = (e, record, resourceListObj) => {
        let { resourceList, context } = resourceListObj;
        if (e.shiftKey === true && this.state.lastRowKey > 0) {
            let allKeys = resourceList.map(r => r.key);
            let currIndex = allKeys.findIndex(value => value === record.key);
            let lastIndex = allKeys.findIndex(value => value === this.state.lastRowKey);
            let beginIndex = currIndex > lastIndex ? lastIndex : currIndex;
            let endIndex = currIndex > lastIndex ? currIndex : lastIndex;
            let newSelection = [...this.state.selectedRowKeys];
            if (this.state.selectedRowKeys.find(k => k === this.state.lastRowKey)) {//select items between
                for (let i = beginIndex; i <= endIndex; i++) {
                    if (newSelection.find(k => k === allKeys[i]) == null) {
                        newSelection = [...newSelection, allKeys[i]];
                    }
                }
            } else {
                for (let i = beginIndex; i <= endIndex; i++) {
                    newSelection = _.filter(newSelection, k => k !== allKeys[i]);
                }
            }
            this.setState({ selectedRowKeys: newSelection, lastRowKey: record.key });
        } else {
            let newSelection = this.state.selectedRowKeys;
            if (_.find(this.state.selectedRowKeys, k => k === record.key) != null) {
                newSelection = _.filter(this.state.selectedRowKeys, k => k !== record.key);
            } else {
                newSelection = [...this.state.selectedRowKeys, record.key]
            }
            this.setState({ selectedRowKeys: newSelection, lastRowKey: record.key });
        }
    }

    appendDepositoryQuantity = (dataSources) => {
        for (let dataSource of dataSources) {
            let depositoryQuantity = ProfitHelper.getDepositoryQuantity(dataSource.key);
            dataSource.depositoryQuantity = depositoryQuantity;
            let depositoryMissingQuantity = dataSource.quantity - depositoryQuantity;
            if (depositoryMissingQuantity < 0) {
                depositoryMissingQuantity = 0;
            }
            dataSource.depositoryMissingQuantity = depositoryMissingQuantity;

            let depositoryMissingCeilQuantity = dataSource.ceilQuantity - depositoryQuantity;
            if (depositoryMissingCeilQuantity < 0) {
                depositoryMissingCeilQuantity = 0;
            }
            dataSource.depositoryMissingCeilQuantity = depositoryMissingCeilQuantity;
        }
    }

    render() {
        let resourceListObj = this.props.resourceListObj;
        let resourceWithInventMaterials = this.props.resourceWithInventMaterials;
        this.appendDepositoryQuantity(resourceWithInventMaterials);
        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                let notNullSelectedRowKeys = selectedRows.filter((r) => r != null).map((r) => r.key);
                this.setState({ selectedRowKeys: notNullSelectedRowKeys });
            },
        };

        return (
            <div>
                <Dropdown overlay={this.menu(resourceListObj)} trigger={['contextMenu']} visible={this.state.menuVisible} onVisibleChange={this.onMenuVisibleChange}>
                    <div onClick={(e) => e.preventDefault()} >
                        <Table
                            columns={this.getColumns()}
                            rowKey={record => record.key}
                            dataSource={resourceWithInventMaterials}
                            size='small'
                            rowSelection={rowSelection}
                            pagination={{ showSizeChanger: true, pageSize: 100 }}
                            onRow={record => {
                                return {
                                    onClick: (e) => this.onResourceClick(e, record, resourceListObj), // 点击行
                                };
                            }}
                        />
                    </div>
                </Dropdown>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        PriceDataReducer: state.PriceDataReducer,
        SettingReducer: state.SettingReducer,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        SettingAction: bindActionCreators(SettingAction, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MaterialList);