import * as ActionTypes from "./ActionTypes";
import QQUserApi from '../api/QQUserApi';
import { initialState } from '../reducer/SettingReducer';
import _ from 'lodash';

export const getUserByOpenId = (openId) => {
  return function (dispatch, getState) {
    return QQUserApi.getQQUser(openId)
      .then((json) => {
        return dispatch({ type: ActionTypes.GET_QQUSER_BY_OPENID_SUCCESS, data: json });
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const isValidName = (name) => {
  return function (dispatch, getState) {
    return QQUserApi.isValidName(name)
      .then((text) => {
        return text;
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const setUserName = (openId, name) => {
  return function (dispatch, getState) {
    return QQUserApi.setUserName(openId, name)
      .then(() => {
        return QQUserApi.getQQUser(openId);
      }).then((json) => {
        return dispatch({ type: ActionTypes.GET_QQUSER_BY_OPENID_SUCCESS, data: json });
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const saveUserSetting = () => {
  return function (dispatch, getState) {
    let openId = getState().QQUserReducer.qqUser.openId;
    if (_.isEmpty(openId)) {
      alert('保存失败。请确保已经登录，并且能看见右上角的昵称。')
      return;
    }
    let version = getState().SettingReducer.settingVersion;
    let settingId = getState().SettingReducer.settingId;
    // let version = new Date().toLocaleString().replaceAll('/', '-').replaceAll(' ', '-');
    let settingName = getState().SettingReducer.settingName;
    let userSetting = {
      id: settingId,
      name: settingName,
      version: version,
      openId: openId,
      settingReducer: getState().SettingReducer
    };
    // return QQUserApi.saveUserSetting(userSetting);
    return QQUserApi.saveUserSetting(userSetting)
      .then(() => {
        return QQUserApi.listUserSetting(openId);
      }).then((json) => {
        return dispatch({ type: ActionTypes.LIST_USER_SETTING_SUCCESS, data: json });
      })
      .catch((err) => {
        throw err;
      });
  };
};


export const listUserSetting = () => {
  return function (dispatch, getState) {
    let openId = getState().QQUserReducer.qqUser.openId;
    if (_.isEmpty(openId)) {
      return;
    }
    return QQUserApi.listUserSetting(openId)
      .then((json) => {
        return dispatch({ type: ActionTypes.LIST_USER_SETTING_SUCCESS, data: json });
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const getUserSetting = (settingId) => {
  return function (dispatch, getState) {
    return QQUserApi.getUserSetting(settingId)
      .then((json) => {
        console.log('getUserSetting');
        let newReducer = {};
        _.assign(newReducer, initialState);
        console.log('newReducer+initialState');
        console.log(newReducer);
        let settingReducer = JSON.parse(json.settingReducer);
        console.log('setting from cloud');
        console.log(settingReducer);
        _.assign(newReducer, settingReducer);
        console.log('newReducer + setting from cloud');
        console.log(newReducer);
        if (!_.isEmpty(newReducer)) {
          return dispatch({ type: ActionTypes.IMPORT_SETTINGS, data: newReducer });
        }
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const removeUserSetting = (settingId) => {
  return function (dispatch, getState) {
    let openId = getState().QQUserReducer.qqUser.openId;
    return QQUserApi.removeUserSetting(settingId)
      .then(() => {
        return QQUserApi.listUserSetting(openId);
      }).then((json) => {
        return dispatch({ type: ActionTypes.LIST_USER_SETTING_SUCCESS, data: json });
      })
      .catch((err) => {
        throw err;
      });
  };
};