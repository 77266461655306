import React from "react";
import * as SettingAction from '../action/SettingAction';
import * as PriceDataAction from '../action/PriceDataAction';
import { initialState } from '../reducer/SettingReducer';
import CustomPriceView from './CustomPriceView';
import { Checkbox, Slider, InputNumber, Radio, Input, Button, Divider, Tabs, Select, Space, Switch, Tooltip } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import copy from "copy-to-clipboard";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ProfitHelper from '../common/ProfitHelper';
import _ from 'lodash';
const { TextArea } = Input;

class PriceSetting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showCustomPriceView: false
        }
    }

    componentDidMount() { }

    onResourceGalaxyChange = (value) => {
        this.props.PriceDataAction.setResourceGalaxy(value);
    };

    onProductGalaxyChange = (value) => {
        this.props.PriceDataAction.setProductGalaxy(value);
    };

    onResourcePriceVendorChange = (value) => {
        this.props.SettingAction.setResourcePriceVendor(value.target.value);
    }

    onProductPriceVendorChange = (value) => {
        this.props.SettingAction.setProductPriceVendor(value.target.value);
    }

    toggleShowCustomPriceView = () => {
        this.setState({ showCustomPriceView: !this.state.showCustomPriceView });
    }

    onResourceOffChange = (e) => {
        if (isNaN(Number(e))) {
            return;
        };
        this.props.SettingAction.setResourceOff(Number(e));
    };

    onProductOffChange = (e) => {
        if (isNaN(Number(e))) {
            return;
        };
        this.props.SettingAction.setProductOff(Number(e));
    };

    galaxyOptions = [
        { label: '索巴色基', value: 30001363 },
        { label: '佩尼尔格曼', value: 30003488 },
    ]

    getServerGalaxyValue = (isResource) => {
        let galaxy = _.find(this.props.SettingReducer.priceGalaxy, g => g.server === this.props.SettingReducer.server);
        if (galaxy == null) {
            return [];
        }
        if (isResource) {
            return galaxy.galaxyResource;
        } else {
            return galaxy.galaxyProduct;
        }
    }

    render() {
        let useCustomPrice = this.props.SettingReducer.useCustomPrice == null ? false : this.props.SettingReducer.useCustomPrice;
        return (
            <div>
                <CustomPriceView show={this.state.showCustomPriceView} onOK={(e) => this.toggleShowCustomPriceView()} onCancel={() => this.toggleShowCustomPriceView()} />
                <div className='flex' style={{ padding: '10px' }}>
                    <div className='price-setting'>
                        <div>
                            <div>原料价格</div>
                            <Divider style={{ background: ProfitHelper.getTextColor() }} />
                            <div style={{ display: (this.props.SettingReducer.server === 'infinity' || this.props.SettingReducer.server === 'infinityPriceEUBlueprint') ? '' : "none" }}>
                                <div className='settings-field-name'>价格来源星系</div>
                                <Checkbox.Group options={this.galaxyOptions} value={this.getServerGalaxyValue(true)} onChange={this.onResourceGalaxyChange} />
                            </div>
                        </div>
                        <div>
                            <div className='settings-field-name'>买价或卖价</div>
                            <Radio.Group onChange={this.onResourcePriceVendorChange} value={this.props.SettingReducer.resourcePriceVendor}>
                                <Radio value={0}>最低卖价</Radio>
                                <Radio value={1}>最高买价</Radio>
                                <Radio value={4}>买卖中间价</Radio>
                                <Radio value={2}>1天内平均成交价</Radio>
                                <Radio value={3}>20天内平均成交价</Radio>
                            </Radio.Group>
                        </div>
                        <div style={{ display: 'flex', marginTop: '20px' }}>
                            <div>
                                <Tooltip title={`原料价格按照市场价的 ${this.props.SettingReducer.resourceOff}% 计算`}>
                                    {'折扣'}
                                </Tooltip>
                            </div>
                            <div className='flex' style={{ width: '100px', height: '30px', bottom: '5px', position: 'relative', marginLeft: '50px' }}>
                                <InputNumber min="0" step="0.1" value={this.props.SettingReducer.resourceOff}
                                    onChange={this.onResourceOffChange} stringMode />
                            </div>
                            <div>%</div>
                        </div>

                    </div>
                    <div className='price-setting'>
                        <div>
                            <div>产品价格</div>
                            <Divider style={{ background: ProfitHelper.getTextColor() }} />
                            <div style={{ display: (this.props.SettingReducer.server === 'infinity' || this.props.SettingReducer.server === 'infinityPriceEUBlueprint') ? '' : "none" }}>
                                <div className='settings-field-name'>价格来源星系</div>
                                <Checkbox.Group options={this.galaxyOptions} value={this.getServerGalaxyValue(false)} onChange={this.onProductGalaxyChange} />
                            </div>
                        </div>

                        <div>
                            <div className='settings-field-name'>买价或卖价</div>
                            <Radio.Group onChange={this.onProductPriceVendorChange} value={this.props.SettingReducer.productPriceVendor}>
                                <Radio value={0}>最低卖价</Radio>
                                <Radio value={1}>最高买价</Radio>
                                <Radio value={4}>买卖中间价</Radio>
                                <Radio value={2}>1天内平均成交价</Radio>
                                <Radio value={3}>20天内平均成交价</Radio>
                            </Radio.Group>
                        </div>
                        <div style={{ display: 'flex', marginTop: '20px' }}>
                            <div>
                                <Tooltip title={`产品价格按照市场价的 ${this.props.SettingReducer.productOff}% 计算`}>
                                    {'折扣'}
                                </Tooltip>
                            </div>
                            <div className='flex' style={{ width: '100px', height: '30px', bottom: '5px', position: 'relative', marginLeft: '50px' }}>
                                <InputNumber min="0" max="100" step="0.1" value={this.props.SettingReducer.productOff}
                                    onChange={this.onProductOffChange} stringMode />
                            </div>
                            <div>%</div>
                        </div>
                    </div>
                </div>
                <Divider style={{ background: ProfitHelper.getTextColor() }} />
                <div className='flex'>
                    <div style={{ width: '50%', position: 'relative', top: '5px' }}>自定义价格</div>
                    <div style={{ position: 'relative', top: '5px', marginRight: '20px' }}><Switch
                        checked={useCustomPrice}
                        checkedChildren='启用'
                        unCheckedChildren='禁用'
                        onChange={() => this.props.SettingAction.setUseCustomPrice(!useCustomPrice)}
                        defaultChecked
                    /></div>
                    {useCustomPrice ? <Button type='primary' onClick={this.toggleShowCustomPriceView}>编辑自定义价格...</Button> : ''}
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        SettingReducer: state.SettingReducer
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        SettingAction: bindActionCreators(SettingAction, dispatch),
        PriceDataAction: bindActionCreators(PriceDataAction, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PriceSetting);