import React from "react";
import * as SettingAction from '../action/SettingAction';
import * as PriceDataAction from '../action/PriceDataAction';
import * as RefineAction from '../action/RefineAction';
import { initialState } from '../reducer/SettingReducer';
import CustomPriceView from './CustomPriceView';
import { Checkbox, Slider, InputNumber, Radio, Input, Button, Divider, Tabs, Select, Space, Switch, Tooltip } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import copy from "copy-to-clipboard";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ProfitHelper from '../common/ProfitHelper';
import _ from 'lodash';
const { TextArea } = Input;

class RefineSetting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            useCustomizedMineralRefineRatio: false,
            useCustomizedEquipRefineRatio: false,
        };
        this.mineralRefineOptions = [
            { label: 1, value: 0.906 },
            { label: 2, value: 0.858 },
            { label: 3, value: 0.809 },
        ];
        this.equipRefineOptions = [
            { label: 1, value: 0.55 },
            { label: 2, value: 0.5 },
        ]
    }

    componentDidMount() { }


    getMineralRefineRadioLabel = () => {
        if (this.state.useCustomizedMineralRefineRatio) {
            return 4;
        }
        let mineralRefineOption = _.find(this.mineralRefineOptions, i => i.value === this.props.SettingReducer.mineralRefinePercent);
        if (mineralRefineOption == null) {
            return 4;
        } else {
            return mineralRefineOption.label;
        }
    }

    getEquipRefineRadioLabel = () => {
        if (this.state.useCustomizedEquipRefineRatio) {
            return 3;
        }
        let equipRefineOptions = _.find(this.equipRefineOptions, i => i.value === this.props.SettingReducer.equipRefinePercent);
        if (equipRefineOptions == null) {
            return 3;
        } else {
            return equipRefineOptions.label;
        }
    }

    mineralRefineRatioChanged = (value) => {
        if (value === 1) {
            this.props.RefineAction.setMineralRefinePercent(0.906);
            this.setState({ useCustomizedMineralRefineRatio: false });
        } else if (value === 2) {
            this.props.RefineAction.setMineralRefinePercent(0.858);
            this.setState({ useCustomizedMineralRefineRatio: false });
        } else if (value === 3) {
            this.props.RefineAction.setMineralRefinePercent(0.809);
            this.setState({ useCustomizedMineralRefineRatio: false });
        } else {
            this.setState({ useCustomizedMineralRefineRatio: true });
        }
    }

    mineralRefineCustomValueChanged = (value) => {
        this.props.RefineAction.setMineralRefinePercent(value);
    }

    equipRefineRatioChanged = (value) => {
        if (value === 1) {
            this.props.RefineAction.setEquipRefinePercent(0.55);
            this.setState({ useCustomizedEquipRefineRatio: false });
        } else if (value === 2) {
            this.props.RefineAction.setEquipRefinePercent(0.5);
            this.setState({ useCustomizedEquipRefineRatio: false });
        } else {
            this.setState({ useCustomizedEquipRefineRatio: true });
        }
    }

    equipRefineCustomValueChanged = (value) => {
        this.props.RefineAction.setEquipRefinePercent(value);
    }

    getRefineTaxRate = () => {
        let rate = this.props.SettingReducer.refineTaxRate;
        if (rate == null) {
            rate = 0;
        }
        return rate * 100;
    }

    setRefineTaxRate = (value) => {
        this.props.SettingAction.setRefineTaxRate(value / 100);
    }

    render() {
        const radioStyle = {
            display: 'block',
            height: '30px',
            lineHeight: '30px',
        };
        return (
            <div style={{ margin: '10px' }}>
                <div>
                    <div className='settings-field-name'>矿物提炼效率</div>
                    <Radio.Group value={this.getMineralRefineRadioLabel()} onChange={(e) => this.mineralRefineRatioChanged(e.target.value)}>
                        <Radio style={radioStyle} value={1}>0.0极限化矿 (90.6%)</Radio>
                        <Radio style={radioStyle} value={2}>低安极限化矿 (85.8%)</Radio>
                        <Radio style={radioStyle} value={3}>高安极限化矿 (80.9%)</Radio>
                        <Radio style={radioStyle} value={4}>自定义...
          {this.getMineralRefineRadioLabel() === 4 ?
                                <InputNumber min={0} max={1} style={{ margin: '0 16px', width: '200px' }} step={0.001} 
                                    value={this.props.SettingReducer.mineralRefinePercent} onChange={(e) => this.mineralRefineCustomValueChanged(e)}
                                /> : null}
                        </Radio>
                    </Radio.Group>
                </div>
                <Divider style={{ background: ProfitHelper.getTextColor() }} />
                <div>
                    <div className='settings-field-name'>装备提炼效率</div>
                    <Radio.Group value={this.getEquipRefineRadioLabel()} onChange={(e) => this.equipRefineRatioChanged(e.target.value)}>
                        <Radio style={radioStyle} value={1}>碎铁5 (55%)</Radio>
                        <Radio style={radioStyle} value={2}>无技能 (50%)</Radio>
                        <Radio style={radioStyle} value={3}>自定义...
          {this.getEquipRefineRadioLabel() === 3 ?
                                <InputNumber min={0} max={1} style={{ margin: '0 16px', width: '200px' }} step={0.001}
                                    value={this.props.SettingReducer.equipRefinePercent} onChange={(e) => this.equipRefineCustomValueChanged(e)}
                                /> : null}
                        </Radio>
                    </Radio.Group>
                </div>
                <Divider style={{ background: ProfitHelper.getTextColor() }} />
                <div style={{ display: 'flex' }}>
                    <div className='settings-field-name' style={{ width: '100px' }}>化矿税</div>
                    <div className='flex' style={{ width: '100px', height: '30px', bottom: '-5px', position: 'relative' }}>
                        <InputNumber min="0" max="100" step="0.1" value={this.getRefineTaxRate()} onChange={this.setRefineTaxRate} stringMode />
                    </div>
                    <div className='settings-field-name'>%</div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        SettingReducer: state.SettingReducer
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        SettingAction: bindActionCreators(SettingAction, dispatch),
        PriceDataAction: bindActionCreators(PriceDataAction, dispatch),
        RefineAction: bindActionCreators(RefineAction, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RefineSetting);