import React from 'react';
import * as PriceDataAction from '../action/PriceDataAction';
import * as RefineAction from '../action/RefineAction';
import RefineItem from './RefineItem';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Button, Slider, Spin, Tooltip, Table, Statistic, Dropdown, message } from 'antd';
import UpsertRefineItemModal from './UpsertRefineItemModal';
import PasteBinModal from './PasteBinModal';
import * as Utils from '../common/Utils';
import ProfitHelper from '../common/ProfitHelper';
import MineralList from './MineralList';
import { PlusOutlined, DeleteOutlined, UnorderedListOutlined, ExportOutlined, SaveOutlined, SnippetsOutlined, TableOutlined } from '@ant-design/icons';
import Initializer from '../common/Initializer';
import copy from "copy-to-clipboard";

const columns = [
  {
    title: '',
    dataIndex: 'typeId',
    sorter: Utils.createNumericSorter('typeId'),
    render: (text, record, index) => {
      if (_.endsWith(record.name, '蓝图')) {
        return (<div><img style={{ width: '25px' }} nsrc={`https://image.evepc.163.com/Type/${record.typeId}_64.png`} src={`https://images.evetech.net/types/${record.typeId}/bpc`}></img></div>)
      } else {
        return (<div><img style={{ width: '25px' }} nsrc={`https://image.evepc.163.com/Type/${record.typeId}_64.png`} src={`https://images.evetech.net/types/${record.typeId}/icon`}></img></div>)
      }
    }
  },
  {
    title: '物品名称',
    dataIndex: 'name',
    sorter: Utils.createStringSorter('name'),
  },
  {
    title: `数量`,
    dataIndex: 'quantity',
    sorter: Utils.createNumericSorter('quantity'),
    render: (text) => <span>{`${Utils.formatISK(text, 0)} 个`}</span>,
    align: 'right',
  },
  {
    title: '单价（卖价）',
    dataIndex: 'singleSellPrice',
    sorter: Utils.createNumericSorter('singleSellPrice'),
    render: (text) => <span>{`${Utils.formatISK(text)} ISK`}</span>,
    align: 'right',
  },
  {
    title: '单价（买价）',
    dataIndex: 'singleBuyPrice',
    sorter: Utils.createNumericSorter('singleBuyPrice'),
    render: (text) => <span>{`${Utils.formatISK(text)} ISK`}</span>,
    align: 'right',
  },
  {
    title: '总价（卖价）',
    dataIndex: 'totalSellPrice',
    sorter: Utils.createNumericSorter('totalSellPrice'),
    render: (text) => <span>{`${Utils.formatISK(text)} ISK`}</span>,
    align: 'right',
  },
  {
    title: '总价（买价）',
    dataIndex: 'totalBuyPrice',
    sorter: Utils.createNumericSorter('totalBuyPrice'),
    render: (text) => <span>{`${Utils.formatISK(text)} ISK`}</span>,
    align: 'right',
  },
  // {
  //   title: '价格占比%',
  //   dataIndex: 'pricePercent',
  //   sorter: Utils.createNumericSorter('pricePercent'),
  //   align: 'right',
  // }
]

class Refine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showUpsertItemModal: false,
      showPasteBinModal: false,
      showMineralList: false,
      isAdd: false,
      editingIndex: -1,
      leftDisplayMode: 0,
      rightDisplayMode: 0
    };
  }

  componentDidMount() {
    Initializer.initPriceData();
  }

  componentWillReceiveProps(nextProps) {

  }

  isReady = () => {
    return !_.isEmpty(this.props.PriceDataReducer.allTypeIdSde) &&
      !_.isEmpty(this.props.PriceDataReducer.allTypeMaterials) &&
      !_.isEmpty(this.props.PriceDataReducer.allMarketDump)
  }

  getRefinePercent = (typeId) => {
    let sde = _.find(this.props.PriceDataReducer.allTypeIdSde, sde => sde.typeId === typeId);
    if (sde == null) {
      return 0;
    }
    let categories = ['标准矿石', '冰矿', '卫星矿石'];
    if (sde.categories.length > 3 && categories.indexOf(sde.categories[3]) >= 0) {
      return this.props.SettingReducer.mineralRefinePercent;
    } else {
      return this.props.SettingReducer.equipRefinePercent;
    }
  }

  getRefinedItems = () => {
    let refinedItems = new Map();
    for (let preRefineItem of this.props.RefineReducer.preRefineItems) {
      let material = _.find(this.props.PriceDataReducer.allTypeMaterials, m => m.typeId === preRefineItem.typeId);
      if (material == null) {
        continue;
      }
      let preRefineTypeIdSde = _.find(this.props.PriceDataReducer.allTypeIdSde, sde => sde.typeId === preRefineItem.typeId);
      if (preRefineTypeIdSde == null) {
        continue;
      }
      for (let materialItem of material.items) {
        let refinedItem = { materialTypeId: materialItem.materialTypeId, quantity: materialItem.quantity };
        let preRefineItemPortion = Math.floor(preRefineItem.quantity / preRefineTypeIdSde.portionSize);
        refinedItem.quantity = Math.floor(materialItem.quantity * preRefineItemPortion * this.getRefinePercent(preRefineItem.typeId));
        if (!refinedItems.has(materialItem.materialTypeId)) {
          refinedItems.set(materialItem.materialTypeId, refinedItem);
        } else {
          refinedItems.get(materialItem.materialTypeId).quantity += refinedItem.quantity;
        }
      }
    }
    return [...refinedItems.values()];
  }

  addItem = () => {
    this.setState({ showUpsertItemModal: true, isAdd: true });
  }

  onItemUpserted = (e) => {
    let newPreRefineItem = [];
    if (this.state.isAdd) {
      newPreRefineItem = [...this.props.RefineReducer.preRefineItems, e];
    } else {
      newPreRefineItem = this.props.RefineReducer.preRefineItems.map((value, index) => {
        if (index === this.state.editingIndex) {
          return e;
        } else {
          return value;
        }
      })
    }
    this.props.RefineAction.setRefineItems(newPreRefineItem);
    this.closeUpsertModal();
  }

  setLeftDisplayMode = (mode) => {
    this.setState({ leftDisplayMode: mode });
  }

  setRightDisplayMode = (mode) => {
    this.setState({ rightDisplayMode: mode });
  }

  closeUpsertModal = () => {
    this.setState({ showUpsertItemModal: false });
  }

  openEditModal = (index) => {
    this.setState({ editingIndex: index, isAdd: false, showUpsertItemModal: true });
  }

  deleteItem = (index) => {
    let newPreRefineItem = [...this.props.RefineReducer.preRefineItems];
    newPreRefineItem.splice(index, 1);
    this.props.RefineAction.setRefineItems(newPreRefineItem);
  }

  togglePasteBinModal = () => {
    this.setState({ showPasteBinModal: !this.state.showPasteBinModal });
  }

  toggleMineralList = () => {
    this.setState({ showMineralList: !this.state.showMineralList });
  }
  
  onPasteBinOK = (newItems) => {
    let newPreRefineItem = [...this.props.RefineReducer.preRefineItems, ...newItems];
    this.props.RefineAction.setRefineItems(newPreRefineItem);
    this.togglePasteBinModal();
  }

  clearRefineItems = () => {
    this.props.RefineAction.setRefineItems([]);
  }

  getTableDataSource = (items, isLeft) => {
    let dataSource = items.map(item => {
      let typeId = item.typeId == null ? item.materialTypeId : item.typeId;
      let typeIdSde = ProfitHelper.getTypeIdSdeById(typeId);
      let marketDumpSell = ProfitHelper.getMarketDumpById(typeId, { isResource: isLeft, vendor: 0 });
      if (marketDumpSell == null) {
        marketDumpSell = {};
      }
      let marketDumpBuy = ProfitHelper.getMarketDumpById(typeId, { isResource: isLeft, vendor: 1 });
      if (marketDumpBuy == null) {
        marketDumpBuy = {};
      }
      let category = '';
      if (typeIdSde.categories != null) {
        category = ProfitHelper.getCategory(typeId);
      }
      return {
        typeId: typeId,
        name: typeIdSde.name,
        category: category,
        quantity: item.quantity,
        singleSellPrice: marketDumpSell.price,
        singleBuyPrice: marketDumpBuy.price,
        totalSellPrice: marketDumpSell.price * item.quantity,
        totalBuyPrice: marketDumpBuy.price * item.quantity,
      }
    });
    return dataSource;
  }

  renderLeft = () => {
    if (!this.isReady()) {
      return (
        <div style={{ position: 'absolute', left: '50%', top: '50%' }}>
          <Spin size='large' />
        </div>
      );
    } else {
      if (this.state.leftDisplayMode === 0) {
        return (<div className="left-icons">
          {this.props.RefineReducer.preRefineItems.map((item, index) => {
            return (<RefineItem item={item} key={index} onEdit={() => this.openEditModal(index)}
              onDelete={() => this.deleteItem(index)} isSource />);
          })}
        </div>);
      } else {
        return (
          <div style={{ height: 'calc(100vh - 180px)', overflow: 'scroll' }}>
            <Table columns={columns} rowKey={record => record.typeId} size='small' pagination={{ showSizeChanger: true, pageSize: 100 }}
              dataSource={this.getTableDataSource(this.props.RefineReducer.preRefineItems, true)} />
          </div>
        )
      }
    }
  }

  renderRight = (refinedItems) => {
    if (!this.isReady()) {
      return (
        <div style={{ position: 'absolute', left: '50%', top: '50%' }}>
          <Spin size='large' />
        </div>
      );
    } else {
      if (this.state.rightDisplayMode === 0) {
        return (<div className="right-icons">
          {_.sortBy(refinedItems, i => i.materialTypeId).map(item => {
            return (<RefineItem item={{ typeId: item.materialTypeId, quantity: item.quantity }} isSource={false} />);
          })}
        </div>);
      } else {
        return (
          <div style={{ height: 'calc(100vh - 180px)', overflow: 'scroll' }}>
            <Table columns={columns} rowKey={record => record.typeId} size='small' pagination={{ showSizeChanger: true, pageSize: 100 }}
              dataSource={this.getTableDataSource(refinedItems, false)} />
          </div>
        )
      }
    }
  }

  getPriceEstimation = (items, isResource, vendor) => {
    let totalPrice = 0;
    for (let item of items) {
      if (item.typeId == null) {
        item.typeId = item.materialTypeId;
      }
      let marketDump = ProfitHelper.getMarketDumpById(item.typeId, { isResource: isResource, vendor: vendor });
      if (marketDump == null) {
        continue;
      }
      totalPrice += marketDump.price * item.quantity;
    }
    return Utils.formatISK(totalPrice);
  }

  getVolumeEstimation = (items) => {
    let totalVolume = 0;
    for (let item of items) {
      if (item.typeId == null) {
        item.typeId = item.materialTypeId;
      }
      let typeIdSde = ProfitHelper.getTypeIdSdeById(item.typeId);
      if (typeIdSde == null) {
        continue;
      }
      totalVolume += typeIdSde.packagedVolume * item.quantity;
    }
    return Utils.formatISK(totalVolume);
  }

  formatter = (value) => {
    return `${value}%`;
  }

  mineralRefinePercentChange = (value) => {
    this.props.RefineAction.setMineralRefinePercent(value / 100);
  }

  equipRefinePercentChange = (value) => {
    this.props.RefineAction.setEquipRefinePercent(value / 100);
  }


  renderStatisticText = (text) => {
    return (<div style={{ fontSize: '15px' }}>
      {text}
    </div>)
  }

  getExportMenuItems = () => {
    return [
      {
        key: 'exportToMarket',
        label: (
          <div>导出到游戏市场</div>
        ),
        icon: <SnippetsOutlined />,
      },
      {
        key: 'exportToAsset',
        label: (
          <div>导出到估价</div>
        ),
        icon: <SnippetsOutlined />,
      },
      {
        key: 'exportToExcel',
        label: (
          <div>导出到Excel</div>
        ),
        icon: <SnippetsOutlined />,
      },
    ];
  }

  onExportMenuClick = ({ key }) => {
    console.log(key);
    let refinedDataSource = this.getTableDataSource(this.getRefinedItems(), false);
    switch (key) {
      case 'exportToMarket': {
        let order = '';
        for (let resource of refinedDataSource) {
          order += `${resource.name}\t${resource.quantity}\n`;
        }
        if (_.isEmpty(refinedDataSource)) {
          message.info(`没有找到化矿产出。`);
        } else {
          copy(order);
          message.info(`${refinedDataSource.length}件物品已经复制到剪贴板，粘贴到游戏市场中，通过创建批量订单来获取它们的市场价。`);
        }
        break;
      } case 'exportToAsset': {
        let order = '';
        for (let resource of refinedDataSource) {
          let typeIdSde = ProfitHelper.getTypeIdSdeById(resource.typeId);
          let category = ProfitHelper.getCategory(resource.typeId);
          let largestCategory = typeIdSde.categories[0];
          order += `${typeIdSde.name}\t${resource.quantity}\t${category}\t${largestCategory}\n`;
        }
        if (_.isEmpty(refinedDataSource)) {
          message.info(`没有找到化矿产出。`);
        } else {
          copy(order);
          message.info(`${refinedDataSource.length}件物品已经复制到剪贴板，可以粘贴到各种估价工具。`);
        }
        break;
      } case 'exportToExcel': {
        let order = '';
        order += `物品ID\t名称\t数量\t单价（卖价）\t单价（买价）\t总价（卖价）\t总价（买价\n`;
        for (let resource of refinedDataSource) {
          order += `${resource.typeId}\t${resource.name}\t${resource.quantity}\t${resource.singleSellPrice}\t${resource.singleBuyPrice}\t${resource.totalSellPrice}\t${resource.totalBuyPrice}\n`;
        }
        if (_.isEmpty(refinedDataSource)) {
          message.info(`没有找到化矿产出。`);
        } else {
          copy(order, { format: 'text/plain' });
          message.info(`${refinedDataSource.length}件物品已经复制到剪贴板，可以去Excel粘贴了。`);
        }
        break;
      } default: {
        break;
      }
    }
  };

  getRefineTax = (refinedItems) => {
    let tax = 0;
    for (let item of refinedItems) {
      let estimatedPrice = ProfitHelper.getEstimatedPrice(item.typeId);
      tax += estimatedPrice * item.quantity * this.props.SettingReducer.refineTaxRate;
    }
    return Utils.formatISK(tax);
  }

  render() {
    let refinedItems = this.getRefinedItems();
    return (<div className="refine-box" id="refineBox" style={{ color: this.props.SettingReducer.antdTheme === 0 ? '' : 'white' }}>
      <UpsertRefineItemModal show={this.state.showUpsertItemModal} isAdd={this.state.isAdd} editingItem={this.props.RefineReducer.preRefineItems[this.state.editingIndex]}
        onOK={(e) => this.onItemUpserted(e)} onCancel={() => this.closeUpsertModal()} />
      <PasteBinModal show={this.state.showPasteBinModal} onCancel={() => this.togglePasteBinModal()} onOK={(e) => this.onPasteBinOK(e)} />
      <MineralList show={this.state.showMineralList} onCancel={() => this.toggleMineralList()} onOK={() => this.toggleMineralList()} />
      <div className="left-part">
        <div style={{ display: 'flex', boxShadow: '0px 12px 8px -12px #000', alignItems: 'center' }}>
          <div style={{ width: '100%' }}>
            <div style={{ display: 'flex', marginTop: '10px' }}>
              <div style={{ margin: '0px 10px', fontSize: 'x-large' }}>输入材料</div>
              <div style={{ display: 'flex', marginLeft: '50px' }}>
                <Tooltip title={'图标模式'}>
                  <Button type="default" icon={<img style={{ width: '40px', display: 'block', marginTop: '-6px', marginLeft: '-3px' }} nsrc={`https://image.evepc.163.com/Type/34_64.png`} src={`https://images.evetech.net/types/34/icon`}></img>} style={{ borderRadius: '2px', boxShadow: this.state.leftDisplayMode === 0 ? '0px 0px 10px gray inset' : '' }} size="large" onClick={() => this.setLeftDisplayMode(0)} />
                </Tooltip>
                <Tooltip title={'列表模式'}>
                  <Button type="default" icon={<UnorderedListOutlined />} style={{ borderRadius: '2px', boxShadow: this.state.leftDisplayMode === 1 ? '0px 0px 10px gray inset' : '' }} size="large" onClick={() => this.setLeftDisplayMode(1)} />
                </Tooltip>
              </div>
              <div style={{ width: '50px' }}></div>
              <Tooltip title={'添加物品'}>
                <Button style={{ borderRadius: '2px' }} type="default" icon={<PlusOutlined />} size="large" onClick={() => this.addItem()} />
              </Tooltip>
              <Tooltip title={'从剪贴板导入'}>
                <Button style={{ borderRadius: '2px' }} type="default" icon={<SnippetsOutlined />} size="large" onClick={() => this.togglePasteBinModal()} />
              </Tooltip>
              <Tooltip title={'清空材料列表'}>
                <Button style={{ borderRadius: '2px' }} type="default" icon={<DeleteOutlined />} size="large" onClick={() => this.clearRefineItems()} />
              </Tooltip>
              <Tooltip title={'矿物价值列表'}>
                <Button style={{ borderRadius: '2px' }} type="default" icon={<UnorderedListOutlined />} size="large" onClick={() => this.toggleMineralList()} />
              </Tooltip>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginLeft: '10px', paddingRight: '30px' }}>
              <Statistic title='估价（最低卖价）' value={this.getPriceEstimation(this.props.RefineReducer.preRefineItems, true, 0) + ' ISK'} formatter={(text) => this.renderStatisticText(text)} />
              <Statistic title='估价（最高买价）' value={this.getPriceEstimation(this.props.RefineReducer.preRefineItems, true, 1) + ' ISK'} formatter={(text) => this.renderStatisticText(text)} />
              <Statistic title='体积' value={this.getVolumeEstimation(this.props.RefineReducer.preRefineItems) + ' m³'} formatter={(text) => this.renderStatisticText(text)} />
            </div>
          </div>
        </div>
        {this.renderLeft()}
      </div>

      <div className="right-part">
        <div style={{ display: 'flex', boxShadow: '0px 12px 8px -12px #000', alignItems: 'center', flexDirection: 'column' }}>
          <div style={{ display: 'flex', marginTop: '10px' }}>
            <div style={{ fontSize: 'x-large', width: '130px' }}>化矿产出</div>
            <div style={{ display: 'flex', marginLeft: '20px' }}>
              <Tooltip title={'图标模式'}>
                <Button type="default" icon={<img style={{ width: '40px', display: 'block', marginTop: '-6px', marginLeft: '-3px' }} nsrc={`https://image.evepc.163.com/Type/34_64.png`} src={`https://images.evetech.net/types/34/icon`}></img>} style={{ borderRadius: '2px', boxShadow: this.state.rightDisplayMode === 0 ? '0px 0px 10px gray inset' : '' }} size="large" onClick={() => this.setRightDisplayMode(0)} />
              </Tooltip>
              <Tooltip title={'列表模式'}>
                <Button type="default" icon={<UnorderedListOutlined />} style={{ borderRadius: '2px', boxShadow: this.state.rightDisplayMode === 1 ? '0px 0px 10px gray inset' : '' }} size="large" onClick={() => this.setRightDisplayMode(1)} />
              </Tooltip>
              <div style={{ width: '20px' }}></div>
              <Dropdown menu={{ items: this.getExportMenuItems(), onClick: this.onExportMenuClick }}>
                <Tooltip title={'复制到剪贴板...'}>
                  <Button style={{ borderRadius: '2px' }} type="default" icon={<SnippetsOutlined />} size="large" />
                </Tooltip>
              </Dropdown>
            </div>
            <div style={{ marginLeft: 'auto', marginRight: '20px', display: 'flex' }}>
              <div className='flex' style={{ marginLeft: '20px' }}>
                <div style={{ marginTop: '5px' }}>矿石提炼效率：{Math.round(this.props.SettingReducer.mineralRefinePercent * 1000) / 10}%</div>
                <div style={{ width: '200px' }}>
                  <Slider defaultValue={this.props.SettingReducer.mineralRefinePercent * 100} onAfterChange={this.mineralRefinePercentChange} tipFormatter={this.formatter} step={0.1} />
                </div>
              </div>
              <div className='flex'>
                <div style={{ marginTop: '5px' }}>装备提炼效率：{Math.round(this.props.SettingReducer.equipRefinePercent * 1000) / 10}%</div>
                <div style={{ width: '200px' }}>
                  <Slider defaultValue={this.props.SettingReducer.equipRefinePercent * 100} onAfterChange={this.equipRefinePercentChange} tipFormatter={this.formatter} step={0.1} />
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginLeft: '10px', paddingRight: '30px' }}>
            <Statistic title='估价（最低卖价）' value={this.getPriceEstimation(refinedItems, false, 0) + ' ISK'} formatter={(text) => this.renderStatisticText(text)} />
            <Statistic title='估价（最高买价）' value={this.getPriceEstimation(refinedItems, false, 1) + ' ISK'} formatter={(text) => this.renderStatisticText(text)} />
            <Statistic title='化矿税' value={this.getRefineTax(refinedItems) + ' ISK'} formatter={(text) => this.renderStatisticText(text)} />
            <Statistic title='体积' value={this.getVolumeEstimation(refinedItems) + ' m³'} formatter={(text) => this.renderStatisticText(text)} />
          </div>
        </div>
        {this.renderRight(refinedItems)}
      </div>
    </div>);
  }
}

const mapStateToProps = (state) => {
  return {
    PriceDataReducer: state.PriceDataReducer,
    RefineReducer: state.RefineReducer,
    SettingReducer: state.SettingReducer
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    PriceDataAction: bindActionCreators(PriceDataAction, dispatch),
    RefineAction: bindActionCreators(RefineAction, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Refine);
