import * as ActionTypes from '../action/ActionTypes';
import update from 'immutability-helper';
import _ from 'lodash';

const initialState = {
  allBlueprint: [],
  allTypeIdSde: [],
  allMarketDump: [],
  allMarketHistory: [],
  blueprintDetailList: [],
  materialBlueprintIds: [],
  allBlueprintConfig: new Map(),
  blueprintConfigId: '未命名',
  latestBlurprintDetail: {},
  allTypeMaterials: [],
  industryIndices: [],
  orders: { orders: [] },
  rankData: [],
  rankCheckedKeys: [],
  previousMonthsMarketHistory: [],
  selectedMaterialStats: [],
  lastOrderDate: '',
  dealPriceList: [],
  estimatedPriceList: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ALL_BLUEPRINTS_SUCCESS:
      if (!_.isEmpty(state.allTypeIdSde)) {
        action.data.forEach(bp => {
          let typeIdSde = _.find(state.allTypeIdSde, t => t.typeId === bp.typeIdSde.typeId);
          bp.typeIdSde = typeIdSde;
        })
      };
      return update(state, {
        allBlueprint: { $set: action.data },
      });
    case ActionTypes.GET_ALL_TYPEMATERIALS_SUCCESS:
      action.data.forEach(material => material.items = JSON.parse(material.itemsJson));
      return update(state, { allTypeMaterials: { $set: action.data } });
    case ActionTypes.GET_ALL_MARKET_DUMP_SUCCESS:
      return update(state, { allMarketDump: { $set: action.data } });
    case ActionTypes.GET_ALL_MARKET_HISTORY_SUCCESS:
      return update(state, { allMarketHistory: { $set: action.data } });
    case ActionTypes.GET_INDUSTTRY_INDICES_SUCCESS:
      return update(state, { industryIndices: { $set: action.data } });
    case ActionTypes.UPSERT_BLUEPRINT_CONFIG_SUCCESS:
      return update(state, { allBlueprintConfig: { $add: [[action.data.productId, action.data.config]] } });
    case ActionTypes.REMOVE_BLUEPRINT_CONFIG_SUCCESS:
      var map = new Map();
      for (let [key, value] of state.allBlueprintConfig) {
        map.set(key, value);
      }
      map.delete(action.data);
      return update(state, { allBlueprintConfig: { $set: map } });
    case ActionTypes.UPSERT_BLUEPRINT_BUILD_SUCCESS:
      var map = new Map();
      var build = JSON.parse(action.data.build);
      for (var i = 0; i < build.keys.length; i++) {
        map.set(build.keys[i], build.values[i]);
      }
      return update(state, {
        blueprintConfigId: { $set: action.data.id },
        allBlueprintConfig: { $set: map },
      });
    case ActionTypes.GET_ALL_TYPEID_SDE_SUCCESS:
      action.data.forEach((typeIdSde) => {
        typeIdSde.categories = JSON.parse(typeIdSde.categories);
      });
      return update(state, { allTypeIdSde: { $set: action.data } });
    case ActionTypes.GET_ORDERS_SUCCESS:
      return update(state, { orders: { $set: action.data } });
    case ActionTypes.SET_RANK_DATA:
      return update(state, { rankData: { $set: action.data } });
    case ActionTypes.SET_RANK_CHECKED_KEYS:
      return update(state, { rankCheckedKeys: { $set: action.data } });
    case ActionTypes.GET_PREVIOUS_MONTHS_MARKET_HISTORY_SUCCESS:
      return update(state, { previousMonthsMarketHistory: { $set: action.data } });
    case ActionTypes.SET_SELECTED_MATERIAL_STATS:
      let newStats = state.selectedMaterialStats.filter(s => {
        if (s.activeTabKey === action.data.activeTabKey) {
          return false;
        } else {
          return true;
        }
      })
      return update(state, { selectedMaterialStats: { $set: [...newStats, action.data] } });
    case ActionTypes.GET_LAST_ORDER_DATE_SUCCESS:
      return update(state, { lastOrderDate: { $set: action.data } });
    case ActionTypes.GET_DEAL_PRICE_LIST_SUCCESS:
      return update(state, { dealPriceList: { $set: action.data } });
    case ActionTypes.GET_ESTIMATED_PRICE_LIST_SUCCESS:
      return update(state, { estimatedPriceList: { $set: action.data } });
    default:
      return state;
  }
};
