import * as ActionTypes from "../action/ActionTypes";
import update from "immutability-helper";

const initialState = {
  messagePage: {},
  messageCount: 0,
  createMessageFlag: 0,
  ip: '',
  authCode: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_MESSAGE_SUCCESS:
      return update(state, { messagePage: { $set: action.data } });
    case ActionTypes.GET_MESSAGE_COUNT_SUCCESS:
      return update(state, { messageCount: { $set: parseInt(action.data) } });
    case ActionTypes.CREATE_MESSAGE_SUCCESS:
    case ActionTypes.REMOVE_MESSAGE_SUCCESS:
      return update(state, { createMessageFlag: { $set: state.createMessageFlag + 1 } });
    case ActionTypes.GET_IP_SUCCESS:
      return update(state, { ip: { $set: action.data } });
    case ActionTypes.GET_AUTH_CODE_SUCCESS:
      return update(state, { authCode: { $set: action.data } });
    default:
      return state;
  }
};
