import React from "react";
import * as SettingAction from '../action/SettingAction';
import * as PriceDataAction from '../action/PriceDataAction';
import { initialState } from '../reducer/SettingReducer';
import { Modal, Slider, InputNumber, Radio, Input, Button, Divider, Tabs, Select, Space, Switch, Tooltip } from 'antd';
import { CheckOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import copy from "copy-to-clipboard";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ProfitHelper from '../common/ProfitHelper';
import * as Utils from '../common/Utils';
import inventformula from "../resource/inventformula.png";
import _ from 'lodash';
const { TextArea } = Input;

class BlueprintSetting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            useCustomizedInventAddition: false,
            showInventFormula: false
        };
        this.inventAdditionOptions = [
            { label: 1, value: 1 },
            { label: 2, value: 1.46 },
        ]
    }

    componentDidMount() { }

    onMaterialEffectChange = (newValue) => {
        if (newValue >= 0 && newValue <= 20) {
            this.props.PriceDataAction.setMaterialEffect(newValue);
        }
    };

    onMarketFeeChange = (value) => {
        if (isNaN(value.target.value)) {
            return;
        }
        this.props.PriceDataAction.setMarketFee(value.target.value);
    };

    getInventOptions = () => {
        let decoderOptions = this.props.ItemInfoReducer.allDecoders.map(decoder => {
            let marketDump = ProfitHelper.getMarketDumpById(decoder.typeId);
            let price = marketDump == null ? 0 : marketDump.price;
            let label = `${decoder.name.replace(/装置解码器/, '')} ${decoder.materialEffect}材料 ${decoder.timeEffect}时间 ${decoder.quantity}流程 成功率*${decoder.probability + 1} 价格${Utils.formatISK(price)}ISK`;
            return { label: label, value: decoder.typeId };
        })
        decoderOptions = [
            { label: '不使用解码器', value: 0 },
            ...decoderOptions,
            { label: '发明成本为0', value: -1 },
        ];
        return decoderOptions;
    }

    onInventChange = (decoderId) => {
        console.log(decoderId);
        this.props.SettingAction.setDefaultDecoderId(decoderId);
    }

    hideInventFormula = () => {
        this.setState({ showInventFormula: false });
    }

    showInventFormula = () => {
        this.setState({ showInventFormula: true });
    }

    getInventAdditionLabel = () => {
        if (this.state.useCustomizedInventAddition) {
            return 3;
        }
        let inventAdditionOptions = _.find(this.inventAdditionOptions, i => i.value === this.props.SettingReducer.inventAddition);
        if (inventAdditionOptions == null) {
            return 3;
        } else {
            return inventAdditionOptions.label;
        }
    }

    inventAdditionChanged = (value) => {
        if (value === 1) {
            this.props.SettingAction.setInventAddition(1);
            this.setState({ useCustomizedInventAddition: false });
        } else if (value === 2) {
            this.props.SettingAction.setInventAddition(1.46);
            this.setState({ useCustomizedInventAddition: false });
        } else {
            this.setState({ useCustomizedInventAddition: true });
        }
    }

    onCustomInventAdditionChange = (e) => {
        if (isNaN(Number(e))) {
            return;
        };
        this.props.SettingAction.setInventAddition(Number(e) / 100);
    };


    render() {
        return (
            <div>
                <div>
                    <div className='settings-field-name'>默认材料效率</div>
                    <div style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }}>
                            <Slider defaultValue={10} max={10} step={1}
                                value={this.props.SettingReducer.materialEffect}
                                onChange={this.onMaterialEffectChange} />
                        </div>
                        <InputNumber min={0} max={20} style={{ margin: '0 16px', width: '20%' }}
                            value={this.props.SettingReducer.materialEffect}
                            onChange={this.onMaterialEffectChange} />
                    </div>
                </div>
                <Divider style={{ background: ProfitHelper.getTextColor() }} />
                <div>
                    <div className='settings-field-name'>默认解码器</div>
                    <div>
                        <Select options={this.getInventOptions()} style={{ width: '500px' }}
                            value={this.props.SettingReducer.defaultDecoderId} onChange={this.onInventChange} />
                    </div>
                </div>
                <Divider style={{ background: ProfitHelper.getTextColor() }} />
                <div>
                    <div className='settings-field-name'>发明成功率加成</div>
                    <Radio.Group value={this.getInventAdditionLabel()} onChange={(e) => this.inventAdditionChanged(e.target.value)}>
                        <Radio value={1}>无加成 (×100%)</Radio>
                        <Radio value={2}><div style={{ display: 'flex' }}>满技能 (×146%)
                        <div style={{ marginLeft: '5px' }} onMouseEnter={(e) => this.showInventFormula()} onMouseLeave={(e) => this.hideInventFormula()}>
                                <QuestionCircleOutlined />
                                <img src={inventformula} style={{ position: 'absolute', zIndex: 2, display: this.state.showInventFormula ? '' : 'none' }}></img>
                            </div>
                        </div>
                        </Radio>
                        <Radio style={{ height: '30px', lineHeight: '30px' }} value={3}><div style={{ display: 'flex' }}><p>自定义...</p>
                            {this.getInventAdditionLabel() === 3 ?
                                <div className='flex' style={{ height: '30px', bottom: '-2px', position: 'relative', marginLeft: '10px' }}>
                                    <InputNumber min="0" step="0.1" value={this.props.SettingReducer.inventAddition * 100} size='small'
                                        onChange={this.onCustomInventAdditionChange} stringMode />
                                    <div>%</div>
                                </div>
                                : null}</div>
                        </Radio>
                    </Radio.Group>
                </div>
                <Divider style={{ background: ProfitHelper.getTextColor() }} />
                <Tooltip title={'把势力，故事线物品，衍生装备，以及某些t1装备比如跳刀的拷贝图价格计入成本。'} placement='topLeft'>
                    <div className='flex'>
                        <div style={{ width: '50%' }}>计入拷贝图成本</div>
                        <Switch
                            checked={this.props.SettingReducer.considerBlueprintCopyCost == null ? true : this.props.SettingReducer.considerBlueprintCopyCost}
                            checkedChildren='计入'
                            unCheckedChildren='不计入'
                            onChange={() => this.props.SettingAction.toggleConsiderBlueprintCopyCost()}
                            defaultChecked
                        />
                    </div>
                </Tooltip>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        SettingReducer: state.SettingReducer,
        ItemInfoReducer: state.ItemInfoReducer
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        SettingAction: bindActionCreators(SettingAction, dispatch),
        PriceDataAction: bindActionCreators(PriceDataAction, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BlueprintSetting);