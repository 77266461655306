import React from 'react';
import * as PriceDataAction from '../action/PriceDataAction';
import * as RefineAction from '../action/RefineAction';
import RefineItem from './RefineItem';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Button, Input, Spin, Tooltip, Table, Statistic, Row, Col } from 'antd';
import QQUserApi from '../api/QQUserApi';

class CallBack extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        let loginStatus = window.QC.Login.check();
        if (loginStatus == true) {
            window.QC.Login.getMe((openId, accessToken) => {
                console.log('openid = ' + openId);
                console.log('accessToken = ' + accessToken);
                let qquser = { openId: openId, accessToken: accessToken };
                QQUserApi.login(qquser).then(text => {
                    console.log('login result: ' + text);
                    if (window.location.host !== 'localhost') {
                        window.location.href = 'http://www.ceve-industry.cn/#/';
                    } else {
                        window.location.href = 'http://localhost:3000/';
                    }
                })
            })
        }
    }

    componentWillReceiveProps(nextProps) {

    }

    render() {
        return (
            <div style={{ position: 'absolute', left: '50%', top: '50%' }}>
                登录功能施工中，敬请期待...<Spin size='large' />
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        PriceDataReducer: state.PriceDataReducer,
        SettingReducer: state.SettingReducer
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        PriceDataAction: bindActionCreators(PriceDataAction, dispatch),
        RefineAction: bindActionCreators(RefineAction, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CallBack);
