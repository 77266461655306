import React from 'react';
import { Modal, Slider, InputNumber, Radio, Input, Button, Divider, Tabs, Select, Space, Switch, Tooltip } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as PriceDataAction from '../action/PriceDataAction';
import * as BlueprintBuildAction from '../action/BlueprintBuildAction';
import * as SettingAction from '../action/SettingAction';
import * as RefineAction from '../action/RefineAction';
import ProfitHelper from '../common/ProfitHelper';
import RefineSetting from './RefineSetting';
import LocationSetting from './LocationSetting';
import BlueprintSetting from './BlueprintSetting';
import ThemeSetting from './ThemeSetting';
import PriceSetting from './PriceSetting';
import BackupSetting from './BackupSetting';
import BackUpTableSetting from './BackUpTableSetting';
import * as IDUtils from '../common/IDUtils';
import _ from 'lodash';

const { TabPane } = Tabs;

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      materialEffect: 0,
      marketFee: '',
      galaxyManuCost: '',
    };
  }

  componentDidMount() { }

  componentWillReceiveProps(nextProps) {
    if (nextProps.show === true && this.props.show === false) {
      this.setState({
        materialEffect: nextProps.SettingReducer.materialEffect,
        marketFee: nextProps.SettingReducer.marketFee,
        galaxyManuCost: nextProps.SettingReducer.galaxyManuCost,
      });
    }
  }

  onOK = () => {
    if (this.state.galaxyManuCost !== this.props.SettingReducer.galaxyManuCost) {
      this.props.PriceDataAction.setGalaxyManuCost(this.state.galaxyManuCost);
    }
    this.props.onCancel();
  };

  onCancel = () => {
    this.props.onCancel();
  };

  upsertBlueprintBuild = () => {
    let allBlueprintConfigMap = this.props.PriceDataReducer.allBlueprintConfig;
    let allBlueprintConfigObj = {
      keys: [...allBlueprintConfigMap.keys()],
      values: [...allBlueprintConfigMap.values()],
    };
    let blueprintConfigId = this.props.PriceDataReducer.blueprintConfigId;
    if (blueprintConfigId === '未命名') {
      blueprintConfigId = IDUtils.guid();
    }
    let newBlueprintBuild = {
      id: blueprintConfigId,
      build: JSON.stringify(allBlueprintConfigObj)
    };
    this.props.BlueprintBuildAction.upsertBlueprintBuild(newBlueprintBuild);
  };

  render() {
    return (
      <div>
        <Modal
          title='设置'
          visible={this.props.show}
          onOk={this.onOK}
          onCancel={this.onCancel}
          okText={'好'}
          cancelText={'关闭'}
          width={600}
        >
          <Tabs defaultActiveKey="1" type="card" size='large' >
            <TabPane tab="价格" key="1">
              <PriceSetting />
            </TabPane>
            <TabPane tab="蓝图" key="2">
              <BlueprintSetting />
            </TabPane>
            <TabPane tab="设施" key="3">
              <LocationSetting />
            </TabPane>
            <TabPane tab="化矿" key="4">
              <RefineSetting />
              {/* <div style={{ margin: '10px' }}>
                <div>
                  <div className='settings-field-name'>矿物提炼效率</div>
                  <Radio.Group value={this.state.minerRefineRadioValue} onChange={(e) => this.mineralRefineRatioChanged(e.target.value)}>
                    <Radio style={radioStyle} value={1}>0.0极限化矿 (90.6%)</Radio>
                    <Radio style={radioStyle} value={2}>低安极限化矿 (85.8%)</Radio>
                    <Radio style={radioStyle} value={3}>高安极限化矿 (80.9%)</Radio>
                    <Radio style={radioStyle} value={4}>自定义...
          {this.state.minerRefineRadioValue === 4 ?
                        <InputNumber min={0} max={1} style={{ margin: '0 16px', width: '200px' }} step={0.001}
                          value={this.props.SettingReducer.mineralRefinePercent} onChange={(e) => this.mineralRefineCustomValueChanged(e)}
                        /> : null}
                    </Radio>
                  </Radio.Group>
                </div>
                <Divider style={{ background: ProfitHelper.getTextColor() }} />
                <div>
                  <div className='settings-field-name'>装备提炼效率</div>
                  <Radio.Group value={this.state.equipRefineRadioValue} onChange={(e) => this.equipRefineRatioChanged(e.target.value)}>
                    <Radio style={radioStyle} value={1}>碎铁5 (55%)</Radio>
                    <Radio style={radioStyle} value={2}>无技能 (50%)</Radio>
                    <Radio style={radioStyle} value={3}>自定义...
          {this.state.equipRefineRadioValue === 3 ?
                        <InputNumber min={0} max={1} style={{ margin: '0 16px', width: '200px' }} step={0.001}
                          value={this.props.SettingReducer.equipRefinePercent} onChange={(e) => this.equipRefineCustomValueChanged(e)}
                        /> : null}
                    </Radio>
                  </Radio.Group>
                </div>
              </div> */}
            </TabPane>
            <TabPane tab="界面" key="5">
              <ThemeSetting />
            </TabPane>
            {/* <TabPane tab="备份" key="6">
              <ImportExportSettings />
            </TabPane> */}
            {/* <TabPane tab="云备份" key="7">
              <BackupSetting />
            </TabPane> */}
            <TabPane tab="云备份" key="8">
              <BackUpTableSetting />
            </TabPane>
          </Tabs>

        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    PriceDataReducer: state.PriceDataReducer,
    RefineReducer: state.RefineReducer,
    SettingReducer: state.SettingReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    PriceDataAction: bindActionCreators(PriceDataAction, dispatch),
    BlueprintBuildAction: bindActionCreators(BlueprintBuildAction, dispatch),
    RefineAction: bindActionCreators(RefineAction, dispatch),
    SettingAction: bindActionCreators(SettingAction, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
