import * as Utils from '../common/Utils';

export default class ItemInfoApi {
  static getIconSdeById(baseUrl, id) {
    return Utils.getRequest(`${baseUrl}/itemInfo/iconSde/${id}`).then((resp) => resp.json());
  }

  static getTypeIdSdeById(baseUrl, id) {
    return Utils.getRequest(`${baseUrl}/itemInfo/typeIdSde/${id}`).then((resp) => resp.json());
  }

  static getAllDecoders(baseUrl) {
    return Utils.getRequest(`${baseUrl}/itemInfo/allDecoders`).then((resp) => resp.json());
  }
}
