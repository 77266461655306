import * as Utils from "../common/Utils";

export default class MessagBoardApi {
  static createMessage(baseUrl, message, authCodeId, authCodeInput, openId) {
    return Utils.postRequest(`${baseUrl}/messageBoard/createMessage/${authCodeId}/${authCodeInput}/${openId}`, message).then(resp => resp.text());
  }

  static getMessage(baseUrl, pageSize, pageNum) {
  return Utils.getRequest(`${baseUrl}/messageBoard/getMessage/${pageSize}/${pageNum}`).then(resp => resp.json());
}

  static getMessageCount(baseUrl) {
  return Utils.getRequest(`${baseUrl}/messageBoard/messageCount`).then(resp => resp.text());
}

  static getIP(baseUrl) {
  return Utils.getRequest(`${baseUrl}/messageBoard/getIP`).then(resp => resp.text());
}

  static removeMessage(baseUrl, messageId, openId) {
  return Utils.getRequest(`${baseUrl}/messageBoard/removeMessage/${messageId}/${openId}`);
}

  static uploadAttachment(baseUrl, imageBase64) {
  return Utils.postRequest(`${baseUrl}/messageBoard/uploadAttachment`, { imageBase64: imageBase64 }).then(resp => resp.text());;
}

  static getAuthCode(baseUrl, messageId) {
  return Utils.getRequest(`${baseUrl}/messageBoard/authCode`).then(resp => resp.json());;
}
}
