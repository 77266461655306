import React from "react";
import { Tabs, Modal, Table, Checkbox, Button } from "antd";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Utils from '../common/Utils';
import * as PriceDataAction from '../action/PriceDataAction';
import ProfitHelper from '../common/ProfitHelper';
import PriceHistory from './PriceHistory';
import _ from 'lodash';
import moment from 'moment';

const { TabPane } = Tabs;

const columns = [
    {
        title: '数量',
        dataIndex: 'quantity',
        key: 'quantity',
        sorter: Utils.createNumericSorter('quantity'),
        render: (text) => <span>{`${Utils.formatISK(text, 0)} 个`}</span>,
    },
    {
        title: '价格',
        dataIndex: 'price',
        key: 'price',
        sorter: Utils.createNumericSorter('price'),
        render: (text) => <span>{`${Utils.formatISK(text)} ISK`}</span>,
    },
    {
        title: '地点',
        dataIndex: 'position',
        key: 'position',
        sorter: Utils.createStringSorter('position')
    },
    {
        title: '距到期还有',
        dataIndex: 'remaining',
        key: 'remaining',
        sorter: Utils.createStringSorter('remaining')
    }
];

class Orders extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() { }

    componentWillReceiveProps(nextProps) {
        if (nextProps.show !== this.props.show && nextProps.show === true && this.props.typeIdSde.typeId > 0) {
            this.props.PriceDataAction.getOrders(this.props.typeIdSde.typeId);
            this.props.PriceDataAction.getPreviousMonthsMarketHistory(this.props.typeIdSde.typeId);
        }
    }

    onOK = () => {
        this.props.onOK();
    };

    onCancel = () => {
        this.props.onOK();
    };

    getPositionNameById = (id) => {
        if (id === '60003760') {
            return '吉他 IV - 卫星 4 - 加达里海军 组装车间';
        } else if (id === '1013918880239') {
            return '皮尔米特 - 星城';
        } else if (id === '60000843') {
            return '索巴色基 V - 星捷运 自由贸易中心';
        } else if (id === '60006399') {
            return '佩尼尔格曼 III - 星捷运 自由贸易中心';
        } else {
            return id;
        }
    }

    getOrders = (isBuy) => {
        let orders = this.props.PriceDataReducer.orders.orders.filter(o => o.is_buy_order === isBuy)
            .map(order => {
                let createDate = moment(order.issued);
                let duration = order.interval == null ? order.duration : order.interval;
                let finishDate = createDate.add(duration, 'days');
                let now = moment();
                let diffDay = finishDate.diff(now, 'day');
                return {
                    quantity: order.volume_remain,
                    price: order.price,
                    position: this.getPositionNameById(order.location_id),
                    remaining: diffDay + '天'
                };
            });
        return _.sortBy(orders, o => isBuy ? -o.price : o.price);
    }

    render() {
        return (
            <div>
                <Modal title={this.props.typeIdSde.name} visible={this.props.show} onOk={this.onOK} onCancel={this.onCancel}
                    okText={'好'} cancelText={'关闭'} width={'90%'} wrapClassName='orders-modal'>
                    <div style={{ position: 'absolute', right: '100px', fontSize: 'x-large', zIndex: 1, display: 'flex' }}>
                        <img style={{ width: '50px', marginRight: '20px' }} nsrc={`https://image.evepc.163.com/Type/${this.props.typeIdSde.typeId}_64.png`} src={`https://images.evetech.net/types/${this.props.typeIdSde.typeId}/icon`} />
                        {this.props.typeIdSde.name}
                    </div>
                    <Tabs defaultActiveKey="1" type="card" size='large' >
                        <TabPane tab="市场挂单" key="1">
                            <div style={{ boxShadow: '0 0 9px 3px #999', marginTop: '10px' }}>
                                <Table title={() => { return '出售' }} columns={columns} size='small'
                                    pagination={{ pageSize: 10 }} dataSource={this.getOrders(false)} />
                            </div>
                            <div style={{ boxShadow: '0 0 9px 3px #999', marginTop: '10px' }}>
                                <Table title={() => { return '收购' }} columns={columns} size='small'
                                    pagination={{ pageSize: 10 }} dataSource={this.getOrders(true)} />
                            </div>
                        </TabPane>
                        <TabPane tab="价格趋势" key="2">
                            <PriceHistory />
                        </TabPane>
                    </Tabs>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        PriceDataReducer: state.PriceDataReducer,
        RefineReducer: state.RefineReducer,
        SettingReducer: state.SettingReducer
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        PriceDataAction: bindActionCreators(PriceDataAction, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
