import { Divider, Radio, Select, Space, InputNumber, Checkbox, Tooltip } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as PriceDataAction from '../action/PriceDataAction';
import * as SettingAction from '../action/SettingAction';
import ProfitHelper from '../common/ProfitHelper';
import _ from 'lodash';

class LocationSetting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps) { }


    onArchTierChange = (value) => {
        this.props.SettingAction.setArchTier(value);
    };

    onReactArchTierChange = (value) => {
        this.props.SettingAction.setReactArchTier(value);
    };

    getArchTypeValue = () => {
        if (this.props.SettingReducer.archType == null) {
            return 1;
        } else {
            return this.props.SettingReducer.archType;
        }
    }

    getReactArchTypeValue = () => {
        if (this.props.SettingReducer.reactArchType == null) {
            return 1;
        } else {
            return this.props.SettingReducer.reactArchType;
        }
    }

    onArchTypeChange = (value) => {
        this.props.SettingAction.setArchType(value);
    };

    onReactArchTypeChange = (value) => {
        this.props.SettingAction.setReactArchType(value);
    };

    getArchTierValue = () => {
        if (this.props.SettingReducer.archTier == null) {
            return 1;
        } else {
            return this.props.SettingReducer.archTier;
        }
    }

    getReactArchTierValue = () => {
        if (this.props.SettingReducer.reactArchTier == null) {
            return 1;
        } else {
            return this.props.SettingReducer.reactArchTier;
        }
    }

    onArchSecurityChange = (value) => {
        this.props.SettingAction.setArchSecurity(value);
    };

    onReactArchSecurityChange = (value) => {
        this.props.SettingAction.setReactArchSecurity(value);
    };

    getArchSecurityValue = () => {
        if (this.props.SettingReducer.archSecurity == null) {
            return 1;
        } else {
            return this.props.SettingReducer.archSecurity;
        }
    }

    getReactArchSecurityValue = () => {
        if (this.props.SettingReducer.reactArchSecurity == null) {
            return 1;
        } else {
            return this.props.SettingReducer.reactArchSecurity;
        }
    }

    getManuSystemOptions = () => {
        let staticOptions = [
            { label: '自定义...', value: -1 },
            { label: '无星系成本 (0%)', value: 0 }
        ];
        let result = this.props.PriceDataReducer.industryIndices.filter(v => {
            return v.systemName !== '0';
        }).map(v => {
            return {
                label: `${v.systemName} (${Math.round(v.manuCostIndex * 10000) / 100}%)`,
                value: v.systemId
            }
        });
        result = _.orderBy(result, r => r.label);
        return [...staticOptions, ...result];
    }

    getReactSystemOptions = () => {
        let staticOptions = [
            { label: '自定义...', value: -1 },
            { label: '无星系成本 (0%)', value: 0 }
        ];
        let result = this.props.PriceDataReducer.industryIndices.filter(v => {
            return v.systemName !== '0';
        }).map(v => {
            return {
                label: `${v.systemName} (${Math.round(v.reactCostIndex * 10000) / 100}%)`,
                value: v.systemId
            }
        });
        result = _.orderBy(result, r => r.label);
        return [...staticOptions, ...result];
    }

    onManuSystemIdChange = (e) => {
        this.props.SettingAction.setManuSystemId(e);
    }

    onReactSystemIdChange = (e) => {
        this.props.SettingAction.setReactSystemId(e);
    }

    onCustomsFeeChange = (e) => {
        this.props.SettingAction.setCustomsFee(e);
    };

    onTransportFeeChange = (e) => {
        this.props.SettingAction.setTransportFee(e);
    };

    onTransportUnitChange = (e) => {
        this.props.SettingAction.setTransportUnit(e);
    };

    onCustomManuIndexChange = (e) => {
        this.props.SettingAction.setCustomManuIndex(e);
    };

    onCustomReactIndexChange = (e) => {
        this.props.SettingAction.setCustomReactIndex(e);
    };

    onMarketFeeChange = (e) => {
        this.props.PriceDataAction.setMarketFee(e);
    };

    toggleTransportCeil = () => {
        let currentValue = this.props.SettingReducer.transportCeil;
        this.props.SettingAction.setTransportCeil(!currentValue);
    }

    shouldShowTransportCeilSelection = () => {
        return this.props.SettingReducer.transportFee > 0 && this.props.SettingReducer.transportUnit > 0;
    }

    getArchTierOptions = () => {
        let archTypeOption = this.props.SettingReducer.archType == null ? 1 : this.props.SettingReducer.archType;

        if (archTypeOption === 1) {
            //白板建筑
            return [
                { label: '没有插 (0%)', value: 0 },
            ];
        } else if (archTypeOption === 2) {
            //工业建筑
            return [
                { label: '没有插 (0%)', value: 3 },
                { label: 'T1 (2%)', value: 1 },
                { label: 'T2 (2.4%)', value: 2 },
                { label: '图克尔 (3.7%)', value: 4 }
            ]
        } else {
            //势力铁壁
            return [
                { label: '0%', value: 10 },
                { label: '1%', value: 11 },
                { label: '2%', value: 12 },
                { label: '3%', value: 13 },
                { label: '5%', value: 15 }
            ]
        }
    }


    getArchTypeOptions = () => {
        return [
            { label: '工业建筑 (1%)', value: 2 },
            { label: '势力铁壁 (0%)', value: 3 },
            { label: '其他建筑 (0%)', value: 1 }
        ]
    }

    getReactArchTypeOptions = () => {
        return [
            { label: '工业建筑 (0%)', value: 2 },
            { label: '势力铁壁 (0%)', value: 3 },
            { label: '其他建筑 (0%)', value: 1 }
        ]
    }

    getReactArchTierOptions = () => {
        let archTypeOption = this.props.SettingReducer.reactArchType == null ? 1 : this.props.SettingReducer.reactArchType;

        if (archTypeOption === 1) {
            //白板建筑
            return [
                { label: '没有插 (0%)', value: 0 },
            ];
        } else if (archTypeOption === 2) {
            //工业建筑
            return [
                { label: '没有插 (0%)', value: 3 },
                { label: 'T1 (2%)', value: 1 },
                { label: 'T2 (2.4%)', value: 2 },
                { label: '图克尔 (3.7%)', value: 4 }
            ]
        } else {
            //势力铁壁
            return [
                { label: '没有插 (0%)', value: 0 },
            ]
        }
    }

    getArchSecurityOptions = () => {
        return [
            { label: '高安 (×1)', value: 1 },
            { label: '低安 (×1.9)', value: 2 },
            { label: '0.0 (×2.1)', value: 3 },
        ]
    }

    getReactArchSecurityOptions = () => {
        return [
            { label: '高安 (×1)', value: 1 },
            { label: '低安 (×1)', value: 2 },
            { label: '0.0 (×1.1)', value: 3 },
        ]
    }

    render() {
        return (
            <div>
                <div className='flex-column'>
                    <div className='settings-field-name'>星系成本指数</div>
                    <div className='flex'>
                        <div style={{ width: '50%', display: 'flex' }}>
                            <span style={{ marginRight: '20px', position: 'relative', top: '5px' }}>制造星系</span>
                            <span style={{ display: 'flex', flexDirection: 'column' }}>
                                <div>
                                    <Select showSearch style={{ width: 150 }}
                                        filterOption={(input, option) => {
                                            let systemName = option.label.substr(0, option.label.indexOf('(') - 1);
                                            return systemName.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                        }}
                                        value={this.props.SettingReducer.manuSystemId}
                                        onChange={this.onManuSystemIdChange}
                                        options={this.getManuSystemOptions()}></Select>
                                </div>
                                {this.props.SettingReducer.manuSystemId !== -1 ? '' : (
                                    <div style={{ marginTop: '0.5em' }}>
                                        <InputNumber min={0} max={100} defaultValue={0} style={{ width: 150 }} onChange={this.onCustomManuIndexChange} value={this.props.SettingReducer.customManuIndex}
                                            formatter={value => `${value} %`} parser={value => value.replace('%', '').replace(' ', '')} step={0.1} />
                                    </div>
                                )}
                            </span>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <span style={{ marginRight: '20px', position: 'relative', top: '5px' }}>反应星系</span>
                            <span style={{ display: 'flex', flexDirection: 'column' }}>
                                <div>
                                    <Select showSearch style={{ width: 150 }}
                                        filterOption={(input, option) => {
                                            let systemName = option.label.substr(0, option.label.indexOf('(') - 1);
                                            return systemName.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                        }}
                                        value={this.props.SettingReducer.reactSystemId}
                                        onChange={this.onReactSystemIdChange}
                                        options={this.getReactSystemOptions()}></Select>
                                </div>
                                {this.props.SettingReducer.reactSystemId !== -1 ? '' : (
                                    <div style={{ marginTop: '0.5em' }}>
                                        <InputNumber min={0} max={100} defaultValue={0} style={{ width: 150 }} onChange={this.onCustomReactIndexChange} value={this.props.SettingReducer.customReactIndex}
                                            formatter={value => `${value} %`} parser={value => value.replace('%', '').replace(' ', '')} step={0.1} />
                                    </div>
                                )}
                            </span>
                        </div>
                    </div>
                </div>
                <Divider style={{ background: ProfitHelper.getTextColor() }} />
                <div style={{ display: 'flex' }}>
                    <div style={{ width: '50%' }}>
                        <div>制造设施</div>
                        <div className='settings-field-name'>建筑制造加成：{(ProfitHelper.getArchMaterialPercent() * 100).toFixed(4).trimEnd('0')} %</div>
                        <div className='flex' style={{ marginTop: '10px' }}>
                            <div style={{ width: '100px', position: 'relative', top: '5px' }}>建筑类型：</div>
                            <div style={{ flex: 1 }}>
                                <Select style={{ width: '150px' }} value={this.getArchTypeValue()} onChange={this.onArchTypeChange}
                                    options={this.getArchTypeOptions()}></Select>
                            </div>
                        </div>
                        <div className='flex' style={{ marginTop: '10px' }}>
                            <div style={{ width: '100px', position: 'relative', top: '5px' }}>建筑插：</div>
                            <div style={{ flex: 1 }}>
                                <Select style={{ width: '150px' }} value={this.getArchTierValue()} onChange={this.onArchTierChange}
                                    options={this.getArchTierOptions()}></Select>
                            </div>

                        </div>
                        <div className='flex' style={{ marginTop: '10px', display: this.getArchTierValue() === 4 ? 'none' : '' }}>
                            <div style={{ width: '100px', position: 'relative', top: '5px' }}>安全等级：</div>
                            <div style={{ flex: 1 }}>
                                <Select style={{ width: '150px' }} value={this.getArchSecurityValue()} onChange={this.onArchSecurityChange}
                                    options={this.getArchSecurityOptions()}></Select>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>反应设施</div>
                        <div className='settings-field-name'>建筑反应加成：{(ProfitHelper.getReactMaterialPercent() * 100).toFixed(4).trimEnd('0')} % </div>
                        <div className='flex' style={{ marginTop: '10px' }}>
                            <div style={{ width: '100px', position: 'relative', top: '5px' }}>建筑类型：</div>
                            <div style={{ flex: 1 }}>
                                <Select style={{ width: '150px' }} value={this.getReactArchTypeValue()} onChange={this.onReactArchTypeChange}
                                    options={this.getReactArchTypeOptions()}></Select>
                            </div>
                        </div>
                        <div className='flex' style={{ marginTop: '10px' }}>
                            <div style={{ width: '100px', position: 'relative', top: '5px' }}>建筑插：</div>
                            <div style={{ flex: 1 }}>
                                <Select style={{ width: '150px' }} value={this.getReactArchTierValue()} onChange={this.onReactArchTierChange}
                                    options={this.getReactArchTierOptions()}></Select>
                            </div>
                        </div>
                        <div className='flex' style={{ marginTop: '10px', display: this.getReactArchTierValue() === 4 ? 'none' : '' }}>
                            <div style={{ width: '100px', position: 'relative', top: '5px' }}>安全等级：</div>
                            <div style={{ flex: 1 }}>
                                <Select style={{ width: '150px' }} value={this.getReactArchSecurityValue()} onChange={this.onReactArchSecurityChange}
                                    options={this.getReactArchSecurityOptions()}></Select>
                            </div>
                        </div>
                    </div>
                </div>
                <Divider style={{ background: ProfitHelper.getTextColor() }} />
                <div style={{ display: 'flex' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '50%' }}>
                        <div className='settings-field-name' style={{ width: '50px' }}>海关税</div>
                        <div className='flex' style={{ width: '100px', height: '30px', bottom: '-5px', position: 'relative', marginLeft: '10px' }}>
                            <InputNumber min="0" max="100" step="0.1" value={this.props.SettingReducer.customsFee}
                                onChange={this.onCustomsFeeChange} stringMode />
                        </div>
                        <div className='settings-field-name'>%</div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div className='settings-field-name' style={{ width: '50px' }}>市场税</div>
                        <div className='flex' style={{ width: '100px', height: '30px', bottom: '-5px', position: 'relative', marginLeft: '10px' }}>
                            <InputNumber min="0" max="100" step="0.1" value={this.props.SettingReducer.marketFee}
                                onChange={this.onMarketFeeChange} stringMode />
                        </div>
                        <div className='settings-field-name'>%</div>
                    </div>
                </div>
                <div style={{ display: 'flex' }}>
                    <Tooltip title={'运输原料的费用。不包含运输产物的费用。'}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div className='settings-field-name'>原料运费</div>
                            <div className='flex' style={{ width: '150px', height: '30px', bottom: '-5px', position: 'relative', marginLeft: '10px' }}>
                                <InputNumber style={{ width: '100%' }} min="0" step="1" value={this.props.SettingReducer.transportFee}
                                    onChange={this.onTransportFeeChange} stringMode />
                            </div>
                            <div className='settings-field-name'>&nbsp;&nbsp;ISK 每</div>
                            <div className='flex' style={{ width: '100px', height: '30px', bottom: '-5px', position: 'relative', marginLeft: '10px' }}>
                                <InputNumber style={{ width: '100%' }} min="0" step="1" value={this.props.SettingReducer.transportUnit}
                                    onChange={this.onTransportUnitChange} stringMode />
                            </div>
                            <div className='settings-field-name'>&nbsp;&nbsp;m³</div>
                        </div>
                    </Tooltip>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        SettingReducer: state.SettingReducer,
        PriceDataReducer: state.PriceDataReducer
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        PriceDataAction: bindActionCreators(PriceDataAction, dispatch),
        SettingAction: bindActionCreators(SettingAction, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationSetting);
