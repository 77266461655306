import React from "react";
import * as SettingAction from '../action/SettingAction';
import * as PriceDataAction from '../action/PriceDataAction';
import { initialState } from '../reducer/SettingReducer';
import CustomPriceView from './CustomPriceView';
import { Modal, Slider, InputNumber, Radio, Input, Button, Divider, Tabs, Select, Space, Switch, Tooltip } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import copy from "copy-to-clipboard";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import * as Utils from '../common/Utils';
import { Line } from '@ant-design/plots';
import { DualAxes } from '@ant-design/plots';
const { TextArea } = Input;

class PriceHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showCustomPriceView: false
        }
    }

    componentDidMount() { }

    getTableConfig = () => {
        return {
            data: [this.props.PriceDataReducer.previousMonthsMarketHistory, this.props.PriceDataReducer.previousMonthsMarketHistory],
            padding: 'auto',
            xField: 'date',
            yField: ['average', 'volume'],
            meta: {
                average: {
                    alias: '平均成交价',
                    formatter: (value) => { return Utils.formatISK(value, 2) + ' ISK' }
                },
                volume: {
                    alias: '成交量',
                    formatter: (value) => { return value + ' 件' }
                }
            },
            geometryOptions: [
                {
                    geometry: 'line',
                    color: 'skyblue',
                },
                {
                    geometry: 'line',
                    color: 'orange',
                },
            ],
            // xAxis: {
            //     tickCount: 5,
            // },
            // slider: {
            //     start: 0.5,
            //     end: 1,
            // },
        };
    }

    render() {
        return (
            <div>
                {/* <Line {...this.tableConfig} /> */}
                <DualAxes {...this.getTableConfig()} />
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        SettingReducer: state.SettingReducer,
        PriceDataReducer: state.PriceDataReducer,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        SettingAction: bindActionCreators(SettingAction, dispatch),
        PriceDataAction: bindActionCreators(PriceDataAction, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PriceHistory);