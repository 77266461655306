import React from 'react';
import * as PriceDataAction from '../action/PriceDataAction';
import * as ItemInfoAction from '../action/ItemInfoAction';
import ProfitPercentRank from './ProfitPercentRank';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Spin, Tabs } from 'antd';
import { RiseOutlined } from '@ant-design/icons';
import moment from 'moment';
import _ from 'lodash';
import * as XLSX from 'xlsx';
import * as Utils from '../common/Utils';
import * as ExcelUtil from '../common/ExcelUtil';
import Spreadsheet from "x-data-spreadsheet";
import zhCN from 'x-data-spreadsheet/src/locale/zh-cn';


class RankSpreadSheet extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.showSpreadSheet();
    }

    componentWillReceiveProps(nextProps) {
        if (!_.isEqual(this.props.filteredRankData, nextProps.filteredRankData)) {
            let filteredRankData = nextProps.filteredRankData;
            let excel = ExcelUtil.getExcel(filteredRankData);
            if (this.spreadSheet != null) {
                this.spreadSheet.loadData(ExcelUtil.stox(excel)) // load data
                    .change(data => {
                        // save data to db
                    });

                // data validation
                this.spreadSheet.validate();
            }
        }
    }

    showSpreadSheet = () => {
        const option = {
            mode: 'edit', // edit | read
            showToolbar: true, // 顶部工具栏
            showGrid: true,
            showContextmenu: true, // 切换右键菜单显示状态
            showBottomBar: true,  // 底部工具栏
            view: {
                // height: () => 570,
                height: () => document.documentElement.clientHeight - 150,
                width: () => document.documentElement.clientWidth - 300,
                // width: () => document.getElementsByClassName('spreadsheet-box')[0].clientWidth - 300,
            },
            row: {
                len: 3500,
                height: 25,
            },
            col: {
                len: 26,
                width: 100,
                indexWidth: 60,
                minWidth: 60,
            },
            style: {
                bgcolor: '#ffffff',
                align: 'left',
                valign: 'middle',
                textwrap: false,
                strike: false,
                underline: false,
                color: '#0a0a0a',
                font: {
                    name: 'Helvetica',
                    size: 10,
                    bold: false,
                    italic: false,
                },
            },
        }

        let filteredRankData = this.props.filteredRankData;
        let excel = ExcelUtil.getExcel(filteredRankData);
        Spreadsheet.locale('zh-cn', zhCN);
        this.spreadSheet = new Spreadsheet("#x-spreadsheet-demo", option)
            .loadData(ExcelUtil.stox(excel)) // load data
            .change(data => {
                // save data to db
            });

        // data validation
        this.spreadSheet.validate();
    }

    render() {
        return <div>
            <div id="x-spreadsheet-demo"></div></div>;
    }
}

const mapStateToProps = (state) => {
    return {
        PriceDataReducer: state.PriceDataReducer,
        ItemInfoReducer: state.ItemInfoReducer,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        PriceDataAction: bindActionCreators(PriceDataAction, dispatch),
        ItemInfoAction: bindActionCreators(ItemInfoAction, dispatch),
    };
};


function* g() {
    yield fetch("url1");
    yield fetch("url2");
}

export default connect(mapStateToProps, mapDispatchToProps)(RankSpreadSheet);
