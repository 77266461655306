import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConfigProvider, theme } from 'antd';
import { createStore, applyMiddleware, compose } from "redux";
import { HashRouter, Route } from "react-router-dom";
import { persistStore, persistReducer } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import store from './store.js';

const persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <HashRouter>
          <App />
      </HashRouter>
    </PersistGate>
    {/* <App /> */}
  </Provider>
  ,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
