import React from 'react';
import * as PriceDataAction from '../action/PriceDataAction';
import * as ItemInfoAction from '../action/ItemInfoAction';
import ProfitPercentRank from './ProfitPercentRank';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Spin, Tabs } from 'antd';
import { RiseOutlined } from '@ant-design/icons';
import jingli from "../resource/jingli-optimize.gif";
import moment from 'moment';
import _ from 'lodash';
import * as Utils from '../common/Utils';
import Initializer from '../common/Initializer';

const { TabPane } = Tabs;

class Rank extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    Initializer.initPriceData();
  }

  componentWillReceiveProps(nextProps) { }

  isReady = () => {
    return !_.isEmpty(this.props.PriceDataReducer.allBlueprint) && !_.isEmpty(this.props.PriceDataReducer.allTypeIdSde)
      && !_.isEmpty(this.props.PriceDataReducer.allMarketDump) && !_.isEmpty(this.props.PriceDataReducer.allMarketHistory)
      && !_.isEmpty(this.props.PriceDataReducer.industryIndices);
  }

  renderSpinOrTable = () => {
    if (!this.isReady) {
      return (
        <div style={{position:'absolute', display: 'flex', left:'calc(50% - 200px)', top: 'calc(50% - 60px)'}}>
          <div style={{ fontSize: 'xx-large', alignItems: 'center', marginRight: '50px', display: 'flex' }}>下载蓝图数据...</div>
          <div><img src={jingli} style={{ width: '200px' }} /></div>
        </div>
      );
    } else {
      return <ProfitPercentRank />;
    }
  };

  render() {
    return <div style={{ margin: '0px 10px', paddingBottom: '20px' }}>{this.renderSpinOrTable()}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    PriceDataReducer: state.PriceDataReducer,
    ItemInfoReducer: state.ItemInfoReducer,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    PriceDataAction: bindActionCreators(PriceDataAction, dispatch),
    ItemInfoAction: bindActionCreators(ItemInfoAction, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Rank);
