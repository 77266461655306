import * as ActionTypes from './ActionTypes';
import ItemInfoApi from '../api/ItemInfoApi';
import * as UrlHelper from './UrlHelper';
import ProfitHelper from '../common/ProfitHelper';

const host = UrlHelper.getHost();
const backendHostAndPort = `http://${host}:8080`;

export const getTypeIdSdeById = (id) => {
  return function (dispatch, getState) {
    return ItemInfoApi.getTypeIdSdeById(backendHostAndPort, id)
      .then((json) => {
        return dispatch({ type: ActionTypes.GET_TYPEID_SDE_SUCCESS, data: json });
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const getIconSdeById = (id) => {
  return function (dispatch, getState) {
    return ItemInfoApi.getIconSdeById(backendHostAndPort, id)
      .then((json) => {
        return dispatch({ type: ActionTypes.GET_ICON_SDE_SUCCESS, data: json });
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const getAllDecoders = () => {
  return function (dispatch, getState) {
    return ItemInfoApi.getAllDecoders(backendHostAndPort)
      .then((json) => {
        json.forEach(decoder => {
          let typeIdSde = ProfitHelper.getTypeIdSdeById(decoder.typeId);
          decoder.name = typeIdSde.name;
        })
        return dispatch({ type: ActionTypes.GET_ALL_DECODERS_SUCCESS, data: json });
      })
      .catch((err) => {
        throw err;
      });
  };
};

