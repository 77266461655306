import React from "react";
import { Table, Switch, Tooltip, Menu, Button, Dropdown, message, InputNumber, Radio } from "antd";
import { PlayCircleOutlined, DownloadOutlined, UndoOutlined, LockFilled, PlusSquareOutlined, MinusSquareOutlined, CopyOutlined, FileExcelOutlined, AlipayOutlined, ToolOutlined } from "@ant-design/icons";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import * as SettingAction from '../action/SettingAction';
import * as Utils from '../common/Utils';
import shoubing from "../resource/shoubing.jpg";
import ProfitHelper from '../common/ProfitHelper';
import copy from "copy-to-clipboard";
import * as IDUtils from '../common/IDUtils';

const { SubMenu } = Menu;

class IndustryPlan extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    columns = [
        {
            title: '序号',
            dataIndex: 'indexNumber',
            key: 'indexNumber',
            sorter: Utils.createNumericSorter('indexNumber'),
            render: (text, record, index) => {
                return (record.indexNumber + 1);
            },
        },
        {
            title: '',
            dataIndex: 'typeId',
            sorter: Utils.createNumericSorter('typeId'),
            render: (text, record, index) => {
                return (<div style={{ display: 'flex', top: '5px' }}><img style={{ width: '25px' }} src={ProfitHelper.getTypeImage(record.key)}></img></div>)
            },
        },
        {
            title: '产物名称',
            dataIndex: 'name',
            key: 'name',
            sorter: Utils.createStringSorter('name'),
        },
        {
            title: '产物类别',
            dataIndex: 'category',
            key: 'category',
            sorter: Utils.createStringSorter('category'),
        },
        {
            title: '数量',
            dataIndex: 'quantity',
            key: 'quantity',
            sorter: Utils.createNumericSorter('quantity'),
            render: (text, record, index) => {
                if (record.quantity === record.ceilQuantity) {
                    return (`${Utils.formatISK(record.quantity, 0)}`)
                }
                return (`${Utils.formatISK(record.quantity, 0)} (${Utils.formatISK(record.ceilQuantity, 0)})`)
            },
            align: 'right',
        },
        {
            title: '流程',
            dataIndex: 'quantity',
            key: 'quantity',
            sorter: Utils.createNumericSorter('quantity'),
            render: (text, record, index) => {
                let processCount = this.getProcessCount(record.key, record.quantity);
                let ceilProcessCount = this.getProcessCount(record.key, record.ceilQuantity);
                if (processCount === ceilProcessCount) {
                    return (`${Utils.formatISK(processCount, 0)}`)
                }
                return (`${Utils.formatISK(processCount, 0)} (${Utils.formatISK(ceilProcessCount, 0)})`)
            },
            align: 'right',
        },
        {
            title: '价格',
            dataIndex: 'price',
            key: 'price',
            sorter: Utils.createNumericSorter('price'),
            render: (text, record, index) => {
                return (`${Utils.formatISK(record.price)} ISK`)
            },
            align: 'right',
        },
        {
            title: '利润率',
            dataIndex: 'profitPercent',
            sorter: Utils.createNumericSorter('profitPercent'),
            render: (value, record) => {
                return Utils.toPercentString(value);
            },
            align: 'right',
        },
        {
            title: '利润',
            dataIndex: 'profitPercent',
            key: 'profitPercent',
            sorter: Utils.createNumericSorter('profitPercent'),
            render: (value, record) => {
                return (`${Utils.formatISK(record.quantity * (record.price - record.oneProductCost))} ISK`);
            },
            align: 'right',
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            sorter: Utils.createStringSorter('status'),
            render: (value, record) => {
                if (record.status === '不可启动') {
                    return (<div>
                        <Tooltip title={this.generateStatusTooltip(record)}>
                            <div>
                                {record.status}
                            </div>
                        </Tooltip>
                    </div>)
                }
                return (record.status);
            },
        },
        {
            title: '操作',
            dataIndex: 'typeId',
            key: 'typeId',
            width: 100,
            sorter: Utils.createNumericSorter('typeId'),
            render: (typeId, record) => {
                return (
                    <div style={{ display: 'flex' }}>
                        <div style={{ marginLeft: '5px', display: this.canStart(record) ? '' : 'none' }}>
                            <Tooltip title={'启动生产'}>
                                <Button type="default" icon={<PlayCircleOutlined />} style={{ borderRadius: '2px' }} onClick={() => this.startPlan(record)} />
                            </Tooltip>
                        </div>
                        <div style={{ marginLeft: '5px', display: this.canFinish(record) ? '' : 'none' }}>
                            <Tooltip title={'完成生产'}>
                                <Button type="default" icon={<DownloadOutlined />} style={{ borderRadius: '2px' }} onClick={() => this.finishPlan(record)} />
                            </Tooltip>
                        </div>
                        <div style={{ marginLeft: '5px', display: this.canRemove(record) ? '' : 'none' }}>
                            <Tooltip title={'撤销生产'}>
                                <Button type="default" icon={<UndoOutlined />} style={{ borderRadius: '2px' }} onClick={() => this.removePlan(record)} />
                            </Tooltip>
                        </div>
                    </div>
                )
            }
        }
    ]

    getProcessCount = (typeId, quantity) => {
        let blueprint = ProfitHelper.getBlueprintByProductId(typeId);
        if (blueprint == null) {
            return 0;
        }
        let quantityPerProcess = blueprint.activities.manufacturing.products[0].quantity;
        return Math.ceil(quantity / quantityPerProcess);
    }

    startPlan = (plan) => {
        this.props.SettingAction.setPlanStatus({ stepIndex: plan.targetItem.stepIndex, key: plan.targetItem.key, status: '生产中' });
    }

    finishPlan = (plan) => {
        this.props.SettingAction.setPlanStatus({ stepIndex: plan.targetItem.stepIndex, key: plan.targetItem.key, status: '已完成' });
    }

    removePlan = (plan) => {
        this.props.SettingAction.removePlanStatus({ stepIndex: plan.targetItem.stepIndex, key: plan.targetItem.key });
    }

    canStart = (plan) => {
        return plan.status === '可以启动';
    }

    canFinish = (plan) => {
        return plan.status === '生产中';
    }

    canRemove = (plan) => {
        return _.find(this.props.plans, p => p.key === plan.targetItem.key && p.stepIndex === plan.targetItem.stepIndex) != null;
    }

    expandedRowRender = (plan) => {
        const columns = [
            {
                title: '',
                dataIndex: 'typeId',
                sorter: Utils.createNumericSorter('typeId'),
                render: (text, record, index) => {
                    return (<div style={{ display: 'flex', top: '5px' }}><img style={{ width: '25px' }} src={ProfitHelper.getTypeImage(record.key)}></img></div>)
                },
            },
            {
                title: '原料名称',
                dataIndex: 'name',
                key: 'name',
                sorter: Utils.createStringSorter('name'),
            },
            {
                title: '原料类别',
                dataIndex: 'category',
                key: 'category',
                sorter: Utils.createStringSorter('category'),
            },
            {
                title: '原料数量',
                dataIndex: 'quantity',
                key: 'quantity',
                sorter: Utils.createNumericSorter('quantity'),
                render: (text, record, index) => {
                    if(record.quantity === record.ceilQuantity){
                        return (`${Utils.formatISK(record.quantity, 0)}`)
                    }else{
                        return (`${Utils.formatISK(record.quantity, 0)} (${Utils.formatISK(record.ceilQuantity, 0)})`)
                    }
                },
                align: 'right',
            },
            {
                title: '单价',
                dataIndex: 'price',
                key: 'price',
                sorter: Utils.createNumericSorter('price'),
                render: (text, record, index) => {
                    return (`${Utils.formatISK(record.price)} ISK`)
                },
                align: 'right',
            },
            {
                title: '总价',
                dataIndex: 'totalPrice',
                key: 'totalPrice',
                sorter: Utils.createNumericSorter('totalPrice'),
                render: (text, record, index) => {
                    return (`${Utils.formatISK(record.totalPrice)} ISK`)
                },
                align: 'right',
            },
        ];
        return <Table columns={columns} dataSource={this.getPlanResources(plan)} pagination={false} />;
    };

    componentDidMount() { }

    getPlanResources = (plan) => {
        let blueprint = ProfitHelper.getBlueprintByProductId(plan.key);
        let productQuantityPerBatch = blueprint.activities.manufacturing.products[0].quantity;
        let materials = blueprint.activities.manufacturing.materials;

        let ceilProcessCount = this.getProcessCount(plan.key, plan.ceilQuantity);

        let result = [];
        for (let material of materials) {
            let row = {};
            let typeIdSde = ProfitHelper.getTypeIdSdeById(material.typeId);
            row.key = typeIdSde.typeId;
            row.name = typeIdSde.name;
            row.category = ProfitHelper.getCategory(material.typeId);
            row.quantity = plan.quantity * material.quantity / productQuantityPerBatch;
            row.ceilQuantity = ceilProcessCount * material.quantity;
            let marketDump = ProfitHelper.getMarketDumpById(material.typeId);
            row.price = _.isEmpty(marketDump) ? 0 : marketDump.price;
            row.totalPrice = row.price * row.quantity;
            result.push(row);
        }
        result = _.sortBy(result, r => r.key);
        return result;
    }

    generateProfit = (plan) => {
        let blueprint = ProfitHelper.getBlueprintByProductId(plan.key);
        if (_.isEmpty(blueprint)) {
            return {};
        }
        let productQuantityPerBatch = blueprint.activities.manufacturing.products[0].quantity;
        let blueprintSetting = {blueprintTypeId: blueprint.blueprintTypeId, blueprint: blueprint, productQuantity: 1, blueprintCount: 1, decoderId: 0 };
        let [dataSources, context] = ProfitHelper.getResourceList([blueprintSetting], [], new Map());
        plan.profitPercent = context.profitPercent;
        plan.oneProductCost = context.singleCost;
    }

    getLastSameItem = (targetItem) => {
        let result = targetItem;
        while (result.sourceItems.length == 1 && result.sourceItems[0].key === result.key) {
            result = result.sourceItems[0];
        }
        return result;
    }

    getPlans = () => {
        let plans = [];
        for (let processStep of this.getProcessList()) {
            for (let targetItem of processStep) {
                if (!targetItem.isSelfProducted) {
                    continue;
                }

                if (targetItem.sourceItems.length === 1 && targetItem.sourceItems[0].key === targetItem.key) {
                    continue;
                }
                let plan = { ...targetItem };
                plan.targetItem = targetItem;
                let marketDump = ProfitHelper.getMarketDumpById(targetItem.key, { isResource: false });
                plan.price = marketDump == null ? 0 : marketDump.price;
                plan.status = this.generateStatus(plan);
                this.generateProfit(plan);
                plans.push(plan);
            }
        }
        plans = _.reverse(plans)
        for (let plan of plans) {
            plan.indexNumber = plans.indexOf(plan);
        }
        return plans;
    }

    alreadyProducted = (item) => {
        let planSetting = _.find(this.props.plans, p => p.key === item.key && p.stepIndex === item.stepIndex);
        if (planSetting == null) {
            return false;
        }
        return planSetting.status === '已完成';
    }

    generateStatusTooltip = (plan) => {
        let dependsOn = [];
        for (let sourceItem of plan.targetItem.sourceItems) {
            let lastSourceItem = this.getLastSameItem(sourceItem);
            if (lastSourceItem.isSelfProducted && !this.alreadyProducted(lastSourceItem)) {
                dependsOn.push(lastSourceItem);
            }
        }

        let dependsOnString = '';
        for (let depends of dependsOn) {
            dependsOnString += depends.name;
            dependsOnString += ',';
        }
        dependsOnString = dependsOnString.substr(0, dependsOnString.length - 1);
        return `需等待${dependsOnString}完成后才能开始制造。`
    }

    generateStatus = (plan) => {
        for (let planSetting of this.props.plans) {
            if (planSetting.key === plan.targetItem.key && planSetting.stepIndex === plan.targetItem.stepIndex) {
                return planSetting.status;
            }
        }
        let canStart = true;
        for (let sourceItem of plan.targetItem.sourceItems) {
            let lastSourceItem = this.getLastSameItem(sourceItem);
            if (lastSourceItem.isSelfProducted && !this.alreadyProducted(lastSourceItem)) {
                canStart = false;
                break;
            }
        }
        if (canStart) {
            return '可以启动';
        } else {
            return '不可启动';
        }
    }

    getProcessList = () => {
        if (_.isEmpty(this.props.context.processList)) {
            return this.props.context.processList;
        }

        let lastProcessStep = this.props.blueprintSettings.map(bs => {
            let product = ProfitHelper.getProductByBlueprintId(bs.blueprintTypeId);
            let blueprint = ProfitHelper.getBlueprintByTypeId(bs.blueprintTypeId);
            let quantityPerProcess = blueprint.activities.manufacturing.products[0].quantity;
            let quantity = bs.blueprintCount * bs.productQuantity * quantityPerProcess;
            let sourceItems = [];
            for (let item of this.props.context.processList[0]) {
                for (let material of blueprint.activities.manufacturing.materials) {
                    if (item.key === material.typeId) {
                        sourceItems.push(item);
                        break;
                    }
                }
            }
            return {
                key: product.typeId,
                name: product.name,
                category: ProfitHelper.getCategory(product.typeId),
                quantity: quantity,
                ceilQuantity: quantity,
                sourceItems: sourceItems,
                targetItems: [],
                isSelfProducted: true
            }
        });

        return [lastProcessStep, ...this.props.context.processList];
    }


    clickMenu = (e) => {
        console.log(e);
        if (e.key === 'removeAll') {
            this.props.SettingAction.resetPlanStatus();
        }
    }

    menu = () => {
        return (<Menu onClick={(e) => this.clickMenu(e)} mode="vertical"  >
            <Menu.Item key='removeAll' icon={<UndoOutlined style={{ fontSize: '1.3em' }} />}>重置所有生产线</Menu.Item>
        </Menu >
        );
    }

    render() {
        console.log(this.props.blueprintSettings);
        console.log(this.props.context);
        return (
            <Dropdown overlay={this.menu()} trigger={['contextMenu']}>
                <div onClick={(e) => e.preventDefault()} >
                    <div onClick={(e) => e.preventDefault()} >
                        <Table size="small"
                            columns={this.columns} dataSource={this.getPlans()}
                            expandable={{
                                expandedRowRender: this.expandedRowRender,
                            }}
                            pagination={{ showSizeChanger: true, pageSize: 500 }}
                        />
                    </div>
                </div>
            </Dropdown>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        PriceDataReducer: state.PriceDataReducer,
        SettingReducer: state.SettingReducer,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        SettingAction: bindActionCreators(SettingAction, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(IndustryPlan);