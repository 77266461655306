import * as XLSX from 'xlsx';
import store from '../store';
import ProfitHelper from './ProfitHelper';
import _ from 'lodash';

const t3List = [
    {
        ancientName: '毁坏的船体舱段',
        ancientId: 30754,
        probability: 0.14,
        products: [29985, 29991, 29989, 29987],
        productQuantity: 3,
        materials: [
            { typeId: 20412, quantity: 3 },
            { typeId: 20424, quantity: 3 }
        ]
    },
    {
        ancientName: '毁坏的能源核心',
        ancientId: 30588,
        probability: 0.14,
        productQuantity: 3,
        products: [45717, 45718, 45719, 45720, 45721, 45722, 45723, 45724, 45725, 45726, 45727, 45728],
        materials: [
            { typeId: 20115, quantity: 3 },
            { typeId: 20414, quantity: 3 }
        ]
    },
    {
        ancientName: '毁坏的推进器',
        ancientId: 30562,
        probability: 0.14,
        productQuantity: 3,
        products: [45705, 45706, 45707, 45708, 45709, 45710, 45711, 45712, 45713, 45714, 45715, 45716],
        materials: [
            { typeId: 20114, quantity: 3 },
            { typeId: 20420, quantity: 3 }
        ]
    },
    {
        ancientName: '毁坏的武器子程序',
        ancientId: 30633,
        probability: 0.14,
        productQuantity: 3,
        products: [45693, 45694, 45695, 45696, 45697, 45698, 45699, 45700, 45702, 45703, 45704],
        materials: [
            { typeId: 20412, quantity: 3 },
            { typeId: 20425, quantity: 3 }
        ]
    },
    {
        ancientName: '毁坏的小型冬眠者船体',
        ancientId: 34416,
        probability: 0.14,
        productQuantity: 3,
        products: [34318, 34563, 34829, 35684],
        materials: [
            { typeId: 20412, quantity: 3 },
            { typeId: 20424, quantity: 3 }
        ]
    },
    {
        ancientName: '毁坏的装甲纳米机器人',
        ancientId: 30618,
        probability: 0.14,
        productQuantity: 3,
        products: [45681, 45682, 45683, 45684, 45685, 45686, 45687, 45688, 45689, 45690, 45691, 45692],
        materials: [
            { typeId: 11496, quantity: 3 },
            { typeId: 20171, quantity: 3 }
        ]
    },
    {
        ancientName: '失灵的船体舱段',
        ancientId: 30753,
        probability: 0.21,
        products: [29985, 29991, 29989, 29987],
        productQuantity: 10,
        materials: [
            { typeId: 20412, quantity: 3 },
            { typeId: 20424, quantity: 3 }
        ]
    },
    {
        ancientName: '失灵的能源核心',
        ancientId: 30586,
        probability: 0.21,
        productQuantity: 10,
        products: [45717, 45718, 45719, 45720, 45721, 45722, 45723, 45724, 45725, 45726, 45727, 45728],
        materials: [
            { typeId: 20115, quantity: 3 },
            { typeId: 20414, quantity: 3 }
        ]
    },
    {
        ancientName: '失灵的推进器',
        ancientId: 30558,
        probability: 0.21,
        productQuantity: 10,
        products: [45705, 45706, 45707, 45708, 45709, 45710, 45711, 45712, 45713, 45714, 45715, 45716],
        materials: [
            { typeId: 20114, quantity: 3 },
            { typeId: 20420, quantity: 3 }
        ]
    },
    {
        ancientName: '失灵的武器子程序',
        ancientId: 30632,
        probability: 0.21,
        productQuantity: 10,
        products: [45693, 45694, 45695, 45696, 45697, 45698, 45699, 45700, 45702, 45703, 45704],
        materials: [
            { typeId: 20412, quantity: 3 },
            { typeId: 20425, quantity: 3 }
        ]
    },
    {
        ancientName: '失灵的小型船体舱段',
        ancientId: 34414,
        probability: 0.21,
        productQuantity: 10,
        products: [34318, 34563, 34829, 35684],
        materials: [
            { typeId: 20412, quantity: 3 },
            { typeId: 20424, quantity: 3 }
        ]
    },
    {
        ancientName: '失灵的装甲纳米机器人',
        ancientId: 30615,
        probability: 0.21,
        productQuantity: 10,
        products: [45681, 45682, 45683, 45684, 45685, 45686, 45687, 45688, 45689, 45690, 45691, 45692],
        materials: [
            { typeId: 11496, quantity: 3 },
            { typeId: 20171, quantity: 3 }
        ]
    },
    {
        ancientName: '完整的船体舱段',
        ancientId: 30752,
        probability: 0.26,
        products: [29985, 29991, 29989, 29987],
        productQuantity: 20,
        materials: [
            { typeId: 20412, quantity: 3 },
            { typeId: 20424, quantity: 3 }
        ]
    },
    {
        ancientName: '完整的能源核心',
        ancientId: 30582,
        probability: 0.26,
        productQuantity: 20,
        products: [45717, 45718, 45719, 45720, 45721, 45722, 45723, 45724, 45725, 45726, 45727, 45728],
        materials: [
            { typeId: 20115, quantity: 3 },
            { typeId: 20414, quantity: 3 }
        ]
    },
    {
        ancientName: '完整的推进器',
        ancientId: 30187,
        probability: 0.26,
        productQuantity: 20,
        products: [45705, 45706, 45707, 45708, 45709, 45710, 45711, 45712, 45713, 45714, 45715, 45716],
        materials: [
            { typeId: 20114, quantity: 3 },
            { typeId: 20420, quantity: 3 }
        ]
    },
    {
        ancientName: '完整的武器子程序',
        ancientId: 30628,
        probability: 0.26,
        productQuantity: 20,
        products: [45693, 45694, 45695, 45696, 45697, 45698, 45699, 45700, 45702, 45703, 45704],
        materials: [
            { typeId: 20412, quantity: 3 },
            { typeId: 20425, quantity: 3 }
        ]
    },
    {
        ancientName: '完整的小型船体舱段',
        ancientId: 34412,
        probability: 0.26,
        productQuantity: 20,
        products: [34318, 34563, 34829, 35684],
        materials: [
            { typeId: 20412, quantity: 3 },
            { typeId: 20424, quantity: 3 }
        ]
    },
    {
        ancientName: '完整的装甲纳米机器人',
        ancientId: 30614,
        probability: 0.26,
        productQuantity: 20,
        products: [45681, 45682, 45683, 45684, 45685, 45686, 45687, 45688, 45689, 45690, 45691, 45692],
        materials: [
            { typeId: 11496, quantity: 3 },
            { typeId: 20171, quantity: 3 }
        ]
    },
]

export function getT3List() {
    return t3List;
}

export function isT3Blueprint(bid) {
    return _.find(t3List, t => t.products.includes(bid)) != null;
}

export function generateT3QuantityAndMaterialEffect(blueprintTypeId, ancientId, decoder) {
    let availableAncient = t3List.filter(a => a.products.includes(blueprintTypeId));
    let ancient = availableAncient[0];
    if (ancientId != null) {
        ancient = _.find(t3List, a => a.ancientId === ancientId);
    }
    let quantity = ancient.productQuantity;
    let materialEffect = 2;

    if (decoder != null) {
        quantity += decoder.quantity;
        materialEffect += decoder.materialEffect;
    }
    return { quantity, materialEffect };
}

export function generateT3BlueprintSetting(blueprintSetting) {
    let availableAncient = t3List.filter(a => a.products.includes(blueprintSetting.blueprintTypeId));
    let ancient = availableAncient[0];
    if (blueprintSetting.ancientId != null) {
        ancient = _.find(t3List, a => a.ancientId === blueprintSetting.ancientId);
    }
    let quantity = ancient.productQuantity;
    let materialEffect = 2;

    let decoder = ProfitHelper.getDecoderById(blueprintSetting.decoderId);
    if (decoder != null) {
        quantity += decoder.quantity;
        materialEffect += decoder.materialEffect;
    }
    blueprintSetting.productQuantity = quantity;
    blueprintSetting.materialEffect = materialEffect;
}


export function getAncientById(ancientId) {
    return _.find(t3List, a => a.ancientId === ancientId);
}

export function getAncientsByBlueprintId(bid) {
    return _.filter(t3List, a => a.products.includes(bid));
}