import React from "react";
import { Layout, Modal, Input, Divider, Button } from "antd";
import { LineChartOutlined } from "@ant-design/icons";
import tesila from "../resource/tesila.PNG";
import zs from "../resource/zs.png";

export default class LoginBenefit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() { }

  onOK = () => {
    this.props.onCancel();
  };

  onCancel = () => {
    this.props.onCancel();
  };

  render() {
    return (
      <div>
        <Modal title="为什么要登录？" visible={this.props.show} footer={null}
        onOk={this.onOK} onCancel={this.onCancel} okText={'好'} cancelText={'关闭'}>
          <div style={{ display: "flex", flexDirection: 'column', fontSize: 'large'}}>
            <p>登录后，你可以：</p>
            <ul>
                <li>跨浏览器保存和加载设置</li>
                <li>在留言板用昵称留言</li>
                <li>没了。</li>
            </ul>
          </div>
        </Modal>
      </div>
    );
  }
}
