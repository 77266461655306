import React from 'react';
import * as PriceDataAction from '../action/PriceDataAction';
import * as ItemInfoAction from '../action/ItemInfoAction';
import * as SettingAction from '../action/SettingAction';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Layout, Tree, Input, Spin } from 'antd';
import { PlayCircleTwoTone, StarTwoTone } from '@ant-design/icons';
import * as IDUtils from '../common/IDUtils';
import _ from 'lodash';
import ProfitHelper from '../common/ProfitHelper';

const { Header, Content, Sider } = Layout;

class BlueprintTree extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: '',
      treeData: [],
      allBlueprint: [],
      allBlueprintConfig: [],
      expandedKeys: [],
      selectedKeys: []
    };
  }

  componentDidMount() {
    if (_.isEmpty(this.props.PriceDataReducer.allBlueprint)) {
      // this.props.PriceDataAction.getAllBlueprints();
    } else {
      this.setState({ allBlueprint: this.props.PriceDataReducer.allBlueprint, allBlueprintConfig: this.props.PriceDataReducer.allBlueprintConfig }, () => {
        this.setState({ treeData: this.getTreeData() });
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(nextProps.PriceDataReducer.allBlueprint, this.state.allBlueprint)) {
      this.setState({ allBlueprint: nextProps.PriceDataReducer.allBlueprint }, () => {
        this.setState({ treeData: this.getTreeData() });
      });
    }

    if (!_.isEqual(nextProps.PriceDataReducer.allBlueprintConfig, this.state.allBlueprintConfig)) {
      this.setState({ allBlueprintConfig: nextProps.PriceDataReducer.allBlueprintConfig }, () => {
        this.setState({ treeData: this.getTreeData() });
      });
    }

    if (!_.isEqual(this.props.SettingReducer.favourites, nextProps.SettingReducer.favourites)) {
      setTimeout(() => {
        this.setState({ treeData: this.getTreeData() });
      }, 1);
    }
  }

  isProductCustomized = (productId) => {
    return this.props.PriceDataReducer.allBlueprintConfig.has(productId);
  };

  generateTreeData = (parentList, allBlueprintList) => {
    let result = [];
    let childNameSet = new Set();
    allBlueprintList.forEach((bp) => {
      let isBelongsToParent = true;
      for (let i = 0; i < parentList.length; i++) {
        if (bp.typeIdSde.categories[i] !== parentList[i]) {
          isBelongsToParent = false;
        }
      }
      if (isBelongsToParent) {
        if (bp.typeIdSde.categories[parentList.length] != null) {
          childNameSet.add(bp.typeIdSde.categories[parentList.length]);
        } else {
          result.push({
            blueprintTypeId: bp.blueprintTypeId,
            key: parentList.join('-') + '|' + bp.blueprintTypeId,
            title: bp.typeIdSde.name,
            // switcherIcon: this.isProductCustomized(bp.typeIdSde.typeId) ? (
            //   <StarTwoTone twoToneColor='red' />
            // ) : (
            //     <PlayCircleTwoTone twoToneColor='white' />
            //   ),
          });
        }
      }
    });
    childNameSet.forEach((name) => {
      result.push({
        key: parentList.join('-') + name,
        title: name,
        children: this.generateTreeData([...parentList, name], allBlueprintList),
      });
    });
    return result;
  };

  getTreeData = () => {
    let allBlueprintList = this.state.allBlueprint.filter((bp) => {
      if (bp.typeIdSde == null) {
        return false;
      }
      if (_.isEmpty(bp.typeIdSde.categories)) {
        return false;
      }
      if (!_.isEmpty(this.state.searchKey) && bp.typeIdSde.name.indexOf(this.state.searchKey) < 0) {
        return false;
      }
      return true;
    });

    let result = this.generateTreeData([], allBlueprintList);
    let favouriteTree = this.getFavouriteTree();
    result = [...favouriteTree, ...result];
    result.forEach(r => this.sortTree(r));
    return result;
  };

  sortTree = (node) => {
    if (_.isEmpty(node.children)) {
      return;
    } else {
      if (node.children[0].blueprintTypeId != null) {
        node.children = _.sortBy(node.children, c => c.title);
      } else {
        node.children.forEach(v => this.sortTree(v));
      }
    }
  }

  getFavouriteTree = () => {
    if (!_.isEmpty(this.props.SettingReducer.favourites)) {
      let favouriteBlueprintList = this.props.SettingReducer.favourites.sort().map(blueprintTypeId => {
        let blueprint = ProfitHelper.getBlueprintByTypeId(blueprintTypeId);
        if (blueprint == null) {
          return null;
        }
        blueprint = _.cloneDeep(blueprint);
        blueprint.typeIdSde.categories = ['收藏', blueprint.typeIdSde.categories[0]];
        return blueprint;
      });
      favouriteBlueprintList = _.filter(favouriteBlueprintList, b => {
        if (b == null) {
          return false;
        }
        if (!_.isEmpty(this.state.searchKey) && b.typeIdSde.name.indexOf(this.state.searchKey) < 0) {
          return false;
        }
        return true;
      });
      let favouriteTree = this.generateTreeData([], favouriteBlueprintList);
      return favouriteTree;
    } else {
      return [];
    }
  }

  onSearch = (e) => {
    this.setState({ searchKey: e }, () => {
      let treeData = this.getTreeData();
      let expandedKeys = [];
      if (!_.isEmpty(this.state.searchKey)) {
        expandedKeys = this.getKeys(treeData);
        expandedKeys = expandedKeys.filter(k => isNaN(k));
      }
      this.setState({ treeData, expandedKeys });
    });
  };

  getKeys = (treeData) => {
    let result = treeData.map(t => t.key);
    for (let node of treeData) {
      if (!_.isEmpty(node.children)) {
        result = [...result, ...this.getKeys(node.children)];
      }
    }
    return result;
  }

  selectTreeNode = (e) => {
    if (e[0].indexOf('|') >= 0) {
      let blueprintTypeId = e[0].split('|')[1];
      this.setState({ selectedKeys: [blueprintTypeId] });
      this.props.SettingAction.showNewType(blueprintTypeId);
    } else {
      if (_.find(this.state.expandedKeys, k => k === e[0]) != null) {
        this.setState({ expandedKeys: this.state.expandedKeys.filter(k => k !== e[0]) });
      } else {
        this.setState({ expandedKeys: [...this.state.expandedKeys, ...e] });
      }
    }
  };

  isReady = () => {
    return !_.isEmpty(this.props.PriceDataReducer.allBlueprint) && !_.isEmpty(this.props.PriceDataReducer.allTypeIdSde)
      && !_.isEmpty(this.props.PriceDataReducer.allMarketDump) && !_.isEmpty(this.props.PriceDataReducer.allMarketHistory)
      && !_.isEmpty(this.props.PriceDataReducer.industryIndices);
  }

  onExpand = (e) => {
    console.log(e)
    this.setState({ expandedKeys: e });
  }

  treeNodeRender = (nodeData) => {
    let product = ProfitHelper.getProductByBlueprintId(nodeData.blueprintTypeId);
    if (product == null) {
      return (<div>{nodeData.title}</div>)
    } else {
      return <div style={{display: 'flex'}}><img style={{ width: '25px', height: '25px', marginRight: '5px' }}  nsrc={`https://image.evepc.163.com/Type/${product.typeId}_64.png`} src={`https://images.evetech.net/types/${product.typeId}/icon`}></img>{nodeData.title}</div>;
    }
  }

  render() {
    return (
      <div>
        <Input.Search style={{ marginBottom: 8 }} placeholder='输入物品名称' onSearch={this.onSearch} style={{marginLeft: '5px'}} />
        {!this.isReady() ? (
          <div style={{ textAlign: 'center' }}>
            <Spin size='large' />
          </div>
        ) : (
            <Tree blockNode treeData={this.state.treeData} onSelect={this.selectTreeNode} selectedKeys={this.state.selectedKeys}
              onExpand={(e) => this.onExpand(e)} expandedKeys={this.state.expandedKeys} titleRender={this.treeNodeRender} showIcon />
          )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    PriceDataReducer: state.PriceDataReducer,
    SettingReducer: state.SettingReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    PriceDataAction: bindActionCreators(PriceDataAction, dispatch),
    ItemInfoAction: bindActionCreators(ItemInfoAction, dispatch),
    SettingAction: bindActionCreators(SettingAction, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BlueprintTree);
