import * as ActionTypes from "./ActionTypes";
import ProfitHelper from '../common/ProfitHelper';

export const setArchTier = (newArchTier) => {
    return { type: ActionTypes.SET_ARCH_TIER_SUCCESS, data: newArchTier };
};

export const setArchType = (newArchType) => {
    return { type: ActionTypes.SET_ARCH_TYPE_SUCCESS, data: newArchType };
};

export const setArchSecurity = (newArchSecurity) => {
    return { type: ActionTypes.SET_ARCH_SECURITY_SUCCESS, data: newArchSecurity };
};

export const setReactArchTier = (newArchTier) => {
    return { type: ActionTypes.SET_REACT_ARCH_TIER_SUCCESS, data: newArchTier };
};

export const setReactArchType = (newArchType) => {
    return { type: ActionTypes.SET_REACT_ARCH_TYPE_SUCCESS, data: newArchType };
};

export const setReactArchSecurity = (newArchSecurity) => {
    return { type: ActionTypes.SET_REACT_ARCH_SECURITY_SUCCESS, data: newArchSecurity };
};

export const setManuSystemId = (systemId) => {
    return { type: ActionTypes.SET_MANU_SYSTEM_ID_SUCCESS, data: systemId };
};

export const setReactSystemId = (systemId) => {
    return { type: ActionTypes.SET_REACT_SYSTEM_ID_SUCCESS, data: systemId };
};

export const setTabInfoList = (tabInfoList) => {
    return { type: ActionTypes.SET_TAB_INFO_LIST_SUCCESS, data: tabInfoList };
};

export const setActiveTabKey = (key) => {
    return { type: ActionTypes.SET_ACTIVE_TAB_KEY_SUCCESS, data: key };
};

export const showNewType = (blueprintTypeIdStr) => {
    let blueprintTypeId = Number(blueprintTypeIdStr);
    let inventBaseBlueprint = ProfitHelper.getInventBaseBlueprint(blueprintTypeId);
    let defaultDecoder = ProfitHelper.getDefaultDecoder();
    let blueprint = ProfitHelper.getBlueprintByTypeId(blueprintTypeId);
    let product = ProfitHelper.getProductByBlueprintId(blueprintTypeId);
    let blueprintTypeIdSde = ProfitHelper.getTypeIdSdeById(blueprintTypeId);
    return { type: ActionTypes.SHOW_NEW_TYPE, data: {blueprintTypeId: blueprintTypeId, inventBaseBlueprint, defaultDecoder, blueprint, product, blueprintTypeIdSde} };
};

export const toggleConsiderBlueprintCopyCost = () => {
    return { type: ActionTypes.TOGGLE_CONSIDER_BLUEPRINT_COPY_COST };
};

export const toggleExtractMaterials = () => {
    return { type: ActionTypes.TOGGLE_EXTRACT_MATERIALS };
}

export const toggleFavourite = (blueprintTypeId) => {
    return { type: ActionTypes.TOGGLE_FAVOURITE, data: blueprintTypeId };
}

export const importSettings = (settings) => {
    return { type: ActionTypes.IMPORT_SETTINGS, data: settings };
}

export const setCustomsFee = (customsFee) => {
    return { type: ActionTypes.SET_CUSTOMS_FEE, data: customsFee };
}

export const setCustomManuIndex = (manuIndex) => {
    return { type: ActionTypes.SET_CUSTOM_MANU_INDEX, data: manuIndex };
}

export const setCustomReactIndex = (reactIndex) => {
    return { type: ActionTypes.SET_CUSTOM_REACT_INDEX, data: reactIndex };
}

export const setServer = (server) => {
    return { type: ActionTypes.SET_SERVER, data: server };
}

export const setLanguage = (language) => {
    return { type: ActionTypes.SET_LANGUAGE, data: language };
}

export const setCustomPrice = (typeId, price) => {
    return { type: ActionTypes.SET_CUSTOM_PRICE, data: { typeId, price } };
}

export const setAntdColor = (color) => {
    return { type: ActionTypes.SET_ANTD_COLOR, data: color };
}

export const removeCustomPrice = (typeId) => {
    return { type: ActionTypes.REMOVE_CUSTOM_PRICE, data: typeId };
}

export const setUseCustomPrice = (useCustomPrice) => {
    return { type: ActionTypes.SET_USE_CUSTOM_PRICE, data: useCustomPrice };
}

export const setResourceOff = (resourceOff) => {
    return { type: ActionTypes.SET_RESOURCE_OFF, data: resourceOff };
}

export const setProductOff = (productOff) => {
    return { type: ActionTypes.SET_PRODUCT_OFF, data: productOff };
}

export const setAlwaysBuy = (bid) => {
    return { type: ActionTypes.SET_ALWAYS_BUY_BID, data: bid };
}

export const removeAlwaysBuy = (bid) => {
    return { type: ActionTypes.REMOVE_ALWAYS_BUY_BID, data: bid };
}

export const setAlwaysMake = (bid) => {
    return { type: ActionTypes.SET_ALWAYS_MAKE_BID, data: bid };
}

export const removeAlwaysMake = (bid) => {
    return { type: ActionTypes.REMOVE_ALWAYS_MAKE_BID, data: bid };
}

export const setExtract = (typeId, extract) => {
    return { type: ActionTypes.SET_EXTRACT, data: { typeId, extract } };
}

export const setTransportFee = (fee) => {
    return { type: ActionTypes.SET_TRANSPORT_FEE, data: fee };
}

export const setTransportUnit = (value) => {
    return { type: ActionTypes.SET_TRANSPORT_UNIT, data: value };
}

export const setTransportCeil = (value) => {
    return { type: ActionTypes.SET_TRANSPORT_CEIL, data: value };
}

export const setResourcePriceVendor = (value) => {
    return { type: ActionTypes.SET_RESOURCE_PRICE_VENDOR, data: value };
}

export const setProductPriceVendor = (value) => {
    return { type: ActionTypes.SET_PRODUCT_PRICE_VENDOR, data: value };
}

export const setAntdTheme = (value) => {
    return { type: ActionTypes.SET_ANTD_THEME, data: value };
}

export const setSelfProductedCategories = (value) => {
    return { type: ActionTypes.SET_SELF_PRODUCTED_CATEGORIES, data: value };
}

export const setSettingName = (value) => {
    return { type: ActionTypes.SET_SETTING_NAME, data: value };
}

export const setSettingId = (value) => {
    return { type: ActionTypes.SET_SETTING_ID, data: value };
}

export const setBlueprintSetting = (value) => {
    return { type: ActionTypes.SET_BLUEPRINT_SETTING, data: value };
}

export const setPlanStatus = (value) => {
    return { type: ActionTypes.SET_PLAN_STATUS, data: value };
}

export const removePlanStatus = (value) => {
    return { type: ActionTypes.REMOVE_PLAN_STATUS, data: value };
}

export const resetPlanStatus = (value) => {
    return { type: ActionTypes.RESET_PLAN_STATUS};
}

export const removeBlueprintSetting = (blueprintTypeId) => {
    return { type: ActionTypes.REMOVE_BLUEPRINT_SETTING, data: blueprintTypeId };
}

export const setRefineTaxRate = (value) => {
    return { type: ActionTypes.SET_REFINE_TAX_RATE, data: value };
}

export const setDefaultDecoderId = (value) => {
    return { type: ActionTypes.SET_DEFAULT_DECODER_ID, data: value };
}

export const setDepository = (value) => {
    return { type: ActionTypes.SET_DEPOSITORY, data: value };
}

export const setSelfProductThreshold = (value) => {
    return { type: ActionTypes.SET_SELF_PRODUCT_THRESHOLD, data: value };
}

export const setInventAddition = (value) => {
    return { type: ActionTypes.SET_INVENT_ADDITION, data: value };
}

export const setViewMode = (value) => {
    return { type: ActionTypes.SET_VIEW_MODE, data: value };
}

export const setExpandedRowKeys = (value) => {
    return { type: ActionTypes.SET_EXPANDED_ROWKEYS, data: value };
}