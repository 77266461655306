import * as ActionTypes from './ActionTypes';
import PriceDataApi from '../api/PriceDataApi';
import * as UrlHelper from './UrlHelper';
import ProfitHelper from '../common/ProfitHelper';
import _ from 'lodash';

const host = UrlHelper.getHost();
const hostAndPort = `http://${host}:8080`;

export const getAllBlueprints = () => {
  return function (dispatch, getState) {
    return PriceDataApi.getAllBlueprints(hostAndPort)
      .then((json) => {
        json.forEach(blueprint => {
          blueprint.typeIdSde = ProfitHelper.getTypeIdSdeById(blueprint.typeIdSde.typeId);
        })
        return dispatch({ type: ActionTypes.GET_ALL_BLUEPRINTS_SUCCESS, data: json });
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const getAllTypeMaterials = () => {
  return function (dispatch, getState) {
    return PriceDataApi.getAllTypeMaterials(hostAndPort)
      .then((json) => {
        return dispatch({ type: ActionTypes.GET_ALL_TYPEMATERIALS_SUCCESS, data: json });
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const getAllTypeIdSde = () => {
  return function (dispatch, getState) {
    return PriceDataApi.getAllTypeIdSde(hostAndPort)
      .then((json) => {
        let server = getState().SettingReducer.server;
        if (server === 'tranquility') {
          json = json.map(t => {
            if (!_.isEmpty(t.tranquilityChnName)) {
              t.name = t.tranquilityChnName;
            }
            return t;
          })
        }

        if (getState().SettingReducer.language == 'english') {
          console.log('switching to english');
          for (let typeIdSde of json) {
            if (typeIdSde.engName != null) {
              typeIdSde.chnName = typeIdSde.name;
              typeIdSde.name = typeIdSde.engName;
            }
            if (typeIdSde.engCategories != null) {
              typeIdSde.chnCategories = typeIdSde.categories;
              typeIdSde.categories = typeIdSde.engCategories;
            }
          }
        }
        return dispatch({ type: ActionTypes.GET_ALL_TYPEID_SDE_SUCCESS, data: json });
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const getAllMarketDump = () => {
  return function (dispatch, getState) {
    return PriceDataApi.getAllMarketDump(hostAndPort)
      .then((json) => {
        return dispatch({ type: ActionTypes.GET_ALL_MARKET_DUMP_SUCCESS, data: json });
      })
      .catch((err) => {
        throw err;
      });
  };
};


export const getDealPriceList = () => {
  return function (dispatch, getState) {
    return PriceDataApi.getDealPriceList(hostAndPort)
      .then((json) => {
        return dispatch({ type: ActionTypes.GET_DEAL_PRICE_LIST_SUCCESS, data: json });
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const getAllMarketHistory = () => {
  return function (dispatch, getState) {
    return PriceDataApi.getAllMarketHistory(hostAndPort)
      .then((json) => {
        return dispatch({ type: ActionTypes.GET_ALL_MARKET_HISTORY_SUCCESS, data: json });
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const getIndustryIndices = () => {
  return function (dispatch, getState) {
    return PriceDataApi.getIndustryIndices(hostAndPort)
      .then((json) => {
        return dispatch({ type: ActionTypes.GET_INDUSTTRY_INDICES_SUCCESS, data: json });
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const setMaterialEffect = (newMaterialEffect) => {
  return { type: ActionTypes.SET_MATERIAL_EFFECT_SUCCESS, data: newMaterialEffect };
};

export const setProductPriceSetting = (newSetting) => {
  return { type: ActionTypes.SET_PRODUCT_PRICE_SETTING_SUCCESS, data: newSetting };
};

export const setResourcePriceSetting = (newSetting) => {
  return { type: ActionTypes.SET_RESOURCE_PRICE_SETTING_SUCCESS, data: newSetting };
};

export const setResourceGalaxy = (galaxy) => {
  return { type: ActionTypes.SET_RESOURCE_GALAXY_SUCCESS, data: galaxy };
};

export const setProductGalaxy = (galaxy) => {
  return { type: ActionTypes.SET_PRODUCT_GALAXY_SUCCESS, data: galaxy };
};

export const setMarketFee = (value) => {
  return { type: ActionTypes.SET_MARKET_FEE_SUCCESS, data: value };
}

export const setGalaxyManuCost = (value) => {
  return { type: ActionTypes.SET_GALAXY_MANU_COST_SUCCESS, data: value };
}

export const upsertBlueprintConfig = (productId, config) => {
  return { type: ActionTypes.UPSERT_BLUEPRINT_CONFIG_SUCCESS, data: { productId, config } };
}

export const removeBlueprintConfig = (productId) => {
  return { type: ActionTypes.REMOVE_BLUEPRINT_CONFIG_SUCCESS, data: productId };
}

export const setRankData = (rankData) => {
  return { type: ActionTypes.SET_RANK_DATA, data: rankData };
}

export const setRankCheckedKeys = (keys) => {
  return { type: ActionTypes.SET_RANK_CHECKED_KEYS, data: keys };
}

export const getOrders = (typeId) => {
  return function (dispatch, getState) {
    return PriceDataApi.getOrders(hostAndPort, typeId)
      .then((json) => {
        return dispatch({ type: ActionTypes.GET_ORDERS_SUCCESS, data: { typeId: typeId, orders: json } });
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const getLastOrderDate = () => {
  return function (dispatch, getState) {
    return PriceDataApi.getLastOrderDate(hostAndPort)
      .then((text) => {
        return dispatch({ type: ActionTypes.GET_LAST_ORDER_DATE_SUCCESS, data: text });
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const getPreviousMonthsMarketHistory = (typeId) => {
  return function (dispatch, getState) {
    return PriceDataApi.getPreviousMonthsMarketHistory(hostAndPort, typeId)
      .then((json) => {
        return dispatch({ type: ActionTypes.GET_PREVIOUS_MONTHS_MARKET_HISTORY_SUCCESS, data: json });
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const setSelectedMaterialStats = (stats) => {
  return function (dispatch, getState) {
    let activeTabKey = getState().SettingReducer.activeTabKey;
    return dispatch({ type: ActionTypes.SET_SELECTED_MATERIAL_STATS, data: { activeTabKey, stats } });
  };
}


export const getEstimatedPriceList = () => {
  return function (dispatch, getState) {
    return PriceDataApi.getEstimatedPriceList(hostAndPort)
      .then((json) => {
        return dispatch({ type: ActionTypes.GET_ESTIMATED_PRICE_LIST_SUCCESS, data: json });
      })
      .catch((err) => {
        throw err;
      });
  };
};