import React from 'react';
import * as MessageBoardAction from '../action/MessageBoardAction';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Tooltip, Avatar, List, Divider, Button, message, Modal, Input } from 'antd';
import moment from 'moment';
import _ from 'lodash';
import * as Utils from '../common/Utils';
import ProfitHelper from '../common/ProfitHelper';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';


class MessageBoard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      pageSize: 20,
      pageNum: 0,
      authCode: ''
    };
  }

  componentDidMount() {
    this.reloadMessages();
    this.initRichEditor();
    this.props.MessageBoardAction.getAuthCode();
    this.props.MessageBoardAction.getIP();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.MessageBoardReducer.createMessageFlag > this.props.MessageBoardReducer.createMessageFlag) {
      this.reloadMessages();
    }
  }

  imageUploadHandler = (blobInfo, success) => {
    this.props.MessageBoardAction.uploadAttachment(blobInfo.base64()).then((text => {
      success(`http://www.ceve-industry.cn/attachment/${text}`);
    }));
  };

  initRichEditor = () => {
    window.tinymce.remove();
    window.tinymce.init({
      selector: '#rich-editor',
      width: '100%',
      placeholder: '写留言...',
      height: 300,
      language: 'zh_CN',
      emoticons_images_url: 'http://www.ceve-industry.cn/emotionimg/',
      images_upload_handler: this.imageUploadHandler,
      plugins: [
        'advlist autolink link image lists charmap print preview hr anchor pagebreak',
        'searchreplace wordcount visualblocks code fullscreen insertdatetime media nonbreaking',
        'table emoticons template paste help'
      ],
      toolbar: 'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | ' +
        'bullist numlist outdent indent | link image | print preview media fullscreen | ' +
        'forecolor backcolor emoticons | help',
      menu: {
        favs: { title: 'My Favorites', items: 'code visualaid | searchreplace | emoticons' }
      },
      menubar: 'favs file edit view insert format tools table help',
      content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
    });
  }

  reloadMessages = () => {
    this.props.MessageBoardAction.getMessageCount();
    this.props.MessageBoardAction.getMessage(this.state.pageSize, this.state.pageNum);
  };

  getDataSource = () => {
    let dataSource = [];
    if (this.props.MessageBoardReducer.messageCount == 0 || _.isEmpty(this.props.MessageBoardReducer.messagePage)) {
      return dataSource;
    } else {
      dataSource = new Array(this.props.MessageBoardReducer.messageCount);
      for (let i = 0; i < dataSource.length; i++) {
        dataSource[i] = {};
      }
      for (let i = 0; i < this.props.MessageBoardReducer.messagePage.pageData.length; i++) {
        let index = this.props.MessageBoardReducer.messagePage.pageNum * this.state.pageSize + i;
        dataSource[index] = this.props.MessageBoardReducer.messagePage.pageData[i];
      }
    }
    dataSource = dataSource.map((v) => {
      let author = _.isEmpty(v.senderName) ? `克隆飞行员${Utils.getHashCode(v.sender)}` : v.senderName;
      return {
        sender: v.sender,
        author: author,
        messageId: v.id,
        content: <div style={{ color: this.props.SettingReducer.antdTheme === 0 ? 'black' : 'lightgray' }} dangerouslySetInnerHTML={{ __html: v.message }} ></div>,
        title: (
          <div style={{ display: 'flex' }}>
            <span style={{ marginRight: '10px' }}>{author}</span>
            <span>{moment(v.createAt).format('YYYY-MM-DD HH:mm:ss')}</span>
            <div style={{ marginLeft: '10px', position: 'relative', top: '-2px', display: (this.props.MessageBoardReducer.ip === v.sender || v.senderName === this.props.QQUserReducer.qqUser.name) ? '' : 'none' }}>
              <Tooltip title='删除我发表的留言'>
                <Button type="default" icon={<DeleteOutlined />} style={{}} size="small" onClick={() => this.removeMessage(v.id)} />
              </Tooltip>
            </div>
          </div>
        ),
      };
    });
    return dataSource;
  };

  onPageChange = (newPage) => {
    this.setState({ pageNum: newPage - 1 }, () => {
      this.props.MessageBoardAction.getMessage(this.state.pageSize, this.state.pageNum);
    });
  };

  onTinyMce = () => {
    let content = window.tinymce.activeEditor.getContent();
    if (_.isEmpty(content)) {
      return;
    }

    let loginStatus = window.QC.Login.check();
    if (loginStatus == true) {
      window.QC.Login.getMe((openId, accessToken) => {
        this.props.MessageBoardAction.createMessage(content, this.props.MessageBoardReducer.authCode.id, this.state.authCode, openId).then(value => {
          if (_.isEmpty(value.data)) {
            window.tinymce.activeEditor.setContent('');
            message.info('留言发表成功！');
          } else {
            message.error(value.data);
          }
          this.props.MessageBoardAction.getAuthCode();
        });
      })
    } else {
      this.props.MessageBoardAction.createMessage(content, this.props.MessageBoardReducer.authCode.id, this.state.authCode, 'anonymous').then(value => {
        if (_.isEmpty(value.data)) {
          window.tinymce.activeEditor.setContent('');
          message.info('留言发表成功！');
        } else {
          message.error(value.data);
        }
        this.props.MessageBoardAction.getAuthCode();
      });
    }


  };

  showWarning = (title, message) => {
    return new Promise((resolve, reject) => {
      Modal.confirm({
        title: title,
        icon: <ExclamationCircleOutlined />,
        content: message,
        okText: '是',
        okType: 'danger',
        cancelText: '否',
        onOk: () => {
          resolve();
        }
        ,
        onCancel: () => {
          reject();
        },
      });
    })
  };

  removeMessage = (messageId) => {
    this.showWarning('删除留言', '确定要删除这段留言吗？').then(() => {
      let loginStatus = window.QC.Login.check();
      if (loginStatus == true) {
        window.QC.Login.getMe((openId, accessToken) => {
          this.props.MessageBoardAction.removeMessage(messageId, openId);
        });
      } else {
        this.props.MessageBoardAction.removeMessage(messageId, 'anonymous');
      }

      message.info('留言已删除。')
    }).catch(() => {
      message.info('中止删除留言。')
    });
  }

  onAuthCodeChange = (e) => {
    this.setState({ authCode: e.target.value });
  }

  refreshAuthCode = () => {
    this.props.MessageBoardAction.getAuthCode();
  }

  getAuthorImg = (item) => {
    switch (item.sender) {
      case 'D5CF418ED58FB0A800A0B4852B971217':
        return 'touxiang/touxiang2.png';
      case 'D861D803906FA10F15BCBF5D4BFA594F':
        return 'touxiang/touxiang1.png';
      default:
        return `https://xsgames.co/randomusers/avatar.php?g=pixel&key=${item.author}`;
    }
  }

  render() {
    return (
      <div style={{
        position: 'absolute', paddingLeft: '10%', paddingRight: '10%', width: '100%',
        marginBottom: '30px', background: this.props.SettingReducer.antdTheme === 0 ? '' : 'black',
        color: this.props.SettingReducer.antdTheme === 0 ? '' : 'white'
      }}>
        {/* <Vote show={true} /> */}
        <List
          className='comment-list'
          itemLayout='horizontal'
          pagination={{
            onChange: this.onPageChange,
            pageSize: this.state.pageSize,
          }}
          dataSource={this.getDataSource()}
          renderItem={(item, index) => {
            return (
              <div>
                <List.Item>
                  <List.Item.Meta
                    avatar={<Avatar src={this.getAuthorImg(item)} />}
                    title={item.title}
                    description={item.content}
                  >
                  </List.Item.Meta>

                </List.Item>
              </div>
            )
          }}
        />
        <Divider style={{ background: ProfitHelper.getTextColor() }} />
        <textarea id="rich-editor">

        </textarea>

        <div style={{ display: 'flex', marginBottom: '70px', marginTop: '20px' }}>
          <Tooltip title='换一个验证码' placement='top'>
            <div style={{ width: '60px', height: '30px', overflow: 'hidden', marginRight: '20px', cursor: 'pointer' }} onClick={this.refreshAuthCode}>
              <img style={{ width: '100%' }} src={`data:image/png;base64,${this.props.MessageBoardReducer.authCode.base64}`} />
            </div>
          </Tooltip>
          <div style={{ width: '100px', marginRight: '20px' }}>
            <Input placeholder="验证码" onChange={this.onAuthCodeChange} value={this.state.authCode} />
          </div>
          <div>
            <Button type='primary' onClick={this.onTinyMce} disabled={_.isEmpty(this.state.authCode)} >
              发表
            </Button>
          </div>
        </div>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    MessageBoardReducer: state.MessageBoardReducer,
    SettingReducer: state.SettingReducer,
    QQUserReducer: state.QQUserReducer
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    MessageBoardAction: bindActionCreators(MessageBoardAction, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageBoard);
